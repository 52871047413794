import React, { useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  InputGroup,
  Center,
  Heading,
  Input,
  Icon,
  InputRightElement,
  VStack,
  Box,
  Text,
  Divider,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { IoSearchSharp } from 'react-icons/io5';
import Item, { ItemInfoProps } from '~/pages/Menu/components/CategoryItemList/Item';
import { categoryItemListSelector } from '~/recoil/selectors/menuItemList';
import isSearchItemModalOpenSelector from '~/recoil/selectors/isSearchItemModalOpen';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import { useRecoilValue, useRecoilState } from 'recoil';

import { debounce } from 'lodash';

import SearchImg from '~/assets/images/Search.png';

import { useTranslation } from 'react-i18next';

// 搜尋餐點彈窗，搜尋欄固定上方，下方按照分類顯示搜尋結果列表
export default function SearchItemModal() {
  const navigate = useNavigate();

  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const categoryItemList = useRecoilValue(categoryItemListSelector);

  const [isSearchItemModalOpen, setIsSearchItemModalOpen] = useRecoilState(
    isSearchItemModalOpenSelector,
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [searchedItems, setSearchedItems] = useState<Array<ItemInfoProps>>([]);

  const { t } = useTranslation();

  // 搜尋過濾
  const handleSearchItems = useCallback(
    debounce((searchInput: string) => {
      const itemList = categoryItemList.reduce(
        (acc, { items }) => acc.concat(items),
        [] as Array<ItemInfoProps>,
      );

      setSearchedItems(() => {
        return searchInput.length === 0
          ? []
          : itemList.filter(
              ({ itemName, description }) =>
                itemName.includes(searchInput) || description.includes(searchInput),
            );
      });
    }, 700),
    [],
  );

  const handleCloseModal = () => {
    setIsSearchItemModalOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleSearchItems(e.target.value);
  };

  const handleNavigateItem = (itemId: string) => {
    handleCloseModal();
    navigate(`item/${itemId}`);
  };

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={isSearchItemModalOpen}
        onClose={handleCloseModal}
        size="lg"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent m={0} minH="90%">
          <ModalCloseButton />
          <ModalHeader>
            <Box mb={5}>
              <Center pt={4} pb={3}>
                <Heading fontSize="xl">{t('search.title')}</Heading>
              </Center>
              <Divider />
            </Box>
            <InputGroup>
              <Input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder={t('search.placeholder')}
              />
              <InputRightElement>
                <Icon as={IoSearchSharp} boxSize="1.25rem" />
              </InputRightElement>
            </InputGroup>
          </ModalHeader>

          <ModalBody>
            {inputValue.length > 0 && searchedItems.length > 0 && (
              <VStack align="stretch" spacing={6}>
                {searchedItems.map(({ itemId, itemName, description, price, imageUrl }, index) => {
                  return (
                    <Item
                      key={`${itemId}_${index}`}
                      itemId={itemId}
                      itemName={itemName}
                      description={description}
                      price={price}
                      imageUrl={imageUrl}
                      onNavigate={() => handleNavigateItem(itemId)}
                    />
                  );
                })}
              </VStack>
            )}
            {inputValue.length === 0 && searchedItems.length === 0 && (
              <VStack align="center" spacing={4}>
                <Text fontSize="sm">{t('search.item')}</Text>
                <Image src={SearchImg}></Image>
              </VStack>
            )}
            {inputValue.length !== 0 && searchedItems.length === 0 && (
              <VStack align="center" spacing={4}>
                <Text fontSize="sm">{t('search.notFind')}</Text>
                <Image src={SearchImg}></Image>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Drawer isOpen={isSearchItemModalOpen} onClose={handleCloseModal} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Box mb={5}>
            <Center pt={4} pb={3}>
              <Heading fontSize="xl">{t('search.title')}</Heading>
            </Center>
            <Divider />
          </Box>
          <InputGroup>
            <Input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder={t('search.placeholder')}
            />
            <InputRightElement>
              <Icon as={IoSearchSharp} boxSize="1.25rem" />
            </InputRightElement>
          </InputGroup>
        </DrawerHeader>
        <DrawerBody>
          {inputValue.length > 0 && searchedItems.length > 0 && (
            <VStack align="stretch" spacing={6}>
              {searchedItems.map(({ itemId, itemName, description, price, imageUrl }, index) => {
                return (
                  <Item
                    key={`${itemId}_${index}`}
                    itemId={itemId}
                    itemName={itemName}
                    description={description}
                    price={price}
                    imageUrl={imageUrl}
                    onNavigate={() => handleNavigateItem(itemId)}
                  />
                );
              })}
            </VStack>
          )}
          {inputValue.length === 0 && searchedItems.length === 0 && (
            <VStack align="center" spacing={4}>
              <Text fontSize="sm">{t('search.item')}</Text>
              <Image src={SearchImg}></Image>
            </VStack>
          )}
          {inputValue.length !== 0 && searchedItems.length === 0 && (
            <VStack align="center" spacing={4}>
              <Text fontSize="sm">{t('search.notFind')}</Text>
              <Image src={SearchImg}></Image>
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
