import { atom } from 'recoil';

const groupMemberInfo = atom<{
  memberId: string;
  memberName: string;
}>({
  key: 'groupMemberInfo',
  default: {
    memberId: window.localStorage.getItem('groupMemberId') || '',
    memberName: window.localStorage.getItem('groupMemberName') || '',
  },
});

export default groupMemberInfo;
