import { Divider, VStack, Icon, Button, HStack, Text } from '@chakra-ui/react';

import {
  OrderTypeBar,
  CheckoutBar,
  PickupSettingInfoSection,
  DeliverySettingInfoSection,
  DeliveryDiscountProgress,
  CouponInfoBar,
  NoItemNotice,
} from './components';
import TotalPriceSection from '~/components/TotalPriceSection';
import GroupMemberProductItems from '~/components/GroupMemberProductItems';
import SectionContainer from '~/container/SectionContainer';
import LoadingModal from '~/components/LoadingModal';

import { useNavigate, useParams } from 'react-router-dom';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import groupMemberInfoAtom from '~/recoil/atom/groupMemberInfo';
import groupCartSelector from '~/recoil/selectors/groupCart';
import groupCartSettlementSelector from '~/recoil/selectors/groupCartSettlement';
import { useRecoilValue } from 'recoil';

import useGroupCartItems from '~/hooks/useGroupCartItems';

import convertOrderType from '~/utils/convertOrderType';

import { IoRestaurantOutline, IoPeopleOutline } from 'react-icons/io5';

import { CartStatus, MealPickUpType } from '~/types';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';
import { useTranslation } from 'react-i18next';

export default function MemberDisplayContent() {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);

  const store = useRecoilValue(storeLocaleSelector);

  const groupCart = useRecoilValue(groupCartSelector);

  const groupMemberInfo = useRecoilValue(groupMemberInfoAtom);

  const { subtotal, shippingFee, freightSubsidy, total, discount } = useRecoilValue(
    groupCartSettlementSelector,
  );

  const { handleDeleteGroupItem } = useGroupCartItems();

  const { t } = useTranslation();

  const { orderType, location, preOrderDate, groupItems, status, coupon } = groupCart;

  const [mealPickupType, pickMealTime] = convertOrderType(orderType);

  const handleGroupOrderButtonOnClick = () => {
    navigate(`/${storeId}/group/${groupId}/groupOrder`);
  };

  if (isLargerThan768) {
    return (
      <VStack spacing={5} py={4} align="stretch">
        <OrderTypeBar mealPickupType={mealPickupType} />

        <HStack align="flex-start" spacing={4}>
          <VStack flex="1" spacing={5} align="stretch">
            {mealPickupType === MealPickUpType.PICKUP && store && (
              <PickupSettingInfoSection
                pickMealTime={pickMealTime}
                preOrderDate={preOrderDate}
                prepareMinutes={store?.orderPrepareMinutes || 30}
                allowOrder={store?.allowOrders || false}
                preorderSetting={store.preOrderSetting}
              />
            )}

            {mealPickupType === MealPickUpType.DELIVERY && store && (
              <DeliverySettingInfoSection
                address={location.address}
                pickMealTime={pickMealTime}
                preOrderDate={preOrderDate}
                prepareMinutes={store?.orderPrepareMinutes || 30}
                allowOrder={store?.allowOrders || false}
                preorderSetting={store.preOrderSetting}
                distanceLimit={store?.delivery.maxDistance || 0}
                allowDisplayError={false}
              />
            )}
            <Divider />
            <SectionContainer
              title={t('store.order.items')}
              icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
              rightHandComponent={
                <Button
                  size="sm"
                  iconSpacing={1}
                  leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
                  onClick={handleGroupOrderButtonOnClick}
                >
                  {t('store.order.group')}
                </Button>
              }
            >
              {groupItems.length > 0 ? (
                <VStack spacing={3} align="stretch">
                  {groupItems.map((groupItem) => {
                    return (
                      <GroupMemberProductItems
                        key={groupItem.memberId}
                        groupItem={groupItem}
                        isSelfItems={groupMemberInfo.memberId === groupItem.memberId}
                        allowEdit={groupMemberInfo.memberId === groupItem.memberId}
                        onDeleteGroupItems={handleDeleteGroupItem}
                      />
                    );
                  })}
                </VStack>
              ) : (
                <NoItemNotice onNavigate={() => navigate(`/${storeId}/group/${groupId}`)} />
              )}
            </SectionContainer>

            {mealPickupType === MealPickUpType.DELIVERY && (
              <DeliveryDiscountProgress
                orderPrice={subtotal - discount}
                costShare={store?.delivery.costShare || 0}
                costShareLimit={store?.delivery.costShareLimit || 0}
                freeLimit={store?.delivery.freeLimit || 0}
                onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
              />
            )}
          </VStack>

          <VStack
            flex="1"
            spacing={0}
            borderColor="wsGray.400"
            borderWidth="1px"
            align="stretch"
            pos="sticky"
            top={5}
          >
            {coupon && (
              <VStack align="stretch">
                <Text fontSize="xs" fontWeight="bold">
                  {t('store.order.couponApplied')}
                </Text>
                <CouponInfoBar
                  coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
                />
              </VStack>
            )}

            <VStack align="stretch" p={4} spacing={4}>
              <TotalPriceSection
                orderType={orderType}
                subtotal={subtotal}
                shippingFee={shippingFee}
                freightSubsidy={freightSubsidy}
                total={total}
                discount={discount}
              />
            </VStack>
            <CheckoutBar total={total} title={t('group.memberCheckout')} />
          </VStack>
        </HStack>
      </VStack>
    );
  }

  return (
    <>
      <VStack py={5} px={4} spacing={5} align="stretch">
        <OrderTypeBar mealPickupType={mealPickupType} />

        {mealPickupType === MealPickUpType.PICKUP && store && (
          <PickupSettingInfoSection
            pickMealTime={pickMealTime}
            preOrderDate={preOrderDate}
            prepareMinutes={store?.orderPrepareMinutes || 30}
            allowOrder={store?.allowOrders || false}
            preorderSetting={store.preOrderSetting}
          />
        )}

        {mealPickupType === MealPickUpType.DELIVERY && store && (
          <DeliverySettingInfoSection
            address={location.address}
            pickMealTime={pickMealTime}
            preOrderDate={preOrderDate}
            prepareMinutes={store?.orderPrepareMinutes || 30}
            allowOrder={store?.allowOrders || false}
            preorderSetting={store.preOrderSetting}
            distanceLimit={store?.delivery.maxDistance || 0}
          />
        )}

        <Divider />

        <SectionContainer
          title={t('store.order.items')}
          icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
          rightHandComponent={
            <Button
              size="sm"
              iconSpacing={1}
              leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
              onClick={handleGroupOrderButtonOnClick}
            >
              {t('store.order.group')}
            </Button>
          }
        >
          {groupItems.length > 0 ? (
            <VStack spacing={3} align="stretch">
              {groupItems.map((groupItem) => {
                return (
                  <GroupMemberProductItems
                    key={groupItem.memberId}
                    groupItem={groupItem}
                    isSelfItems={groupMemberInfo.memberId === groupItem.memberId}
                    allowEdit={groupMemberInfo.memberId === groupItem.memberId}
                    onDeleteGroupItems={handleDeleteGroupItem}
                  />
                );
              })}
            </VStack>
          ) : (
            <NoItemNotice onNavigate={() => navigate(`/${storeId}/group/${groupId}`)} />
          )}
        </SectionContainer>

        {mealPickupType === MealPickUpType.DELIVERY && (
          <DeliveryDiscountProgress
            orderPrice={subtotal - discount}
            costShare={store?.delivery.costShare || 0}
            costShareLimit={store?.delivery.costShareLimit || 0}
            freeLimit={store?.delivery.freeLimit || 0}
            onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
          />
        )}

        <Divider />

        {coupon && (
          <VStack align="stretch">
            <Text fontSize="xs" fontWeight="bold">
              {t('store.order.couponApplied')}
            </Text>
            <CouponInfoBar
              coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
            />
          </VStack>
        )}

        <TotalPriceSection
          orderType={orderType}
          subtotal={subtotal}
          shippingFee={shippingFee}
          freightSubsidy={freightSubsidy}
          total={total}
          discount={discount}
        />

        <CheckoutBar total={total} title={t('group.memberCheckout')} />
      </VStack>

      <LoadingModal isVisible={status === CartStatus.ORDERING} />
    </>
  );
}
