import { VStack, HStack, Text } from '@chakra-ui/react';

import { Trans } from 'react-i18next';

type DeliveryProps = {
  deliveryShare: number;
  costShare: number;
  freeLimit: number;
};

// 店家運費設定
export default function Delivery({ deliveryShare, costShare, freeLimit }: DeliveryProps) {
  return (
    <HStack>
      <VStack spacing={2} align="flex-start">
        {costShare ? (
          <Trans i18nKey="store.shippingDiscountNote" values={{ deliveryShare, costShare }}>
            <HStack spacing={1}>
              <Text fontSize="sm"></Text>
              <Text fontSize="sm" fontWeight="bold"></Text>
              <Text fontSize="sm"></Text>
              <Text fontSize="sm" fontWeight="bold"></Text>
              <Text fontSize="sm"></Text>
            </HStack>
          </Trans>
        ) : null}

        {freeLimit ? (
          <Trans i18nKey="store.freeShipping" values={{ freeLimit }}>
            <HStack spacing={1}>
              <Text fontSize="sm"></Text>
              <Text fontSize="sm" fontWeight="bold"></Text>
              <Text fontSize="sm"></Text>
            </HStack>
          </Trans>
        ) : null}
      </VStack>
    </HStack>
  );
}
