import { useReducer, useState } from 'react';
import { auth } from '~/firebase/index';
import firebase from 'firebase';

import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/max';
import checkValidPhoneNumber from '~/utils/checkValidPhoneNumber';
import useTimer from '~/hooks/useTimer';

import { useToast } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;
  }
}

type LoginErrors = {
  name: string | null;
  phoneNumber: string | null;
  verificationCode: string | null;
};

type LoginState = {
  name: string;
  phoneNumber: string;
  verificationCode: string;
  errors: LoginErrors;
};

enum LoginStateAction {
  SET_NAME = 'set_name',
  SET_PHONE_NUMBER = 'set_phoneNumber',
  SET_VERIFICATION_CODE = 'set_verificationCode',
  SET_ERRORS = 'set_errors',
  CLEAR_ERRORS = 'clear_errors',
  CLEAR_ALL = 'clear_all',
}

type LoginActionStates =
  | {
      type: LoginStateAction.SET_NAME;
      payload: string;
    }
  | {
      type: LoginStateAction.SET_PHONE_NUMBER;
      payload: string;
    }
  | {
      type: LoginStateAction.SET_VERIFICATION_CODE;
      payload: string;
    }
  | {
      type: LoginStateAction.SET_ERRORS;
      payload: Partial<LoginErrors>;
    }
  | {
      type: LoginStateAction.CLEAR_ERRORS;
    }
  | {
      type: LoginStateAction.CLEAR_ALL;
    };

function reducer(state: LoginState, action: LoginActionStates): LoginState {
  switch (action.type) {
    case LoginStateAction.SET_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case LoginStateAction.SET_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumber: action.payload,
        errors: {
          ...state.errors,
          phoneNumber: null,
        },
      };
    }
    case LoginStateAction.SET_VERIFICATION_CODE: {
      return {
        ...state,
        verificationCode: action.payload,
        errors: {
          ...state.errors,
          verificationCode: null,
        },
      };
    }
    case LoginStateAction.SET_ERRORS: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    }
    case LoginStateAction.CLEAR_ERRORS: {
      return {
        ...state,
        errors: {
          name: null,
          phoneNumber: null,
          verificationCode: null,
        },
      };
    }
    case LoginStateAction.CLEAR_ALL: {
      return {
        name: '',
        phoneNumber: '',
        verificationCode: '',
        errors: {
          name: null,
          phoneNumber: null,
          verificationCode: null,
        },
      };
    }
    default: {
      throw Error('no match action type');
    }
  }
}

const initState: LoginState = {
  name: '',
  phoneNumber: '',
  verificationCode: '',
  errors: {
    name: null,
    phoneNumber: null,
    verificationCode: null,
  },
};

export default function useLogin() {
  const [loginState, dispatch] = useReducer(reducer, initState);

  const [isVerifying, setVerifying] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const toast = useToast();

  const { t } = useTranslation();

  const [verificationCredential, setVerificationCredential] =
    useState<firebase.auth.ConfirmationResult | null>(null);

  const [verifyPhoneNumberCredential, setVerifyPhoneNumberCredential] = useState('');
  const { isTimerStart, restTime, handleStart } = useTimer(60);

  const handleUpdateName = (name: string) => {
    dispatch({ type: LoginStateAction.SET_NAME, payload: name });
    dispatch({
      type: LoginStateAction.SET_ERRORS,
      payload: {
        name: null,
      },
    });
  };

  const handleUpdatePhoneNumber = (phoneNumber: string) => {
    dispatch({ type: LoginStateAction.SET_PHONE_NUMBER, payload: phoneNumber });
    dispatch({
      type: LoginStateAction.SET_ERRORS,
      payload: {
        phoneNumber: null,
      },
    });
  };

  const handleModifierPhoneNumber = async () => {
    const { phoneNumber } = loginState;

    const { formattedPhoneNumber, error } = checkValidPhoneNumber(phoneNumber);

    if (error) {
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: {
          phoneNumber: error,
        },
      });
      return;
    }

    if (isTimerStart) {
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: { phoneNumber: t('loginForm.error.waitingCode') },
      });
      return;
    }

    window.recaptchaVerifier =
      window.recaptchaVerifier ||
      new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });

    try {
      await window.recaptchaVerifier.render();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      const credentialId = await phoneAuthProvider.verifyPhoneNumber(
        formattedPhoneNumber,
        window.recaptchaVerifier,
      );

      setVerifyPhoneNumberCredential(credentialId);
      setIsConfirmed(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleVerifyPhoneCode = async (code: string) => {
    if (code.length <= 6) {
      dispatch({ type: LoginStateAction.SET_VERIFICATION_CODE, payload: code });
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: {
          verificationCode: null,
        },
      });
    }

    if (code.length === 6 && verifyPhoneNumberCredential !== '') {
      try {
        setVerifying(true);
        const credential = firebase.auth.PhoneAuthProvider.credential(
          verifyPhoneNumberCredential,
          code,
        );
        await auth.currentUser?.updatePhoneNumber(credential);
        if (credential !== null) {
          setIsConfirmed(true);
          dispatch({ type: LoginStateAction.SET_VERIFICATION_CODE, payload: '' });
        }
      } catch (err: any) {
        if (err.code === 'auth/invalid-verification-code') {
          dispatch({
            type: LoginStateAction.SET_ERRORS,
            payload: {
              verificationCode: t('loginForm.error.verificationCode'),
            },
          });
        }
      } finally {
        setVerifying(false);
      }
    }
  };

  const handleSendVerificationCode = async () => {
    const { phoneNumber, name } = loginState;

    const { formattedPhoneNumber, error } = checkValidPhoneNumber(phoneNumber);

    if (error) {
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: {
          phoneNumber: error,
        },
      });
      return;
    }

    if (name.length <= 1) {
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: {
          name: 'loginForm.error.missingName',
        },
      });
      return;
    }

    if (isTimerStart) {
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: { phoneNumber: 'loginForm.error.waitingCode' },
      });
      return;
    }

    handleStart();

    window.recaptchaVerifier =
      window.recaptchaVerifier ||
      new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });

    try {
      dispatch({
        type: LoginStateAction.CLEAR_ERRORS,
      });
      await window.recaptchaVerifier.render();

      const credential = await auth.signInWithPhoneNumber(
        formattedPhoneNumber,
        window.recaptchaVerifier,
      );

      setVerificationCredential(credential);
    } catch (err: any) {
      console.error(err);

      if (err.code === 'auth/too-many-requests') {
        dispatch({
          type: LoginStateAction.SET_ERRORS,
          payload: { phoneNumber: 'loginForm.error.highFrequency' },
        });
        return;
      }
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: { phoneNumber: 'loginForm.error.unknown' },
      });
    }
  };

  const handleUpdateVerificationCode = async (code: string) => {
    if (code.length <= 6) {
      dispatch({ type: LoginStateAction.SET_VERIFICATION_CODE, payload: code });
      dispatch({
        type: LoginStateAction.SET_ERRORS,
        payload: {
          verificationCode: null,
        },
      });
    }

    if (code.length === 6 && verificationCredential) {
      try {
        setVerifying(true);
        const user = await verificationCredential.confirm(code);

        // update user name
        await user.user?.updateProfile({ displayName: loginState.name });
      } catch (err: any) {
        if (err.code === 'auth/invalid-verification-code') {
          dispatch({
            type: LoginStateAction.SET_ERRORS,
            payload: {
              verificationCode: 'loginForm.error.verificationCode',
            },
          });
        }
      } finally {
        setVerifying(false);
      }
    }
  };

  const handleClear = () => {
    dispatch({ type: LoginStateAction.CLEAR_ALL });
    setVerifying(false);
    setVerificationCredential(null);
  };

  const handleCancelModified = () => {
    dispatch({ type: LoginStateAction.SET_VERIFICATION_CODE, payload: '' });
    setVerifyPhoneNumberCredential('');
    setVerifying(false);
    setVerificationCredential(null);
  };

  return {
    loginState,
    isVerifying,
    isConfirmed,
    verificationCredential,
    verifyPhoneNumberCredential,
    isTimerStart,
    restTime,
    handleSendVerificationCode,
    handleUpdateName,
    handleUpdatePhoneNumber,
    handleUpdateVerificationCode,
    handleModifierPhoneNumber,
    handleVerifyPhoneCode,
    handleClear,
    handleCancelModified,
  };
}
