import { atom } from 'recoil';
import { StoreWithId } from '~/types';

const store = atom<StoreWithId | null>({
  key: 'store',
  default: null,
  dangerouslyAllowMutability: true,
});

export default store;
