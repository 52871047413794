import { VStack, FormControl, FormLabel, InputGroup } from '@chakra-ui/react';
import DisplayInput from './DisplayInput';

import { useTranslation } from 'react-i18next';

type ContactInfoProps = {
  phoneNumber: string;
  name: string;
};

// 聯絡資訊顯示
export default function ContactInfo({ name, phoneNumber }: ContactInfoProps) {
  const { t } = useTranslation();

  return (
    <VStack align="stretch" spacing={4}>
      <FormControl>
        <FormLabel fontWeight="normal">{t('name')}</FormLabel>
        <InputGroup>
          <DisplayInput isReadOnly value={name} fontWeight="normal" />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontWeight="normal">{t('phone')}</FormLabel>
        <InputGroup>
          <DisplayInput isReadOnly value={phoneNumber} fontWeight="normal" />
        </InputGroup>
      </FormControl>
    </VStack>
  );
}
