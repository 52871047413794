import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function Pickup(props: IconProps) {
  return (
    <Icon
      viewBox='0 0 25 23'
      fill='transparent'
      stroke='currentColor'
      {...props}
    >
      <rect
        x='1.98497'
        y='16.5461'
        width='3.84925'
        height='7.33718'
        transform='rotate(-42.3328 1.98497 16.5461)'
        stroke='currentcolor'
        strokeWidth='1.4'
      />
      <path
        d='M5.76562 14.4486C6.49571 12.8842 8.76939 10.0681 12.0235 11.3197C15.2776 12.5713 18.9071 10.6949 18.9071 12.8852C18.9071 13.8238 18.5942 14.4496 17.3426 14.4496C16.0911 14.4496 14.0051 14.4493 12.9622 14.4496'
        stroke='currentcolor'
        strokeWidth='1.4'
      />
      <path
        d='M18.9059 13.3431C19.5317 13.0302 20.9084 12.1548 21.4091 11.1535C21.9097 10.1523 23.1535 10.2142 23.5994 10.8393C24.1209 11.5696 24.0737 12.3591 22.0349 14.9076C20.7832 16.4721 19.9001 17.4107 18.9059 17.4107L11.3965 17.7236C10.6664 17.8279 9.83203 18.08 9.83203 18.8309'
        stroke='currentcolor'
        strokeWidth='1.4'
      />
      <path
        d='M9.83203 11.0086V5.125C9.83203 4.57272 10.2797 4.125 10.832 4.125H17.9059C18.4582 4.125 18.9059 4.57272 18.9059 5.125V12.5731'
        stroke='currentcolor'
        strokeWidth='1.4'
      />
      <path
        d='M11.6714 4.55753C11.6714 3.13296 11.9473 0.996094 14.4288 0.996094C16.9103 0.996094 17.0135 3.32579 17.0135 4.55753'
        stroke='currentcolor'
        strokeWidth='1.4'
      />
    </Icon>
  );
}
