import axios from 'axios';
import { PaymentType, WsOrderStatus } from '~/types';

const baseUrl = process.env.REACT_APP_CHECKOUT_BASEURL;

export default async function checkoutOrder(
  storeId: string,
  authToken: string,
  cartId: string,
): Promise<{
  orderId: string;
  paymentType: PaymentType;
  status: WsOrderStatus;
  isLargeOrder: boolean;
}> {
  const response = await axios({
    method: 'put',
    url: `${baseUrl}/${storeId}`,
    data: {
      cartId,
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data;
}
