import { Icon, HStack, Text, Radio, RadioGroup, VStack, Box, Flex } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { IoCreateOutline } from 'react-icons/io5';
import { GoPlus } from 'react-icons/go';

import userLocationSelector from '~/recoil/selectors/userLocation';

import { Location } from '~/types';

import { useTranslation } from 'react-i18next';

type AddressListProps = {
  userLocationList: Location[] | null;
  selectedUserLocationIndex?: number;
  onAddressSelect: (index: number) => void;
  onAddressEdit: (index?: number) => void;
};

// 儲存地址列表
export default function AddressList({
  userLocationList,
  selectedUserLocationIndex,
  onAddressSelect,
  onAddressEdit,
}: AddressListProps) {
  const { t } = useTranslation();
  const userLocation = useRecoilValue(userLocationSelector);
  const handleRadioChange = (value: string) => {
    onAddressSelect(Number(value));
  };

  return (
    <RadioGroup onChange={handleRadioChange} value={selectedUserLocationIndex} mt={2}>
      <VStack align="stretch" spacing={3}>
        {userLocationList?.map((location, index) => {
          return (
            <VStack key={`${location.address}_${index}`} spacing={1} align="stretch">
              <Radio value={index}>
                <Text fontSize="sm">{t('address.addressRecord', { index: index + 1 })}</Text>
              </Radio>
              <HStack justify="space-between" spacing={3} align="flex-start">
                <Text fontSize="sm">{location.address}</Text>
                <Icon
                  onClick={() => onAddressEdit(index)}
                  as={IoCreateOutline}
                  boxSize="1.125rem"
                />
              </HStack>
              <Text fontSize="xs" color="wsGray.500">
                {t('address.floor')}：{location.floor}
              </Text>
              <Text fontSize="xs" color="wsGray.500">
                {t('address.note')}：{location.note}
              </Text>
            </VStack>
          );
        })}
        {(!userLocation || userLocation.length < 2) && (
          <Flex justify="flex-end">
            <HStack onClick={() => onAddressEdit()} cursor="pointer">
              <Icon as={GoPlus} boxSize=".8rem" />
              <Text fontSize="sm" fontWeight="bold">
                {t('address.newAddress')}
              </Text>
            </HStack>
          </Flex>
        )}
      </VStack>
    </RadioGroup>
  );
}
