import { VStack, Text, Icon, Box, HStack, Divider, Link } from '@chakra-ui/react';
import { HiOutlineChat } from 'react-icons/hi';
import { IoPeopleOutline, IoStopwatchOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

type GroupOrderMemberNoticeProps = {
  leader: string;
  notice?: string; // 團長的話
  limitTime?: string; // 限制團購時間
  onOpen: () => void;
};

// 給團員顯示的揪團基本資料以及選擇是否離開揪團，放置在菜單的上方
export default function GroupOrderMemberNotice({
  leader,
  notice,
  limitTime,
  onOpen,
}: GroupOrderMemberNoticeProps) {
  const { t } = useTranslation();

  return (
    <Box borderColor="wsGray.400" borderWidth="1px" borderRadius="5px" p={3}>
      <VStack spacing={2} align="stretch">
        <Text fontSize="sm" fontWeight="bold">
          {t('group.inWhatGroupNote', { leader })}
        </Text>
        {notice && (
          <HStack>
            <Icon as={HiOutlineChat} boxSize="1.125rem" />
            <Text fontSize="xs">{notice}</Text>
          </HStack>
        )}

        <Divider />
        <HStack justify="space-between" align="stretch">
          <HStack>
            <Icon
              as={limitTime ? IoStopwatchOutline : IoPeopleOutline}
              color={limitTime ? 'wsRed.600' : 'wsGray.500'}
              boxSize="1.25rem"
            />
            <Text fontSize="sm" color={limitTime ? 'wsRed.600' : 'wsGray.500'}>
              {limitTime ? limitTime : t('group.inWhatGroupNote', { leader })}
            </Text>
          </HStack>
          <Link fontSize="xs" color="wsGray.500" textDecoration="none" onClick={onOpen}>
            {t('group.leave')}
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
}
