import {
  VStack,
  HStack,
  Text,
  Divider,
  Button,
  Icon,
  Center,
  Box,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IoCreateOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { NoteInput } from '~/pages/Item/components';
import { CartItem, OrderOption } from '~/types';

import formatPriceNumber from '~/utils/formatPriceNumber';

import sumBy from 'lodash/sumBy';

export type GroupProductItemProps = {
  item: CartItem;
  allowEdit?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export default function GroupProductItem({
  item,
  allowEdit = false,
  onEdit,
  onDelete,
}: GroupProductItemProps) {
  const { t } = useTranslation();
  const { count, note, name, modifiers, price } = item;

  const optionList = modifiers.reduce((acc: Array<OrderOption>, modifier) => {
    return acc.concat(modifier.options);
  }, []);

  const itemTotalPrice = (sumBy(optionList, ({ price }) => price) + price) * count;

  return (
    <VStack align="stretch" p={0} spacing={0}>
      <VStack align="stretch" px={2} pt={2}>
        <HStack
          align="flex-start"
          onClick={allowEdit ? onEdit : undefined}
          cursor={allowEdit ? 'pointer' : 'default'}
        >
          <Center borderRadius="5px" bg="wsGray.200" w="2rem" h="2rem">
            <Text>{count}</Text>
          </Center>

          <Text flex="1" py={1} fontWeight="bold" fontSize="sm">
            {name}
          </Text>
          {allowEdit && (
            <Center py={1}>
              <Icon as={IoCreateOutline} boxSize="1.2rem" />
            </Center>
          )}
        </HStack>

        {optionList.map(({ name, id, price }, index) => (
          <HStack key={`${id}_${index}`} align="center" justify="space-between" w="full">
            <Text color="wsGray.500" fontSize="xs">
              {name}
            </Text>
            {price !== 0 && (
              <Text color="wsGray.500" fontSize="xs">
                +{formatPriceNumber(price)}
              </Text>
            )}
          </HStack>
        ))}

        {note && (
          <Text color="wsGray.500" fontSize="xs">
            {note}
          </Text>
        )}

        <Divider />
      </VStack>

      <Flex>
        {allowEdit && (
          <Button
            py={5}
            pl={2}
            pr={4}
            bg="white"
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={IoRemoveCircleOutline} boxSize="1.2rem" color="wsRed.600" />}
            iconSpacing={1}
            fontWeight="normal"
            color="wsGray.500"
            onClick={onDelete}
            letterSpacing={1}
            _hover={{ bg: 'wsRed.200' }}
            _active={{ bg: 'wsRed.200' }}
          >
            {t('store.order.removeItem')}
          </Button>
        )}
        <Spacer />
        <Text fontSize="xs" fontWeight="bold" px={2} py={2.5}>
          {formatPriceNumber(itemTotalPrice)}
        </Text>
      </Flex>
    </VStack>
  );
}
