import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { Flex, HStack, Icon, Text, StackProps, useDisclosure } from '@chakra-ui/react';

import { updateCartData } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import storeMenuAvailablePickupTypesSelector from '~/recoil/selectors/storeMenuAvailablePickupTypes';

import useMealPickupType from '~/hooks/useMealPickupType';

import ConfirmModal from '~/components/ConfirmModal';

import { AiOutlineCar } from 'react-icons/ai';
import { Pickup } from '~/icons';
import { MealPickUpType } from '~/types';

import { useTranslation } from 'react-i18next';

type OrderTypeBarProps = {
  mealPickupType: MealPickUpType;
};

export default function OrderTypeBar({ mealPickupType }: OrderTypeBarProps) {
  const { storeId = '' } = useParams();
  const auth = useRecoilValue(authAtom);
  const storeMenuAvailablePickupTypes = useRecoilValue(storeMenuAvailablePickupTypesSelector);
  const [currentMealPickupType, setCurrentMealPickupType] = useState(mealPickupType);
  const handleUpdateMealPickupType = useMealPickupType();
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const { t } = useTranslation();

  const handleMealPickupTypeClick = async (clickedMealPickupType: MealPickUpType) => {
    if (clickedMealPickupType === mealPickupType) return;

    handleUpdateMealPickupType(clickedMealPickupType);
  };

  const handleConfirmClearChange = async () => {
    if (!auth) {
      handleUpdateMealPickupType(currentMealPickupType);
      onConfirmClose();
      return;
    }

    try {
      await updateCartData(storeId, auth, {
        items: [],
      });

      handleUpdateMealPickupType(currentMealPickupType);
    } catch (err) {
      console.log('[updateCartData error]', err);
    } finally {
      onConfirmClose();
    }
  };

  const handleCancelClearChange = () => {
    setCurrentMealPickupType(mealPickupType);
    onConfirmClose();
  };

  const handleIsActiveBtn = (isActive: boolean): StackProps => {
    if (isActive) {
      return {
        bgColor: 'wsBlack',
        color: 'white',
      };
    }
    return {
      bgColor: 'white',
      color: 'wsBlack',
    };
  };
  return (
    <>
      <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
        {storeMenuAvailablePickupTypes.includes(MealPickUpType.DELIVERY) && (
          <HStack
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(mealPickupType === MealPickUpType.DELIVERY)}
            onClick={() => handleMealPickupTypeClick(MealPickUpType.DELIVERY)}
          >
            <Icon boxSize="1.2rem" as={AiOutlineCar} />
            <Text fontWeight="bold" color="inherit">
              {t('store.orderType.delivery')}
            </Text>
          </HStack>
        )}
        {storeMenuAvailablePickupTypes.includes(MealPickUpType.PICKUP) && (
          <HStack
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(mealPickupType === MealPickUpType.PICKUP)}
            onClick={() => handleMealPickupTypeClick(MealPickUpType.PICKUP)}
          >
            <Icon boxSize="1.2rem" as={Pickup} />
            <Text fontWeight="bold" color="inherit">
              {t('store.orderType.pickUp')}
            </Text>
          </HStack>
        )}
      </Flex>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={t('store.order.changePickTypeModal.title')}
        message={t('store.order.changePickTypeModal.content')}
        onConfirm={handleConfirmClearChange}
        onClose={handleCancelClearChange}
      />
    </>
  );
}
