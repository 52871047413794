import { Global, css } from '@emotion/react';

export default function Fonts() {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
      `}
    ></Global>
  );
}
