import React from 'react';

import { Flex, HStack, Text, Icon } from '@chakra-ui/react';

import { AiOutlineCar } from 'react-icons/ai';
import { Pickup } from '~/icons';

import { useTranslation } from 'react-i18next';

type OrderTypeSectionProps = {
  isDelivery: boolean;
};

// 顯示取餐方式 : 自取 or 外送
export default function OrderTypeSection({ isDelivery }: OrderTypeSectionProps) {
  const { t } = useTranslation();
  return (
    <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
      <HStack
        flex="1"
        borderRadius="5px"
        justify="center"
        py={2}
        bgColor={isDelivery ? 'wsBlack' : 'white'}
        color={isDelivery ? 'white' : 'wsGray.400'}
        boxShadow={isDelivery ? '0px 0px 10px rgba(51, 51, 51, 0.3)' : ''}
      >
        <Icon boxSize="1.2rem" as={AiOutlineCar} />
        <Text fontWeight="bold" color="inherit">
          {t('store.orderType.delivery')}
        </Text>
      </HStack>
      <HStack
        flex="1"
        borderRadius="5px"
        justify="center"
        py={2}
        color={isDelivery ? 'wsGray.400' : 'white'}
        bgColor={isDelivery ? 'white' : 'wsBlack'}
        boxShadow={isDelivery ? '' : '0px 0px 10px rgba(51, 51, 51, 0.3)'}
      >
        <Icon boxSize="1.2rem" as={Pickup} />
        <Text fontWeight="bold" color="inherit">
          {t('store.orderType.pickUp')}
        </Text>
      </HStack>
    </Flex>
  );
}
