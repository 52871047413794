import { Text, RadioGroup, Stack, Radio } from '@chakra-ui/react';

import DisplayInput from './DisplayInput';

import { TaxType } from '~/types';

import { useTranslation } from 'react-i18next';

type DisplayTaxSectionProps = {
  isUseInvoice?: boolean;
  email?: string;
  taxType: TaxType;
  taxInfo?: {
    title: string;
    invoice: string;
  };
};

// 顯示不可編輯的 radio
export default function DisplayTaxSection({
  isUseInvoice,
  email,
  taxType,
  taxInfo,
}: DisplayTaxSectionProps) {
  const { t } = useTranslation();
  return (
    <Stack>
      {isUseInvoice && email && (
        <Stack direction="column">
          <Text fontSize="sm">{t('store.order.email')}</Text>
          <DisplayInput displayText={email} />
        </Stack>
      )}
      <RadioGroup value={taxType}>
        <Stack direction="row">
          <Radio
            value={TaxType.PERSONAL}
            _checked={{
              background: 'wsBlack',
              borderWidth: '0.15rem',
              borderColor: 'white',
              boxShadow: '0px 0px 1px 1px #333',
            }}
          >
            {t('store.order.invoice.personal')}
          </Radio>
          <Radio
            value={TaxType.COMPANY}
            _checked={{
              background: 'wsBlack',
              borderWidth: '0.15rem',
              borderColor: 'white',
              boxShadow: '0px 0px 1px 1px #333',
            }}
          >
            {t('store.order.invoice.company')}
          </Radio>
        </Stack>
      </RadioGroup>
      {taxType === TaxType.COMPANY && taxInfo && (
        <>
          <Stack direction="column">
            <Text fontSize="sm">{t('store.order.companyName')}</Text>
            <DisplayInput displayText={taxInfo.title} />
          </Stack>
          <Stack direction="column">
            <Text fontSize="sm">{t('store.order.VAT')}</Text>
            <DisplayInput displayText={taxInfo.invoice} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
