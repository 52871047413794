import { VStack, Text } from '@chakra-ui/react';

import formatPriceNumber from '~/utils/formatPriceNumber';

import { useTranslation } from 'react-i18next';

type ItemInfoProps = {
  title: string;
  description: string;
  price: number;
  inventory: number;
};

// 顯示餐點品項的基本資訊，包含品項名、敘述、定價
export default function ItemInfo({ title, description, price, inventory }: ItemInfoProps) {
  const { t } = useTranslation();

  return (
    <VStack align="flex-start" spacing={2}>
      <Text as="h4" fontSize="lg" fontWeight="bold">
        {title}
      </Text>
      {description.split('\n').map((line) => (
        <Text fontSize="xs" color="wsGray.500" key={line}>
          {line}
        </Text>
      ))}
      <Text fontWeight="bold" lineHeight="1">
        {formatPriceNumber(price)}
      </Text>
      {inventory !== Infinity && (
        <Text lineHeight="1.5" fontSize="xs" color="wsGray.500">
          {t('store.item.remain')} {inventory > 100 ? `100+` : `${inventory}`}
        </Text>
      )}
    </VStack>
  );
}
