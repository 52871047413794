import firebase from 'firebase';
import { firestore, auth } from './index';

import {
  Store,
  StoreWithId,
  notCreatedOrderStatus,
  inProgressOrderStatus,
  finishOrderStatus,
  OrderWithId,
  Order,
  FirebaseCart,
  FirebaseGroupCart,
  FirebaseCustomer,
} from '~/types';

export function storeListener(storeId: string, storeUpdater: (data: StoreWithId | null) => void) {
  return firestore
    .collection('stores')
    .doc(storeId)
    .onSnapshot(
      (doc) => {
        const storeData = doc.data() as Store;

        storeUpdater(storeData ? { id: storeId, ...storeData } : null);
      },
      (err) => {
        console.error('listen store error', err);
      },
    );
}

export function authListener(authUpdater: (auth: firebase.User | null) => void) {
  return auth.onAuthStateChanged(
    (auth) => {
      authUpdater(auth);
    },
    (err) => {
      console.log('login error');
    },
  );
}

// order List listener
export function inProgressOrdersListener(
  storeId: string,
  user: firebase.User,
  ordersUpdater: (orders: Array<OrderWithId>) => void,
) {
  return firestore
    .collection('orders')
    .where('store.id', '==', storeId)
    .where('customer.uid', '==', user.uid)
    .where('status', 'in', inProgressOrderStatus)
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (orderDocs) => {
        let ordersList: Array<OrderWithId> = [];

        orderDocs.forEach((orderDoc) => {
          const order = orderDoc.data() as Order;

          if (notCreatedOrderStatus.includes(order.status)) {
            return;
          }

          ordersList.push({ id: orderDoc.id, ...order });
        });
        //console.log('order list', ordersList);
        ordersUpdater(ordersList);
      },
      (err) => {
        console.error('inProgressOrder listener error', err);
      },
    );
}

// past order listener
export function pastOrderListener(
  storeId: string,
  user: firebase.User,
  pastOrderNum: number,
  ordersUpdater: (orders: OrderWithId[]) => void,
) {
  return firestore
    .collection('orders')
    .where('store.id', '==', storeId)
    .where('customer.uid', '==', user.uid)
    .where('status', 'in', finishOrderStatus)
    .orderBy('completedAt', 'desc')
    .limit(pastOrderNum)
    .onSnapshot(
      (orderDocs) => {
        let ordersList: Array<OrderWithId> = [];

        orderDocs.forEach((orderDoc) => {
          const order = orderDoc.data() as Order;

          if (notCreatedOrderStatus.includes(order.status)) {
            return;
          }

          ordersList.push({ id: orderDoc.id, ...order });
        });
        ordersUpdater(ordersList);
      },
      (err) => {
        console.error('inProgressOrder listener error', err);
      },
    );
}

// inprogress order number listener
export function inProgressOrderNumberListener(
  storeId: string,
  user: firebase.User,
  ordersNumberUpdater: (num: number) => void,
) {
  return firestore
    .collection('orders')
    .where('store.id', '==', storeId)
    .where('customer.uid', '==', user.uid)
    .where('status', 'in', inProgressOrderStatus)
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (snaps) => {
        ordersNumberUpdater(snaps.size);
      },
      (error) => {
        ordersNumberUpdater(0);
      },
    );
}

// cart listener
export function cartListener(
  storeId: string,
  auth: firebase.User,
  cartUpdater: (firebaseCart: FirebaseCart) => void,
) {
  return firestore.doc(`stores/${storeId}/carts/${auth.uid}`).onSnapshot(
    (doc) => {
      const firebaseCart = doc.data();

      if (firebaseCart) {
        cartUpdater(firebaseCart as FirebaseCart);
      }
    },
    (err) => {
      console.error('cart listener error', err);
    },
  );
}

export function groupCartListener(
  storeId: string,
  groupId: string,
  groupCartUpdater: (firebaseGroupCart: FirebaseGroupCart | undefined) => void,
) {
  return firestore.doc(`stores/${storeId}/carts/${groupId}`).onSnapshot(
    (doc) => {
      const firebaseGroupCart = doc.data();

      groupCartUpdater(firebaseGroupCart as FirebaseGroupCart);
    },
    (err) => {
      console.error('group cart listener error', err);
    },
  );
}

export function customerListener(
  storeId: string,
  auth: firebase.User,
  customerUpdater: (firebaseCustomer: FirebaseCustomer | undefined) => void,
) {
  return firestore.doc(`stores/${storeId}/customers/${auth.uid}`).onSnapshot(
    (doc) => {
      const firebaseCustomer = doc.data();
      customerUpdater(firebaseCustomer as FirebaseCustomer);
    },
    (err) => {
      console.error('customer listener error', err);
    },
  );
}
