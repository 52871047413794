import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  Heading,
  Box,
  ModalFooter,
  Circle,
  Icon,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import groupOrderNoticeAtom from '~/recoil/atom/groupOrderNotice';
import { useRecoilState } from 'recoil';
import { IconType } from 'react-icons';
import { CartStatus } from '~/types';

import { useTranslation } from 'react-i18next';

type GroupOrderNoticeModalProps = {
  icon: IconType;
};

// 揪團結束提示
export default function GroupOrderNoticeModal({ icon }: GroupOrderNoticeModalProps) {
  const { t } = useTranslation();
  const { storeId } = useParams();
  const navigate = useNavigate();
  const [groupOrderNotice, setGroupOrderNotice] = useRecoilState(groupOrderNoticeAtom);

  const handleClose = () => {
    navigate(`/${storeId}`);
    setGroupOrderNotice((prev) => ({ ...prev, isOpen: false }));
  };

  let title = '';
  let notice = '';
  if (groupOrderNotice.cartStatus === CartStatus.CANCELLED) {
    title = t('group.canceledTitle');
    notice = t('group.canceledNote');
  }

  if (groupOrderNotice.cartStatus === CartStatus.ORDERED) {
    title = t('group.endedTitle');
    notice = t('group.endedNote');
  }

  return (
    <Modal isOpen={groupOrderNotice.isOpen} onClose={handleClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent m={0}>
        <ModalCloseButton />

        <ModalBody>
          <VStack align="stretch" px={{ base: 2, md: 2 }} spacing={4} mb={4}>
            <Center>
              <VStack spacing={4} align="center" justify="space-between">
                <Circle size="3rem" bg="wsGray.200" mt={4}>
                  <Icon boxSize="1.75rem" as={icon} />
                </Circle>

                <Heading fontSize="md">{title}</Heading>

                <Text fontSize="sm">{notice}</Text>
              </VStack>
            </Center>
          </VStack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px" borderTopColor="admin.gray.300" justifyContent="center">
          <HStack align="stretch" w="full" spacing={0}>
            <Box as="button" flex="1" onClick={handleClose}>
              <Text fontSize="sm">{t('understood')}</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
