import { Text, Icon, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import FormInputMessage from '~/components/FormInputMessage';
import { TaxInfoState } from '~/pages/Cart/hooks/useTaxInfo';
import SectionContainer from '~/container/SectionContainer';
import { TaxInfoForm, TaxRadioGroup } from '.';

import { useTranslation } from 'react-i18next';
import { IoReaderOutline } from 'react-icons/io5';
import { TaxType } from '~/types';

type TaxInfoWithEmailSectionProps = {
  email: string;
  emailError: string;
  taxType: TaxType;
  taxInfoState: TaxInfoState;
  showError: boolean;
  onChangeEmail: (value: string) => void;
  onChangeTaxType: (value: TaxType) => void;
  onChangeTitle: (value: string) => void;
  onChangeInvoice: (value: string) => void;
};

export default function TaxInfoWithEmailSection({
  email,
  emailError,
  taxType,
  taxInfoState,
  showError,
  onChangeEmail,
  onChangeTaxType,
  onChangeInvoice,
  onChangeTitle,
}: TaxInfoWithEmailSectionProps) {
  const { t } = useTranslation();

  return (
    <SectionContainer
      title={t('store.order.eInvoice')}
      icon={<Icon as={IoReaderOutline} boxSize="1.5rem" />}
    >
      <VStack align="stretch" spacing={4}>
        <FormControl isRequired>
          <FormLabel fontWeight="normal">{t('store.order.email')}</FormLabel>
          <Input
            type="text"
            maxLength={20}
            value={email}
            isInvalid={showError && !!emailError}
            onChange={(e) => onChangeEmail(e.target.value)}
          />
          {showError && emailError && <FormInputMessage type="error" message={t(emailError)} />}
        </FormControl>
        <TaxRadioGroup taxType={taxType} onChangeTaxType={onChangeTaxType} />
        {taxType === TaxType.COMPANY && (
          <TaxInfoForm
            taxInfoState={taxInfoState}
            onChangeInvoice={onChangeInvoice}
            onChangeTitle={onChangeTitle}
            showError={showError}
          />
        )}
      </VStack>
    </SectionContainer>
  );
}
