import { Input } from '@chakra-ui/react';

type DisplayInputProps = {
  displayText: string;
};
// 顯示不可編輯的 input
export default function DisplayInput({ displayText }: DisplayInputProps) {
  return (
    <Input
      disabled
      value={displayText}
      fontWeight="bold"
      _disabled={{
        color: 'wsBlack',
      }}
      _hover={{
        borderColor: 'wsBlack',
      }}
    />
  );
}
