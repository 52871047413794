import { Center, Text } from '@chakra-ui/react';

type DisableProps = {
  children?: JSX.Element | string | number;
};

export default function Disable({ children }: DisableProps) {
  return (
    <Center w="full" py={3} borderRadius="5px">
      <Text w="1.4rem" textAlign="center" fontWeight="bold" color="wsGray.400">
        {children}
      </Text>
    </Center>
  );
}
