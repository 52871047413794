import { VStack } from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';

import ProductItem from '~/components/ProductItem';
import { OrderItem } from '~/types';

import useCartItems from '~/hooks/useCartItems';

type ItemListProps = {
  items: Array<OrderItem>;
  allowEdit?: boolean;
};

// 餐點列表
export default function ItemList({ items, allowEdit = false }: ItemListProps) {
  const { storeId } = useParams();
  const navigate = useNavigate();

  const { cartItems, handleDeleteItem } = useCartItems();

  const handleEdit = (id: string, index: number) => {
    if (cartItems.some((item, i) => item.id === id && index === i)) {
      navigate(`/${storeId}/item/${id}/edit/${index}`);
    }
  };

  return (
    <VStack spacing={3} align="stretch">
      {items.map((item, index) => {
        return (
          <ProductItem
            key={`${item.id}_${index}`}
            item={item}
            allowEdit={allowEdit}
            onDelete={() => handleDeleteItem(item.id, index)}
            onEdit={() => handleEdit(item.id, index)}
          />
        );
      })}
    </VStack>
  );
}
