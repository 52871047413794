import { MealPickUpType, PickMealTime, OrderType } from '~/types';

export default function convertMealPickupType(
  mealPickupType: MealPickUpType,
  pickMealTime: PickMealTime,
): OrderType {
  if (mealPickupType === MealPickUpType.PICKUP && pickMealTime === PickMealTime.IMMEDIATELY) {
    return OrderType.WS_PICKUP;
  }

  if (mealPickupType === MealPickUpType.PICKUP && pickMealTime === PickMealTime.RESERVATION) {
    return OrderType.WS_PREORDER_PICKUP;
  }

  if (mealPickupType === MealPickUpType.DELIVERY && pickMealTime === PickMealTime.IMMEDIATELY) {
    return OrderType.WS_DELIVERY;
  }

  if (mealPickupType === MealPickUpType.DELIVERY && pickMealTime === PickMealTime.RESERVATION) {
    return OrderType.WS_PREORDER_DELIVERY;
  }

  return OrderType.WS_PICKUP;
}
