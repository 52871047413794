import { HStack, Heading, Icon, Text, StackProps } from '@chakra-ui/react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

export type OrderStatusTitleProps = {
  status: string;
  isMatchId: boolean;
  onToggle: () => void;
} & StackProps;

export default function OrderStatusTitle({
  status,
  isMatchId,
  onToggle,
  ...props
}: OrderStatusTitleProps) {
  const { t } = useTranslation();
  return (
    <HStack
      align="stretch"
      justify="space-between"
      bgColor="wsBlack"
      borderTopRadius="5px"
      width="full"
      py={2}
      px={3}
      onClick={onToggle}
      {...props}
    >
      <Heading fontSize="sm" letterSpacing={2} color="white">
        {t(status)}
      </Heading>
      <HStack spacing={1}>
        <Text fontSize="xs" color="wsGray.400">
          {isMatchId ? t('store.order.close') : t('store.order.expand')}
        </Text>
        <Icon as={isMatchId ? IoChevronUp : IoChevronDown} color="wsGray.400" />
      </HStack>
    </HStack>
  );
}
