import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import uidAtom from '~/recoil/atom/uid';
import cartTypeAtom from '~/recoil/atom/cartType';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import locationAtom from '~/recoil/atom/location';
import taxTypeAtom from '~/recoil/atom/taxType';
import taxInfoAtom from '~/recoil/atom/taxInfo';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import paymentTypeAtom from '~/recoil/atom/paymentType';
import groupItemsAtom from '~/recoil/atom/groupItems';
import groupHostInfoAtom from '~/recoil/atom/groupHostInfo';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
import couponAtom from '~/recoil/atom/coupon';
import shippingFeeAtom from '~/recoil/atom/shippingFee';
import emailAtom from '~/recoil/atom/email';
import { useSetRecoilState } from 'recoil';

import convertOrderType from '~/utils/convertOrderType';

import { groupCartListener } from '~/firebase/listener';

import { CartType, FirebaseGroupCart } from '~/types';

export default function useGroupCart() {
  const navigate = useNavigate();
  const { storeId, groupId } = useParams();

  const setUid = useSetRecoilState(uidAtom);
  const setCartType = useSetRecoilState(cartTypeAtom);
  const setMealPickupType = useSetRecoilState(mealPickupTypeAtom);
  const setPickMealTime = useSetRecoilState(pickMealTimeAtom);
  const setPreOrderDate = useSetRecoilState(preOrderDateAtom);
  const setLocation = useSetRecoilState(locationAtom);
  const setEmail = useSetRecoilState(emailAtom);
  const setTaxType = useSetRecoilState(taxTypeAtom);
  const setTaxInfo = useSetRecoilState(taxInfoAtom);
  const setCartStatus = useSetRecoilState(cartStatusAtom);
  const setPaymentType = useSetRecoilState(paymentTypeAtom);
  const setGroupItems = useSetRecoilState(groupItemsAtom);
  const setGroupHostInfo = useSetRecoilState(groupHostInfoAtom);

  const setShippingFee = useSetRecoilState(shippingFeeAtom);

  const setIsGotInitData = useSetRecoilState(isGotInitDataAtom);

  const setCoupon = useSetRecoilState(couponAtom);

  const handleUpdateGroupCart = (firebaseGroupCart: FirebaseGroupCart | undefined) => {
    setIsGotInitData((prev) => ({ ...prev, groupCart: true }));
    if (!firebaseGroupCart) {
      navigate('/404');
      return;
    }
    const {
      email,
      uid,
      orderType,
      location,
      preOrderDate,
      status,
      taxInfo,
      taxType,
      groupItems,
      paymentType,
      groupHostInfo,
      coupon,
      shippingFee,
    } = firebaseGroupCart;
    const [mealPickUpType, pickMealTime] = convertOrderType(orderType);

    setUid(uid);
    setCartType(CartType.GROUP);
    setMealPickupType(mealPickUpType);
    setPickMealTime(pickMealTime);
    setPreOrderDate(preOrderDate?.toDate() || null);
    setCartStatus(status);
    setEmail(email);
    setTaxInfo(taxInfo);
    setTaxType(taxType);
    setGroupItems(groupItems);
    setLocation(location);
    setPaymentType(paymentType);
    setGroupHostInfo(groupHostInfo);

    setShippingFee(shippingFee);

    setCoupon(
      coupon
        ? { ...coupon, startDate: coupon.startDate.toDate(), endDate: coupon.endDate.toDate() }
        : null,
    );
  };

  useEffect(() => {
    let groupCartUnlisten: (() => void) | null = null;

    if (storeId && groupId) {
      groupCartUnlisten = groupCartListener(storeId, groupId, handleUpdateGroupCart);
    }

    return () => {
      groupCartUnlisten?.();
      setUid('');
      setGroupItems([]);
    };
  }, [storeId, groupId]);
}
