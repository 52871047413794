import { useEffect } from 'react';
import groupOrderNoticeAtom from '~/recoil/atom/groupOrderNotice';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import cartItemsAtom from '~/recoil/atom/cartItems';
import isHostSelector from '~/recoil/selectors/isHost';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { CartStatus } from '~/types';

export default function useCheckGroupCartStatus() {
  const cartStatus = useRecoilValue(cartStatusAtom);
  const [groupOrderNotice, setGroupOrderNotice] = useRecoilState(groupOrderNoticeAtom);
  const setCartItems = useSetRecoilState(cartItemsAtom);
  const isHost = useRecoilValue(isHostSelector);
  useEffect(() => {
    if ([CartStatus.CANCELLED, CartStatus.ORDERED].includes(cartStatus)) {
      setCartItems([]);
      setGroupOrderNotice({
        isOpen: isHost ? false : true,
        cartStatus: cartStatus,
      });
    } else {
      if (groupOrderNotice.isOpen) {
        setGroupOrderNotice({ isOpen: false, cartStatus });
      }
    }
  }, [cartStatus, isHost]);
}
