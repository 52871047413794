import { Icon, HStack, Text, VStack, Button } from '@chakra-ui/react';
import { useState } from 'react';

import { GoPlus } from 'react-icons/go';
import { IoCreateOutline } from 'react-icons/io5';
import EditAddressModal from '~/components/EditAddressModal';
import { useRecoilValue } from 'recoil';

import userLocationSelector from '~/recoil/selectors/userLocation';

import { Location } from '~/types';

import { useTranslation } from 'react-i18next';

type AddressListProps = {
  userLocationList: Location[] | null;
  onAddressEdit: (index?: number) => void;
};

// 儲存地址列表
export default function AddressList({ userLocationList, onAddressEdit }: AddressListProps) {
  const userLocation = useRecoilValue(userLocationSelector);
  const { t } = useTranslation();

  return (
    <VStack align="stretch" spacing={3}>
      {userLocationList?.map((location, index) => {
        return (
          <VStack key={`${location.address}_${index}`} spacing={1} align="stretch">
            <Text fontSize="sm">
              {t('address.addressRecord')}
              {index + 1}
            </Text>
            <HStack justify="space-between" spacing={3} align="flex-start">
              <Text fontSize="sm">{location.address}</Text>
              <Icon onClick={() => onAddressEdit(index)} as={IoCreateOutline} boxSize="1.125rem" />
            </HStack>
            <Text fontSize="xs" color="wsGray.500">
              {t('address.floor')}：{location.floor}
            </Text>
            <Text fontSize="xs" color="wsGray.500">
              {t('address.note')}：{location.note}
            </Text>
          </VStack>
        );
      })}

      {(!userLocation || userLocation.length < 2) && (
        <Button
          isFullWidth
          h="auto"
          py={3}
          bgColor="wsGray.300"
          letterSpacing={2}
          color="wsBlack"
          _hover={{ bgColor: 'wsGray.400' }}
          _focus={{
            borderColor: 'wsBlack',
          }}
          onClick={() => onAddressEdit()}
        >
          + {t('address.newAddress')}
        </Button>
      )}
    </VStack>
  );
}
