import { selector } from 'recoil';
import modalControlAtom from '../atom/modalControl';

const isLoginModalOpen = selector<boolean>({
  key: 'isLoginModalOpen',
  get: ({ get }) => {
    const { isLoginOpen } = get(modalControlAtom);
    return isLoginOpen;
  },
  set: ({ get, set }, value) => {
    const modalControl = get(modalControlAtom);
    set(modalControlAtom, { ...modalControl, isLoginOpen: value as boolean });
  },
});

export default isLoginModalOpen;
