import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { VStack, Text, SimpleGrid, useDisclosure } from '@chakra-ui/react';

import { IoReceiptOutline } from 'react-icons/io5';

import PageContainer from '~/container/PageContainer';

import PageTitle from '~/components/PageTitle';
import {
  SectionContainer,
  OrderContainer,
  ContactStoreAlertDialog,
  EmptyOrders,
} from './components';

import authAtom from '~/recoil/atom/auth';
import store from '~/recoil/atom/store';
import { useRecoilValue } from 'recoil';

import useInProgressOrders from './hooks/useInProgressOrders';

import { updateGroupCart } from '~/firebase/updateData';

import { useTranslation } from 'react-i18next';
import { CartStatus } from '~/types';

const OrderList = () => {
  const { t } = useTranslation();
  const { storeId = '', groupId } = useParams();
  const [searchParams] = useSearchParams();
  const existCartId = searchParams.get('cartId');
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const { phoneNumber } = useRecoilValue(store) || {};

  const { inProgressOrdersList, pastOrders, isNoMorePastOrders, handleMorePastOrder } =
    useInProgressOrders(storeId, auth);

  const { isOpen: isOpenContact, onOpen: onOpenContact, onClose: onCloseContact } = useDisclosure();
  const [openOrderId, setOpenOrderId] = useState('');

  const handleChangeOrderId = (id: string) => {
    setOpenOrderId((prev) => {
      if (prev === id) {
        return '';
      }
      return id;
    });
  };

  const handleConfirmContactStore = () => {
    window.open(`tel:${phoneNumber || ''}`);
    onCloseContact();
  };

  const handleGroupPaymentFailedRedirect = async () => {
    if (existCartId) {
      try {
        await updateGroupCart(storeId, existCartId, { status: CartStatus.ACTIVE });
      } catch (error) {
        //
      }
      navigate(`/${storeId}/group/${existCartId}`);
    }
  };

  useEffect(() => {
    handleGroupPaymentFailedRedirect();
  }, []);

  return (
    <>
      <PageContainer hideHeader>
        <PageTitle
          title={t('store.order.title')}
          onNavigate={() => navigate(groupId ? `/${storeId}/group/${groupId}` : `/${storeId}`)}
        />
        <VStack align="stretch" px={{ base: 4, md: 0 }} py={3} spacing={8}>
          <SectionContainer
            icon={IoReceiptOutline}
            title={t('store.order.title')}
            uncompletedOrder={inProgressOrdersList.length}
          >
            {inProgressOrdersList.length + pastOrders.length === 0 ? (
              <EmptyOrders onNavigate={() => navigate(`/${storeId}`)} />
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <VStack align="stretch" spacing={4}>
                  <Text fontSize="sm" color="wsGray.500">
                    {t('store.order.ongoingTitle')}
                  </Text>
                  {inProgressOrdersList.map((order) => (
                    <OrderContainer
                      key={order.id}
                      openOrderId={openOrderId}
                      onChangeOpenOrder={handleChangeOrderId}
                      onOpenContact={onOpenContact}
                      order={order}
                    />
                  ))}
                </VStack>

                <VStack align="stretch" spacing={4}>
                  <Text fontSize="sm" color="wsGray.500">
                    {t('store.order.pastOrderTitle')}
                  </Text>
                  {pastOrders.map((order) => (
                    <OrderContainer
                      key={order.id}
                      openOrderId={openOrderId}
                      onChangeOpenOrder={handleChangeOrderId}
                      onOpenContact={onOpenContact}
                      order={order}
                    />
                  ))}

                  <Text
                    fontSize="sm"
                    textAlign="center"
                    color="wsGray.500"
                    onClick={handleMorePastOrder}
                    cursor="pointer"
                  >
                    {isNoMorePastOrders ? t('store.order.noMore') : t('store.order.more')}
                  </Text>
                </VStack>
              </SimpleGrid>
            )}
          </SectionContainer>
        </VStack>
      </PageContainer>
      <ContactStoreAlertDialog
        storePhoneNumber={phoneNumber || ''}
        isOpen={isOpenContact}
        onClose={onCloseContact}
        onConfirm={handleConfirmContactStore}
      />
    </>
  );
};

export default OrderList;
