import { selector } from 'recoil';
import cartTypeAtom from '../atom/cartType';
import cartItemsAtom from '../atom/cartItems';
import groupItemsAtom from '../atom/groupItems';

import { CartType, CartItem, OrderOption } from '~/types';

export type OrderOptionWithCount = OrderOption & { modifierId: string; count: number };

const getAllItemsOptionsWithCount = (items: Array<CartItem>): Array<OrderOptionWithCount> => {
  return items.flatMap((item) =>
    item.modifiers.flatMap(({ options, id }) =>
      options.map((option) => ({ ...option, modifierId: id, count: item.count })),
    ),
  );
};
// 將購物車內所有品項的選項數量加總（重複的會合併相加）
const cartItemsOptionsMap = selector<Map<string, OrderOptionWithCount>>({
  key: 'cartItemsOptionsMap',
  get: ({ get }) => {
    const cartType = get(cartTypeAtom);
    const cartItems = get(cartItemsAtom);
    const groupItems = get(groupItemsAtom);

    const allItemsOptionsWithCount =
      cartType === CartType.PERSONAL
        ? getAllItemsOptionsWithCount(cartItems)
        : getAllItemsOptionsWithCount(groupItems.flatMap(({ items }) => items));

    const optionsCountMap = allItemsOptionsWithCount.reduce<Map<string, OrderOptionWithCount>>(
      (acc, option) => {
        const modifierIdWithOptionId = `${option.modifierId}_${option.id}`;

        acc.set(modifierIdWithOptionId, {
          ...option,
          count: option.count + (acc.get(modifierIdWithOptionId)?.count || 0),
        });
        return acc;
      },
      new Map(),
    );

    return optionsCountMap;
  },
});

export default cartItemsOptionsMap;
