import { RewardType, Coupon } from '~/types';

export default function getCouponCostLimit(coupon: Coupon): number {
  const { rewardCondition } = coupon;
  const couponGift = rewardCondition[RewardType.DESCRIPTION];
  const couponDiscount = rewardCondition[RewardType.DISCOUNT];
  const couponDiscountPercentage = rewardCondition[RewardType.DISCOUNT_PERCENTAGE];

  return (
    couponGift?.costLimit ||
    couponDiscount?.costLimit ||
    couponDiscountPercentage?.costLimit ||
    Infinity
  );
}
