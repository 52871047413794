import { firestore } from '~/firebase';
import firebase from 'firebase';

import { FirebaseCart, FirebaseGroupCart, FirebaseCustomer } from '~/types';

export function updateCartData(
  storeId: string,
  auth: firebase.User,
  updateData: Partial<FirebaseCart>,
) {
  return firestore.doc(`stores/${storeId}/carts/${auth.uid}`).update(updateData);
}

export function updateGroupCart(
  storeId: string,
  groupId: string,
  updateData: Partial<FirebaseGroupCart>,
) {
  return firestore.doc(`stores/${storeId}/carts/${groupId}`).update(updateData);
}

export function updateCustomer(
  storeId: string,
  auth: firebase.User,
  updateData: Partial<FirebaseCustomer>,
) {
  return firestore.doc(`stores/${storeId}/customers/${auth.uid}`).update(updateData);
}
