import PageContainer from '~/container/PageContainer';
import PageTitle from '~/components/PageTitle';

import HostDisplayContent from './HostDisplayContent';
import MemberDisplayContent from './MemberDisplayContent';

import { useParams, useNavigate } from 'react-router-dom';

import isHostSelector from '~/recoil/selectors/isHost';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

export default function GroupCart() {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const isHost = useRecoilValue(isHostSelector);
  const { t } = useTranslation();

  return (
    <PageContainer hideFooter hideHeader>
      <PageTitle
        title={t('store.order.title')}
        onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
      />
      {isHost ? <HostDisplayContent /> : <MemberDisplayContent />}
    </PageContainer>
  );
}
