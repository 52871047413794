import { Heading, Center, VStack, HStack, Icon, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FixedBackwardBar } from '~/pages/Item/components';

type PageTitleProps = {
  title: string;
  onNavigate?: () => void;
};

export default function PageTitle({ title, onNavigate }: PageTitleProps) {
  const { t } = useTranslation();
  return (
    <VStack align="stretch" py={3} spacing={1}>
      <FixedBackwardBar title={t('backCoupon')} onNavigate={onNavigate} />
      <Center>
        <Heading fontSize="xl" letterSpacing={2}>
          {title}
        </Heading>
      </Center>
    </VStack>
  );
}
