import { atom, useRecoilState } from 'recoil';

import useScroll from '~/hooks/useScroll';

const itemPositionAtom = atom<{ positionTop: null | number }>({
  key: 'itemPositionAtom',
  default: {
    positionTop: null,
  },
});

export default function useItemPosition() {
  const [itemPosition, setItemPosition] = useRecoilState(itemPositionAtom);
  const { positionInfo } = useScroll();

  const handleSetItemPosition = () => {
    setItemPosition({
      positionTop: positionInfo.scrollTop,
    });
  };

  return {
    itemPosition: itemPosition.positionTop,
    handleSetItemPosition,
  };
}
