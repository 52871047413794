import { VStack, Center } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';

import { IoPeopleOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

import PageContainer from '~/container/PageContainer';

import PageTitle from '~/components/PageTitle';
import { SectionLabel, LeaderContent, MemberContent } from './components';

import { useParams, useNavigate } from 'react-router-dom';

import isHostAtom from '~/recoil/selectors/isHost';
import groupHostInfoAtom from '~/recoil/atom/groupHostInfo';
import { useRecoilValue } from 'recoil';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

const GroupInvite = () => {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const isHost = useRecoilValue(isHostAtom);
  const groupHostInfo = useRecoilValue(groupHostInfoAtom);
  const store = useRecoilValue(storeLocaleSelector);
  const { t } = useTranslation();

  if (!store) {
    return <Navigate to="/404" />;
  }
  return (
    <PageContainer hideHeader>
      <PageTitle
        title={t('group.inviteBtn')}
        onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
      />

      <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={7} height="40rem">
        <SectionLabel icon={IoPeopleOutline} title={t('group.inviteBtn')} />
        <Center>
          {isHost ? (
            <LeaderContent storeName={store.name} groupHostInfo={groupHostInfo} />
          ) : (
            <MemberContent groupHostInfo={groupHostInfo} storeName={store.name} />
          )}
        </Center>
      </VStack>
    </PageContainer>
  );
};

export default GroupInvite;
