import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Button,
  Center,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import { useRecoilValue } from 'recoil';

import { useTranslation } from 'react-i18next';

type GroupOrderModalProps = {
  isOpen: boolean;
  groupNote: string;
  onChangeNote: (value: string) => void;
  onClose: () => void;
  onConfirm: () => void;
  textLimit?: number;
};

// 揪團訂modal：按下按鈕會跳出modal視窗，可以輸入給團員的話並且發起揪團訂
export default function GroupOrderModal({
  isOpen,
  groupNote,
  onChangeNote,
  onClose,
  onConfirm,
  textLimit = 40,
}: GroupOrderModalProps) {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);

  const { t } = useTranslation();

  if (isLargerThan768) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
        <ModalOverlay />
        <ModalContent m={0} h="100%" maxH={{ base: '100%', md: '100%' }}>
          <ModalHeader borderColor="wsGray.300" borderBottomWidth="1px">
            <Center mt={4}>
              <Heading fontSize="xl">{t('group.start')}</Heading>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" px={{ base: 4, md: 4 }} py={2}>
              <FormControl id="first-name">
                <FormLabel>{t('group.noteToTeam')}</FormLabel>
                <Textarea
                  value={groupNote}
                  focusBorderColor="Black"
                  resize="none"
                  borderRadius="4px"
                  borderColor="wsGray.400"
                  maxLength={textLimit}
                  _hover={{
                    borderColor: 'wsGray.400',
                  }}
                  size="sm"
                  onChange={(e) => onChangeNote(e.target.value)}
                />
              </FormControl>
              <Flex justify="flex-end">
                {textLimit ? (
                  <Text fontSize="xs" color="wsGray.400">{`(${
                    groupNote?.toString().length
                  }/${textLimit})`}</Text>
                ) : (
                  <Text h="24px" />
                )}
              </Flex>
            </VStack>
          </ModalBody>
          <ModalFooter p={0}>
            <Button
              onClick={onConfirm}
              variant="unstyled"
              bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
              borderRadius="unset"
              color="white"
              isFullWidth
              p={3.5}
              h="auto"
            >
              {t('group.startBtn')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader>
          <Center mt={4}>
            <Heading fontSize="xl">{t('group.start')}</Heading>
          </Center>
        </DrawerHeader>
        <DrawerBody>
          <VStack align="stretch" px={{ base: 4, md: 4 }} py={2}>
            <FormControl id="first-name">
              <FormLabel>{t('group.noteToTeam')}</FormLabel>
              <Textarea
                value={groupNote}
                focusBorderColor="Black"
                resize="none"
                borderRadius="4px"
                borderColor="wsGray.400"
                maxLength={textLimit}
                _hover={{
                  borderColor: 'wsGray.400',
                }}
                size="sm"
                onChange={(e) => onChangeNote(e.target.value)}
              />
            </FormControl>
            <Flex justify="flex-end">
              {textLimit ? (
                <Text fontSize="xs" color="wsGray.400">{`(${
                  groupNote?.toString().length
                }/${textLimit})`}</Text>
              ) : (
                <Text h="24px" />
              )}
            </Flex>
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Button
            onClick={onConfirm}
            variant="unstyled"
            bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
            borderRadius="unset"
            color="white"
            isFullWidth
          >
            {t('group.startBtn')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
