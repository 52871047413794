import { useEffect } from 'react';
import localeAtom from '../recoil/atom/locale';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
export default function useLocale() {
  const locale = useRecoilValue(localeAtom);
  const { i18n } = useTranslation();
  console.log(locale);
  useEffect(() => {
    console.log(locale);
    i18n.changeLanguage(locale.split('_')[0]);
    window.localStorage.setItem('locale', locale);
    return () => {
      //do nothing
    };
  }, [locale]);

  return locale;
}
