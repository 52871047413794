import { MealPickUpType, PickMealTime } from '~/types';
import { format, addMinutes } from 'date-fns';
import { t } from 'i18next';

export default function generatePrepareString(
  mealPickupTime: MealPickUpType,
  pickMealTime: PickMealTime,
  prepareMinutes: number,
  preOrderDate: Date | null,
  timeRange: number,
): string {
  if (pickMealTime === PickMealTime.IMMEDIATELY) {
    const minutesRange = mealPickupTime === MealPickUpType.DELIVERY ? 30 : 15;
    return `${t('store.pickUpSetting.ASAP')}（${prepareMinutes}~${prepareMinutes + minutesRange}${t(
      'minutes',
    )}）`;
  }

  if (preOrderDate && pickMealTime === PickMealTime.RESERVATION) {
    return `${format(preOrderDate, 'MM/dd hh:mm aa')}~${format(
      addMinutes(preOrderDate, timeRange),
      'hh:mm aa',
    )}`;
  }

  return t('store.pickUpSetting.error.noPreorderTime');
}
