import { firestore } from '~/firebase';
import {
  VStack,
  Icon,
  Text,
  Button,
  HStack,
  Link,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { IoPaperPlaneOutline } from 'react-icons/io5';
import { BtnVariant } from '~/customTheme/components/Button';

import { CancelModal } from '.';

import { useParams } from 'react-router-dom';

import QRCode from 'react-qr-code';

import { CartStatus } from '~/types';

import { useTranslation, Trans } from 'react-i18next';

type LeaderContentProps = {
  groupHostInfo: {
    note: string;
    name: string;
  };
  storeName: string;
};

// 團長揪團購頁面：邀請成員揪團的詳細內容，包括QRCode以及以及取消揪團的按鈕
export default function LeaderContent({ storeName, groupHostInfo }: LeaderContentProps) {
  const { storeId, groupId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { t } = useTranslation();

  const sharedLink = `${window.location.origin}/${storeId}/group/${groupId}`;

  const handleShareLink = async () => {
    if (!!window.navigator.share) {
      const shareData = {
        title: t('group.storeGroup', { storeName }),
        text: t('group.openGroup', { host: groupHostInfo.name, note: groupHostInfo.note }),
        url: sharedLink,
      };

      await window.navigator.share(shareData);
      return;
    }

    try {
      await window.navigator.clipboard.writeText(sharedLink);
      toast({
        position: 'top',
        status: 'success',
        title: t('group.linkCopied'),
        duration: 1000,
      });
    } catch (err) {
      toast({
        position: 'top',
        status: 'error',
        title: t('group.linkCopyFailed'),
        duration: 1000,
      });
    }
  };

  const handleConfirmCancelGroup = async () => {
    try {
      await firestore
        .doc(`stores/${storeId}/carts/${groupId}`)
        .update('status', CartStatus.CANCELLED);
    } catch (err) {
      toast({
        position: 'top',
        status: 'error',
        title: t('group.failCancel'),
        duration: 1000,
      });
    }
  };

  return (
    <VStack justify="space-between" spacing={6} py={4}>
      <QRCode value={sharedLink} size={256} />
      <Trans i18nKey="group.inviteQRCodeNote">
        <VStack>
          <Text fontSize="sm" color="wsGray.500"></Text>
          <Text fontSize="sm" color="wsGray.500"></Text>
        </VStack>
      </Trans>

      <Button variant={BtnVariant.SOLID} color="white" width="10rem">
        <HStack onClick={handleShareLink}>
          <Icon as={IoPaperPlaneOutline} boxSize="1.125rem" />
          <Text fontSize="sm" fontWeight="bold" color="white" letterSpacing={2}>
            {t('group.shareLink')}
          </Text>
        </HStack>
      </Button>

      <Link fontSize="sm" color="wsGray.500" textDecoration="none" onClick={onOpen}>
        {t('group.cancel')}
      </Link>

      <CancelModal
        title={t('group.cancelModal.title')}
        notice={t('group.cancelModal.note')}
        isOpen={isOpen}
        onConfirm={handleConfirmCancelGroup}
        onClose={onClose}
      />
    </VStack>
  );
}
