import { selector } from 'recoil';
import { Store, Category } from '~/types';
import { storeLocaleSelector } from './storeLocale';

export const categoriesSelector = selector<Record<string, Category> | null>({
  key: 'categoriesSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeLocaleSelector);
    return store?.categories ? store.categories : null;
  },
});
