import axios from 'axios';
import isEmpty from 'lodash/fp/isEmpty';
import formatISO from 'date-fns/fp/formatISO';
import { ShippingFeeSetting } from '~/types';

const getDeliveryQuoteAsync = async (quoteRequest: {
  store: {
    location: {
      geopoint: {
        latitude: number;
        longitude: number;
      };
      address: string;
    };
    orderPrepareMinutes: number;
    shippingFeeSetting: ShippingFeeSetting;
  };
  customer: {
    address: string;
    pickupDate: Date;
  };
  authToken: string;
}): Promise<{
  distance: number;
  quotes: number;
  estimateSecondsToArrival: number;
}> => {
  const {
    store: {
      location: {
        geopoint: { latitude, longitude },
        address: storeAddress,
      },
      orderPrepareMinutes,
      shippingFeeSetting,
    },
    customer: { address: customerAddress, pickupDate },
    authToken,
  } = quoteRequest;

  const locale = process.env.REACT_APP_DEFAULT_LOCALE;
  const urlForGetQuotes = process.env.REACT_APP_DELIVERY_QUOTE_URL || '';

  if (isEmpty(urlForGetQuotes)) {
    throw new Error('Get Delivery Quotes API url is missing');
  }

  try {
    const response = await axios.post(
      urlForGetQuotes,
      {
        store: {
          location: {
            geopoint: {
              latitude,
              longitude,
            },
            address: storeAddress,
          },
          orderPrepareMinutes,
          shippingFeeSetting,
        },
        delivery: {
          location: {
            address: customerAddress,
          },
          pickupDate: formatISO(pickupDate),
        },
        locale,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 15000,
      },
    );
    const quoteInfoResponse = response.data;
    const quoteInfo: {
      distance: number;
      quotes: number;
      estimateSecondsToArrival: number;
    } = {
      distance: quoteInfoResponse.distance,
      quotes: quoteInfoResponse.quotes,
      estimateSecondsToArrival: quoteInfoResponse.estimateSecondsToArrival,
    };

    return quoteInfo;
  } catch (error) {
    console.log(error);
    throw new Error('Get Delivery Quotes API url is failed to get quote');
  }
};

export default getDeliveryQuoteAsync;
