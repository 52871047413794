import { useEffect } from 'react';
import { Flex, HStack, Box, Button, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import NotOperatingTimeNotice from '~/components/NotOperatingTimeNotice';

import formatPriceNumber from '~/utils/formatPriceNumber';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import { useTranslation } from 'react-i18next';

type AddItemToCartButtonProps = {
  isDisabled?: boolean;
  amount: number;
  unitPrice: number;
  currentInventory: number;
  onChangeCount: (num: number) => void;
  onAddItemClick: () => void;
};

// 可加減品項數量顯示金額，並點擊加入訂單
export default function AddToCartBottomBar({
  isDisabled,
  amount = 1,
  unitPrice,
  currentInventory,
  onChangeCount,
  onAddItemClick,
}: AddItemToCartButtonProps) {
  const { t } = useTranslation();

  const handleIncreaseAmount = () => {
    if (currentInventory > amount) {
      onChangeCount(amount + 1);
    }
  };

  const handleDecreaseAmount = () => {
    if (amount > 1) {
      onChangeCount(amount - 1);
    }
  };

  const store = useRecoilValue(storeLocaleSelector);
  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);

  const isAllowOrder = !!store?.allowOrders;
  const isAllowRealTimeOrder = !!store?.allowRealTimeOrder;
  const isAllowPreOder = !!store?.preOrderSetting.allowPreOrder;
  const preOrderSetting = store?.preOrderSetting;
  return (
    <Box
      position="sticky"
      zIndex="1000"
      bottom="0"
      w="calc(100% + 2rem)"
      transition=".3s all"
      transform="translateX(-1rem)"
    >
      {store?.operatingTime && (
        <NotOperatingTimeNotice
          time={preOrderDate}
          mealPickUpType={mealPickupType}
          pickMealTime={pickMealTime}
          timeRange={store.operatingTime}
          isFixed={true}
          isAllowOrder={isAllowOrder}
          isAllowRealTimeOrder={isAllowRealTimeOrder}
          isAllowPreOder={isAllowPreOder}
          preOrderRange={{
            preOrderAfter: preOrderSetting?.preOrderAfter || 1,
            preOrderBefore: preOrderSetting?.preOrderBefore || 24,
          }}
          enableNavigate={true}
        />
      )}
      <Box
        bgColor={isDisabled || amount > currentInventory ? 'wsGray.400' : 'wsBlack'}
        color="white"
      >
        <Flex direction="row" align="center" px={4} py={4}>
          <Flex flex="1" justify="flex-start">
            <HStack justify="space-between" align="center" spacing={1}>
              <Button
                onClick={handleDecreaseAmount}
                px={2}
                fontWeight="light"
                variant="unstyled"
                cursor="pointer"
                _focus={{
                  outline: 'none',
                }}
              >
                一
              </Button>
              <Text align="center" fontSize="lg" fontWeight="bold" color="white">
                {amount}
              </Text>
              <Button
                onClick={handleIncreaseAmount}
                px={2}
                fontSize="xl"
                fontWeight="light"
                variant="unstyled"
                cursor="pointer"
                _focus={{
                  outline: 'none',
                }}
              >
                ＋
              </Button>
            </HStack>
          </Flex>
          <Flex
            flex="2"
            as="button"
            onClick={isDisabled || amount > currentInventory ? undefined : onAddItemClick}
          >
            <Text flex="1" textAlign="center" color="white" fontWeight="bold" fontSize="md">
              {t('store.item.addToCart')}
            </Text>

            <Text flex="1" textAlign="end" fontSize="sm" color="white">
              {formatPriceNumber(amount * unitPrice)}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
