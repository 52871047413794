import { useEffect, useState, useMemo } from 'react';
import useDeliveryLocation from '~/hooks/useDeliveryLocation';
import useLocationModal from '~/components/EditAddressModal/hooks/useLocationModal';
import useOrderSetting from '~/hooks/useOrderSetting';

import { Location } from '~/types';

export default function useLocationList() {
  const { userLocationList } = useDeliveryLocation();
  const {
    mealOrderSetting: { location },
  } = useOrderSetting();

  const [selectedUserLocationIndex, setSelectedUserLocationIndex] = useState(0);

  const { startEdit } = useLocationModal();

  useEffect(() => {
    if (!userLocationList) return;

    const locationIndex = userLocationList.findIndex(
      (userLocation) => location.address === userLocation.address,
    );

    if (locationIndex === -1) {
      setSelectedUserLocationIndex(0);
      return;
    }

    setSelectedUserLocationIndex(locationIndex);
  }, [location.address, userLocationList]);

  const handleLocationSelect = (index: number) => {
    setSelectedUserLocationIndex(index);
  };

  const handleLocationEdit = (index?: number) => {
    startEdit(index);
  };

  const selectedLocation = useMemo(() => {
    if (!userLocationList) return null;
    return userLocationList[selectedUserLocationIndex];
  }, [selectedUserLocationIndex, userLocationList]);

  return {
    userLocationList,
    selectedUserLocationIndex,
    selectedLocation,
    handleLocationSelect,
    handleLocationEdit,
  };
}
