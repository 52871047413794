import { Text, Icon, Box, HStack, Link } from '@chakra-ui/react';

import { IoStopwatchOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

export type MemberHeaderProps = {
  onOpen: () => void;
  leader: string;
};

// 給團員顯示參加的揪團基本資料以及選擇是否離開揪團，在畫面下拉時會顯示fix在畫面最上面
export default function MemberHeader({ leader, onOpen }: MemberHeaderProps) {
  const { t } = useTranslation();
  return (
    <Box borderColor="wsGray.400" borderBottomWidth="1px" bgColor="white" py={2} px={3}>
      <HStack justify="space-between" align="stretch">
        <HStack>
          <Icon as={IoStopwatchOutline} color="wsBlack" boxSize="1.25rem" />
          <Text fontSize="sm" color="wsBlack">
            {t('group.inWhatGroupNote', { leader })}
          </Text>
        </HStack>
        <Link fontSize="xs" color="wsGray.500" textDecoration="none" onClick={onOpen}>
          {t('group.leave')}
        </Link>
      </HStack>
    </Box>
  );
}
