import { HStack, Heading, Icon, Circle } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export type SectionLabelProps = {
  icon: IconType;
  title: string;
  uncompletedOrder: number;
};

export default function SectionLabel({ icon, title, uncompletedOrder }: SectionLabelProps) {
  return (
    <HStack spacing={1}>
      <Icon as={icon} boxSize="1.25rem" />
      <Heading fontSize="sm" letterSpacing={2}>
        {title}
      </Heading>
      <Circle size="1.25rem" bg="wsRed.600" color="white" fontWeight="bold" fontSize="xx-small">
        {uncompletedOrder}
      </Circle>
    </HStack>
  );
}
