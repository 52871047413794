import { ChakraProvider } from '@chakra-ui/react';
import customTheme from '~/customTheme';
import { BrowserRouter } from 'react-router-dom';
import RouteContainer from '~/routes';

import { RecoilRoot } from 'recoil';

import Fonts from '~/customTheme/Fonts';
import MediaQuery from '~/globalScript/MediaQuery';
import ErrorBoundary from './globalScript/ErrorBoundary';

function App() {
  return (
    //@ts-ignore
    <ErrorBoundary>
      <RecoilRoot>
        <ChakraProvider theme={customTheme}>
          <Fonts />
          <MediaQuery />
          <BrowserRouter>
            <RouteContainer />
          </BrowserRouter>
        </ChakraProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default App;
