import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

type InventoryNoticeModalProps = {
  isOpen: boolean;
  title: string;
  message: string;
  onFooterClick?: () => void;
};

export default function InventoryNoticeModal({
  isOpen,
  title,
  message,
  onFooterClick,
}: InventoryNoticeModalProps) {
  const { t } = useTranslation();

  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" color="wsBlack">
          {title}
        </ModalHeader>
        <ModalBody p={4}>
          <Text textAlign="center" fontSize="sm" color="wsGray.500">
            {message}
          </Text>
        </ModalBody>
        <ModalFooter
          as="button"
          cursor="pointer"
          p={2}
          borderTopWidth="1px"
          borderColor="wsGray.300"
          onClick={onFooterClick}
        >
          <Text m="auto">{t('store.item.reselectConfirm')}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
