import React from 'react';
import { Box, Image, VStack, Text, Center } from '@chakra-ui/react';
import NoMenu from '~/assets/images/NoMenu.png';

import { useTranslation } from 'react-i18next';

// 當訂單列表為空時，顯示的佔位提示
export default function EmptyMenu() {
  const { t } = useTranslation();
  return (
    <Box pt={16}>
      <Center>
        <VStack justify="space-between" spacing={4} py={4}>
          <Image src={NoMenu} />
          <VStack justify="space-between" spacing={1}>
            <Text fontSize="sm" color="wsGray.500">
              {t('store.menu.noMenu')}
            </Text>
            <Text fontSize="xs" color="wsGray.500">
              {t('store.menu.noMenuNote')}
            </Text>
          </VStack>
        </VStack>
      </Center>
    </Box>
  );
}
