import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  InputProps,
} from '@chakra-ui/react';

import FormInputMessage from '~/components/FormInputMessage';

import { useTranslation } from 'react-i18next';

type VerificationCodeInputProps = {
  error: string | null;
  isVerifying: boolean;
} & InputProps;

// 驗證碼輸入
export default function VerificationCodeInput({
  error,
  isVerifying,
  ...props
}: VerificationCodeInputProps) {
  const { t } = useTranslation();
  return (
    <VStack align="flex-start">
      <FormControl id="first-name" isRequired>
        <FormLabel>{t('personalInfo.VerificationCode')}</FormLabel>
        <InputGroup>
          <Input type="text" {...props} isInvalid={!!error} disabled={isVerifying} />
          <InputRightElement>{isVerifying && <Spinner color="wsBlack" />}</InputRightElement>
        </InputGroup>

        {error && <FormInputMessage message={t(error)} type="error" />}
      </FormControl>
    </VStack>
  );
}
