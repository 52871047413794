import { atom } from 'recoil';

const isGotInitData = atom({
  key: 'isGotInitData',
  default: {
    store: false,
    auth: false,
    groupCart: false,
  },
});

export default isGotInitData;
