import { Schedule, Week } from '../types';
import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfDay from 'date-fns/startOfDay';

/**
 * @param timeRange your week open and close time
 * @param time optional if not assign default now Date
 * @returns boolean
 */

function checkOperatingTime(timeRange: Schedule, time?: Date) {
  const date = time ? time : new Date();
  const day = format(date, 'E').toUpperCase();

  const currentTime = differenceInMinutes(date, startOfDay(date));
  const dailySchedule = timeRange[day as Week] || [];

  return dailySchedule.some(({ open, close }) => currentTime >= open && currentTime <= close);
}
export default checkOperatingTime;
