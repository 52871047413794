import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export default function Phone(props: IconProps) {
  return (
    <Icon viewBox="0 0 58 60" fill="transparent" {...props}>
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="58"
        height="60"
      >
        <rect width="58" height="60" rx="5" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M7.53896 8.75997L5.70065 11.1614C5.31478 11.6649 5.22912 12.3922 5.5346 13.1967L20.8095 53.0981C21.3692 54.5627 23.0371 55.7117 24.5323 55.6662L45.2107 55.0303C45.5023 55.0361 45.7915 54.9759 46.0566 54.8541C46.3217 54.7323 46.5557 54.5522 46.7413 54.3271C47.3521 53.5257 47.9642 52.7258 48.5773 51.9274L7.53896 8.75997Z"
          fill="white"
          stroke="#333333"
          strokeWidth="1.08336"
          strokeMiterlimit="10"
        />
        <path
          d="M48.743 49.8938L33.4683 9.99045C32.9069 8.52353 31.2389 7.37448 29.7415 7.42182L9.0654 8.05587C7.57002 8.10347 6.81133 9.32799 7.371 10.7926L22.6476 50.6963C23.2073 52.1609 24.8753 53.31 26.3704 53.2644L47.0468 52.6283C48.5439 52.583 49.3026 51.3585 48.743 49.8938Z"
          fill="white"
          stroke="#333333"
          strokeWidth="1.08336"
          strokeMiterlimit="10"
        />
        <path
          d="M23.3785 10.0837C23.4225 10.0862 23.4664 10.0768 23.5055 10.0566C23.5447 10.0364 23.5777 10.0061 23.6012 9.96886C23.6247 9.93159 23.6377 9.88872 23.6391 9.84469C23.6404 9.80067 23.6299 9.75708 23.6087 9.71847C23.5661 9.61971 23.496 9.53529 23.4067 9.47526C23.3175 9.41523 23.2128 9.38213 23.1053 9.3799C23.0614 9.37818 23.0178 9.38813 22.9789 9.40873C22.9401 9.42933 22.9074 9.45986 22.8842 9.4972C22.861 9.53454 22.8481 9.57737 22.8468 9.62132C22.8455 9.66527 22.8559 9.70877 22.8768 9.74741C22.9193 9.84577 22.9892 9.92981 23.0781 9.98946C23.1671 10.0491 23.2714 10.0818 23.3785 10.0837Z"
          fill="#333333"
        />
        <path
          d="M17.299 10.277L21.4293 10.1499C21.473 10.1514 21.5162 10.1413 21.5547 10.1208C21.5933 10.1003 21.6257 10.07 21.6488 10.033C21.672 9.99594 21.6849 9.95349 21.6865 9.90986C21.688 9.86624 21.678 9.82298 21.6575 9.78442C21.6165 9.68452 21.5474 9.59863 21.4586 9.53716C21.3698 9.47568 21.2651 9.44125 21.1572 9.43803L17.0271 9.56312C16.9828 9.56113 16.9389 9.57099 16.8997 9.59167C16.8605 9.61235 16.8276 9.64311 16.8043 9.68077C16.781 9.71843 16.7682 9.76163 16.7671 9.8059C16.7661 9.85018 16.7769 9.89393 16.7984 9.93265C16.8401 10.0319 16.9095 10.1171 16.9982 10.1782C17.087 10.2392 17.1914 10.2735 17.299 10.277Z"
          fill="#333333"
        />
        <path
          d="M46.982 49.9475L31.7054 10.0438C31.5975 9.78523 31.4173 9.5632 31.1865 9.40428C30.9557 9.24537 30.6839 9.1563 30.4038 9.14774L27.1514 9.24619C26.957 9.25401 26.7651 9.33824 26.7997 9.61213C26.8363 9.81742 26.8267 10.0283 26.7716 10.2294C26.7166 10.4305 26.6175 10.6169 26.4815 10.7749C26.3455 10.933 26.176 11.0589 25.9854 11.1433C25.7947 11.2278 25.5876 11.2688 25.3791 11.2633L16.2348 11.5432C15.2456 11.5738 14.1485 10.8625 13.5992 10.0183C13.5353 9.91235 13.4441 9.82554 13.3352 9.76696C13.2262 9.70839 13.1035 9.68021 12.9799 9.68537L9.72744 9.78382C9.20485 9.80069 8.93972 10.2292 9.13556 10.7358L24.4102 50.6392C24.5183 50.8976 24.6985 51.1195 24.9293 51.2784C25.1601 51.4373 25.4317 51.5264 25.7118 51.5353L46.3879 50.9012C46.9102 50.8864 47.1774 50.4581 46.982 49.9475Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
