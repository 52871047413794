import {
  VStack,
  Text,
  Icon,
  Box,
  Progress,
  Button,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import { BtnVariant } from '~/customTheme/components/Button';

import { HiCheckCircle } from 'react-icons/hi';

import { Trans, useTranslation } from 'react-i18next';

type DeliveryDiscountProps = {
  orderPrice: number;
  costShareLimit: number; // 補助最低門檻
  costShare: number; // 運費補助金額
  freeLimit: number; // 免運門檻
  onNavigate?: () => void;
};

// 運費優惠補助進度條
export default function DeliveryDiscountProgress({
  orderPrice,
  costShareLimit,
  costShare,
  freeLimit,
  onNavigate,
}: DeliveryDiscountProps) {
  const { t } = useTranslation();
  const isReachCostShareLimit = orderPrice >= costShareLimit;
  const isReachFreeLimit = orderPrice >= freeLimit;
  // 還差多少享運費補助
  const discountShortBy = costShareLimit - orderPrice;
  // 還差多少享免運
  const freeShortBy = freeLimit - orderPrice;
  if ((!costShareLimit || !costShare) && !freeLimit) {
    return <></>;
  }

  return (
    <Box borderColor="wsGray.400" borderWidth="1px" borderRadius="5px" p={3}>
      <VStack spacing={3} align="stretch">
        {costShareLimit && costShare ? (
          <>
            <HStack justify="space-between" align="stretch">
              {isReachCostShareLimit ? (
                <Wrap spacing={1}>
                  <Trans i18nKey="store.order.getDeliverySubsidy" values={{ costShare }}>
                    <Text></Text>
                    <Text fontWeight="bold"></Text>
                  </Trans>
                </Wrap>
              ) : (
                <>
                  <Trans
                    i18nKey="store.order.deliveryDiscountProgress"
                    values={{ discountShortBy, costShare }}
                  >
                    <Wrap spacing={1}>
                      <WrapItem whiteSpace="pre">
                        <Text></Text>
                        <Text fontWeight="bold"></Text>
                        <Text></Text>
                      </WrapItem>
                      <WrapItem whiteSpace="pre">
                        <Text></Text>
                        <Text fontWeight="bold"></Text>
                      </WrapItem>
                    </Wrap>
                  </Trans>
                </>
              )}
              <Icon
                as={HiCheckCircle}
                color={isReachCostShareLimit ? 'wsGreen.500' : 'wsGray.300'}
                boxSize="1.5rem"
              />
            </HStack>
            <Progress
              background="wsGray.300"
              colorScheme="wsGreen"
              size="sm"
              value={(orderPrice / costShareLimit) * 100}
              borderRadius={4}
            />
          </>
        ) : (
          <></>
        )}
        {freeLimit ? (
          <>
            <HStack justify="space-between" align="stretch">
              {isReachFreeLimit ? (
                <Text fontWeight="bold">{t('store.order.reachFreeDeliveryLimit')}</Text>
              ) : (
                <>
                  <Trans i18nKey="store.order.deliveryFreeProgress" values={{ freeShortBy }}>
                    <Wrap spacing={1}>
                      <WrapItem whiteSpace="pre">
                        <Text></Text>
                        <Text fontWeight="bold"></Text>
                        <Text></Text>
                      </WrapItem>
                      <WrapItem whiteSpace="pre">
                        <Text></Text>
                      </WrapItem>
                    </Wrap>
                  </Trans>
                </>
              )}
              <Icon
                as={HiCheckCircle}
                color={isReachFreeLimit ? 'wsGreen.500' : 'wsGray.300'}
                boxSize="1.5rem"
              />
            </HStack>
            <Progress
              background="wsGray.300"
              colorScheme="wsGreen"
              size="sm"
              value={(orderPrice / freeLimit) * 100}
              borderRadius={4}
            />
          </>
        ) : (
          <></>
        )}
        <Button onClick={onNavigate} variant={BtnVariant.SOLID}>
          {t('store.order.continue')}
        </Button>
      </VStack>
    </Box>
  );
}
