import { VStack, Text, Icon, Button, Image, HStack, Link, Divider } from '@chakra-ui/react';

import { BtnVariant } from '~/customTheme/components/Button';

import OrderStatusProgress from './OrderStatusProgress';
import DisplayInput from './DisplayInput';
import DisplayTaxSection from './DisplayTaxSection';
import OrderTypeSection from './OrderTypeSection';
import SectionContainer from '~/container/SectionContainer';
import ProductItem from '~/components/ProductItem';
import GroupMemberProductItems from '~/components/GroupMemberProductItems';
import TotalPriceSection from '~/components/TotalPriceSection';
import LargeOrderNotice from './LargeOrderNotice';
import { CouponInfoBar } from './index';

import {
  IoRestaurantOutline,
  IoCashOutline,
  IoGolfOutline,
  IoChevronForward,
  IoReaderOutline,
} from 'react-icons/io5';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiMapPinLine } from 'react-icons/ri';
import LINEPay from '~/assets/images/LINEPay.png';

import {
  OrderType,
  OrderWithId,
  PaymentType,
  WsOrderStatus,
  PickupTimeRange,
  DeliveryTimeRange,
} from '~/types';
import { format, getDay, add } from 'date-fns';

import { useTranslation } from 'react-i18next';

type OrderDetailProps = {
  order: OrderWithId;
  onOpen: () => void;
  onOpenAlert: () => void;
  onOpenContact: () => void;
};

const MINUTES_IN_A_HOUR = 60;

// 訂單詳細
export default function OrderDetail({
  order,
  onOpen,
  onOpenAlert,
  onOpenContact,
}: OrderDetailProps) {
  const { t } = useTranslation();
  const {
    id,
    status,
    isGroupOrder,
    type,
    delivery,
    discount,
    statusRecord,
    items,
    groupItems,
    paymentType,
    taxType,
    taxInfo,
    shippingFeeDetails,
    store,
    subtotal,
    total,
    paymentInfo,
    preOrderAt,
    createdAt,
    largeOrderPrepareTime,
    reasonForCancelling = [],
    allowPaymentNotRefund = false,
    coupon,
    isLargeOrder,
    customer,
  } = order;

  const paymentTypeText = {
    [PaymentType.NONE]: <></>,
    [PaymentType.CASH]: <Text>{t('store.order.paymentMethodCash')}</Text>,
    [PaymentType.CREDIT]: (
      <HStack justify="space-between">
        <Text>{t('payment.card')}</Text>
        <HStack spacing={1} align="center">
          {paymentInfo?.createdAt && (
            <>
              <Link
                fontSize="xs"
                fontWeight="bold"
                color="wsGray.500"
                textDecoration="none"
                onClick={onOpen}
              >
                {t('store.order.expand')}
              </Link>
              <Icon as={IoChevronForward} color="wsGray.500" boxSize="0.85rem" />
            </>
          )}
        </HStack>
      </HStack>
    ),
    [PaymentType.LINEPAY]: (
      <HStack justify="space-between">
        <Image src={LINEPay} h={4} />
        <HStack spacing={1} align="center">
          {paymentInfo && (
            <>
              <Link
                fontSize="xs"
                fontWeight="bold"
                color="wsGray.500"
                textDecoration="none"
                onClick={onOpen}
              >
                {t('store.order.expand')}
              </Link>
              <Icon as={IoChevronForward} color="wsGray.500" boxSize="0.85rem" />
            </>
          )}
        </HStack>
      </HStack>
    ),
  };

  const handleTimeFormat = ({
    statusTime,
    after = 0,
    range = 0,
  }: {
    statusTime: Date | undefined;
    after?: number;
    range?: number;
  }) => {
    const prepareTimeRange = range
      ? format(add(statusTime || new Date(), { minutes: after + range }), ' ~ HH:mm')
      : '';
    return statusTime
      ? format(add(statusTime, { minutes: after }), `MM/dd(E) HH:mm`) + prepareTimeRange
      : ' - ';
  };

  const orderPrepareMinutes = largeOrderPrepareTime
    ? largeOrderPrepareTime
    : store.orderPrepareMinutes;
  const createdTime = handleTimeFormat({ statusTime: createdAt.toDate() });

  let displayTime = null;
  switch (status) {
    case WsOrderStatus.CANCELLED:
    case WsOrderStatus.FAILED:
    case WsOrderStatus.CREATED:
      displayTime = '-';
      break;
    case WsOrderStatus.COMPLETED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.COMPLETED]?.toDate(),
      });
      break;
    case WsOrderStatus.PICKED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.PICKED]?.toDate(),
        after: Math.floor((delivery?.estimateInTraffic || 0) / MINUTES_IN_A_HOUR),
        range: DeliveryTimeRange,
      });
      break;
    default:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.ACCEPTED]?.toDate(),
        after: orderPrepareMinutes,
        range: type === OrderType.WS_PICKUP ? PickupTimeRange : DeliveryTimeRange,
      });
  }
  if (preOrderAt && status !== WsOrderStatus.COMPLETED) {
    displayTime = handleTimeFormat({
      statusTime: preOrderAt.toDate(),
      after: 0,
      range: type === OrderType.WS_PREORDER_PICKUP ? PickupTimeRange : DeliveryTimeRange,
    });
  }

  const createdTimeForFailNote = format(createdAt.toDate(), 'yyyy/MM/dd HH:mm');

  return (
    <VStack align="stretch" spacing={5}>
      <SectionContainer
        title={t('store.order.progress')}
        icon={<Icon as={IoGolfOutline} boxSize="1.5rem" color="wsBlack" />}
      >
        <OrderStatusProgress
          orderStatus={status}
          type={type}
          isSelfDelivery={delivery?.isSelfDelivery}
          statusRecord={statusRecord}
          createdTime={createdTimeForFailNote}
          total={total}
          isAbandonOrder={allowPaymentNotRefund}
          isTransactionFailed={paymentInfo?.isTransactionFailed}
          reasonForCancelling={reasonForCancelling}
        />
      </SectionContainer>

      <OrderTypeSection isDelivery={!!delivery} />

      {delivery && (
        <SectionContainer
          title={t('address.delivery')}
          icon={<Icon as={RiMapPinLine} boxSize="1.5rem" color="wsBlack" />}
        >
          <DisplayInput displayText={delivery.location.address} />
        </SectionContainer>
      )}

      <SectionContainer
        title={
          status === WsOrderStatus.COMPLETED
            ? t('store.order.completedAt')
            : preOrderAt
            ? t('store.order.preorderTime')
            : t('store.order.estimatedPreparation')
        }
        icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" color="wsBlack" />}
      >
        <VStack spacing={2} align="stretch">
          <DisplayInput displayText={displayTime} />
          <Text fontSize="sm">
            {t('store.order.time')}：{createdTime}
          </Text>
          {isLargeOrder && <LargeOrderNotice />}
        </VStack>
      </SectionContainer>
      <Divider />

      <SectionContainer
        title={t('store.order.items')}
        icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" color="wsBlack" />}
      >
        <VStack align="stretch">
          {isGroupOrder
            ? groupItems.map((groupItem, index) => (
                <GroupMemberProductItems
                  key={`${index}_${groupItem.name}`}
                  groupItem={{ memberId: '', memberName: groupItem.name, items: groupItem.items }}
                />
              ))
            : items.map((item, index) => (
                <ProductItem key={`${id}_${item.id}_${index}`} item={item} />
              ))}
        </VStack>
      </SectionContainer>
      <Divider />

      <SectionContainer
        title={t('store.order.invoice.title')}
        icon={<Icon as={IoReaderOutline} boxSize="1.5rem" color="wsBlack" />}
      >
        <DisplayTaxSection
          email={customer.email}
          isUseInvoice={store.isUseInvoice}
          taxInfo={taxInfo}
          taxType={taxType}
        />
        <Text fontSize="xs">{t('store.order.invoice.note')}</Text>
      </SectionContainer>
      <Divider />
      {/* <TaxRadioGroup /> */}

      <SectionContainer
        title={t('store.order.paymentMethod')}
        icon={<Icon as={IoCashOutline} boxSize="1.5rem" color="wsBlack" />}
      >
        {paymentTypeText[paymentType]}
      </SectionContainer>

      <Divider />

      {coupon && (
        <VStack align="stretch">
          <Text fontSize="xs" fontWeight="bold">
            {t('store.order.couponApplied')}
          </Text>
          <CouponInfoBar coupon={{ type: coupon.type, condition: coupon.condition }} />
        </VStack>
      )}

      <TotalPriceSection
        orderType={type}
        subtotal={subtotal}
        shippingFee={shippingFeeDetails?.customer.value}
        discount={discount}
        total={total}
        freightSubsidy={shippingFeeDetails?.customer.discount}
      />
      {status === WsOrderStatus.CREATED && (
        <Button
          variant={BtnVariant.OUTLINE}
          borderColor="wsGray.400"
          fontWeight="normal"
          fontSize="sm"
          onClick={onOpenAlert}
        >
          {t('store.order.cancelModal.title')}
        </Button>
      )}
      {![
        WsOrderStatus.CREATED,
        WsOrderStatus.COMPLETED,
        WsOrderStatus.FAILED,
        WsOrderStatus.CANCELLED,
      ].includes(status) && (
        <Button
          variant={BtnVariant.OUTLINE}
          borderColor="wsGray.400"
          fontWeight="normal"
          fontSize="sm"
          onClick={onOpenContact}
        >
          {t('store.order.contactStore')}
        </Button>
      )}
    </VStack>
  );
}
