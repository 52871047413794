import React from 'react';
import { Box, Image, VStack, Text } from '@chakra-ui/react';
import EmptyCouponImg from '~/assets/images/Coupon.png';
import { useTranslation } from 'react-i18next';

type EmptyDiscountProps = {};

// 當優惠券列表為空時，顯示的佔位提示
export default function EmptyDiscount(props: EmptyDiscountProps) {
  const { t } = useTranslation();
  return (
    <Box pt={16}>
      <VStack justify="center" align="center" spacing={6}>
        <Image src={EmptyCouponImg} />
        <Text fontSize="sm" color="wsGray.500">
          {t('discount.noCoupons')}
        </Text>
      </VStack>
    </Box>
  );
}
