import { atom } from 'recoil';

import { PreOrderDate } from '~/types';

const preOrderDate = atom<PreOrderDate>({
  key: 'preOrderDate',
  default: null,
});

export default preOrderDate;
