import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { getFirebaseGroupCart } from '~/firebase/getData';
import authAtom from '~/recoil/atom/auth';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
import uidAtom from '~/recoil/atom/uid';
import groupMemberInfoAtom from '~/recoil/atom/groupMemberInfo';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import { useRecoilValue, useRecoilState } from 'recoil';

import LoadingModal from '~/components/LoadingModal';
import JoinGroupOrderModal from '~/components/JoinGroupOrderModal';
import { CartStatus, GroupCartItem } from '~/types';

import { updateGroupCart } from '~/firebase/updateData';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

export default function GroupRequireGlobalDataMiddleware() {
  const { storeId, groupId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: false,
  });
  const store = useRecoilValue(storeLocaleSelector);
  const auth = useRecoilValue(authAtom);
  const uid = useRecoilValue(uidAtom);
  const cartStatus = useRecoilValue(cartStatusAtom);
  const isGotInitData = useRecoilValue(isGotInitDataAtom);
  const [groupMemberInfo, setGroupMemberInfo] = useRecoilState(groupMemberInfoAtom);
  useEffect(() => {
    if (!isGotInitData.auth || !isGotInitData.groupCart) {
      return;
    }

    if (auth) {
      const handleMigrateGroupCartItems = async () => {
        if (groupMemberInfo.memberId !== auth.uid && storeId && groupId) {
          try {
            const groupCart = await getFirebaseGroupCart(storeId, groupId);
            const groupItems = groupCart?.groupItems || [];
            const updateGroupItems = migrateOldUserItemsToNewUserItems(
              groupItems,
              groupMemberInfo.memberId,
              auth.uid,
              auth.uid === uid,
              auth.displayName || '',
            );

            await updateGroupCart(storeId, groupId, { groupItems: updateGroupItems });

            setGroupMemberInfo({
              memberId: auth.uid,
              memberName: auth.displayName || '',
            });
            auth.uid === uid ? onClose() : onOpen();
          } catch (err) {
            console.error('get group cart and update error');
          }
        }
      };

      handleMigrateGroupCartItems();
      // if old uid and auth uid is different, update group cart
    } else {
      setGroupMemberInfo({
        memberId: window.localStorage.getItem('groupMemberId') || '',
        memberName: window.localStorage.getItem('groupMemberName') || '',
      });
      onOpen();
    }
  }, [isGotInitData.auth, isGotInitData.groupCart, groupId, storeId, auth, uid]);

  useEffect(() => {
    if (cartStatus !== CartStatus.ACTIVE) {
      onClose();
    }
  }, [cartStatus]);

  if (!isGotInitData.store || !isGotInitData.auth || !isGotInitData.groupCart || !store) {
    return <LoadingModal isVisible={true} />;
  }

  if (isGotInitData.store && !store) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Outlet />
      <JoinGroupOrderModal
        storeName={store.name}
        isOpen={isOpen}
        onClose={onClose}
        defaultMemberName={groupMemberInfo.memberName}
      />
    </>
  );
}

function migrateOldUserItemsToNewUserItems(
  groupItems: Array<GroupCartItem>,
  oldUid: string,
  newUid: string,
  isHost: boolean,
  authMemberName: string,
): Array<GroupCartItem> {
  const findOldUidGroupItems = groupItems.find(({ memberId }) => memberId === oldUid);
  const findNewUidGroupItems = groupItems.find(({ memberId }) => memberId === newUid);
  if (!findOldUidGroupItems) {
    return groupItems;
  }

  if (findNewUidGroupItems) {
    return groupItems
      .map((groupItem) =>
        groupItem.memberId === newUid
          ? {
              ...findNewUidGroupItems,
              memberName: isHost ? authMemberName : findNewUidGroupItems.memberName,
              items: findNewUidGroupItems.items.concat(findOldUidGroupItems.items),
            }
          : groupItem,
      )
      .filter(({ memberId }) => memberId !== oldUid);
  }

  return [
    ...groupItems,
    {
      memberId: newUid,
      memberName: isHost ? authMemberName : findOldUidGroupItems.memberName,
      items: findOldUidGroupItems.items,
    },
  ].filter(({ memberId }) => memberId !== oldUid);
}
