import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { auth as firebaseAuth } from '~/firebase';

import authAtom from '~/recoil/atom/auth';
import isLoginModalOpenSelector from '~/recoil/selectors/isLoginModalOpen';

export type ContactInfoStr = {
  displayName: string;
  phoneNumber: string;
};

export default function useUser() {
  const auth = useRecoilValue(authAtom);
  const setLoginModalOpen = useSetRecoilState(isLoginModalOpenSelector);

  const contactInfo = useMemo(() => {
    if (!auth || !auth.displayName || !auth.phoneNumber)
      return {
        displayName: '',
        phoneNumber: '',
      };

    return {
      displayName: auth.displayName,
      phoneNumber: auth.phoneNumber,
    };
  }, [auth]);

  const login = () => {
    setLoginModalOpen(true);
  };

  const logout = async () => {
    if (!auth) return;
    try {
      await firebaseAuth.signOut();
    } catch (error) {
      console.log('[firebaseAuth.signOut Error]', error);
    }
  };

  const handleContactInfoUpdateAsync = async ({ displayName, phoneNumber }: ContactInfoStr) => {
    // await userDocRef.update({ //TODO
    //   ...auth,
    //   displayName,
    //   phoneNumber,
    // });
  };

  return {
    auth,
    isLogin: !!auth,
    contactInfo,
    login,
    logout,
    handleContactInfoUpdateAsync,
  };
}
