import { atom } from 'recoil';
import { Locales } from '~/types';

const auth = atom<Locales>({
  key: 'locale',
  default: (window.localStorage.getItem('locale') as Locales) || 'zh_TW',
  dangerouslyAllowMutability: true,
});

export default auth;
