import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerHeader,
  VStack,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';

import {
  IoClipboardOutline,
  IoPeopleOutline,
  IoReceiptOutline,
  IoSearchOutline,
  IoLogIn,
} from 'react-icons/io5';
import { BiStoreAlt } from 'react-icons/bi';
import { StoreInfoIcon } from '~/icons';
import { Coupon } from '~/icons';
import { useTranslation } from 'react-i18next';

import { useParams, useNavigate } from 'react-router-dom';

const navList = [
  {
    title: 'nav.menu',
    icon: IoClipboardOutline,
    link: '',
  },
  {
    title: 'nav.groupOrder',
    icon: IoPeopleOutline,
    link: 'groupOrder',
  },
  {
    title: 'nav.orders',
    icon: IoReceiptOutline,
    link: 'orders',
  },
  {
    title: 'nav.discount',
    icon: Coupon,
    link: 'discount',
  },
  {
    title: 'nav.storeInfo',
    icon: StoreInfoIcon,
    link: 'storeInfo',
  },
];

type NavDrawerProps = {
  isOpen: boolean;
  isLogin: boolean;
  onClose: () => void;
  onLoginModalOpen: () => void;
  onLogout: () => void;
  onSearchModalOpen: () => void;
};

export default function NavDrawer({
  isOpen,
  isLogin,
  onClose,
  onLoginModalOpen,
  onLogout,
  onSearchModalOpen,
}: NavDrawerProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (path: string) => {
    if (storeId && groupId) {
      return navigate(`/${storeId}/group/${groupId}/${path}`);
    }

    if (storeId) {
      return navigate(`/${storeId}/${path}`);
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent maxW="250px">
        <DrawerHeader />
        <DrawerCloseButton
          bg="wsBlack"
          color="white"
          borderRadius="full"
          _focus={{
            boxShadow: 'unset',
          }}
        />
        <DrawerBody>
          <VStack align="stretch" spacing={6}>
            {navList.map(({ title, icon, link }) => {
              return (
                <HStack
                  key={title}
                  align="center"
                  cursor="pointer"
                  onClick={() => handleNavigate(link)}
                >
                  <Icon as={icon} boxSize="1.5rem" />
                  <Text fontSize="lg">{t(title)}</Text>
                </HStack>
              );
            })}
            <HStack align="center" cursor="pointer" onClick={onSearchModalOpen}>
              <Icon as={IoSearchOutline} boxSize="1.5rem" />
              <Text fontSize="lg">{t('nav.search')}</Text>
            </HStack>
            <HStack
              align="center"
              cursor="pointer"
              onClick={() => (isLogin ? onLogout() : onLoginModalOpen())}
            >
              <Icon as={IoLogIn} boxSize="1.5rem" />
              <Text fontSize="lg">{isLogin ? t('nav.logout') : t('nav.login')}</Text>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
