const styles = {
  global: {
    body: {
      w: 'full',
      height: '100vh',
      overflowX: 'hidden',
    },
  },
};

export default styles;
