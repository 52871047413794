import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, VStack, Select } from '@chakra-ui/react';
import { differenceInMinutes, startOfDay } from 'date-fns';

import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import checkOperatingTime from '~/utils/checkOperatingTime';
import { useTranslation } from 'react-i18next';

type PickupDurationSelectProps = {
  durationOptionList: { durationString: string; value: number }[] | undefined;
  selectedDuration: number | undefined;
  selectedDay: Date | undefined;
  preOrderDate: Date | null;
  onDurationSelect: (minutes: number) => void;
};

// 時間選擇
export default function PickupDurationSelect({
  durationOptionList,
  selectedDuration,
  selectedDay,
  preOrderDate,
  onDurationSelect,
}: PickupDurationSelectProps) {
  const operatingTime = useRecoilValue(operatingTimeSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (!durationOptionList || !durationOptionList[0] || !selectedDay || !operatingTime) return;

    if (
      preOrderDate &&
      differenceInMinutes(preOrderDate, startOfDay(preOrderDate)) >= durationOptionList[0]?.value &&
      checkOperatingTime(operatingTime, preOrderDate)
    )
      return;

    const initialValue = durationOptionList[0]?.value;
    onDurationSelect(initialValue);
  }, [durationOptionList, selectedDay, preOrderDate, operatingTime]);

  const handleDurationSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onDurationSelect(Number(e.target.value));
  };

  return (
    <VStack align="stretch">
      <Text fontSize="sm">{t('store.pickUpSetting.select')}</Text>
      <Select
        onChange={handleDurationSelect}
        value={selectedDuration}
        variant="flushed"
        borderBottom="4px solid"
        borderBottomColor="wsBlack"
        _focus={{
          boxShadow: 'unset',
        }}
      >
        {durationOptionList?.map(({ durationString, value }) => {
          return (
            <option key={durationString} value={value}>
              {durationString}
            </option>
          );
        })}
      </Select>
    </VStack>
  );
}
