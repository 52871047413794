import { VStack, Flex, Text, Textarea, TextareaProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type CommonNoteInputProps = {
  textLimit?: number;
} & TextareaProps;

// 餐點備註區塊
export default function NoteInput({ textLimit = 100, ...props }: CommonNoteInputProps) {
  const { t } = useTranslation();

  return (
    <VStack align="stretch" spacing={2}>
      <VStack align="flex-start" px={2} py={1} spacing={1} bgColor="wsGray.200" borderRadius="5px">
        <Text as="h4" fontSize="sm" fontWeight="bold">
          {t('store.item.noteTitle')}
        </Text>
        <Text fontSize="xs" color="wsGray.500">
          {t('store.item.specialRequestsNote')}
        </Text>
      </VStack>
      <VStack align="stretch" spacing={0}>
        <Textarea
          isFullWidth
          fontSize="sm"
          focusBorderColor="Black"
          resize="none"
          borderRadius="4px"
          borderColor="wsGray.400"
          maxLength={textLimit}
          _hover={{
            borderColor: 'wsGray.400',
          }}
          {...props}
        />
        <Flex justify="flex-end">
          {textLimit ? (
            <Text fontSize="xs" color="wsGray.400">{`(${
              props.value?.toString().length
            }/${textLimit})`}</Text>
          ) : (
            <Text h="24px" />
          )}
        </Flex>
      </VStack>
    </VStack>
  );
}
