import { useEffect } from 'react';
import { storeListener } from '~/firebase/listener';
import { useSetRecoilState } from 'recoil';
import storeAtom from '~/recoil/atom/store';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
import { useParams } from 'react-router-dom';

import { StoreWithId } from '~/types';

import updateDocumentHeader from '~/utils/updateDocumentHeader';

export default function useStore() {
  const { storeId = '' } = useParams();

  const setStore = useSetRecoilState(storeAtom);
  const setIsGotInitData = useSetRecoilState(isGotInitDataAtom);

  const handleUpdateStore = (data: StoreWithId | null) => {
    setIsGotInitData((prev) => ({ ...prev, store: true }));
    setStore(data);
    updateDocumentHeader(data?.name, data?.description, data?.logo);
  };

  useEffect(() => {
    const storeUnlisten = storeListener(storeId, handleUpdateStore);

    return () => {
      storeUnlisten?.();
    };
  }, [storeId]);
}
