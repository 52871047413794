import { useState, useEffect } from 'react';
import { VStack, Box, Image, Stack, AspectRatio } from '@chakra-ui/react';
import {
  AddToCartBottomBar,
  FixedBackwardBar,
  ItemModifier,
  ItemInfo,
  NoteInput,
  LoginBottomBar,
  InventoryNoticeModal,
} from './components';
import PageContainer from '~/container/PageContainer';
import LoginModal from '~/components/LoginModal';
import useScroll from '~/hooks/useScroll';

import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';

import { itemSelector } from '~/recoil/selectors/items';
import { useRecoilValue } from 'recoil';

import useModifiersControl from './hooks/useModifiersControl';
import useAllowOperateCart from './hooks/useAllowOperateCart';
import useRemainInventory from './hooks/useRemainInventory';
import useCartItems from '~/hooks/useCartItems';
import useItemModifiers from './hooks/useItemModifiers';

import { CartItem, OrderModifier } from '~/types';
import useUser from '~/hooks/useUser';

import ImageSrcError from '~/assets/images/ImageSrcError.png';

import { useTranslation } from 'react-i18next';

export default function ItemPage() {
  const { t } = useTranslation();
  const { storeId, itemId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { positionInfo } = useScroll();

  const [showError, setShowError] = useState<boolean>(false);
  const [inventoryErrorModal, setInventoryErrorModal] = useState<'none' | 'item' | 'option'>(
    'none',
  );

  const { cartItems, handleAddItem } = useCartItems();

  const item = useRecoilValue(itemSelector(itemId));

  const { isLogin, login } = useUser();

  // check operating time
  const allowOperateCart = useAllowOperateCart(itemId);

  // 選項庫存剩餘量
  const { modifiersWithRemainCount, handleCheckModifierOptionError } = useItemModifiers(
    item?.modifiers || [],
    {},
  );

  // 品項庫存剩餘量 remainInventory 剩餘庫存 , targetItemCount 購物車總共選了多少個
  const { remainInventory, targetItemCount } = useRemainInventory(
    item?.inventory,
    itemId,
    cartItems,
  );

  // control item info hook
  const {
    selectedModifiers,
    modifierErrors,
    optionsTotalPrice,
    count,
    note,
    handleChangeCount,
    handleChangeModifier,
    handleChangeNote,
  } = useModifiersControl(item?.modifiers || null, {});

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!item) {
    return <Navigate to={`/${storeId}`} />;
  }

  const handleClickAddItem = async () => {
    if (modifierErrors.size > 0) {
      setShowError(true);
      return;
    }

    if (count > remainInventory - targetItemCount) {
      console.log('add item inventory error');
      setInventoryErrorModal('item');
      return;
    }

    const modifiers: Array<OrderModifier> = [...selectedModifiers.values()];

    if (handleCheckModifierOptionError(modifiers, count)) {
      console.log('add item option inventory error');
      setInventoryErrorModal('option');
      return;
    }

    const cartItem: CartItem = {
      id: itemId,
      name: item.name,
      price: item.price,
      tax: item.tax,
      modifiers,
      count,
      note,
    };

    handleAddItem(cartItem);
    navigate(`/${storeId}`);
  };

  const handleBackToMenuPage = () => {
    navigate(`/${storeId}`, { state: { from: location.pathname } });
  };

  const displayInventory =
    remainInventory - targetItemCount <= 0 ? 0 : remainInventory - targetItemCount;

  return (
    <PageContainer hideHeader hideFooter>
      <FixedBackwardBar title={t('store.order.continue')} onNavigate={handleBackToMenuPage} />
      <VStack align="stretch" justify="space-between" spacing={4} minH="100%" px={4}>
        <Stack direction={{ base: 'column', md: 'row' }} align="stretch" spacing={10}>
          <Box flex="1" position="relative">
            {item.image && (
              <AspectRatio
                m="auto"
                maxW="1000px"
                ratio={1}
                pos={{ base: 'static', md: 'sticky' }}
                top={20}
                zIndex="50"
              >
                <Image src={item.image} fallbackSrc={ImageSrcError} w="full" />
              </AspectRatio>
            )}
          </Box>
          <VStack flex="1" align="stretch" spacing={5}>
            <ItemInfo
              title={item.name}
              description={item.description}
              price={item.price}
              inventory={displayInventory}
            />
            <ItemModifier
              itemModifiers={modifiersWithRemainCount}
              selectorModifiers={selectedModifiers}
              modifierErrors={modifierErrors}
              showError={showError}
              itemCount={count}
              positionInfo={positionInfo}
              onChangeModifier={handleChangeModifier}
            />
            <NoteInput
              value={note}
              onChange={(e) => handleChangeNote(e.target.value)}
              textLimit={100}
            />
            {!item.image && (
              <Box h="400px" pos={{ base: 'static', md: 'sticky' }} top={20} zIndex="100" />
            )}
          </VStack>
        </Stack>
        {isLogin ? (
          <AddToCartBottomBar
            isDisabled={!allowOperateCart}
            amount={count}
            currentInventory={displayInventory}
            unitPrice={optionsTotalPrice + item.price}
            onChangeCount={handleChangeCount}
            onAddItemClick={handleClickAddItem}
          />
        ) : (
          <LoginBottomBar login={login} />
        )}
      </VStack>
      <LoginModal />
      <InventoryNoticeModal
        isOpen={inventoryErrorModal === 'item'}
        title={t('store.item.soldOutNote')}
        message={t('store.item.reselectNote')}
        onFooterClick={() => {
          setInventoryErrorModal('none');
          navigate(`/${storeId}`);
        }}
      />
      <InventoryNoticeModal
        isOpen={inventoryErrorModal === 'option'}
        title={t('store.item.option.soldOut')}
        message={t('store.item.option.reselectNote')}
        onFooterClick={() => setInventoryErrorModal('none')}
      />
    </PageContainer>
  );
}
