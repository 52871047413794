import {
  addHours,
  startOfDay,
  differenceInDays,
  format,
  addDays,
  isSameDay,
  getHours,
  getMinutes,
  addMinutes,
} from 'date-fns';
import { Schedule, MealPickUpType, Week } from '~/types';

export default function getRecentPreOrderDate(
  mealPickUpType: MealPickUpType,
  currentTime: Date,
  schedule: Schedule,
  {
    preOrderAfter,
    preOrderBefore,
  }: {
    preOrderAfter: number;
    preOrderBefore: number;
  },
): Date | null {
  const ONE_HOUR_MINUTES = 60;
  const TIME_RANGE = mealPickUpType === MealPickUpType.DELIVERY ? 30 : 15;

  // 可預訂幾小時後
  const startTime = addHours(currentTime, preOrderAfter);
  // 幾個小時前可預訂
  const endTime = addHours(currentTime, preOrderBefore);

  // startTime ~ endTime 包含的天數
  const dayRange = differenceInDays(startOfDay(endTime), startOfDay(startTime));

  const recentPreOrderDate = Array.from({ length: dayRange }, (_, index) => {
    return addDays(startOfDay(startTime), index);
  }).reduce((acc: Date | null, date) => {
    if (acc) return acc;

    const weekDaySchedule = [...(schedule[format(date, 'E').toUpperCase() as Week] || [])].sort(
      (a, b) => a.open - b.open,
    );

    // 找出相符的時間段
    const findAvailableTimeRange = weekDaySchedule.find(({ open, close }) => {
      const minutes = isSameDay(date, startTime)
        ? getMinutes(startTime) + getHours(startTime) * ONE_HOUR_MINUTES
        : 0;

      return open <= minutes && close - TIME_RANGE >= minutes;
    });

    if (!findAvailableTimeRange) {
      return acc;
    }

    const { open } = findAvailableTimeRange;

    // 拿出當天可以預訂的分鐘數
    const timeMinutes = isSameDay(date, startTime)
      ? Math.ceil(
          (getMinutes(startTime) + getHours(startTime) * ONE_HOUR_MINUTES - open) / TIME_RANGE,
        ) *
          TIME_RANGE +
        open
      : open;

    return addMinutes(date, timeMinutes);
  }, null);

  return recentPreOrderDate;
}
