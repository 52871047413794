import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, HStack, Text, Icon, Box, StackProps } from '@chakra-ui/react';

import storeMenuAvailablePickupTypesSelector from '~/recoil/selectors/storeMenuAvailablePickupTypes';

import { AiOutlineCar } from 'react-icons/ai';
import { Pickup } from '~/icons';

import { MealPickUpType } from '~/types';
import { useTranslation } from 'react-i18next';

type OrderTypeSwitcherProps = {
  mealPickupType: MealPickUpType;
  disableSwitch?: boolean;
  onMealPickupTypeChange?: (mealPickupType: MealPickUpType) => void;
};

// 取餐放方式切換 自取 or 外送
export default function OrderTypeSwitcher({
  mealPickupType,
  onMealPickupTypeChange,
}: OrderTypeSwitcherProps) {
  const storeMenuAvailablePickupTypes = useRecoilValue(storeMenuAvailablePickupTypesSelector);
  const { t } = useTranslation();

  const handleMealPickupTypeClick = (mealPickupType: MealPickUpType) => {
    onMealPickupTypeChange?.(mealPickupType);
  };

  const handleIsActiveBtn = (isActive: boolean): StackProps => {
    if (isActive) {
      return {
        bgColor: 'wsBlack',
        color: 'white',
      };
    }
    return {
      bgColor: 'white',
      color: 'wsBlack',
    };
  };

  return (
    <>
      <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
        {(storeMenuAvailablePickupTypes.includes(MealPickUpType.DELIVERY) ||
          storeMenuAvailablePickupTypes.length === 0) && (
          <HStack
            onClick={() => handleMealPickupTypeClick(MealPickUpType.DELIVERY)}
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(mealPickupType === MealPickUpType.DELIVERY)}
          >
            <Icon boxSize="1.2rem" as={AiOutlineCar} />
            <Text fontWeight="bold" color="inherit" display="inline-block">
              {t('store.orderType.delivery')}
            </Text>
          </HStack>
        )}
        {(storeMenuAvailablePickupTypes.includes(MealPickUpType.PICKUP) ||
          storeMenuAvailablePickupTypes.length === 0) && (
          <HStack
            onClick={() => handleMealPickupTypeClick(MealPickUpType.PICKUP)}
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(mealPickupType === MealPickUpType.PICKUP)}
          >
            <Icon boxSize="1.2rem" as={Pickup} />
            <Text fontWeight="bold" color="inherit">
              {t('store.orderType.pickUp')}
            </Text>
          </HStack>
        )}
      </Flex>
    </>
  );
}
