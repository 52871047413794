import { useState } from 'react';
import {
  VStack,
  HStack,
  Text,
  Image,
  Center,
  Button,
  useDisclosure,
  Icon,
  useToast,
} from '@chakra-ui/react';

import { IoPeopleOutline } from 'react-icons/io5';
import { BtnVariant } from '~/customTheme/components/Button';

import PageContainer from '~/container/PageContainer';

import PageTitle from '~/components/PageTitle';
import { SectionLabel, GroupOrderModal } from './components';
import image from '~/assets/images/GroupOrder.png';

import { useNavigate, useParams } from 'react-router-dom';

import useCreateGroupCart from '~/hooks/useCreateGroupCart';
import authAtom from '~/recoil/atom/auth';
import { useRecoilValue } from 'recoil';

import { useTranslation } from 'react-i18next';

const GroupOrder = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const auth = useRecoilValue(authAtom);

  const [groupNote, setGroupNote] = useState<string>('');

  const handleCreateGroupCart = useCreateGroupCart();

  const handleOpenModal = () => {
    if (auth) {
      onOpen();
      return;
    }
    toast({
      position: 'top',
      status: 'error',
      title: t('group.loginFirst'),
    });
  };

  return (
    <PageContainer hideHeader>
      <PageTitle
        title={t('group.inviteBtn')}
        onNavigate={() => navigate(groupId ? `/${storeId}/group/${groupId}` : `/${storeId}`)}
      />

      <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={7} height="40rem">
        <SectionLabel icon={IoPeopleOutline} title={t('group.inviteBtn')} />
        <Center>
          <VStack justify="space-between" spacing={6} py={4}>
            <Image src={image} />
            <Text fontSize="sm" color="wsGray.500">
              {t('group.inviteCTANote')}
            </Text>
            <Button
              variant={BtnVariant.SOLID}
              color="white"
              width="10rem"
              onClick={handleOpenModal}
            >
              <HStack>
                <Icon as={IoPeopleOutline} boxSize="1.25rem" />
                <Text fontSize="sm" fontWeight="bold" color="white" letterSpacing={2}>
                  {t('group.inviteBtn')}
                </Text>
              </HStack>
            </Button>
          </VStack>
        </Center>
      </VStack>
      <GroupOrderModal
        isOpen={isOpen}
        onClose={onClose}
        groupNote={groupNote}
        onChangeNote={(value: string) => setGroupNote(value)}
        onConfirm={() => handleCreateGroupCart(groupNote)}
      />
    </PageContainer>
  );
};

export default GroupOrder;
