import React from 'react';
import { StackDivider, VStack, Collapse, useDisclosure, useToast } from '@chakra-ui/react';
import OrderStatusTitle from './OrderStatusTitle';
import OrderTimeInfo from './OrderTimeInfo';
import OrderDetail from './OrderDetail';
import OrderPaymentModal from './OrderPaymentModal';
import CancelAlertDialog from './CancelAlertDialog';

import { getMatchOrderTypeMapString } from '../orderStatusMapString';

import {
  GroupOrderWithId,
  OrderWithId,
  WsOrderStatus,
  OrderType,
  PickupTimeRange,
  DeliveryTimeRange,
} from '~/types';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import add from 'date-fns/add';
import { firestore } from '~/firebase';

import { useTranslation } from 'react-i18next';

type OrderContainerProps = {
  openOrderId: string;
  order: GroupOrderWithId | OrderWithId;
  onChangeOpenOrder: (id: string) => void;
  onOpenContact: () => void;
};

const MINUTES_IN_A_HOUR = 60;

// 訂單資訊 dropdown
export default function OrderContainer({
  openOrderId,
  order,
  onChangeOpenOrder,
  onOpenContact,
}: OrderContainerProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    status,
    type,
    id,
    statusRecord,
    paymentInfo,
    delivery,
    store,
    largeOrderPrepareTime,
    allowPaymentNotRefund,
    preOrderAt,
  } = order;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

  const isMatchId = id === openOrderId;

  const orderTypeMapString = getMatchOrderTypeMapString(type, order?.delivery?.isSelfDelivery);

  const orderPrepareMinutes = largeOrderPrepareTime
    ? largeOrderPrepareTime
    : store.orderPrepareMinutes;

  const handleCancelOrder = async () => {
    if (!isMatchId || status !== WsOrderStatus.CREATED) {
      return;
    }
    try {
      await firestore.doc(`orders/${id}`).update({ status: WsOrderStatus.CANCELLED });
    } catch (error) {
      console.error('cancel order error', error);
      toast({
        position: 'top',
        title: t('store.order.cancelModal.fail'),
        status: 'error',
        duration: 2000,
      });
      return;
    }
    toast({
      position: 'top',
      title: t('store.order.cancelModal.success'),
      status: 'success',
      duration: 2000,
    });
  };

  const handleConfirmCancelOrder = async () => {
    await handleCancelOrder();
    onCloseAlert();
  };

  const handleTimeFormat = ({
    statusTime,
    after = 0,
    range = 0,
  }: {
    statusTime: Date | undefined;
    after?: number;
    range?: number;
  }) => {
    const prepareTimeRange = range
      ? format(add(statusTime || new Date(), { minutes: after + range }), ' ~ HH:mm')
      : '';
    return statusTime
      ? format(add(statusTime, { minutes: after }), `MM/dd(E) HH:mm`) + prepareTimeRange
      : ' - ';
  };

  let displayTime = ' - ';
  switch (status) {
    case WsOrderStatus.COMPLETED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.COMPLETED]?.toDate(),
      });
      break;
    case WsOrderStatus.CANCELLED:
    case WsOrderStatus.FAILED:
      displayTime = ' - ';
      break;
    case WsOrderStatus.PICKED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.PICKED]?.toDate(),
        after: Math.floor((delivery?.estimateInTraffic || 0) / MINUTES_IN_A_HOUR),
        range: DeliveryTimeRange,
      });
      break;
    default:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.ACCEPTED]?.toDate(),
        after: orderPrepareMinutes,
        range: type === OrderType.WS_PICKUP ? PickupTimeRange : DeliveryTimeRange,
      });
  }
  if (preOrderAt && status !== WsOrderStatus.COMPLETED) {
    displayTime = handleTimeFormat({
      statusTime: preOrderAt.toDate(),
      after: 0,
      range: type === OrderType.WS_PREORDER_PICKUP ? PickupTimeRange : DeliveryTimeRange,
    });
  }

  let statusTitle = orderTypeMapString[status] || '';
  if (paymentInfo?.isTransactionFailed) {
    statusTitle = 'store.order.status.paymentFailed';
  }
  if (allowPaymentNotRefund) {
    statusTitle = 'store.order.status.abandonOrder';
  }

  return (
    <VStack align="stretch">
      <OrderStatusTitle
        status={statusTitle}
        onToggle={() => onChangeOpenOrder(id)}
        isMatchId={isMatchId}
        position={isMatchId ? 'sticky' : 'static'}
        top={0}
        zIndex={100}
      />
      {!isMatchId && (
        <OrderTimeInfo time={displayTime} status={status} isPreOrder={preOrderAt ? true : false} />
      )}
      <Collapse in={isMatchId}>
        <OrderDetail
          order={order as OrderWithId}
          onOpen={onOpen}
          onOpenAlert={onOpenAlert}
          onOpenContact={onOpenContact}
        />
      </Collapse>
      <StackDivider borderColor="wsBlack" borderWidth="1px" />

      {!paymentInfo || !paymentInfo.createdAt
        ? ''
        : paymentInfo && (
            <OrderPaymentModal
              last4Digits={paymentInfo.card?.last4Digits}
              createdAt={format(paymentInfo.createdAt.toDate(), 'yyyy-MM-dd HH:mm:ss')}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}

      <CancelAlertDialog
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
        onConfirm={handleConfirmCancelOrder}
      />
    </VStack>
  );
}
