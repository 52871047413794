import {
  VStack,
  Icon,
  Text,
  Link,
  useDisclosure,
  Center,
  Circle,
  Heading,
  Divider,
  Flex,
  Box,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { IoMailOpenOutline, IoTriangleSharp } from 'react-icons/io5';
import CancelModal from './CancelModal';

import { useNavigate, useParams } from 'react-router-dom';

export type MemberContentProps = {
  storeName: string;
  groupHostInfo: { name: string; note: string };
};

// 團員揪團購頁面：所參加的揪團的詳細內容，包括團長的話以及離開揪團的按鈕
export default function MemberContent({ groupHostInfo, storeName }: MemberContentProps) {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  return (
    <VStack justify="space-between" spacing={6}>
      <Center>
        <VStack spacing={4} align="center" justify="space-between">
          <Circle size="3rem" bg="wsGray.200">
            <Icon boxSize="2rem" as={IoMailOpenOutline} />
          </Circle>
          <Heading fontSize="md">{t('group.welcome', { storeName })}</Heading>
          <Text fontSize="sm">{t('group.invite', { hostName: groupHostInfo.name })}</Text>
        </VStack>
      </Center>
      <Divider />
      <VStack align="stretch" spacing={2}>
        {groupHostInfo.note && (
          <>
            <Text fontSize="sm" fontWeight="bold">
              {t('group.note')}
            </Text>
            <Flex direction="column">
              <Icon ml={6} my={-1} as={IoTriangleSharp} color="wsGray.300" boxSize="0.75rem" />
              <Box w="full" px={3} py={2} borderRadius="5px" backgroundColor="wsGray.300">
                <Text fontSize="xs">{groupHostInfo.note}</Text>
              </Box>
            </Flex>
          </>
        )}
      </VStack>
      <Link fontSize="sm" color="wsGray.500" textDecoration="none" onClick={onOpen}>
        {t('group.leave')}
      </Link>

      <CancelModal
        title={t('group.leaveModal.title')}
        notice={t('group.leaveModal.note')}
        isOpen={isOpen}
        onConfirm={() => navigate(`/${storeId}`)}
        onClose={onClose}
      />
    </VStack>
  );
}
