import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogFooter,
  Button,
  Heading,
  Text,
  VStack,
  HStack,
  StackDivider,
} from '@chakra-ui/react';

import { useTranslation, Trans } from 'react-i18next';

type ContactStoreAlertDialogProps = {
  storePhoneNumber: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

// 聯絡店家彈出視窗 （純顯示）
export default function ContactStoreAlertDialog({
  storePhoneNumber,
  isOpen,
  onClose,
  onConfirm,
}: ContactStoreAlertDialogProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogBody my={6}>
          <VStack align="center">
            <Heading fontSize="lg">{t('store.order.contactStoreModal.title')}</Heading>
            <Trans i18nKey="store.order.contactStoreModal.note" values={{ storePhoneNumber }}>
              <Text fontSize="sm">
                <br />
              </Text>
            </Trans>
          </VStack>
        </AlertDialogBody>

        <AlertDialogFooter p={0} borderColor="wsGray.300" borderTopWidth="1px">
          <HStack w="full" spacing={0} divider={<StackDivider />}>
            <Button
              variant="unstyled"
              fontWeight="normal"
              flex="1"
              ref={cancelRef}
              onClick={onClose}
            >
              {t('store.order.contactStoreModal.wait')}
            </Button>
            <Button variant="unstyled" fontWeight="normal" flex="1" onClick={onConfirm}>
              {t('store.order.contactStoreModal.confirm')}
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
