import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import {
  VStack,
  HStack,
  Flex,
  Text,
  Icon,
  StackProps,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react';

import { updateCartData } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';

import OrderTypeSwitcher from '~/components/OrderTypeSwitcher';
import ConfirmModal from '~/components/ConfirmModal';

import useOrderSetting from '~/hooks/useOrderSetting';
import useMealPickupType from '~/hooks/useMealPickupType';
import useModalControl from '~/hooks/useModalControl';

import getPreOrderDateString from '~/utils/getPreOrderDateString';

import { AiOutlineCar, AiOutlineClockCircle } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { RiMapPinLine } from 'react-icons/ri';
import { Pickup } from '~/icons';

import { MealPickUpType, PickMealTime } from '~/types';

import { useTranslation } from 'react-i18next';

// 顯示部分訂餐資訊
export default function MealPickUpSection() {
  const { t } = useTranslation();
  const { storeId = '' } = useParams();
  const auth = useRecoilValue(authAtom);
  const { allowPreOrder } = useRecoilValue(storeOrderSettingSelector);
  const { changeModalOpen } = useModalControl();
  const {
    mealOrderSetting: { mealPickupType, location, pickMealTime, preOrderDate },
  } = useOrderSetting();
  const handleUpdateMealPickupType = useMealPickupType();
  const [currentMealPickupType, setCurrentMealPickupType] = useState(mealPickupType);
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const address = location?.address;

  const handleMealPickupTypeClick = async (clickedMealPickupType: MealPickUpType) => {
    if (clickedMealPickupType === mealPickupType) return;

    handleUpdateMealPickupType(clickedMealPickupType);
  };

  const handleConfirmClearChange = async () => {
    if (!auth) {
      handleUpdateMealPickupType(currentMealPickupType);
      onConfirmClose();
      return;
    }

    try {
      await updateCartData(storeId, auth, {
        items: [],
      });

      handleUpdateMealPickupType(currentMealPickupType);
    } catch (err) {
      console.log('[updateCartData error]', err);
    } finally {
      onConfirmClose();
    }
  };

  const handleCancelClearChange = () => {
    setCurrentMealPickupType(mealPickupType);
    onConfirmClose();
  };

  const handlePickupSettingClick = () => {
    changeModalOpen('isPickupSettingOpen', true);
  };

  return (
    <>
      <VStack align="stretch" borderRadius="5px" bgColor="wsGray.200" p={2}>
        <OrderTypeSwitcher
          mealPickupType={mealPickupType}
          onMealPickupTypeChange={handleMealPickupTypeClick}
        />

        {mealPickupType === MealPickUpType.DELIVERY && (
          <HStack onClick={handlePickupSettingClick} divider={<StackDivider />}>
            {address ? (
              <HStack align="center" flex="1" overflow="hidden">
                <Icon as={RiMapPinLine} boxSize="1.2rem" />
                <Text whiteSpace="nowrap">{address}</Text>
              </HStack>
            ) : (
              <HStack align="center" flex="1" overflow="hidden" color="wsRed.600">
                <Icon as={BiMap} boxSize="1.5rem" color="inherit" />
                <Text whiteSpace="nowrap" color="inherit">
                  {t('store.order.inputAddress')}
                </Text>
              </HStack>
            )}
            {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text w="5.3rem" whiteSpace="nowrap" overflow="hidden">
                  {getPreOrderDateString(preOrderDate, mealPickupType, t)}
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text whiteSpace="nowrap" overflow="hidden">
                  {t('store.pickUpSetting.ASAP')}
                </Text>
              </HStack>
            )}
          </HStack>
        )}
        {mealPickupType === MealPickUpType.PICKUP && (
          <HStack onClick={handlePickupSettingClick} divider={<StackDivider />}>
            {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text whiteSpace="nowrap" overflow="hidden">
                  {getPreOrderDateString(preOrderDate, mealPickupType, t)}
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text w="full" whiteSpace="nowrap" overflow="hidden">
                  {t('store.ASAP')}
                </Text>
              </HStack>
            )}
          </HStack>
        )}
      </VStack>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={t('store.order.changePickTypeModal.title')}
        message={t('store.order.changePickTypeModal.content')}
        onConfirm={handleConfirmClearChange}
        onClose={handleCancelClearChange}
      />
    </>
  );
}
