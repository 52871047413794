import { atom } from 'recoil';
import { CartStatus } from '~/types';

const groupOrderNotice = atom({
  key: 'groupOrderNotice',
  default: {
    isOpen: false,
    cartStatus: CartStatus.CANCELLED,
  },
});

export default groupOrderNotice;
