import { useState, useEffect, useMemo } from 'react';
import { Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { VStack, Box, CenterProps } from '@chakra-ui/react';

import authAtom from '~/recoil/atom/auth';
import cartItemsAtom from '~/recoil/atom/cartItems';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import { categoryItemListSelector } from '~/recoil/selectors/menuItemList';

import PageContainer from '~/container/PageContainer';

import {
  CategoriesMenu,
  CategoryItemList,
  MealPickUpSection,
  StoreInfoSection,
  FixFooterSection,
  FixHeaderSection,
  OrderHeaderNotice,
  GroupOrderNoticeModal,
  FixFooterLoginSection,
  EmptyMenu,
} from './components';
import ImageSlick from '~/components/ImageSlick';
import PickupSettingModal from '~/components/PickupSettingModal';
import SearchItemModal from '~/components/SearchItemModal';
import EditAddressModal from '~/components/EditAddressModal';
import LoginModal from '~/components/LoginModal';
import NotOperatingTimeNotice from '~/components/NotOperatingTimeNotice';

import useScroll from '~/hooks/useScroll';
import useItemPosition from './hooks/useItemPosition';
import usePickCategory from './hooks/usePickCategory';

import { inProgressOrderNumberListener } from '~/firebase/listener';

import { IoReceiptOutline } from 'react-icons/io5';

import calcCartItemsPrice from '~/utils/calcCartItemsPrice';
import { sumBy } from 'lodash';

import useUser from '~/hooks/useUser';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

export default function MenuPage() {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const categoryItemList = useRecoilValue(categoryItemListSelector);
  const menuCategoryList = useMemo(
    () =>
      categoryItemList.map(({ categoryId, categoryName }) => ({
        label: categoryName,
        value: categoryId,
      })),
    [categoryItemList],
  );

  const { selectedCategory, handleChangeCategory, handleSaveSelectedCategory } = usePickCategory();
  const { positionInfo } = useScroll();
  const { isLogin, login } = useUser();

  const [orderNum, setOrderNum] = useState<number>(0);

  const store = useRecoilValue(storeLocaleSelector);
  const auth = useRecoilValue(authAtom);
  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);

  const cartItems = useRecoilValue(cartItemsAtom);

  const handleSetOrdersNum = (num: number) => {
    setOrderNum(num);
  };

  const handleNavigateToOrders = () => {
    navigate(`/${storeId}/orders`);
  };

  useEffect(() => {
    let listener = () => {};

    if (auth && storeId) {
      listener = inProgressOrderNumberListener(storeId, auth, handleSetOrdersNum);
    } else {
      handleSetOrdersNum(0);
    }

    return () => {
      listener?.();
    };
  }, [storeId, auth]);

  if (!store) {
    return <Navigate to="404" />;
  }

  const orderHeaderNoticeStyles: CenterProps = {
    top: 2,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 20,
    position: 'absolute',
    margin: 'auto',
    w: 'full',
  };

  const cartItemsTotal = calcCartItemsPrice(cartItems);
  const cartItemsAmount = sumBy(cartItems, ({ count }) => count);

  const isAllowOrder = store.allowOrders;
  const isAllowRealTimeOrder = store.allowRealTimeOrder;
  const isAllowPreOder = store.preOrderSetting.allowPreOrder;
  const preOrderSetting = store.preOrderSetting;

  return (
    <>
      <PageContainer
        beforeHeaderComponent={
          <FixHeaderSection
            orderNum={orderNum}
            menuCategoryList={menuCategoryList}
            selectedCategory={selectedCategory}
            positionInfo={positionInfo}
            onChangeCategory={handleChangeCategory}
            onNavigateToOrders={handleNavigateToOrders}
          />
        }
      >
        <Box w="full" overflow="hidden" position="relative">
          {orderNum > 0 && (
            <OrderHeaderNotice
              {...orderHeaderNoticeStyles}
              orderNum={orderNum}
              handleNavigateToOrders={handleNavigateToOrders}
            />
          )}
          <ImageSlick images={store.images} />
        </Box>
        <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={4}>
          <NotOperatingTimeNotice
            time={preOrderDate}
            mealPickUpType={mealPickupType}
            pickMealTime={pickMealTime}
            timeRange={store.operatingTime}
            isAllowOrder={isAllowOrder}
            isAllowRealTimeOrder={isAllowRealTimeOrder}
            isAllowPreOder={isAllowPreOder}
            preOrderRange={{
              preOrderBefore: preOrderSetting.preOrderBefore,
              preOrderAfter: preOrderSetting.preOrderAfter,
            }}
          />

          <StoreInfoSection name={store.name} operatingTime={store.operatingTime} />
          <MealPickUpSection />
          {menuCategoryList.length > 0 ? (
            <>
              <CategoriesMenu
                menuCategoryList={menuCategoryList}
                selectedCategory={selectedCategory}
                onChangeCategory={handleChangeCategory}
              />
              <VStack spacing={6} align="stretch">
                {categoryItemList.map((categoryItemInfo) => {
                  return (
                    <CategoryItemList
                      key={categoryItemInfo.categoryId}
                      categoryId={categoryItemInfo.categoryId}
                      categoryItemInfo={categoryItemInfo}
                      positionInfo={positionInfo}
                      selectedCategory={selectedCategory}
                      onChangeCategory={handleChangeCategory}
                      onSaveSelectedCategory={handleSaveSelectedCategory}
                    />
                  );
                })}
              </VStack>
            </>
          ) : (
            <EmptyMenu />
          )}
        </VStack>

        {isLogin ? (
          <FixFooterSection subtotal={cartItemsTotal} itemAmount={cartItemsAmount} />
        ) : (
          <FixFooterLoginSection login={login} />
        )}
      </PageContainer>

      <PickupSettingModal />

      <GroupOrderNoticeModal icon={IoReceiptOutline} />

      <EditAddressModal />
      <SearchItemModal />
      <LoginModal />
    </>
  );
}
