import { VStack, HStack, Text, Divider, Button, Icon, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IoPhonePortraitOutline,
  IoCalendarOutline,
  IoMaleFemaleOutline,
  IoReaderOutline,
  IoCreateOutline,
} from 'react-icons/io5';
import { GrLocation } from 'react-icons/gr';

import PageContainer from '~/container/PageContainer';
import { PageTitle, SectionLabel, EditBaseInfoModal } from './components';

import { useRecoilValue } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import useLocationList from '~/components/DeliverySettingSection/hooks/useLocationList';

import { useTranslation } from 'react-i18next';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const auth = useRecoilValue(authAtom);
  const { userLocationList } = useLocationList();
  const firstAddress = userLocationList ? userLocationList[0].address : '-';

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { storeId } = useParams();
  const navigate = useNavigate();

  return (
    <PageContainer hideHeader>
      <PageTitle
        title={t('personalInfo.memberCenter')}
        onNavigate={() => navigate(`/${storeId}/discount`)}
      />
      <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={7}>
        {auth && (
          <VStack
            align="stretch"
            p={3}
            borderRadius="5px"
            borderWidth="1px"
            borderColor="wsGray.400"
            divider={<Divider />}
          >
            <Text fontWeight="bold" fontSize="md">
              {auth.displayName}
            </Text>
            <VStack align="stretch" py={3} spacing={3}>
              <SectionLabel
                icon={IoPhonePortraitOutline}
                content={auth.phoneNumber ? auth.phoneNumber : '-'}
              />
              <SectionLabel icon={IoCalendarOutline} />
              <SectionLabel icon={IoMaleFemaleOutline} />
              <SectionLabel icon={GrLocation} content={firstAddress} />
              <SectionLabel icon={IoReaderOutline} />

              {/* <Button
                isFullWidth
                h="auto"
                py={3}
                bgColor="wsGray.300"
                letterSpacing={2}
                color="wsBlack"
                fontWeight="normal"
                _hover={{
                  bgColor: 'wsGray.400',
                }}
                _focus={{
                  borderColor: 'wsBlack',
                }}
              >
                <HStack>
                  <Text>編輯個人資訊</Text>
                  <Icon as={IoCreateOutline} boxSize="1.25rem" />
                </HStack>
              </Button> */}
            </VStack>
          </VStack>
        )}
      </VStack>
      <EditBaseInfoModal isOpen={isOpen} onClose={onClose} />
    </PageContainer>
  );
};

export default PersonalInfo;
