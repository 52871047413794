import { updateCartData } from '~/firebase/updateData';
import { useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import cartItemsAtom from '~/recoil/atom/cartItems';
import { useRecoilValue, useRecoilState } from 'recoil';
import { CartItem } from '~/types';

export default function useCartItems() {
  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);
  const [cartItems, setCartItems] = useRecoilState(cartItemsAtom);

  const handleAddItem = async (cartItem: CartItem) => {
    if (cartItem.count <= 0) return;

    if (!auth) {
      setCartItems((prev) => [...prev, cartItem]);
      return;
    }

    if (storeId) {
      try {
        await updateCartData(storeId, auth, {
          items: [...cartItems, cartItem],
        });
      } catch (err) {
        console.error('add cart items error');
      }
    }
  };

  const handleDeleteItem = async (itemId: string, index: number) => {
    const updateCartItems = cartItems.filter(({ id }, i) => id !== itemId || index !== i);

    if (!auth) {
      setCartItems(updateCartItems);
      return;
    }

    if (storeId) {
      try {
        await updateCartData(storeId, auth, {
          items: updateCartItems,
        });
      } catch (err) {
        console.error('delete cart items error');
      }
    }
  };

  const handleEditItem = async (cartItem: CartItem, index: number) => {
    if (cartItem.count <= 0) {
      handleDeleteItem(cartItem.id, index);
      return;
    }

    const updateCartItems = cartItems.map((item, i) => {
      return cartItem.id === item.id && i === index ? cartItem : item;
    });

    if (!auth) {
      setCartItems(updateCartItems);
      return;
    }

    if (storeId) {
      try {
        await updateCartData(storeId, auth, {
          items: updateCartItems,
        });
      } catch (err) {
        console.error('edit cart items error');
      }
    }
  };

  return {
    cartItems,
    handleEditItem,
    handleAddItem,
    handleDeleteItem,
  };
}
