import firebase from 'firebase';
import { updateCartData, updateGroupCart } from '~/firebase/updateData';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import locationAtom from '~/recoil/atom/location';
import { useRecoilValue } from 'recoil';

import getDeliveryQuoteAsync from '~/utils/getDeliveryQuoteAsync';
import { MealPickUpType, PickMealTime, ShippingFeeSetting } from '~/types';
import shippingFee from '~/recoil/atom/shippingFee';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

export default function useDeliveryQuote() {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const location = useRecoilValue(locationAtom);
  const store = useRecoilValue(storeLocaleSelector);
  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);

  const handleGetDeliveryQuote = async (
    quoteRequest: {
      store: {
        location: {
          geopoint: {
            latitude: number;
            longitude: number;
          };
          address: string;
        };
        orderPrepareMinutes: number;
        shippingFeeSetting: ShippingFeeSetting;
      };
      customer: {
        address: string;
        pickupDate: Date;
      };
    },
    auth: firebase.User,
  ) => {
    try {
      const token = await auth?.getIdToken();
      const shippingFee = await getDeliveryQuoteAsync({ ...quoteRequest, authToken: '' });
      return shippingFee;
    } catch (err) {
      console.log('get shipping fee error', shippingFee);
    }
    return null;
  };

  useEffect(() => {
    if (store && auth && location.address && mealPickupType === MealPickUpType.DELIVERY) {
      const handleUpdate = async () => {
        const shippingFee = await handleGetDeliveryQuote(
          {
            store: {
              location: {
                geopoint: {
                  latitude: store.location.geopoint.latitude,
                  longitude: store.location.geopoint.longitude,
                },
                address: store.location.address,
              },
              orderPrepareMinutes: store.orderPrepareMinutes,
              shippingFeeSetting: store.delivery.shippingFeeSetting,
            },
            customer: {
              address: location.address,
              pickupDate:
                pickMealTime === PickMealTime.RESERVATION ? preOrderDate || new Date() : new Date(),
            },
          },
          auth,
        );

        if (storeId && groupId) {
          try {
            await updateGroupCart(storeId, groupId, {
              shippingFee,
            });
          } catch (err) {
            console.error('update group cart shippingFee error');
          }
        }
        if (storeId) {
          try {
            await updateCartData(storeId, auth, {
              shippingFee,
            });
          } catch (err) {
            console.error('update cart shippingFee error');
          }
        }
      };
      handleUpdate();
    }
  }, [
    location.address,
    mealPickupType,
    auth,
    preOrderDate,
    pickMealTime,
    storeId,
    groupId,
    store?.location.geopoint.latitude,
    store?.location.geopoint.longitude,
    store?.location.address,
    store?.orderPrepareMinutes,
    store?.delivery.shippingFeeSetting?.baseFee,
    store?.delivery.shippingFeeSetting?.rateType,
    store?.delivery.shippingFeeSetting?.rates,
  ]);
}
