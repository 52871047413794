import { selector } from 'recoil';
import { MealPickUpType } from '~/types';
import { storeLocaleSelector } from './storeLocale';

const storeMenuAvailablePickupTypes = selector<Array<MealPickUpType>>({
  key: 'storeMenuAvailablePickupTypes',
  get: ({ get }) => {
    const store = get(storeLocaleSelector);
    const menus = store?.menus || {};

    const menusList = Object.values(menus);

    if (menusList.length === 0) {
      return [MealPickUpType.DELIVERY, MealPickUpType.PICKUP];
    }

    const mealPickupTypeSet = menusList.reduce((acc, { allowDelivery, allowPickup }) => {
      if (allowDelivery) {
        acc.add(MealPickUpType.DELIVERY);
      }
      if (allowPickup) {
        acc.add(MealPickUpType.PICKUP);
      }

      return acc;
    }, new Set() as Set<MealPickUpType>);

    let mealPickupTypes: Array<MealPickUpType> = [];
    mealPickupTypeSet.forEach((type) => {
      mealPickupTypes.push(type);
    });

    return mealPickupTypes;
  },
});

export default storeMenuAvailablePickupTypes;
