import { firestore } from '~/firebase';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import emailAtom from '~/recoil/atom/email';

import debounce from 'lodash/debounce';

const emailValidReg = /.@./;

export default function useEmail() {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const [email, setEmail] = useRecoilState(emailAtom);
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    if (!emailValidReg.test(email)) {
      setEmailError('store.order.error.invalidEmail');
    } else {
      setEmailError('');
    }
  }, [email]);

  const handleUpdateEmail = useCallback(
    debounce(async (email: string) => {
      if (!emailValidReg.test(email)) {
        setEmailError('store.order.error.invalidEmail');
        return;
      }

      if (!auth) {
        setEmail(email);
        return;
      }

      if (storeId && groupId) {
        try {
          await firestore.doc(`stores/${storeId}/carts/${groupId}`).update('email', email);
        } catch (err) {
          console.error('change email error', err);
        }
        return;
      }

      if (storeId) {
        try {
          await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).update('email', email);
        } catch (err) {
          console.error('change email error', err);
        }
      }
    }, 700),
    [],
  );

  const handleChangeEmail = (email: string) => {
    setEmail(email);
    handleUpdateEmail(email);
  };

  return { email, emailError, handleChangeEmail };
}
