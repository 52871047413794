import { useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  InputRightAddon,
  Spinner,
  Text,
} from '@chakra-ui/react';
import FormInputMessage from '~/components/FormInputMessage';

import authAtom from '~/recoil/atom/auth';
import { useRecoilValue } from 'recoil';

import useLogin from '~/hooks/useLogin';
import { useTranslation } from 'react-i18next';

export default function LoginForm() {
  const auth = useRecoilValue(authAtom);
  const { t } = useTranslation();
  const {
    loginState,
    isVerifying,
    verificationCredential,
    isTimerStart,
    restTime,
    handleSendVerificationCode,
    handleUpdateName,
    handleUpdatePhoneNumber,
    handleUpdateVerificationCode,
    handleClear,
  } = useLogin();

  useEffect(() => {
    if (auth) {
      handleClear();
    }
  }, [auth]);

  const { phoneNumber, name, verificationCode, errors } = loginState;

  const sendVerificationCodeButtonProps = isTimerStart
    ? {
        cursor: 'not-allowed',
        bgColor: 'wsGray.300',
        color: 'wsGary.400',
        borderColor: 'wsGray.400',
      }
    : {
        cursor: 'pointer',
        bgColor: 'wsBlack',
        color: 'white',
        borderColor: 'wsBlack',
        onClick: handleSendVerificationCode,
      };

  return (
    <VStack>
      <FormControl isRequired>
        <FormLabel fontWeight="normal">{t('loginForm.name')}</FormLabel>
        <InputGroup>
          <Input
            value={name}
            isDisabled={isVerifying}
            isInvalid={!!errors.name}
            onChange={(e) => handleUpdateName(e.target.value)}
          />
        </InputGroup>
        {errors.name && <FormInputMessage type="error" message={t(errors.name)} />}
      </FormControl>

      <FormControl isRequired>
        <FormLabel fontWeight="normal">{t('loginForm.phone')}</FormLabel>
        <InputGroup>
          <Input
            type="tel"
            inputMode="tel"
            value={phoneNumber}
            isDisabled={isVerifying}
            isInvalid={!!errors.phoneNumber}
            onChange={(e) => handleUpdatePhoneNumber(e.target.value)}
          />
          <InputRightAddon {...sendVerificationCodeButtonProps}>
            {t('loginForm.sendCode')}
          </InputRightAddon>
        </InputGroup>
        {errors.phoneNumber && <FormInputMessage message={t(errors.phoneNumber)} type="error" />}
        {isTimerStart && (
          <Text textAlign="right" fontSize="sm">{`${t('personalInfo.countdown')}：${restTime}${t(
            'seconds',
          )}`}</Text>
        )}
      </FormControl>

      {verificationCredential && (
        <FormControl isRequired>
          <FormLabel fontWeight="normal">{t('loginForm.code')}</FormLabel>
          <InputGroup>
            <Input
              type="text"
              inputMode="numeric"
              value={verificationCode}
              isDisabled={isVerifying}
              isInvalid={!!errors.verificationCode}
              onChange={(e) => handleUpdateVerificationCode(e.target.value)}
            />
            <InputRightElement>{isVerifying && <Spinner color="wsBlack" />}</InputRightElement>
          </InputGroup>

          {errors.verificationCode && (
            <FormInputMessage message={t(errors.verificationCode)} type="error" />
          )}
        </FormControl>
      )}
    </VStack>
  );
}
