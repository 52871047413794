import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Heading: ComponentSingleStyleConfig = {
  baseStyle: {
    letterSpacing: '0.1em',
    color: 'wsBlack',
  },
};

export default Heading;
