import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
  InputProps,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import FormInputMessage from '~/components/FormInputMessage';
import useCounter from '../useCounter';
import checkValidPhoneNumber from '~/utils/checkValidPhoneNumber';

import { useTranslation } from 'react-i18next';

type PhoneNumberProps = {
  value: string;
  isVerifying: boolean;
  isCancel: boolean;
  error: string | null;
  onSendCode: () => void;
} & InputProps;

// 電話號碼輸入
export default function PhoneNumber({
  value,
  isVerifying,
  isCancel,
  error,
  onSendCode,
  ...props
}: PhoneNumberProps) {
  const { t } = useTranslation();
  const { count, handleReStart, handleReset } = useCounter(60);
  const [isSentVerification, setIsSentVerification] = useState(false);

  const handleOnClick = () => {
    if (!(count > 0 && count < 60)) {
      onSendCode();
      if (!value) {
        return;
      }

      const errorString = checkValidPhoneNumber(value);

      if (!!errorString.error) {
        return;
      }

      handleReStart();
      setIsSentVerification(true);
    }
  };

  useEffect(() => {
    if (count === 0) {
      setIsSentVerification(false);
    }
  }, [count]);

  useEffect(() => {
    if (isCancel) {
      handleReset();
      setIsSentVerification(false);
    }
  }, [isCancel]);

  return (
    <VStack align="flex-start">
      <FormControl id="first-name">
        <FormLabel fontWeight="normal" letterSpacing={2}>
          {t('personalInfo.phone')}
        </FormLabel>
        <InputGroup>
          <Input defaultValue={value} width="inherit" type="tel" isInvalid={!!error} {...props} />
          <InputRightAddon
            cursor={isSentVerification ? 'no-drop' : 'pointer'}
            children={t('personalInfo.editPhone')}
            bgColor={isVerifying || isSentVerification ? 'wsGray.300' : 'wsBlack'}
            color={isVerifying || isSentVerification ? 'wsGray.400' : 'white'}
            borderColor="wsBlack"
            onClick={handleOnClick}
          />
        </InputGroup>
        {error && <FormInputMessage message={t(error)} type="error" />}
        {console.log(error, isSentVerification)}
        {!error && isSentVerification && (
          <HStack justify="flex-end">
            <Text fontSize="sm">
              {t('personalInfo.countdown')} {count}
              {t('seconds')}
            </Text>
          </HStack>
        )}
      </FormControl>
    </VStack>
  );
}
