import { useState, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import useDeliveryLocation from '~/hooks/useDeliveryLocation';
import useModalControl from '~/hooks/useModalControl';

import { Location } from '~/types';

const currentLocationIndexAtom = atom<number | null>({
  key: 'currentLocationIndex',
  default: null,
});

export default function useLocationModal() {
  const [currentLocationIndex, setCurrentLocationIndex] = useRecoilState(currentLocationIndexAtom);
  const { userLocationList, handleLocationUpdate, handleLocationAdd } = useDeliveryLocation();

  const { modalControl, changeModalOpen } = useModalControl();

  const editedLocation = useMemo(() => {
    if (!userLocationList || currentLocationIndex === null) return null;
    return userLocationList[currentLocationIndex];
  }, [userLocationList, currentLocationIndex]);

  const startEdit = (index?: number) => {
    //可能為0
    if (index === undefined || index < 0) {
      setCurrentLocationIndex(null);
      changeModalOpen('isEditAddressOpen', true);
      return;
    }

    setCurrentLocationIndex(index);
    changeModalOpen('isEditAddressOpen', true);
  };

  const close = () => {
    setCurrentLocationIndex(null);
    changeModalOpen('isEditAddressOpen', false);
  };

  const submitLocation = async (location: Location, index: number | null) => {
    const isEditMode = !!editedLocation && index !== null;
    const { address, floor, note } = location;

    if (isEditMode) {
      await handleLocationUpdate(index as number, {
        address,
        floor,
        note,
      });
    } else {
      await handleLocationAdd({ address, floor, note });
    }

    changeModalOpen('isEditAddressOpen', false);
  };

  return {
    isModalVisible: modalControl.isEditAddressOpen,
    startEdit,
    close,
    submitLocation,
    editedLocationIndex: currentLocationIndex,
    editedLocation,
  };
}
