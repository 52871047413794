import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type IsLoginTextProps = {
  isLogin: boolean;
  onLogin: () => void;
  onLogout: () => void;
};

// 是否登入訊息提示
export default function IsLoginText({ isLogin, onLogin, onLogout }: IsLoginTextProps) {
  const { t } = useTranslation();
  return (
    <HStack w="full" justify="flex-end">
      {isLogin ? (
        <>
          <Text fontSize="sm">{t('store.deliverySetting.notMyInfo')}</Text>
          <Text onClick={onLogout} fontSize="sm" fontWeight="bold">
            {t('store.deliverySetting.logout')}
          </Text>
        </>
      ) : (
        <>
          <Text fontSize="sm">{t('store.deliverySetting.usedBefore')}</Text>
          <Text onClick={onLogin} fontSize="sm" fontWeight="bold">
            {t('store.deliverySetting.login')}
          </Text>
        </>
      )}
    </HStack>
  );
}
