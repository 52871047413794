import { OrderType, WsOrderStatus } from '~/types';
import {
  deliveryMapString,
  preOrderDeliveryMapString,
  selfDeliveryMapString,
  preOrderSelfDeliveryMapString,
  preOrderSelfPickupMapString,
  selfPickupMapString,
} from '../../orderStatusMapString';

export type StatusInfo = {
  label: string;
  status: WsOrderStatus;
};

export const deliveryProgress: Array<StatusInfo> = [
  {
    label: deliveryMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: deliveryMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: deliveryMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: deliveryMapString[WsOrderStatus.PICKED],
    status: WsOrderStatus.PICKED,
  },
  {
    label: deliveryMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const preOrderDeliveryProgress: Array<StatusInfo> = [
  {
    label: preOrderDeliveryMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: preOrderDeliveryMapString[WsOrderStatus.CONFIRMED],
    status: WsOrderStatus.CONFIRMED,
  },
  {
    label: preOrderDeliveryMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: preOrderDeliveryMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: preOrderDeliveryMapString[WsOrderStatus.PICKED],
    status: WsOrderStatus.PICKED,
  },
  {
    label: preOrderDeliveryMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const selfDeliveryProgress: Array<StatusInfo> = [
  {
    label: selfDeliveryMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: selfDeliveryMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: selfDeliveryMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: selfDeliveryMapString[WsOrderStatus.PICKED],
    status: WsOrderStatus.PICKED,
  },
  {
    label: selfDeliveryMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const preOrderSelfDeliveryProgress: Array<StatusInfo> = [
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.CONFIRMED],
    status: WsOrderStatus.CONFIRMED,
  },
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.PICKED],
    status: WsOrderStatus.PICKED,
  },
  {
    label: preOrderSelfDeliveryMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const selfPickupProgress: Array<StatusInfo> = [
  {
    label: selfPickupMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const preOrderSelfPickupProgress: Array<StatusInfo> = [
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.CONFIRMED],
    status: WsOrderStatus.CONFIRMED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export function getMatchOrderTypeProgress(
  orderType: OrderType,
  isSelfDelivery: boolean = false,
): Array<StatusInfo> {
  switch (orderType) {
    case OrderType.WS_PICKUP: {
      return selfPickupProgress;
    }
    case OrderType.WS_DELIVERY: {
      if (isSelfDelivery) {
        return selfDeliveryProgress;
      }
      return deliveryProgress;
    }
    case OrderType.WS_PREORDER_PICKUP: {
      return preOrderSelfPickupProgress;
    }
    case OrderType.WS_PREORDER_DELIVERY: {
      if (isSelfDelivery) {
        return preOrderSelfDeliveryProgress;
      }
      return preOrderDeliveryProgress;
    }
    default: {
      return [];
    }
  }
}
