import { Navigate } from 'react-router-dom';
import defaultStoreId from '~/domainMap';

const Root = () => {
  if (defaultStoreId === '') {
    return null;
  }

  return <Navigate to={`/${defaultStoreId}`} replace />;
};

export default Root;
