import { Menu, Category } from '~/types';
import checkOperatingTime from './checkOperatingTime';
export default function checkMenuOperatingTime(
  menus: Record<string, Menu>,
  categories: Record<string, Category>,
  itemId: string,
  time: Date = new Date(),
): boolean {
  return Object.values(menus).reduce((acc: boolean, { schedule, categories: menuCategories }) => {
    const items = menuCategories.reduce((acc: Array<string>, categoryId) => {
      return acc.concat(categories[categoryId]?.items || []);
    }, []);
    if (items.some((id) => id === itemId)) {
      return acc || checkOperatingTime(schedule, time);
    }
    return acc;
  }, false);
}
