import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, HStack, VStack, Text, Icon, StackDivider, useDisclosure } from '@chakra-ui/react';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiMapPinLine } from 'react-icons/ri';
import { IoChevronDown, IoChevronUp, IoCheckmarkSharp } from 'react-icons/io5';

import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';

import { ChangeCategoryFn } from '~/pages/GroupMenu';
import { ScrollPositionInfo } from '~/hooks/useScroll';
import useOrderSetting from '~/hooks/useOrderSetting';
import useModalControl from '~/hooks/useModalControl';

import LeaderHeader from './LeaderHeader';
import MemberHeader from './MemberHeader';

import { MenuCategoryList, SelectedCategory } from '~/pages/Menu/types';
import { MealPickUpType, PickMealTime } from '~/types';

import getPreOrderDateString from '~/utils/getPreOrderDateString';
import { CancelModal } from '~/pages/GroupInvite/components';
import { useNavigate, useParams } from 'react-router-dom';
import { updateGroupCart } from '~/firebase/updateData';
import { CartStatus } from '~/types';

import { useTranslation } from 'react-i18next';

type FixHeaderSectionProps = {
  isHost: boolean;
  hostName: string;
  menuCategoryList: MenuCategoryList;
  selectedCategory: SelectedCategory;
  positionInfo: ScrollPositionInfo;
  onChangeCategory: ChangeCategoryFn;
};

// （團購時）定在螢幕上方顯示取餐資訊及類別選擇
export default function FixHeaderSection({
  isHost,
  hostName,
  menuCategoryList,
  selectedCategory,
  positionInfo,
  onChangeCategory,
}: FixHeaderSectionProps) {
  const { t } = useTranslation();
  const { changeModalOpen } = useModalControl();
  const { allowPreOrder } = useRecoilValue(storeOrderSettingSelector);
  const {
    mealOrderSetting: { mealPickupType, location, pickMealTime, preOrderDate },
  } = useOrderSetting();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    isOpen: isSectionDisplay,
    onOpen: onSectionOpen,
    onClose: onSectionClose,
  } = useDisclosure({ isOpen: positionInfo.scrollTop >= 400 });

  const handleClickCategory = (value: string) => {
    onChangeCategory(value, false);
    onClose();
  };

  useEffect(() => {
    if (positionInfo.scrollTop > 400) {
      onSectionOpen();
    } else {
      onSectionClose();
      onClose();
    }
  }, [positionInfo]);

  const { storeId, groupId } = useParams();

  const {
    isOpen: isLeaderNoticeOpen,
    onOpen: onLeaderNoticeOpen,
    onClose: onLeaderNoticeClose,
  } = useDisclosure();

  const {
    isOpen: isMemberNoticeOpen,
    onOpen: onMemberNoticeOpen,
    onClose: onMemberNoticeClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const handleLeaveGroup = () => {
    navigate(`/${storeId}`);
  };

  const handleCancelGroup = async () => {
    if (storeId && groupId) {
      await updateGroupCart(storeId, groupId, {
        status: CartStatus.CANCELLED,
      });
    }
  };

  return (
    <Box
      position="fixed"
      maxWidth="900px"
      top={0}
      w="full"
      zIndex="1"
      transition="0.3s"
      transform={`translateY(${isSectionDisplay ? '0' : '-100%'})`}
    >
      {isHost ? (
        <LeaderHeader onOpen={onLeaderNoticeOpen} />
      ) : (
        <MemberHeader leader={hostName} onOpen={onMemberNoticeOpen} />
      )}

      {mealPickupType === MealPickUpType.DELIVERY && (
        <HStack
          onClick={isHost ? () => changeModalOpen('isPickupSettingOpen', true) : () => {}}
          px={3}
          py={2}
          divider={<StackDivider borderColor="wsGray.400" borderWidth="1px" />}
          bgColor="wsGray.200"
        >
          {location ? (
            <HStack flex="1" overflow="hidden">
              <Icon as={RiMapPinLine} boxSize="1.2rem" />
              <Text whiteSpace="nowrap" overflow="hidden">
                {location.address}
              </Text>
            </HStack>
          ) : (
            <HStack flex="1" overflow="hidden" color="wsRed.600">
              <Icon as={RiMapPinLine} boxSize="1.2rem" color="inherit" />
              <Text whiteSpace="nowrap" overflow="hidden" color="inherit">
                {t('store.order.inputAddress')}
              </Text>
            </HStack>
          )}
          {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
            <HStack>
              <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
              <Text whiteSpace="nowrap" overflow="hidden">
                {getPreOrderDateString(preOrderDate, mealPickupType, t)}
              </Text>
            </HStack>
          ) : (
            <HStack>
              <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
              <Text w="4rem" whiteSpace="nowrap" overflow="hidden">
                {t('store.pickUpSetting.ASAP')}
              </Text>
            </HStack>
          )}
        </HStack>
      )}

      {mealPickupType === MealPickUpType.PICKUP && (
        <HStack
          onClick={isHost ? () => changeModalOpen('isPickupSettingOpen', true) : () => {}}
          px={3}
          py={2}
          divider={<StackDivider borderColor="wsGray.400" borderWidth="1px" />}
          bgColor="wsGray.200"
        >
          {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
            <HStack>
              <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
              <Text whiteSpace="nowrap">
                {getPreOrderDateString(preOrderDate, mealPickupType, t)}
              </Text>
            </HStack>
          ) : (
            <HStack>
              <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
              <Text whiteSpace="nowrap" overflow="hidden">
                {t('store.ASAP')}
              </Text>
            </HStack>
          )}
        </HStack>
      )}

      <Box bgColor="white" borderBottomWidth="2px" borderColor="wsBlack">
        <HStack
          cursor="pointer"
          justify="space-between"
          px={4}
          py={2}
          align="center"
          onClick={onToggle}
        >
          <Text>{selectedCategory.label}</Text>
          <Icon as={isOpen ? IoChevronDown : IoChevronUp} boxSize="1.2rem" />
        </HStack>
        {isOpen ? (
          <VStack align="stretch" divider={<StackDivider />} spacing={0}>
            {menuCategoryList.map(({ label, value }) => {
              return (
                <HStack
                  key={value}
                  align="center"
                  py={2}
                  px={4}
                  onClick={() => handleClickCategory(value)}
                  bgColor={value === selectedCategory.value ? 'wsBlack' : 'white'}
                >
                  <Icon
                    as={IoCheckmarkSharp}
                    color={value === selectedCategory.value ? 'white' : 'transparent'}
                    boxSize="1.2rem"
                  />
                  <Text color={value === selectedCategory.value ? 'white' : 'wsBlack'}>
                    {label}
                  </Text>
                </HStack>
              );
            })}
          </VStack>
        ) : null}
      </Box>
      {isHost ? (
        <CancelModal
          title={t('group.cancelModal.title')}
          notice={t('group.cancelModal.note')}
          isOpen={isLeaderNoticeOpen}
          onConfirm={handleCancelGroup}
          onClose={onLeaderNoticeClose}
        />
      ) : (
        <CancelModal
          title={t('group.leaveModal.title')}
          notice={t('group.leaveModal.note')}
          isOpen={isMemberNoticeOpen}
          onConfirm={handleLeaveGroup}
          onClose={onMemberNoticeClose}
        />
      )}
    </Box>
  );
}
