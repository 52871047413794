import { useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Center,
  VStack,
  Button,
  Divider,
  Icon,
  HStack,
  Text,
} from '@chakra-ui/react';
import { addMinutes, getMonth } from 'date-fns';

import SectionContainer from '~/container/SectionContainer';
import OrderTypeSwitcher from '~/components/OrderTypeSwitcher';
import NotOperatingTimeNotice from '~/components/NotOperatingTimeNotice';
import IsLoginText from './IsLoginText';
import PickupTimeSelect from './PickupTimeSelect';
import PickupDaySelect from './PickupDaySelect';
import PickupDurationSelect from './PickupDurationSelect';
import Calendar from './Calendar';
import {
  AddressInput,
  AddressInputFloor,
  AddressInputRemark,
  AddressList,
} from '~/components/DeliverySettingSection';
import ConfirmModal from '../ConfirmModal';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import preOrderSettingSelector from '~/recoil/selectors/preOrderSetting';
import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';
import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import useModalControl from '~/hooks/useModalControl';
import useUser from '~/hooks/useUser';
import useOrderSetting from '~/hooks/useOrderSetting';
import usePickupType from './hooks/usePickupType';
import usePickupLocation from './hooks/usePickupLocation';
import usePickupTime from './hooks/usePickupTime';
import useUpdateOrderSetting from './hooks/useUpdateOrderSetting';
import useConfirmChangeOrderType from './hooks/useConfirmChangeOrderType';

import { differenceInMinutes, startOfDay } from 'date-fns';

import { RiMapPinLine } from 'react-icons/ri';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoPersonOutline } from 'react-icons/io5';

import { MealPickUpType, PickMealTime } from '~/types';
import { useTranslation } from 'react-i18next';

enum DatePickerType {
  CALENDAR = 'calendar',
  WEEK = 'week',
}

export default function PickupSettingModal() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const { modalControl, changeModalOpen } = useModalControl();

  const { isLogin, login, logout } = useUser();

  const { allowOrders, allowRealTimeOrder, allowPreOrder, preOrderSetting, orderPrepareMinutes } =
    useRecoilValue(storeOrderSettingSelector);
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const { handleLocationClear } = useOrderSetting();

  const [datePickerType, setDatePickerType] = useState(DatePickerType.WEEK);

  const { mealPickupType, handleMealPickupTypeChange } = usePickupType();

  const { t } = useTranslation();

  const {
    address,
    selectedAddress,
    addressErrorMessage,
    locationSearchList,
    isLoading,
    floor,
    floorErrorMessage,
    note,
    handleAddressChange,
    handleAddressClick,
    handleAddressClear,
    handleAddressBlur,
    handleFloorChange,
    handleFloorBlur,
    handleNoteChange,
    userLocationList,
    selectedUserLocationIndex,
    selectedLocation,
    handleLocationSelect,
    handleLocationEdit,
  } = usePickupLocation();

  const {
    selectedTime,
    selectedTimeError,
    controlMonth,
    selectedDay,
    selectedDuration,
    timeRange,
    preOrderDate,
    weekDayList,
    getCheckDayType,
    durationOptionList,
    handlePickupTimeChange,
    handleMonthChange,
    handleSelectedDayChange,
    handleSelectedDurationChange,
  } = usePickupTime(mealPickupType);

  const handlePickupSettingSubmit = useUpdateOrderSetting({
    mealPickupType,
    address,
    selectedAddress,
    addressErrorMessage,
    floor,
    floorErrorMessage,
    note,
    isLoading,
    selectedLocation,
    selectedTime,
    selectedTimeError,
    preOrderDate,
  });

  const {
    isConfirmModalOpen,
    title,
    message,
    handleSafePickupSettingSubmit,
    handleConfirmChangeTime,
    handleCancelChangeTime,
  } = useConfirmChangeOrderType({
    updateMealPickupType: mealPickupType,
    updatePickMealTime: selectedTime,
    updatePreOrderDate: preOrderDate,
    handlePickupSettingSubmit,
  });

  // ================================================================

  const handleModalCloseClick = () => {
    changeModalOpen('isPickupSettingOpen', false);
  };

  const handleToggleDatePickerType = () => {
    if (datePickerType === DatePickerType.CALENDAR) {
      setDatePickerType(DatePickerType.WEEK);
    }

    if (datePickerType === DatePickerType.WEEK) {
      setDatePickerType(DatePickerType.CALENDAR);
    }
  };

  const handleLogin = () => {
    login();
  };

  const handleLogout = async () => {
    await logout();
    handleLocationClear();
  };

  // ================================================================

  const selectedDayString = useMemo(() => {
    const weeks = [
      'store.pickUpSetting.time.sun',
      'store.pickUpSetting.time.mon',
      'store.pickUpSetting.time.tue',
      'store.pickUpSetting.time.wed',
      'store.pickUpSetting.time.thu',
      'store.pickUpSetting.time.fri',
      'store.pickUpSetting.time.sat',
    ];

    if (selectedTime === PickMealTime.IMMEDIATELY) {
      const nowDate = new Date();
      return `${getMonth(nowDate) + 1}/${nowDate.getDate()} (${t(weeks[nowDate.getDay()])})`;
    }

    if (!selectedDay) return;
    return `${getMonth(selectedDay) + 1}/${selectedDay.getDate()} (${t(
      weeks[selectedDay.getDay()],
    )})`;
  }, [t, selectedTime, selectedDay]);

  const selectedDurationString = useMemo(() => {
    const timeStringFn = (time: number) => {
      const hour = Math.floor(time / 60);
      const minute = time % 60;
      const timeString = `${hour < 10 ? '0' + hour.toString() : hour.toString()}:${
        minute < 10 ? '0' + minute.toString() : minute.toString()
      }`;
      return timeString;
    };

    if (!orderPrepareMinutes) return;
    if (selectedTime === PickMealTime.IMMEDIATELY) {
      const nowDate = new Date();
      const immediatelyPickupTimeMinutes = differenceInMinutes(
        addMinutes(nowDate, orderPrepareMinutes),
        startOfDay(nowDate),
      );
      return `${timeStringFn(immediatelyPickupTimeMinutes)}~${timeStringFn(
        immediatelyPickupTimeMinutes + timeRange,
      )}`;
    }

    if (!selectedDuration && selectedDuration !== 0) return;
    return `${timeStringFn(selectedDuration)}~${timeStringFn(selectedDuration + timeRange)}`;
  }, [selectedTime, orderPrepareMinutes, selectedDuration, timeRange]);

  const preOrderDays = useMemo(() => {
    if (!preOrderSetting) return;
    const { preOrderBefore } = preOrderSetting;
    const days = Math.ceil(preOrderBefore / 24);
    return days;
  }, [preOrderSetting]);

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={modalControl.isPickupSettingOpen}
        onClose={handleModalCloseClick}
        size={isLargerThan768 ? 'lg' : 'xl'}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent m={0} h="100%" maxH={{ base: '100%', md: '100%' }} borderRadius="0">
          <ModalCloseButton />
          <ModalHeader borderColor="wsGray.300" borderBottomWidth="1px">
            <Center mt={4}>
              <Heading fontSize="xl">{t('store.pickUpSetting.title')}</Heading>
            </Center>
          </ModalHeader>

          <ModalBody overflow="auto">
            <VStack py={2} align="stretch" spacing={5}>
              <OrderTypeSwitcher
                mealPickupType={mealPickupType}
                onMealPickupTypeChange={handleMealPickupTypeChange}
              />
              {operatingTime && (
                <NotOperatingTimeNotice
                  time={preOrderDate}
                  mealPickUpType={mealPickupType}
                  pickMealTime={selectedTime}
                  timeRange={operatingTime}
                  isAllowOrder={allowOrders}
                  isAllowRealTimeOrder={allowRealTimeOrder}
                  isAllowPreOder={allowPreOrder}
                  preOrderRange={{
                    preOrderAfter: preOrderSetting?.preOrderAfter || 1,
                    preOrderBefore: preOrderSetting?.preOrderBefore || 72,
                  }}
                />
              )}
              <>
                {mealPickupType === MealPickUpType.DELIVERY && (
                  <>
                    <SectionContainer
                      title={t('address.delivery')}
                      icon={<Icon as={RiMapPinLine} boxSize="1.5rem" color="wsBlack" />}
                    >
                      {isLogin && userLocationList ? (
                        <AddressList
                          userLocationList={userLocationList}
                          selectedUserLocationIndex={selectedUserLocationIndex}
                          onAddressSelect={handleLocationSelect}
                          onAddressEdit={handleLocationEdit}
                        />
                      ) : (
                        <VStack spacing={3} align="stretch">
                          <AddressInput
                            address={address}
                            selectedAddress={selectedAddress}
                            locationSearchList={locationSearchList}
                            isLoading={isLoading}
                            errorMessage={addressErrorMessage}
                            onAddressChange={handleAddressChange}
                            onAddressClick={handleAddressClick}
                            onAddressClear={handleAddressClear}
                            onAddressBlur={handleAddressBlur}
                          />
                          <AddressInputFloor
                            floor={floor}
                            errorMessage={floorErrorMessage}
                            onFloorChange={handleFloorChange}
                            onFloorBlur={handleFloorBlur}
                          />
                          <AddressInputRemark note={note} onNoteChange={handleNoteChange} />
                          <IsLoginText
                            isLogin={isLogin}
                            onLogin={handleLogin}
                            onLogout={handleLogout}
                          />
                        </VStack>
                      )}
                    </SectionContainer>
                  </>
                )}

                <Divider />

                <SectionContainer
                  title={t('store.pickUpSetting.pickUpTime')}
                  icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" color="wsBlack" />}
                >
                  <PickupTimeSelect
                    selectedTime={selectedTime}
                    selectedTimeError={selectedTimeError}
                    onPickupTimeChange={handlePickupTimeChange}
                    selectedDayString={selectedDayString}
                    selectedDurationString={selectedDurationString}
                  />
                  {allowPreOrder && selectedTime === PickMealTime.RESERVATION && (
                    <>
                      <HStack justify="space-between" align="flex-start" my={3}>
                        <Text fontSize="xs" color="wsGray.500">
                          {t('store.pickUpSetting.furthestPreOrderDate', { days: preOrderDays })}
                        </Text>
                        <Text fontSize="xs" color="wsBlack" onClick={handleToggleDatePickerType}>
                          {datePickerType === DatePickerType.WEEK &&
                            t('store.pickUpSetting.monthCalendar')}
                          {datePickerType === DatePickerType.CALENDAR &&
                            t('store.pickUpSetting.weekCalendar')}
                        </Text>
                      </HStack>

                      {datePickerType === DatePickerType.WEEK && (
                        <PickupDaySelect
                          dayList={weekDayList}
                          selectedDay={selectedDay}
                          onDayClick={handleSelectedDayChange}
                        />
                      )}
                      {datePickerType === DatePickerType.CALENDAR && (
                        <Calendar
                          controlMonth={controlMonth}
                          selectedDay={selectedDay}
                          onDayTypeCheck={getCheckDayType}
                          onMonthChange={handleMonthChange}
                          onDaySelect={handleSelectedDayChange}
                        />
                      )}

                      <PickupDurationSelect
                        durationOptionList={durationOptionList}
                        selectedDuration={selectedDuration}
                        selectedDay={selectedDay}
                        preOrderDate={preOrderDate}
                        onDurationSelect={handleSelectedDurationChange}
                      />
                    </>
                  )}
                </SectionContainer>
              </>
            </VStack>
          </ModalBody>

          <ModalFooter p={0}>
            <Button
              onClick={handleSafePickupSettingSubmit}
              variant="unstyled"
              bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
              borderRadius="unset"
              color="white"
              isFullWidth
              h="auto"
              p={3.5}
            >
              <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
                {t('confirm')}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={title}
          message={message}
          onConfirm={handleConfirmChangeTime}
          onClose={handleCancelChangeTime}
        />
      </Modal>
    );
  }

  return (
    <Drawer isOpen={modalControl.isPickupSettingOpen} onClose={handleModalCloseClick} size="full">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader borderColor="wsGray.300" borderBottomWidth="1px">
          <Center mt={4}>
            <Heading fontSize="xl">{t('store.pickUpSetting.title')}</Heading>
          </Center>
        </DrawerHeader>
        <DrawerBody>
          <VStack py={2} align="stretch" spacing={5}>
            <OrderTypeSwitcher
              mealPickupType={mealPickupType}
              onMealPickupTypeChange={handleMealPickupTypeChange}
            />
            {operatingTime && (
              <NotOperatingTimeNotice
                time={preOrderDate}
                mealPickUpType={mealPickupType}
                pickMealTime={selectedTime}
                timeRange={operatingTime}
                isAllowOrder={allowOrders}
                isAllowRealTimeOrder={allowRealTimeOrder}
                isAllowPreOder={allowPreOrder}
                preOrderRange={{
                  preOrderAfter: preOrderSetting?.preOrderAfter || 1,
                  preOrderBefore: preOrderSetting?.preOrderBefore || 72,
                }}
              />
            )}
            <>
              {mealPickupType === MealPickUpType.DELIVERY && (
                <>
                  <SectionContainer
                    title={t('address.delivery')}
                    icon={<Icon as={RiMapPinLine} boxSize="1.5rem" />}
                  >
                    {isLogin ? (
                      <AddressList
                        userLocationList={userLocationList}
                        selectedUserLocationIndex={selectedUserLocationIndex}
                        onAddressSelect={handleLocationSelect}
                        onAddressEdit={handleLocationEdit}
                      />
                    ) : (
                      <VStack spacing={3} align="stretch">
                        <AddressInput
                          address={address}
                          selectedAddress={selectedAddress}
                          locationSearchList={locationSearchList}
                          isLoading={isLoading}
                          errorMessage={addressErrorMessage}
                          onAddressChange={handleAddressChange}
                          onAddressClick={handleAddressClick}
                          onAddressClear={handleAddressClear}
                          onAddressBlur={handleAddressBlur}
                        />
                        <AddressInputFloor
                          floor={floor}
                          errorMessage={floorErrorMessage}
                          onFloorChange={handleFloorChange}
                          onFloorBlur={handleFloorBlur}
                        />
                        <AddressInputRemark note={note} onNoteChange={handleNoteChange} />
                        <IsLoginText
                          isLogin={isLogin}
                          onLogin={handleLogin}
                          onLogout={handleLogout}
                        />
                      </VStack>
                    )}
                  </SectionContainer>
                </>
              )}

              <Divider />

              <SectionContainer
                title={t('store.pickUpSetting.pickUpTime')}
                icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
              >
                <PickupTimeSelect
                  selectedTime={selectedTime}
                  selectedTimeError={selectedTimeError}
                  onPickupTimeChange={handlePickupTimeChange}
                  selectedDayString={selectedDayString}
                  selectedDurationString={selectedDurationString}
                />
                {allowPreOrder && selectedTime === PickMealTime.RESERVATION && (
                  <>
                    <HStack justify="space-between" align="flex-start" my={3}>
                      <Text fontSize="xs" color="wsGray.500">
                        {t('store.pickUpSetting.furthestPreOrderDate', { days: preOrderDays })}
                      </Text>
                      <Text fontSize="xs" color="wsBlack" onClick={handleToggleDatePickerType}>
                        {datePickerType === DatePickerType.WEEK &&
                          t('store.pickUpSetting.monthCalendar')}
                        {datePickerType === DatePickerType.CALENDAR &&
                          t('store.pickUpSetting.weekCalendar')}
                      </Text>
                    </HStack>

                    {datePickerType === DatePickerType.WEEK && (
                      <PickupDaySelect
                        dayList={weekDayList}
                        selectedDay={selectedDay}
                        onDayClick={handleSelectedDayChange}
                      />
                    )}
                    {datePickerType === DatePickerType.CALENDAR && (
                      <Calendar
                        controlMonth={controlMonth}
                        selectedDay={selectedDay}
                        onDayTypeCheck={getCheckDayType}
                        onMonthChange={handleMonthChange}
                        onDaySelect={handleSelectedDayChange}
                      />
                    )}

                    <PickupDurationSelect
                      durationOptionList={durationOptionList}
                      selectedDuration={selectedDuration}
                      selectedDay={selectedDay}
                      preOrderDate={preOrderDate}
                      onDurationSelect={handleSelectedDurationChange}
                    />
                  </>
                )}
              </SectionContainer>
            </>
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Button
            onClick={handleSafePickupSettingSubmit}
            variant="unstyled"
            bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
            borderRadius="unset"
            color="white"
            isFullWidth
            h="auto"
            p={3.5}
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
              {t('confirm')}
            </Text>
          </Button>
        </DrawerFooter>
      </DrawerContent>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={title}
        message={message}
        onConfirm={handleConfirmChangeTime}
        onClose={handleCancelChangeTime}
      />
    </Drawer>
  );
}
