import { selectorFamily } from 'recoil';

import couponListAtom from '~/recoil/atom/couponList';

import { Coupon } from '~/types';

const couponItem = selectorFamily<Coupon | null, { couponId: string }>({
  key: 'couponItem',
  get:
    ({ couponId }) =>
    ({ get }) => {
      const couponList = get(couponListAtom);
      const coupon = couponList.find(({ id }) => {
        return id === couponId;
      });
      if (!coupon) return null;
      return coupon;
    },
});

export default couponItem;
