import { PickMealTime, PreorderSetting, Schedule, PreOrderDate } from '~/types';
import fp from 'lodash/fp';

import checkPreorderTime from '~/utils/checkPreorderTime';
import checkOperatingTime from '~/utils/checkOperatingTime';

type CheckPreorderTimeObj = {
  pickMealTime: PickMealTime;
  allowOrder: boolean;
  preorderSetting: PreorderSetting;
  operatingTime: Schedule;
  preOrderDate: PreOrderDate;
};

const checkPickMealTimeError = fp.cond<CheckPreorderTimeObj, string>([
  [fp.matches({ allowOrder: false }), fp.always('store.pickUpSetting.error.storeNotAllowOrders')],
  [
    fp.matches({
      pickMealTime: PickMealTime.RESERVATION,
      preorderSetting: {
        allowPreOrder: false,
      },
    }),
    fp.always('store.pickUpSetting.error.storeNotAllowPreOrders'),
  ],
  [
    fp.matches({
      pickMealTime: PickMealTime.RESERVATION,
      preOrderDate: null,
    }),
    fp.always('store.pickUpSetting.error.noPreorderTime'),
  ],
  [
    (obj) =>
      fp.matches({ pickMealTime: PickMealTime.RESERVATION })(obj) &&
      !!obj.preOrderDate &&
      !checkPreorderTime(obj.preOrderDate, obj.preorderSetting),

    fp.always('store.pickUpSetting.error.preorderNotInRange'),
  ],
  [
    (obj) =>
      fp.matches({ pickMealTime: PickMealTime.IMMEDIATELY })(obj) &&
      !checkOperatingTime(obj.operatingTime),

    fp.always('store.pickUpSetting.error.notInOperating'),
  ],
  [fp.T, fp.always('')],
]);

export default checkPickMealTimeError;
