import { HStack, Text, Spacer, Flex, Icon } from '@chakra-ui/react';

import { MealPickUpType, PickMealTime, Schedule } from '~/types';

import checkOperatingTime from '~/utils/checkOperatingTime';
import getRecentPreOrderDate from '~/utils/getRecentPreOrderDate';
import { format, getDay } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { IoArrowForwardCircle } from 'react-icons/io5';

import { weekDayZhString } from '~/types';
import { useTranslation } from 'react-i18next';

type NotOperatingTimeNoticeProps = {
  mealPickUpType: MealPickUpType;
  pickMealTime: PickMealTime;
  timeRange: Schedule;
  time: Date | null;
  isFixed?: boolean;
  isAllowOrder: boolean;
  isAllowRealTimeOrder: boolean;
  isAllowPreOder: boolean;
  preOrderRange: {
    preOrderAfter: number;
    preOrderBefore: number;
  };
  enableNavigate?: boolean;
};

// 非營業時間通知：分為目前非營業時間以及訂單過大暫停關閉營業
export default function NotOperatingTimeNotice({
  mealPickUpType,
  pickMealTime,
  timeRange,
  time,
  isFixed,
  isAllowOrder,
  isAllowRealTimeOrder,
  isAllowPreOder,
  preOrderRange,
  enableNavigate = false,
}: NotOperatingTimeNoticeProps) {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const preOrderTime = time;
  // If the time is undefined, it is the current time
  const isNotOperatingTime = !checkOperatingTime(timeRange, time || new Date());

  // 僅限預定不提供立刻取餐
  const isAllowOnlyPreOrder = isAllowOrder && !isNotOperatingTime && !isAllowRealTimeOrder;

  const noticeInfo = ((): {
    title: string;
    rightComponent: JSX.Element | null;
    navigate: () => void;
  } => {
    if (!isAllowOrder) {
      return {
        title: t('store.operating.tempClose'),
        rightComponent: null,
        navigate: () => {},
      };
    }

    if (preOrderTime && isNotOperatingTime && isAllowPreOder) {
      return {
        title: t('store.operating.invalidPreOderTime'),
        rightComponent: null,
        navigate: () => {},
      };
    }

    if (!preOrderTime && isNotOperatingTime && !isAllowRealTimeOrder && isAllowPreOder) {
      return {
        title: t('store.operating.onlyPreOrder'),
        rightComponent: null,
        navigate: () => {},
      };
    }

    if (!preOrderTime && !isNotOperatingTime && !isAllowRealTimeOrder) {
      return {
        title: t('store.operating.notPickUp'),
        rightComponent: null,
        navigate: () => {},
      };
    }

    if (pickMealTime === PickMealTime.IMMEDIATELY && isNotOperatingTime && isAllowRealTimeOrder) {
      return {
        title: t('store.operating.close'),
        rightComponent: (
          <HStack align="center" spacing={2} cursor="pointer">
            <Text fontSize="xs" color="wsRed.600">
              {t('store.operating.select')}
            </Text>
            <Icon as={IoArrowForwardCircle} boxSize="1.25rem" color="wsRed.600" />
          </HStack>
        ),
        navigate: () => {
          navigate(`/${storeId}`);
        },
      };
    }

    return {
      title: '',
      rightComponent: null,
      navigate: () => {},
    };
  })();

  const recentPreOrderTimeString = ((): string => {
    if (!preOrderTime && !isAllowRealTimeOrder) {
      const recentPreOrderTime = getRecentPreOrderDate(
        mealPickUpType,
        new Date(),
        timeRange,
        preOrderRange,
      );

      return recentPreOrderTime
        ? `${t('store.operating.closestOrderTime')} ${format(recentPreOrderTime, 'MM/dd')}(${
            weekDayZhString[getDay(recentPreOrderTime)]
          }) ${format(recentPreOrderTime, 'HH:mm')}`
        : '';
    }
    return '';
  })();

  return (
    <>
      {noticeInfo.title && (
        <Flex
          bgColor={isAllowOnlyPreOrder ? 'wsGray.200' : 'wsRed.200'}
          borderRadius={isFixed ? '0px' : '5px'}
          position={isFixed ? 'sticky' : 'static'}
          zIndex={isFixed ? 1000 : 0}
          bottom={0}
          p={3}
          onClick={noticeInfo.navigate}
        >
          <Text color="wsRed.600" fontSize="sm" fontWeight="bold">
            {noticeInfo.title}
          </Text>

          <Spacer />

          {recentPreOrderTimeString && (
            <Text color="wsRed.600" fontSize="sm" fontWeight="bold">
              {recentPreOrderTimeString}
            </Text>
          )}

          {enableNavigate && noticeInfo.rightComponent}
        </Flex>
      )}
    </>
  );
}
