import { getTime } from 'date-fns';
import checkOperatingTime from '~/utils/checkOperatingTime';
import { PickMealTime, Schedule } from '~/types';

export default function checkPickMealTimeError(
  pickMealTime: PickMealTime,
  preOrderDate: Date | null,
  {
    allowOrders,
    allowPreOrder,
    allowRealTimeOrder,
    operatingTime,
  }: {
    allowOrders: boolean;
    allowPreOrder: boolean;
    allowRealTimeOrder: boolean;
    operatingTime: Schedule | null;
  },
): string {
  if (!allowOrders) {
    return 'store.pickUpSetting.error.storeNotAllowOrders';
  }

  if (pickMealTime === PickMealTime.RESERVATION && !allowPreOrder) {
    return 'store.pickUpSetting.error.storeNotAllowPreOrders';
  }

  if (pickMealTime === PickMealTime.RESERVATION && !preOrderDate) {
    return 'store.pickUpSetting.error.noPreorderTime';
  }

  if (
    pickMealTime === PickMealTime.RESERVATION &&
    preOrderDate &&
    getTime(preOrderDate) < getTime(new Date())
  ) {
    return 'store.pickUpSetting.error.expiredPreorderTime';
  }

  if (!operatingTime) return 'store.pickUpSetting.error.noOperating';

  if (
    pickMealTime === PickMealTime.RESERVATION &&
    preOrderDate &&
    !checkOperatingTime(operatingTime, preOrderDate)
  ) {
    return 'store.pickUpSetting.error.preorderNotInRange';
  }

  if (pickMealTime === PickMealTime.IMMEDIATELY && !allowRealTimeOrder) {
    return 'store.pickUpSetting.error.storeNotAllowRealtimeOrders';
  }

  if (pickMealTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
    return 'store.pickUpSetting.error.notInOperating';
  }

  return '';
}
