import { useMemo } from 'react';
import { HStack, Text, Icon, Button } from '@chakra-ui/react';

import { IoChevronForwardOutline } from 'react-icons/io5';
import { Coupon as CouponIcon } from '~/icons';
import { Coupon } from '~/types';

import { useTranslation } from 'react-i18next';

type CouponBarProps = {
  onClick?: () => void;
  availableCouponCount: number;
  couponBarStyle?: 'publish' | 'no-publish';
};

// 優惠卷使用按鈕
export default function CouponBar({
  onClick,
  availableCouponCount,
  couponBarStyle = 'no-publish',
}: CouponBarProps) {
  const { t } = useTranslation();

  if (couponBarStyle === 'publish') {
    return (
      <HStack position="relative" borderRadius="5px" boxShadow="0px 0px 10px rgba(51, 51, 51, 0.2)">
        <HStack flex="1" px={2}>
          <Icon as={CouponIcon} />
          <Text fontWeight="bold">{t('store.order.coupon')}</Text>
        </HStack>

        <Button
          variant="unstyled"
          bgColor="wsBlack"
          color="white"
          rightIcon={<Icon as={IoChevronForwardOutline} boxSize="0.8rem" />}
          p={2}
          fontWeight="normal"
          borderTopLeftRadius="none"
          borderBottomLeftRadius="none"
          onClick={onClick}
        >
          {t('store.order.viewCoupons')}
        </Button>

        {/** Badge text */}
        {availableCouponCount > 0 && (
          <Text
            pos="absolute"
            fontSize="xs"
            bgColor="wsRed.600"
            color="white"
            borderRadius="full"
            w="1.2rem"
            h="1.2rem"
            textAlign="center"
            border="1px solid white"
            top={-1.5}
            right={-1.5}
          >
            {availableCouponCount}
          </Text>
        )}
      </HStack>
    );
  }

  return (
    <HStack as="button" justify="space-between" onClick={onClick}>
      <HStack>
        <Icon as={CouponIcon} />
        <Text fontWeight="bold">{t('store.order.coupon')}</Text>
      </HStack>
      <Icon as={IoChevronForwardOutline} />
    </HStack>
  );
}
