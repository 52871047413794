import React from 'react';
import { Box, Center, Divider, Heading } from '@chakra-ui/react';

type TitleProps = {
  title: string;
};

// 頁面粗字標題及分隔線
export default function Title({ title }: TitleProps) {
  return (
    <Box>
      <Center pb={3}>
        <Heading fontSize="xl" letterSpacing={2}>
          {title}
        </Heading>
      </Center>
      <Divider />
    </Box>
  );
}
