import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import { updateGroupCart, updateCartData } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import isHostSelector from '~/recoil/selectors/isHost';
import storeMenuAvailablePickupTypesSelector from '~/recoil/selectors/storeMenuAvailablePickupTypes';

import convertMealPickupType from '~/utils/convertMealPickupType';

import { MealPickUpType } from '~/types';

export default function useCheckMenuPickupType() {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const isHost = useRecoilValue(isHostSelector);
  const storeMenuAvailablePickupTypes = useRecoilValue(storeMenuAvailablePickupTypesSelector);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const [mealPickupType, setMealPickupType] = useRecoilState(mealPickupTypeAtom);

  useEffect(() => {
    if (storeMenuAvailablePickupTypes.length >= 2 || storeMenuAvailablePickupTypes.length === 0) {
      return;
    }

    const isOnlyPickup = storeMenuAvailablePickupTypes.some(
      (type) => type === MealPickUpType.PICKUP,
    );

    const isOnlyDelivery = storeMenuAvailablePickupTypes.some(
      (type) => type === MealPickUpType.DELIVERY,
    );

    const restMealPickupType = (() => {
      if (isOnlyDelivery) {
        return MealPickUpType.DELIVERY;
      }
      if (isOnlyPickup) {
        return MealPickUpType.PICKUP;
      }
      return MealPickUpType.PICKUP;
    })();

    if (restMealPickupType === mealPickupType) {
      return;
    }

    if (!auth) {
      setMealPickupType(restMealPickupType);
      return;
    }

    if (storeId && groupId && isHost) {
      console.log('update group');
      const handleCheckClearGroupCart = async () => {
        try {
          await updateGroupCart(storeId, groupId, {
            groupItems: [],
            orderType: convertMealPickupType(restMealPickupType, pickMealTime),
          });
        } catch (err) {
          console.error('auto update group meal pickup type error', err);
        }
      };
      handleCheckClearGroupCart();
      return;
    }

    if (storeId) {
      const handleCheckClearCart = async () => {
        try {
          await updateCartData(storeId, auth, {
            items: [],
            orderType: convertMealPickupType(restMealPickupType, pickMealTime),
          });
        } catch (err) {
          console.error('auto update  meal pickup type error', err);
        }
      };
      handleCheckClearCart();
      return;
    }
  }, [auth, storeId, groupId, isHost, pickMealTime, mealPickupType, storeMenuAvailablePickupTypes]);
}
