import { selector, selectorFamily } from 'recoil';

import { Store, Item, ModifierWithId } from '~/types';
import { ItemWithModifier } from '~/pages/Item/types';
import { storeLocaleSelector } from './storeLocale';

export const itemsSelector = selector<Record<string, Item> | null>({
  key: 'itemsSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeLocaleSelector);
    return store?.items ? store.items : null;
  },
});

export const itemIdsSelector = selector<string[]>({
  key: 'itemIdsSelector',
  get: ({ get }) => {
    const items = get(itemsSelector);
    return items ? Object.keys(items) : [];
  },
});

export const itemSelector = selectorFamily<ItemWithModifier | null, string>({
  key: 'itemSelector',
  get:
    (itemId) =>
    ({ get }) => {
      const store = get(storeLocaleSelector);

      if (!store) return null;

      const { items, modifiers } = store;

      const item = items[itemId];

      if (!item) return null;

      const itemModifiers = item.modifiers.reduce((acc: Array<ModifierWithId>, modifierId) => {
        const modifier = modifiers[modifierId];
        if (modifier) {
          acc.push({ ...modifier, id: modifierId });
        }
        return acc;
      }, []);

      return {
        ...item,
        modifiers: itemModifiers,
      };
    },
});
