import { useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';

import { Box, StackDivider, VStack, Text, HStack, Flex, Button, Icon } from '@chakra-ui/react';
import { BsDot, BsFillClockFill } from 'react-icons/bs';
import { AiTwotoneStar } from 'react-icons/ai';
import { IoArrowForwardCircle } from 'react-icons/io5';
import { MdShoppingBasket } from 'react-icons/md';
import { IoPricetagSharp } from 'react-icons/io5';

import { format } from 'date-fns';
import { difference } from 'lodash';

import authAtom from '~/recoil/atom/auth';
import couponItemSelector from '~/recoil/selectors/couponItem';

import useCouponList from '~/hooks/useCouponList';

import PageContainer from '~/container/PageContainer';
import BackwardBar from '~/components/BackwardBar';
import Title from '~/components/Title';

import transformRewardConditionString from '~/utils/transformRewardConditionString';

import { RewardType, OrderType } from '~/types';

import { useTranslation } from 'react-i18next';

// 優惠券詳情頁，優惠券基本資訊包含優惠券說明跟注意事項
export default function CouponDetail() {
  const { storeId, groupId, couponId = '' } = useParams();
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const couponItem = useRecoilValue(couponItemSelector({ couponId }));
  const { t } = useTranslation();

  useCouponList();

  useEffect(() => {
    if (!auth) {
      if (groupId) {
        navigate(`/${storeId}/group/${groupId}`);
        return;
      }
      navigate(`/${storeId}/`);
    }
  }, [auth, storeId, groupId, navigate]);

  const handleGoOrderClick = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}`);
      return;
    }
    navigate(`/${storeId}`);
  };

  const handleBack = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}/discount`);
      return;
    }
    navigate(`/${storeId}/discount`);
  };

  const couponRewardString = useMemo(() => {
    if (!couponItem) return '';

    return transformRewardConditionString(couponItem.rewardType, couponItem.rewardCondition, t);
  }, [couponItem]);

  const allowOrderTypeString = useMemo(() => {
    if (!couponItem) return '';
    const { allowOrderTypes } = couponItem;

    if (difference(Object.values(OrderType), allowOrderTypes).length === 0) {
      return t('coupon.detailPage.anyOrderType');
    }

    if (
      difference([OrderType.WS_DELIVERY, OrderType.WS_PREORDER_DELIVERY], allowOrderTypes)
        .length === 0
    ) {
      return t('coupon.detailPage.onlyDelivery');
    }

    if (
      difference([OrderType.WS_PREORDER_PICKUP, OrderType.WS_PICKUP], allowOrderTypes).length === 0
    ) {
      return t('coupon.detailPage.onlyPickup');
    }

    return '';
  }, [couponItem]);

  if (!couponItem) return <></>;

  const { name, endDate, description, note, rewardType, rewardCondition } = couponItem;

  const couponGift = rewardCondition[RewardType.DESCRIPTION];

  return (
    <PageContainer hideHeader>
      <BackwardBar title={t('coupon.detailPage.back')} onBack={handleBack} />
      <Title title={t('coupon.detailPage.title')} />
      <VStack align="stretch" p={4} spacing={4} divider={<StackDivider />}>
        <Box>
          <VStack align="stretch" spacing={3}>
            <Text fontSize="sm" fontWeight="bold">
              {name}
            </Text>
            <HStack>
              <Icon as={BsFillClockFill} boxSize="1rem" color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                {t('coupon.time', { time: format(endDate, 'yyyy/MM/dd') })}
              </Text>
            </HStack>
            <HStack>
              <Icon as={AiTwotoneStar} boxSize="1.2rem" color="wsRed.600" />
              <Text fontSize="xs" color="wsRed.600">
                {couponRewardString}
              </Text>
            </HStack>
            {rewardType === RewardType.DESCRIPTION && couponGift && (
              <HStack>
                <Icon as={IoPricetagSharp} boxSize="1.2rem" color="wsGray.500" />
                <Text fontSize="xs" color="wsGray.500">
                  {couponGift.description}
                </Text>
              </HStack>
            )}
            <HStack>
              <Icon as={MdShoppingBasket} boxSize="1.2rem" color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                {allowOrderTypeString}
              </Text>
            </HStack>
          </VStack>
        </Box>
        <Box>
          <VStack align="stretch" spacing={2}>
            <HStack spacing={0.5}>
              <Icon as={BsDot} color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                {t('coupon.detailPage.details')}
              </Text>
            </HStack>
            <Text fontSize="sm">{description}</Text>
          </VStack>
        </Box>
        <Box>
          <VStack align="stretch" spacing={2}>
            <HStack spacing={0.5}>
              <Icon as={BsDot} color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                {t('coupon.detailPage.terms')}
              </Text>
            </HStack>
            <Text fontSize="sm">{note}</Text>
          </VStack>
        </Box>
        <Box>
          <Flex justify="center" pt={1} pb={16}>
            <Button
              onClick={handleGoOrderClick}
              h="auto"
              px={16}
              py={3.5}
              rightIcon={<Icon as={IoArrowForwardCircle} boxSize="1.25rem" ml={-1} color="white" />}
            >
              {t('coupon.order')}
            </Button>
          </Flex>
        </Box>
      </VStack>
    </PageContainer>
  );
}
