import { isSameDay, format, startOfDay, addSeconds, addDays } from 'date-fns';
import { Inventory } from '~/types';
export default function calcRemainInventory(
  inventory: Inventory,
  allowReserved: boolean,
  today: Date,
  targetDate: Date | null,
  resetTime: number,
): number {
  const orderDate = targetDate ? targetDate : today;

  const checkTime = addSeconds(startOfDay(orderDate), resetTime);

  const checkDate = orderDate.getTime() > checkTime.getTime() ? addDays(orderDate, 1) : orderDate;

  const isToday = isSameDay(checkDate, today);

  const reservedInventory = allowReserved
    ? (inventory.reservedInventory || {})[format(checkDate, 'yyyyMMdd')] || 0
    : 0;

  if (isToday) {
    return inventory.currentInventory - reservedInventory;
  }

  return inventory.defaultInventory - reservedInventory;
}
