import firebase from 'firebase';

export enum Locales {
  zh_TW = 'zh_TW',
  en_US = 'en_US',
}

export type LocaleString = Partial<Record<Locales, string>>;

export const PickupTimeRange = 15;
export const DeliveryTimeRange = 30;
export const DeliveryCashLimit = 2000;
export const ReservedResetTimeBase = 24 * 60 * 60 - 1;

export const weekDayZhString = ['日', '一', '二', '三', '四', '五', '六'];

export enum TaxType {
  PERSONAL = 'WS_TAX_PERSONAL',
  COMPANY = 'WS_TAX_COMPANY',
}

export enum CarrierType {
  UFAST = 'UFAST',
  GOGOVAN = 'GOGOVAN',
  GRAB = 'GRAB',
  LALAMOVE = 'LALAMOVE',
}

export enum OrderType {
  WS_PICKUP = 'WS_PICKUP',
  WS_DELIVERY = 'WS_DELIVERY',
  WS_PREORDER_PICKUP = 'WS_PREORDER_PICKUP',
  WS_PREORDER_DELIVERY = 'WS_PREORDER_DELIVERY',
}

export enum MealPickUpType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

export enum PickMealTime {
  IMMEDIATELY = 'immediately',
  RESERVATION = 'reservation',
}

export enum WsOrderStatus {
  UNPAID = 'WS_STATUS_UNPAID',
  PENDING = 'WS_STATUS_PENDING',
  CREATED = 'WS_STATUS_CREATED',
  CONFIRMED = 'WS_STATUS_CONFIRMED',
  RETRY = 'WS_STATUS_RETRY',
  ACCEPTED = 'WS_STATUS_ACCEPTED',
  PREPARED = 'WS_STATUS_PREPARED',
  PICKED = 'WS_STATUS_PICKED',
  COMPLETED = 'WS_STATUS_COMPLETED',
  FAILED = 'WS_STATUS_FAILED',
  CANCELLED = 'WS_STATUS_CANCELLED',
}

export const notCreatedOrderStatus = [WsOrderStatus.PENDING, WsOrderStatus.UNPAID];

export const inProgressOrderStatus = [
  WsOrderStatus.CREATED,
  WsOrderStatus.ACCEPTED,
  WsOrderStatus.CONFIRMED,
  WsOrderStatus.PREPARED,
  WsOrderStatus.PICKED,
  WsOrderStatus.RETRY,
];

export const finishOrderStatus = [
  WsOrderStatus.FAILED,
  WsOrderStatus.COMPLETED,
  WsOrderStatus.CANCELLED,
];

export enum FetchingState {
  INIT = 'init',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum Week {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
}
export enum PaymentType {
  NONE = 'NONE',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  LINEPAY = 'LINEPAY',
}

enum RateType {
  ROUND = 'ROUND',
  CEIL = 'CEIL',
  FLOOR = 'FLOOR',
}

export type PreOrderDate = Date | null;

export type Location = {
  address: string;
  floor: string;
  note: string;
};

export type MealOrderSetting = {
  mealPickupType: MealPickUpType;
  location: Location;
  pickMealTime: PickMealTime;
  preOrderDate: PreOrderDate;
};

export type Schedule = Partial<Record<Week, Array<{ open: number; close: number }>>>;

export type Inventory = {
  currentInventory: number;
  defaultInventory: number;
  reservedInventory?: Record<string, number>; // "20210801" : 0
};

export type Option = {
  id: string;
  name: string;
  isSoldOut: boolean;
  price: number;
  inventory: Inventory;
};

export interface OptionWithLocale extends Omit<Option, 'name'> {
  name: LocaleString;
}

export type Modifier = {
  name: string;
  max: number;
  min: number;
  required: boolean;
  options: Array<Option>;
};

export interface ModifierWithLocale extends Omit<Modifier, 'name' | 'options'> {
  name: LocaleString;
  options: Array<OptionWithLocale>;
}

export type ModifierWithId = { id: string } & Modifier;

export type Item = {
  description: string;
  image: string;
  inventory: Inventory;
  isSoldOut: boolean;
  modifiers: Array<string>;
  name: string;
  price: number;
  tax: number;
};

export interface ItemWithLocale extends Omit<Item, 'description' | 'name'> {
  description: LocaleString;
  name: LocaleString;
}

export type ItemWithId = { id: string } & Item;

export type Category = {
  items: Array<string>;
  name: string;
};

export interface CategoryWithLocale extends Omit<Category, 'name'> {
  name: LocaleString;
}

export type CategoryWithId = { id: string } & Category;

export type Menu = {
  name: string;
  allowDelivery: boolean;
  allowPickup: boolean;
  allowPreorder: boolean;
  allowPublish: boolean;
  categories: Array<string>;
  schedule: Schedule;
};

export interface MenuWithLocale extends Omit<Menu, 'name'> {
  name: LocaleString;
}

export type MenuWithId = { id: string } & Menu;

export type PreorderSetting = {
  allowPreOrder: boolean;
  preOrderAfter: number;
  preOrderAfterUnit: string;
  preOrderBefore: number;
  preOrderBeforeUnit: string;
};

export type ShippingFeeSetting = {
  baseFee: number;
  rateType: RateType;
  rates: Array<{ kmUnit: number; feePerUnit: number; max: number; min: number }>;
};

export type Store = {
  allowOrders: boolean;
  allowOverLimitInCash: boolean;
  allowPartnerDelivery: boolean;
  allowQueue: boolean;
  allowRealTimeOrder: boolean;
  allowReservations: boolean;

  banner: string;
  name: string;
  brands: Array<string>;
  currency: string;
  logo: string;
  images: Array<string>;
  deliveryShare: number;
  description: string;
  phoneNumber: string;

  delivery: {
    allowHandlingFeeForCustomer: boolean;
    allowPandago: boolean;
    costShare: number;
    costShareLimit: number;
    freeLimit: number;
    handlingFee: number;
    lalamoveCityCode: string;
    maxDistance: number;
    note: string;
    pandagoStoreId: string;
    shippingFeeSetting: ShippingFeeSetting;
  };

  inventorySetting: {
    allowLowInventoryRatio: boolean;
    allowResetTime: boolean;
    lowInventoryRatio: number;
    resetCloudTaskId: string;
    resetTime: number;
    allowReserved?: boolean;
  };

  is3rdPartyPaymentRequired: boolean;

  isDemo: boolean;
  isUseInvoice?: boolean;

  largeOrderSetting: {
    isItemThreshold: boolean;
    isPriceThreshold: boolean;
    itemThreshold: number;
    priceThreshold: number;
  };
  location: {
    address: string;
    geopoint: firebase.firestore.GeoPoint;
  };
  operatingTime: Schedule;
  orderNumCount: number;
  orderNumLimit: number;
  orderPrepareMinutes: number;
  paymentTypes: Array<PaymentType>;
  preOrderSetting: PreorderSetting;
  preorderLimit: number;
  reasonForCancelling: {
    customer: Array<string>;
    store: Array<string>;
    requireCustomerConfirmedReservations: boolean;
    retryTimeLimit: number;
  };
  timezone: string;

  // the store products
  menus: Record<string, Menu>;
  categories: Record<string, Category>;
  items: Record<string, Item>;
  modifiers: Record<string, Modifier>;
  GATrackingId: string;
};

export interface StoreWithLocale
  extends Omit<Store, 'menus' | 'categories' | 'items' | 'modifiers'> {
  menus: Record<string, MenuWithLocale>;
  categories: Record<string, CategoryWithLocale>;
  items: Record<string, ItemWithLocale>;
  modifiers: Record<string, ModifierWithLocale>;
}

export type StoreWithId = { id: string } & (Store | StoreWithLocale);

// order type
export type ShippingFee = {
  store: number;
  customer: number;
};

export type ShippingFeeDetails = {
  customer: {
    value: number;
    discount: number;
  };
  store: {
    value: number;
    discount: number;
  };
};

export type OrderCustomer = {
  email: string;
  displayName: string;
  phoneNumber: string;
  uid: string;
};

export type OrderStore = {
  banner: string;
  currency: string;
  id: string;
  name: string;
  orderPrepareMinutes: number;
  phoneNumber: string;
  retryTimeLimit: number;
  timezone: string;
  isDemo: string;
  isUseInvoice: boolean;
  location: {
    address: string;
  };
  delivery: {
    allowHandlingFeeForCustomer: boolean;
    allowPandago: boolean;
    costShare: number;
    costShareLimit: number;
    freeLimit: number;
    handlingFee: number;
    lalamoveCityCode: string;
    maxDistance: number;
    note: string;
    pandagoStoreId: string;
  };
};

export type OrderDelivery = {
  activeCarrierType: CarrierType;
  note: string;
  isSelfDelivery: boolean;
  pickupDate: firebase.firestore.Timestamp;
  orderPrepareMinutes: number;
  carriers: { [carrier in CarrierType]?: any };
  quotes: { [carrier in CarrierType]?: { price: number } };
  location: {
    address: string;
    floor: string;
    note: string;
    geopoint: firebase.firestore.GeoPoint;
  };
  estimateInTraffic: number;
};

export type PaymentInfo = {
  card: {
    last4Digits?: string;
  };
  createdAt: firebase.firestore.Timestamp;
  isRefund?: boolean;
  isTransactionFailed: boolean;
};

export type OrderOption = {
  id: string;
  name: string;
  price: number;
};

export type OrderModifier = {
  id: string;
  name: string;
  options: Array<OrderOption>;
};

export type OrderItem = {
  count: number;
  id: string;
  name: string;
  note: string;
  price: number;
  tax: number;
  modifiers: Array<OrderModifier>;
};

export type Order = {
  type: OrderType;
  isGroupOrder: boolean;
  createdAt: firebase.firestore.Timestamp;
  preOrderAt: firebase.firestore.Timestamp;
  customer: OrderCustomer;
  items: Array<OrderItem>;
  isLargeOrder: boolean;
  largeOrderPrepareTime: number;
  note: string;
  orderNumber: number;

  groupItems: Array<GroupItem>;

  // payment
  paymentType: PaymentType;
  paymentInfo?: PaymentInfo;

  // price total
  subtotal: number;
  tax: number;
  total: number;
  discount: number;

  // status
  status: WsOrderStatus;
  statusRecord: Partial<Record<WsOrderStatus, firebase.firestore.Timestamp>>;

  // store and delivery info
  store: OrderStore;
  delivery?: OrderDelivery;

  // shipping fee info
  shippingFeeDetails: ShippingFeeDetails;
  shippingFee: ShippingFee;

  // tax
  taxType: TaxType;
  taxInfo: {
    title: string;
    invoice: string;
  };

  // fail reasons
  reasonForCancelling?: Array<string>;

  // abandon order
  allowPaymentNotRefund?: boolean;

  coupon: {
    condition: RewardCondition;
    type: RewardType;
  } | null;
};

export type OrderWithId = { id: string } & Order;

export type GroupItem = {
  name: string;
  items: Array<OrderItem>;
};

export type GroupOrder = {
  type: OrderType;
  isGroupOrder: boolean;
  createdAt: firebase.firestore.Timestamp;
  preOrderAt: firebase.firestore.Timestamp;
  customer: OrderCustomer;
  groupItems: Array<GroupItem>;

  isLargeOrder: boolean;
  largeOrderPrepareTime: number;

  note: string;
  orderNumber: number;

  // payment
  paymentType: PaymentType;
  paymentInfo: PaymentInfo;

  // price total
  subtotal: number;
  tax: number;
  total: number;
  discount: number;

  // status info
  status: WsOrderStatus;
  statusRecord: Partial<Record<WsOrderStatus, firebase.firestore.Timestamp>>;

  // store and delivery info
  store: OrderStore;
  delivery?: OrderDelivery;

  // shipping fee
  shippingFeeDetails: ShippingFeeDetails;
  shippingFee: ShippingFee;

  // tax
  taxType: TaxType;
  taxInfo: {
    title: string;
    invoice: string;
  };

  // fail reasons
  reasonForCancelling?: Array<string>;

  // abandon order
  allowPaymentNotRefund?: boolean;

  coupon: {
    condition: RewardCondition;
    type: RewardType;
  } | null;
};

export type GroupOrderWithId = { id: string } & GroupOrder;

// cart type
export enum CartType {
  PERSONAL = 'WS_CART_PERSONAL',
  GROUP = 'WS_CART_GROUP',
}

export enum CartStatus {
  ACTIVE = 'WS_CART_STATUS_ACTIVE',
  ORDERING = 'WS_CART_STATUS_ORDERING',
  CANCELLED = 'WS_CART_STATUS_CANCELLED',
  ORDERED = 'WS_CART_STATUS_ORDERED',
}

export type CartItem = OrderItem;

// FirebaseCart
export type FirebaseCart = {
  email: string;
  uid: string;
  cartType: CartType;
  location: Location;
  orderType: OrderType;
  preOrderDate: firebase.firestore.Timestamp | null;
  status: CartStatus;
  paymentType: PaymentType;
  taxType: TaxType;
  taxInfo: {
    invoice: string;
    title: string;
  };
  items: Array<CartItem>;
  coupon: FirebaseCoupon | null;
  shippingFee: {
    distance: number;
    quotes: number;
    estimateSecondsToArrival: number;
  } | null;
};

// Cart convert preOrderDate type from Timestamp to Date
export type Cart = Omit<FirebaseCart, 'preOrderDate' | 'coupon'> & {
  preOrderDate: PreOrderDate;
  coupon: Coupon | null;
};

export type GroupCartItem = {
  memberId: string;
  memberName: string;
  items: Array<CartItem>;
};

export type FirebaseGroupCart = {
  email: string;
  uid: string;
  cartType: CartType;
  location: Location;
  orderType: OrderType;
  preOrderDate: firebase.firestore.Timestamp | null;
  status: CartStatus;
  paymentType: PaymentType;
  taxType: TaxType;
  taxInfo: {
    invoice: string;
    title: string;
  };
  groupItems: Array<GroupCartItem>;
  groupHostInfo: {
    note: string; // 團長的話
    name: string; // 團長的名字
  };
  coupon: FirebaseCoupon | null;
  shippingFee: {
    distance: number;
    quotes: number;
    estimateSecondsToArrival: number;
  } | null;
};

// group cart convert preOrderDate type from Timestamp to Date
export type GroupCart = Omit<FirebaseGroupCart, 'preOrderDate' | 'coupon'> & {
  preOrderDate: PreOrderDate;
  coupon: Coupon | null;
};

export type FirebaseCustomer = {
  displayName?: string;
  phoneNumber?: string;
  recentAddress: Array<Location>;
};

// coupon
export enum RewardType {
  DESCRIPTION = 'REWARD_TYPE_DESCRIPTION',
  DISCOUNT = 'REWARD_TYPE_DISCOUNT',
  DISCOUNT_PERCENTAGE = 'REWARD_TYPE_DISCOUNT_PERCENTAGE',
  ITEMS = 'REWARD_TYPE_ITEMS', // not implement
}

export type CouponDiscount = {
  costLimit: number;
  discount: number;
};

export type CouponDiscountPercentage = {
  costLimit: number;
  percentage: number;
  discountLimit: number;
};

export type CouponGift = {
  costLimit: number;
  name: string;
  description: string;
  price?: number;
};

export type RewardCondition = Partial<{
  [RewardType.DISCOUNT]: CouponDiscount;
  [RewardType.DISCOUNT_PERCENTAGE]: CouponDiscountPercentage;
  [RewardType.DESCRIPTION]: CouponGift;
}>;

export type Coupon = {
  id: string;
  startDate: Date;
  endDate: Date;
  allowOrderTypes: Array<OrderType>;
  allowDisplay: boolean;
  name: string;
  description: string;
  note: string;
  code: string;
  rewardType: RewardType;
  rewardCondition: RewardCondition;
};

export type FirebaseCoupon = Omit<Coupon, 'startDate' | 'endDate'> & {
  startDate: firebase.firestore.Timestamp;
  endDate: firebase.firestore.Timestamp;
};

export type CouponFromApi = Omit<Coupon, 'startDate' | 'endDate'> & {
  startDate: string; // ISO string
  endDate: string; // ISO string
};
