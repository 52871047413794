import { useState } from 'react';
import { VStack, Box, Image, Stack, AspectRatio } from '@chakra-ui/react';
import {
  AddToCartBottomBar,
  FixedBackwardBar,
  ItemModifier,
  ItemInfo,
  NoteInput,
  InventoryNoticeModal,
} from './components';
import PageContainer from '~/container/PageContainer';
import useScroll from '~/hooks/useScroll';

import { useParams, Navigate, useNavigate } from 'react-router-dom';

import { itemSelector } from '~/recoil/selectors/items';
import memberCartItemSelector from '~/recoil/selectors/groupCartItem';

import { useRecoilValue } from 'recoil';

import useModifiersControl from '~/pages/Item/hooks/useModifiersControl';
import useAllowOperateCart from '~/pages/Item/hooks/useAllowOperateCart';
import useGroupCartItems from '~/hooks/useGroupCartItems';
import useItemModifiers from '~/pages/Item/hooks/useItemModifiers';
import useRemainInventory from '~/pages/Item/hooks/useRemainInventory';
import { OrderModifier, CartItem } from '~/types';

import ImageSrcError from '~/assets/images/ImageSrcError.png';

import { useTranslation } from 'react-i18next';

export default function GroupEditItemPage() {
  const { t } = useTranslation();
  const { storeId, groupId, itemId = '', memberId = '', index = '' } = useParams();
  const navigate = useNavigate();
  const { positionInfo } = useScroll();

  const [showError, setShowError] = useState<boolean>(false);
  const [inventoryErrorModal, setInventoryErrorModal] = useState<'none' | 'item' | 'option'>(
    'none',
  );

  const item = useRecoilValue(itemSelector(itemId));
  const memberCartItem = useRecoilValue(
    memberCartItemSelector({ itemId, memberId, index: parseInt(index) }),
  );
  const { groupItems, handleEditGroupItem } = useGroupCartItems();

  // check operating time
  const allowOperateCart = useAllowOperateCart(itemId);

  // 品項庫存剩餘量 remainInventory 剩餘庫存 , targetItemCount 購物車總共選了多少個
  // 因為正在編輯該品項所以不應該把它算進去購物車總量裡面，要加回來 remainInventory - targetItemCount + "memberCartItem.count"
  const { remainInventory, targetItemCount } = useRemainInventory(
    item?.inventory,
    itemId,
    groupItems.map(({ items }) => items).flat(),
  );

  // 選項庫存剩餘量
  const { modifiersWithRemainCount, handleCheckModifierOptionError } = useItemModifiers(
    item?.modifiers || [],
    {
      cartItemModifiers: memberCartItem?.modifiers,
      compensativeCount: memberCartItem?.count,
    },
  );

  // control item info hook
  const {
    selectedModifiers,
    modifierErrors,
    optionsTotalPrice,
    count,
    note,
    handleChangeCount,
    handleChangeModifier,
    handleChangeNote,
  } = useModifiersControl(item?.modifiers || null, {
    defaultNote: memberCartItem?.note,
    defaultCount: memberCartItem?.count,
    defaultModifiers: memberCartItem?.modifiers,
  });

  if (!item || !memberCartItem) {
    return <Navigate to={`/${storeId}/group/${groupId}`} />;
  }

  const handleClickEditItem = async () => {
    if (modifierErrors.size > 0) {
      setShowError(true);
      return;
    }

    if (count > remainInventory - targetItemCount + memberCartItem.count) {
      setInventoryErrorModal('item');
      console.log('edit item inventory error');
      return;
    }

    const modifiers: Array<OrderModifier> = [...selectedModifiers.values()];

    if (handleCheckModifierOptionError(modifiers, count)) {
      console.log('edit item option inventory error');
      setInventoryErrorModal('option');
      return;
    }

    const editCartItem: CartItem = {
      id: itemId,
      count: count,
      note: note,
      name: item.name,
      price: item.price,
      modifiers: modifiers,
      tax: item.tax,
    };

    handleEditGroupItem(editCartItem, memberId, parseInt(index));
    navigate(`/${storeId}/group/${groupId}/cart`);
  };

  const displayInventory =
    remainInventory - targetItemCount + memberCartItem.count <= 0
      ? 0
      : remainInventory - targetItemCount + memberCartItem.count;

  return (
    <PageContainer hideHeader hideFooter>
      <FixedBackwardBar
        title={t('backCart')}
        onNavigate={() => navigate(`/${storeId}/group/${groupId}/cart`)}
      />

      <VStack align="stretch" justify="space-between" spacing={4} minH="100%" px={4}>
        <Stack direction={{ base: 'column', md: 'row' }} align="stretch" spacing={10}>
          <Box flex="1" position="relative">
            {item.image && (
              <AspectRatio
                m="auto"
                maxW="1000px"
                ratio={1}
                pos={{ base: 'static', md: 'sticky' }}
                top={20}
                zIndex="50"
              >
                <Image src={item.image} fallbackSrc={ImageSrcError} w="full" />
              </AspectRatio>
            )}
          </Box>
          <VStack align="stretch" flex="1" spacing={5}>
            <ItemInfo
              title={item.name}
              description={item.description}
              price={item.price}
              inventory={displayInventory}
            />
            <ItemModifier
              itemModifiers={modifiersWithRemainCount}
              selectorModifiers={selectedModifiers}
              modifierErrors={modifierErrors}
              showError={showError}
              itemCount={count}
              positionInfo={positionInfo}
              onChangeModifier={handleChangeModifier}
            />
            <NoteInput
              value={note}
              textLimit={100}
              onChange={(e) => handleChangeNote(e.target.value)}
            />
            {!item.image && (
              <Box h="400px" pos={{ base: 'static', md: 'sticky' }} top={20} zIndex="100" />
            )}
          </VStack>
        </Stack>
        <AddToCartBottomBar
          isDisabled={!allowOperateCart}
          amount={count}
          currentInventory={displayInventory}
          unitPrice={optionsTotalPrice + item.price}
          onChangeCount={handleChangeCount}
          onAddItemClick={handleClickEditItem}
        />
      </VStack>

      <InventoryNoticeModal
        isOpen={inventoryErrorModal === 'item'}
        title={t('store.item.soldOutNote')}
        message={t('store.reselectNote')}
        onFooterClick={() => {
          setInventoryErrorModal('none');
          navigate(`/${storeId}/group/${groupId}/cart`);
        }}
      />
      <InventoryNoticeModal
        isOpen={inventoryErrorModal === 'option'}
        title={t('store.item.option.soldOut')}
        message={t('store.item.option.reselectNote')}
        onFooterClick={() => setInventoryErrorModal('none')}
      />
    </PageContainer>
  );
}
