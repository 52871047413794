import { atom } from 'recoil';

type MediaQueryState = {
  isLargerThan768: boolean;
};

const mediaQueryState = atom<MediaQueryState>({
  key: 'mediaQueryState',
  default: {
    isLargerThan768: false,
  },
});

export default mediaQueryState;
