import { firestore } from '~/firebase';
import { useReducer, useCallback, useEffect } from 'react';
import checkValidTaxNumber from '~/utils/checkValidTaxNumber';

import { useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import taxInfoAtom from '~/recoil/atom/taxInfo';
import { useRecoilValue, useRecoilState } from 'recoil';

import debounce from 'lodash/debounce';

export type TaxInfo = {
  title: string;
  invoice: string;
};

export type TaxInfoState = TaxInfo & {
  errors: Partial<TaxInfo>;
};

enum TaxInfoAction {
  SET_TITLE = 'set_title',
  SET_INVOICE = 'set_invoice',
  SET_ERROR = 'set_error',
  SET_ALL = 'set_all',
}

type TaxInfoStateAction =
  | {
      type: TaxInfoAction.SET_TITLE;
      payload: string;
    }
  | {
      type: TaxInfoAction.SET_INVOICE;
      payload: string;
    }
  | {
      type: TaxInfoAction.SET_ERROR;
      payload: Partial<TaxInfoState>;
    }
  | {
      type: TaxInfoAction.SET_ALL;
      payload: TaxInfoState;
    };

function reducer(state: TaxInfoState, action: TaxInfoStateAction): TaxInfoState {
  switch (action.type) {
    case TaxInfoAction.SET_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case TaxInfoAction.SET_INVOICE: {
      return {
        ...state,
        invoice: action.payload,
      };
    }
    case TaxInfoAction.SET_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case TaxInfoAction.SET_ALL: {
      return action.payload;
    }
    default: {
      throw Error('not match action');
    }
  }
}

function checkTaxInfoErrors(taxInfo: TaxInfo): [boolean, Partial<TaxInfo>] {
  let hasError = false;
  let taxInfoErrors: Partial<TaxInfo> = {};

  const { title, invoice } = taxInfo;

  if (title.length < 1) {
    hasError = true;
    taxInfoErrors.title = 'store.order.error.invalidInvoiceTitleLength';
  }

  if (invoice.length !== 8) {
    hasError = true;
    taxInfoErrors.invoice = 'store.order.error.invalidInvoiceInvoiceLength';
  }

  if (invoice.length === 8 && !checkValidTaxNumber(taxInfo.invoice)) {
    hasError = true;
    taxInfoErrors.invoice = 'store.order.error.invalidInvoiceInvoice';
  }

  return [hasError, taxInfoErrors];
}

export default function useTaxInfo() {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const [taxInfo, setTaxInfo] = useRecoilState(taxInfoAtom);
  const [taxInfoState, dispatch] = useReducer(reducer, {
    ...taxInfo,
    errors: checkTaxInfoErrors(taxInfo)[1],
  });

  const { title, invoice } = taxInfoState;

  useEffect(() => {
    dispatch({
      type: TaxInfoAction.SET_ALL,
      payload: {
        ...taxInfo,
        errors: checkTaxInfoErrors(taxInfo)[1],
      },
    });
  }, [taxInfo]);

  const handleUpdateTaxInfo = useCallback(
    debounce(async (title: string, invoice: string) => {
      const [hasError, errors] = checkTaxInfoErrors({ title, invoice });

      if (hasError) {
        dispatch({ type: TaxInfoAction.SET_ERROR, payload: errors });
      } else {
        dispatch({ type: TaxInfoAction.SET_ERROR, payload: {} });
      }

      if (!auth) {
        setTaxInfo({ title, invoice });
        return;
      }

      if (groupId && storeId) {
        try {
          await firestore
            .doc(`stores/${storeId}/carts/${groupId}`)
            .update('taxInfo', { title, invoice });
        } catch (err) {
          console.error('group tax info update error', err);
        }
        return;
      }

      if (storeId) {
        try {
          await firestore
            .doc(`stores/${storeId}/carts/${auth.uid}`)
            .update('taxInfo', { title, invoice });
        } catch (err) {
          console.error('tax info update error', err);
        }
      }
    }, 700),
    [],
  );

  const handleUpdateTitle = (value: string) => {
    dispatch({ type: TaxInfoAction.SET_TITLE, payload: value });
    handleUpdateTaxInfo(value, invoice);
  };

  const handleUpdateInvoice = (value: string) => {
    dispatch({ type: TaxInfoAction.SET_INVOICE, payload: value });
    handleUpdateTaxInfo(title, value);
  };

  return {
    taxInfoState,
    handleUpdateTitle,
    handleUpdateInvoice,
  };
}
