import { Center } from '@chakra-ui/react';

import Normal from './Normal';
import Disable from './Disable';
import Active from './Active';
import None from './None';

import { DateInfo, DayType } from '../type';

type DayProps = {
  dateInfo: DateInfo;
  isSelectedDay: boolean;
  onDaySelect: (day: Date) => void;
};

export default function Day({ dateInfo, isSelectedDay, onDaySelect }: DayProps) {
  const { dayType, day, date } = dateInfo;

  const handleDayClick = () => {
    onDaySelect(date);
  };

  if (dayType === DayType.DISABLE) {
    return (
      <Center>
        <Disable>{day}</Disable>
      </Center>
    );
  }

  if (dayType === DayType.NORMAL) {
    if (isSelectedDay) {
      return (
        <Center>
          <Active>{day}</Active>
        </Center>
      );
    } else {
      return (
        <Center onClick={handleDayClick}>
          <Normal>{day}</Normal>
        </Center>
      );
    }
  }

  return (
    <Center>
      <None />
    </Center>
  );
}
