import { FormControl, FormLabel, Input, InputGroup, VStack, InputProps } from '@chakra-ui/react';
import { useState } from 'react';

import FormInputMessage from '~/components/FormInputMessage';

import { useTranslation } from 'react-i18next';

type TaxProps = {
  title: string;
  invoice: string;
};

// 統編資料輸入
export default function Tax({ title, invoice }: TaxProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(title);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  return (
    <VStack align="flex-start">
      <FormControl id="first-Tax">
        <FormLabel fontWeight="normal" letterSpacing={2}>
          {t('personalInfo.VAT')}
        </FormLabel>
        <InputGroup>
          <VStack align="stretch" width="inherit">
            <Input
              defaultValue={title}
              width="inherit"
              placeholder={t('personalInfo.companyName')}
              value={value}
              onChange={handleChange}
            />
            {value.length === 1 && (
              <FormInputMessage message={t('personalInfo.invalidCompanyName')} type="error" />
            )}
            <Input
              defaultValue={invoice}
              width="inherit"
              placeholder={t('personalInfo.VTAInput')}
            />
          </VStack>
        </InputGroup>
      </FormControl>
    </VStack>
  );
}
