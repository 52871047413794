import { ComponentStyleConfig } from '@chakra-ui/react';
import Button from './Button';
import Heading from './Heading';
import Text from './Text';
import Input from './Input';
import Radio from './Radio';
import Checkbox from './CheckBox';
import Link from './Link';
import CloseButton from './CloseButton';

const components: Record<string, ComponentStyleConfig> = {
  Button,
  Heading,
  Input,
  Text,
  Radio,
  Checkbox,
  Link,
  CloseButton,
};

export default components;
