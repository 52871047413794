import { GroupCartItem, CartItem } from '~/types';

export default function filterGroupInvalidItems(
  groupItems: Array<GroupCartItem>,
  invalidItems: Array<CartItem>,
  mode: 'invalid' | 'valid',
): Array<GroupCartItem> {
  const invalidItemIdSet = invalidItems.reduce<Set<string>>((acc, { id }) => {
    acc.add(id);
    return acc;
  }, new Set());

  if (mode === 'invalid') {
    return groupItems
      .map((groupItem) => {
        return {
          ...groupItem,
          items: groupItem.items.filter(({ id }) => invalidItemIdSet.has(id)),
        };
      })
      .filter(({ items }) => items.length > 0);
  }

  return groupItems
    .map((groupItem) => {
      return {
        ...groupItem,
        items: groupItem.items.filter(({ id }) => !invalidItemIdSet.has(id)),
      };
    })
    .filter(({ items }) => items.length > 0);
}
