import { VStack, HStack, Text } from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineShop, AiOutlineShoppingCart, AiOutlineClockCircle } from 'react-icons/ai';

import PageContainer from '~/container/PageContainer';
import ImageSlick from '~/components/ImageSlick';

import PageTitle from '~/components/PageTitle';
import { Delivery, Detail, SectionContainer, OperatingTime } from './components';
import { useRecoilValue } from 'recoil';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';
import { useTranslation } from 'react-i18next';

const StoreInfo = () => {
  const store = useRecoilValue(storeLocaleSelector);
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!store) {
    return <Navigate to="404" />;
  }

  const { name, phoneNumber, location, delivery, operatingTime, description, images } = store;

  return (
    <PageContainer hideHeader>
      <PageTitle
        title={t('store.info')}
        onNavigate={() => navigate(groupId ? `/${storeId}/group/${groupId}` : `/${storeId}`)}
      />
      <ImageSlick images={images} />

      <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={7}>
        <SectionContainer icon={AiOutlineShop} title={name}>
          <Detail phoneNumber={phoneNumber} location={location.address} boxSize="1.125rem" />
        </SectionContainer>

        <SectionContainer icon={AiOutlineShoppingCart} title={t('store.shippingSettings')}>
          <Delivery
            deliveryShare={delivery.costShareLimit}
            costShare={delivery.costShare}
            freeLimit={delivery.freeLimit}
          />
        </SectionContainer>

        <SectionContainer icon={AiOutlineClockCircle} title={t('store.operatingTime')}>
          <OperatingTime operatingTime={operatingTime} />
        </SectionContainer>

        <SectionContainer icon={AiOutlineClockCircle} title={t('store.about')}>
          <HStack>
            <Text fontSize="sm">{description}</Text>
          </HStack>
        </SectionContainer>
      </VStack>
    </PageContainer>
  );
};

export default StoreInfo;
