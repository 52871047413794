import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Radio: ComponentSingleStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        bgColor: 'wsBlack',
        borderWidth: '0.15rem',
        borderColor: 'white',
        boxShadow: '0px 0px 1px 1px #333',
        _before: {
          bgColor: 'wsBlack',
        },
        _hover: {
          bgColor: 'wsBlack',
          borderWidth: '0.15rem',
          borderColor: 'white',
          boxShadow: '0px 0px 1px 1px #333',
        },
      },
      _focus: {
        boxShadow: '0px 0px 1px 1px #333',
      },
      _disabled: {
        bgColor: 'white',
        _checked: {
          bgColor: 'wsGray.400',
          borderWidth: '0.15rem',
          borderColor: 'white',
          boxShadow: '0px 0px 1px 1px #C4C4C4',
          _before: {
            bgColor: 'wsGray.400',
          },
        },
      },
    },
    label: {
      display: 'block',
      w: 'full',
      color: 'wsBlack',
      _disabled: {
        opacity: '1',
        color: 'wsGray.400',
      },
    },
  },
};

export default Radio;
