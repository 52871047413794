import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  VStack,
  InputProps,
  Select,
} from '@chakra-ui/react';

import FormInputMessage from '~/components/FormInputMessage';

import { useTranslation } from 'react-i18next';

type GenderProps = {
  gender: string;
};

// 性別輸入
export default function Gender({ gender }: GenderProps) {
  const { t } = useTranslation();
  return (
    <VStack align="flex-start">
      <FormControl id="first-Gender">
        <FormLabel fontWeight="normal" letterSpacing={2}>
          {t('personalInfo.gender')}
        </FormLabel>
        <Select
          focusBorderColor="wsBlack"
          size="md"
          width="inherit"
          borderColor="wsGray.400"
          placeholder="-"
        >
          <option value="option1">{t('personalInfo.male')}</option>
          <option value="option2">{t('personalInfo.female')}</option>
        </Select>
        {/* {error && <FormInputMessage message={error} type="error" />} */}
      </FormControl>
    </VStack>
  );
}
