import { HStack, IconButton, Text, Center, ChakraProps } from '@chakra-ui/react';
import { getYear, getMonth, addMonths } from 'date-fns';
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5';

const monthLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type ControlBarProps = {
  date: Date;
  onChangeDate: (newDate: Date) => void;
};

export default function ControlBar({ date, onChangeDate }: ControlBarProps) {
  const year = getYear(date);
  const month = getMonth(date);

  const handleIncrementMonth = () => {
    onChangeDate(addMonths(date, 1));
  };

  const handleDecrementMonth = () => {
    onChangeDate(addMonths(date, -1));
  };

  const iconBtnStyle: ChakraProps = {
    bgColor: 'transparent',
    color: 'wsBlack',
    _hover: {},
    _focus: {},
  };

  return (
    <HStack>
      <IconButton
        aria-label="prev month"
        icon={<IoChevronBackOutline />}
        onClick={handleDecrementMonth}
        // onTouchStart={handleDecrementMonth}
        {...iconBtnStyle}
      />

      <Center flex="1">
        <Text fontSize="xl" fontWeight="bold">
          {year} {monthLabels[month]}
        </Text>
      </Center>

      <IconButton
        aria-label="next month"
        icon={<IoChevronForwardOutline />}
        onClick={handleIncrementMonth}
        // onTouchStart={handleIncrementMonth}
        {...iconBtnStyle}
      />
    </HStack>
  );
}
