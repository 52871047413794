import { FormControl, FormLabel, VStack, Input } from '@chakra-ui/react';
import FormInputMessage from '~/components/FormInputMessage';
import { TaxInfoState } from '~/pages/Cart/hooks/useTaxInfo';

import { useTranslation } from 'react-i18next';

type TaxInfoFormProps = {
  taxInfoState: TaxInfoState;
  showError: boolean;
  onChangeTitle: (value: string) => void;
  onChangeInvoice: (value: string) => void;
};

export default function TaxInfoForm({
  taxInfoState,
  showError,
  onChangeInvoice,
  onChangeTitle,
}: TaxInfoFormProps) {
  const { title, invoice, errors } = taxInfoState;
  const { t } = useTranslation();

  return (
    <VStack>
      <FormControl isRequired>
        <FormLabel fontWeight="normal">{t('store.order.companyName')}</FormLabel>
        <Input
          type="text"
          maxLength={20}
          value={title}
          isInvalid={showError && !!errors.title}
          onChange={(e) => onChangeTitle(e.target.value)}
        />
        {showError && errors.title && <FormInputMessage type="error" message={t(errors.title)} />}
      </FormControl>

      <FormControl isRequired>
        <FormLabel fontWeight="normal">{t('store.order.VAT')}</FormLabel>
        <Input
          type="tel"
          maxLength={20}
          value={invoice}
          isInvalid={showError && !!errors.invoice}
          onChange={(e) => onChangeInvoice(e.target.value)}
        />
        {showError && errors.invoice && (
          <FormInputMessage type="error" message={t(errors.invoice)} />
        )}
      </FormControl>
    </VStack>
  );
}
