import { useRecoilValue } from 'recoil';
import { RadioGroup, Radio, Text, VStack, Divider, Box } from '@chakra-ui/react';

import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';

import FormInputMessage from '~/components/FormInputMessage';

import { PickMealTime } from '~/types';

import { useTranslation } from 'react-i18next';

type PickupTimeSelectProps = {
  selectedTime: PickMealTime;
  selectedTimeError: string | null;
  onPickupTimeChange: (time: PickMealTime) => void;
  selectedDayString: string | undefined;
  selectedDurationString: string | undefined;
};

// 儘快取餐或預約
export default function PickupTimeSelect({
  selectedTime,
  selectedTimeError,
  onPickupTimeChange,
  selectedDayString,
  selectedDurationString,
}: PickupTimeSelectProps) {
  const { allowRealTimeOrder, allowPreOrder } = useRecoilValue(storeOrderSettingSelector);
  const { t } = useTranslation();
  return (
    <>
      {selectedTimeError && (
        <Box mb={1}>
          <FormInputMessage type="error" message={t(selectedTimeError)} />
        </Box>
      )}
      <RadioGroup value={selectedTime} onChange={onPickupTimeChange}>
        <VStack align="stretch" mt={2}>
          {allowRealTimeOrder && (
            <>
              <Radio value={PickMealTime.IMMEDIATELY}>
                <Text fontSize="sm">{t('store.pickUpSetting.ASAP')}</Text>
              </Radio>
              {selectedTime === PickMealTime.IMMEDIATELY && (
                <Text>
                  {selectedDayString} {selectedDurationString}
                </Text>
              )}
            </>
          )}
          <Divider />

          {allowPreOrder && (
            <>
              <Radio value={PickMealTime.RESERVATION}>
                <Text fontSize="sm">{t('store.pickUpSetting.preorder')}</Text>
              </Radio>
              {selectedTime === PickMealTime.RESERVATION && (
                <Text>
                  {selectedDayString} {selectedDurationString}
                </Text>
              )}
              <Divider />
            </>
          )}
        </VStack>
      </RadioGroup>
    </>
  );
}
