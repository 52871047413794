import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export default function Coupon(props: IconProps) {
  return (
    <Icon viewBox='0 0 20 15' fill='transparent' {...props}>
      <rect
        x='0.781752'
        y='0.781752'
        width='18.3071'
        height='12.7875'
        rx='0.821842'
        stroke='#333333'
        strokeWidth='1.5635'
      />
      <line
        x1='13.2236'
        y1='3.58873'
        x2='9.45425'
        y2='9.87093'
        stroke='#333333'
        strokeWidth='1.5635'
      />
      <circle
        cx='8.15785'
        cy='5.07118'
        r='1.2027'
        stroke='#333333'
        strokeWidth='1.36393'
      />
      <circle
        cx='14.4379'
        cy='8.83876'
        r='1.2027'
        stroke='#333333'
        strokeWidth='1.36393'
      />
      <rect
        x='3.42439'
        y='3.46687'
        width='0.560688'
        height='0.560688'
        fill='#9E9E9E'
        stroke='#333333'
        strokeWidth='0.560688'
      />
      <rect
        x='3.42439'
        y='5.70906'
        width='0.560688'
        height='0.560688'
        fill='#9E9E9E'
        stroke='#333333'
        strokeWidth='0.560688'
      />
      <rect
        x='3.42439'
        y='7.95222'
        width='0.560688'
        height='0.560688'
        fill='#9E9E9E'
        stroke='#333333'
        strokeWidth='0.560688'
      />
      <rect
        x='3.42439'
        y='10.1944'
        width='0.560688'
        height='0.560688'
        fill='#9E9E9E'
        stroke='#333333'
        strokeWidth='0.560688'
      />
    </Icon>
  );
}
