import { endOfDay, isAfter, startOfDay } from 'date-fns';
import { Coupon, MealPickUpType, PickMealTime, RewardType } from '~/types';
import convertMealPickupType from './convertMealPickupType';

export default function checkCouponValid(
  coupon: Coupon,
  subtotal: number,
  {
    mealPickupType,
    pickMealTime,
    preOrderDate,
  }: {
    mealPickupType: MealPickUpType;
    pickMealTime: PickMealTime;
    preOrderDate: Date | null;
  },
): boolean {
  const orderType = convertMealPickupType(mealPickupType, pickMealTime);

  const checkDate =
    pickMealTime === PickMealTime.RESERVATION ? preOrderDate || new Date() : new Date();

  const { rewardCondition } = coupon;
  const costLimit =
    rewardCondition[RewardType.DISCOUNT]?.costLimit ||
    rewardCondition[RewardType.DESCRIPTION]?.costLimit ||
    rewardCondition[RewardType.DISCOUNT_PERCENTAGE]?.costLimit ||
    Infinity;

  if (
    coupon.allowOrderTypes.includes(orderType) &&
    isAfter(checkDate, startOfDay(coupon.startDate)) &&
    isAfter(endOfDay(coupon.endDate), checkDate) &&
    subtotal >= costLimit
  )
    return true;

  return false;
}
