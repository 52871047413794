import { selector } from 'recoil';

import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import locationAtom from '~/recoil/atom/location';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';

import { MealOrderSetting } from '~/types';

const mealOrderSettingSelector = selector<MealOrderSetting>({
  key: 'orderSettingSelector',
  get: ({ get }) => {
    const mealPickupType = get(mealPickupTypeAtom);
    const location = get(locationAtom);
    const pickMealTime = get(pickMealTimeAtom);
    const preOrderDate = get(preOrderDateAtom);

    return {
      mealPickupType,
      location,
      pickMealTime,
      preOrderDate,
    };
  },
});

export default mealOrderSettingSelector;
