import { Coupon, RewardType } from '~/types';

export default function getCouponDiscount(coupon: Coupon, subtotal: number): number {
  const { rewardType, rewardCondition } = coupon;

  if (rewardType === RewardType.DESCRIPTION) {
    return 0;
  }

  const couponDiscount = rewardCondition[RewardType.DISCOUNT];
  if (rewardType === RewardType.DISCOUNT && couponDiscount) {
    return subtotal >= couponDiscount.costLimit ? Math.min(couponDiscount.discount, subtotal) : 0;
  }

  const couponDiscountPercentage = rewardCondition[RewardType.DISCOUNT_PERCENTAGE];
  if (rewardType === RewardType.DISCOUNT_PERCENTAGE && couponDiscountPercentage) {
    const minCostLimit = Math.min(
      couponDiscountPercentage.discountLimit,
      Math.floor(subtotal * couponDiscountPercentage.percentage),
    );
    return subtotal >= couponDiscountPercentage.costLimit ? minCostLimit : 0;
  }

  return 0;
}
