import { HStack, Text, Button } from '@chakra-ui/react';
import ContentContainer from '~/container/Content';

import { useTranslation } from 'react-i18next';

export type FixFooterLoginSectionProps = {
  login: () => void;
};

// 購物車資訊
export default function FixFooterLoginSection({ login }: FixFooterLoginSectionProps) {
  const { t } = useTranslation();
  return (
    <ContentContainer position="sticky" bottom={0} zIndex="1" transition="0.3s">
      <HStack
        justify="space-between"
        px={4}
        py={2}
        bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
        spacing={1}
      >
        <HStack>
          <Text color="white" fontSize="xs">
            {t('store.item.loginFirst')}
          </Text>
        </HStack>
        <Button onClick={login} variant="unstyled" color="white" bg="wsGray.700" w="9.6rem">
          {t('login')}
        </Button>
      </HStack>
    </ContentContainer>
  );
}
