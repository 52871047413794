import useOrderSetting from '~/hooks/useOrderSetting';
import useLocationSetting from '~/components/DeliverySettingSection/hooks/useLocationSetting';
import useLocationList from '~/components/DeliverySettingSection/hooks/useLocationList';

export default function usePickLocation() {
  const { mealOrderSetting } = useOrderSetting();
  const {
    address,
    selectedAddress,
    addressErrorMessage,
    locationSearchList,
    isLoading,
    floor,
    floorErrorMessage,
    note,
    handleAddressChange,
    handleAddressClick,
    handleAddressClear,
    handleAddressBlur,
    handleFloorChange,
    handleFloorBlur,
    handleNoteChange,
  } = useLocationSetting({
    currentLocation: mealOrderSetting.location,
  });

  const {
    userLocationList,
    selectedUserLocationIndex,
    selectedLocation,
    handleLocationSelect,
    handleLocationEdit,
  } = useLocationList();

  return {
    address,
    selectedAddress,
    addressErrorMessage,
    locationSearchList,
    isLoading,
    floor,
    floorErrorMessage,
    note,
    handleAddressChange,
    handleAddressClick,
    handleAddressClear,
    handleAddressBlur,
    handleFloorChange,
    handleFloorBlur,
    handleNoteChange,
    userLocationList,
    selectedUserLocationIndex,
    selectedLocation,
    handleLocationSelect,
    handleLocationEdit,
  };
}
