import { updateCartData, updateGroupCart } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import isHostSelector from '~/recoil/selectors/isHost';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';

import convertMealPickupType from '~/utils/convertMealPickupType';
import { MealPickUpType } from '~/types';

export default function useMealPickupType() {
  const { groupId, storeId } = useParams();
  const [mealPickupType, setMealPickupType] = useRecoilState(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const auth = useRecoilValue(authAtom);
  const isHost = useRecoilValue(isHostSelector);

  const handleUpdateMealPickupType = async (mealPickupType: MealPickUpType) => {
    if (groupId && storeId && isHost) {
      await updateGroupCart(storeId, groupId, {
        orderType: convertMealPickupType(mealPickupType, pickMealTime),
      });
      return;
    }

    if (storeId && !auth) {
      setMealPickupType(mealPickupType);
      return;
    }

    if (storeId && auth) {
      try {
        await updateCartData(storeId, auth, {
          orderType: convertMealPickupType(mealPickupType, pickMealTime),
        });
        setMealPickupType(mealPickupType);
      } catch (err) {}
      return;
    }
  };

  return handleUpdateMealPickupType;
}
