//NOTE: add custom domain names here
const domainMap: Record<string, string> = {
  localhost: 'demo',
  'www.theslice.shop': 'fpBROOlpjZyfF1F9TSOd',
};

//remove www, bit keep subdomains
const domain = window?.location?.hostname || '';
const defaultStoreId = domainMap?.[domain] || '';

export default defaultStoreId;
