import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  SlideFade,
  Text,
  Box,
  VStack,
  Divider,
  HStack,
  Spinner,
  Flex,
} from '@chakra-ui/react';

import { IoCloseCircle } from 'react-icons/io5';

import FormInputMessage from '~/components/FormInputMessage';
import { useTranslation } from 'react-i18next';

type LocationSearchItem = { address: string; distance: number; key: string };

type LocationSearchList = LocationSearchItem[];

type AddressInputProps = {
  address: string;
  selectedAddress: string;
  locationSearchList: LocationSearchList;
  isLoading: boolean;
  errorMessage: string;
  onAddressChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddressClick: (clickedLocationSearchItem: LocationSearchItem) => void;
  onAddressClear: () => void;
  onAddressBlur: () => void;
};

// 輸入地址 input (需選擇確切存在的地址)
export default function AddressInput({
  address,
  selectedAddress,
  locationSearchList,
  isLoading,
  errorMessage,
  onAddressChange,
  onAddressClick,
  onAddressClear,
  onAddressBlur,
}: AddressInputProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <FormControl id="first-name" isRequired>
      <FormLabel fontSize="sm" fontWeight="normal">
        {t('address.address')}
      </FormLabel>
      <InputGroup zIndex={2}>
        <Input
          value={address}
          borderColor={errorMessage ? 'wsRed.600' : 'wsGray.400'}
          autoComplete="off"
          onChange={onAddressChange}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            onAddressBlur();
          }}
          // _hover={{
          //   borderColor: 'wsGray.400',
          // }}
        />
        <InputRightElement>
          {address && (
            <Icon
              onClick={onAddressClear}
              as={IoCloseCircle}
              color="wsGray.400"
              boxSize="1.5rem"
              cursor="pointer"
            />
          )}
        </InputRightElement>
      </InputGroup>
      {errorMessage && <FormInputMessage type="error" message={t(errorMessage)} />}
      <SlideFade in={!!address && !selectedAddress}>
        <VStack
          divider={<Divider />}
          spacing={0}
          align="stretch"
          bg="white"
          pos="absolute"
          zIndex={1}
          width="full"
          border="1px solid #F2F2F2"
        >
          {isLoading ? (
            <Flex w="full" justify="center" p={2}>
              <Spinner />
            </Flex>
          ) : (
            locationSearchList.map((locationSearchItem: LocationSearchItem) => {
              return (
                <Box
                  onClick={() => {
                    onAddressClick(locationSearchItem);
                  }}
                  _hover={{
                    cursor: 'pointer',
                    bg: 'wsGray.200',
                  }}
                  p={2}
                >
                  <Text>{locationSearchItem.address}</Text>
                </Box>
              );
            })
          )}
        </VStack>
      </SlideFade>
    </FormControl>
  );
}
