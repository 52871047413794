import { Icon, IconProps } from '@chakra-ui/react';

export default function StoreInfo(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="transparent" {...props}>
      <rect
        x="2.7"
        y="2.7"
        width="14.6"
        height="14.6"
        rx="1.3"
        stroke="currentcolor"
        strokeWidth="1.4"
      />
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.64706 8.58919C7.14185 9.4287 6.14596 9.99984 5 9.99984C3.47014 9.99984 2.20775 8.98194 2.02321 7.6665H2V7.33317V4.6665H5H10H15H18V7.32513C18 7.32781 18 7.33049 18 7.33317C18 7.33585 18 7.33853 18 7.34121V7.6665H17.9768C17.7923 8.98194 16.5299 9.99984 15 9.99984C13.854 9.99984 12.8582 9.4287 12.3529 8.58918C11.9039 9.4287 11.0186 9.99984 10 9.99984C8.98137 9.99984 8.09614 9.4287 7.64706 8.58919Z"
        />
      </mask>
      <path
        d="M7.64706 8.58919L8.88154 7.92883L7.71869 5.75498L6.44752 7.86731L7.64706 8.58919ZM2.02321 7.6665L3.40963 7.47201L3.24052 6.2665H2.02321V7.6665ZM2 7.6665H0.6V9.0665H2V7.6665ZM2 4.6665V3.2665H0.6V4.6665H2ZM18 4.6665H19.4V3.2665H18V4.6665ZM18 7.32513H16.6L16.6 7.32961L18 7.32513ZM18 7.34121L16.6 7.33673V7.34121H18ZM18 7.6665V9.0665H19.4V7.6665H18ZM17.9768 7.6665V6.2665H16.7595L16.5904 7.47201L17.9768 7.6665ZM12.3529 8.58918L13.5525 7.86731L12.2813 5.75498L11.1185 7.92883L12.3529 8.58918ZM5 11.3998C6.60162 11.3998 8.0704 10.6009 8.8466 9.31106L6.44752 7.86731C6.2133 8.25652 5.69031 8.59984 5 8.59984V11.3998ZM0.636786 7.861C0.933933 9.97916 2.89084 11.3998 5 11.3998V8.59984C4.04944 8.59984 3.48156 7.98472 3.40963 7.47201L0.636786 7.861ZM2.02321 6.2665H2V9.0665H2.02321V6.2665ZM3.4 7.6665V7.33317H0.6V7.6665H3.4ZM3.4 7.33317V4.6665H0.6V7.33317H3.4ZM2 6.0665H5V3.2665H2V6.0665ZM5 6.0665H10V3.2665H5V6.0665ZM10 6.0665H15V3.2665H10V6.0665ZM15 6.0665H18V3.2665H15V6.0665ZM16.6 4.6665V7.32513H19.4V4.6665H16.6ZM19.4 7.33317C19.4 7.32883 19.4 7.32465 19.4 7.32064L16.6 7.32961C16.6 7.33097 16.6 7.33214 16.6 7.33317H19.4ZM19.4 7.3457C19.4 7.34169 19.4 7.33751 19.4 7.33317H16.6C16.6 7.3342 16.6 7.33537 16.6 7.33673L19.4 7.3457ZM16.6 7.34121V7.6665H19.4V7.34121H16.6ZM18 6.2665H17.9768V9.0665H18V6.2665ZM15 11.3998C17.1092 11.3998 19.0661 9.97916 19.3632 7.861L16.5904 7.47201C16.5184 7.98472 15.9506 8.59984 15 8.59984V11.3998ZM11.1534 9.31106C11.9296 10.6009 13.3984 11.3998 15 11.3998V8.59984C14.3097 8.59984 13.7867 8.25652 13.5525 7.86731L11.1534 9.31106ZM10 11.3998C11.5553 11.3998 12.9045 10.5262 13.5874 9.24954L11.1185 7.92883C10.9032 8.33117 10.4819 8.59984 10 8.59984V11.3998ZM6.41258 9.24954C7.09551 10.5262 8.44465 11.3998 10 11.3998V8.59984C9.51808 8.59984 9.09676 8.33117 8.88154 7.92883L6.41258 9.24954Z"
        fill="currentcolor"
        mask="url(#path-2-inside-1)"
      />
      <rect
        x="7.3665"
        y="12.0335"
        width="4.6"
        height="5.26667"
        rx="1.3"
        stroke="currentcolor"
        strokeWidth="1.4"
      />
    </Icon>
  );
}
