import { atom } from 'recoil';

const shippingFee = atom<{
  distance: number;
  quotes: number;
  estimateSecondsToArrival: number;
} | null>({
  key: 'shippingFee',
  default: null,
});

export default shippingFee;
