import firebase from 'firebase';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import couponListAtom from '~/recoil/atom/couponList';
import { useRecoilValue, useRecoilState } from 'recoil';

import getCouponList from '~/httpApi/getCouponList';

import { FetchingState } from '~/types';

export default function useCouponList() {
  const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.INIT);

  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);

  const [couponList, setCouponList] = useRecoilState(couponListAtom);

  const handleFetchCouponList = useCallback(
    async (storeId: string, auth: firebase.User) => {
      try {
        setFetchingState(FetchingState.LOADING);
        const couponList = await getCouponList(storeId, auth);

        setCouponList(couponList);
        setFetchingState(FetchingState.SUCCESS);
      } catch (err) {
        setCouponList([]);
        setFetchingState(FetchingState.FAIL);
      }
    },
    [setCouponList, setFetchingState],
  );

  useEffect(() => {
    if (auth && storeId) {
      handleFetchCouponList(storeId, auth);
    } else {
      setCouponList([]);
    }
  }, [auth, storeId]);

  return {
    couponList,
    fetchingState,
    handleFetchCouponList,
  };
}
