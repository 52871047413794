import { VStack } from '@chakra-ui/react';
import React from 'react';
import SectionLabel, { SectionLabelProps } from './SectionLabel';

type SectionContainerProps = { children?: React.ReactNode } & SectionLabelProps;

// 區段包裝：把區段標題import進來，跟內容一起包成一個區段
export default function SectionContainer({ children, ...props }: SectionContainerProps) {
  return (
    <VStack spacing={4} align="flex-start">
      <SectionLabel {...props} />
      {children}
    </VStack>
  );
}
