import { Center, Text } from '@chakra-ui/react';

type ActiveProps = {
  children?: JSX.Element | string | number;
};

export default function Active({ children }: ActiveProps) {
  return (
    <Center w="full" bgColor="wsBlack" py={3} borderRadius="5px">
      <Text w="1.4rem" textAlign="center" fontWeight="bold" color="white">
        {children}
      </Text>
    </Center>
  );
}
