import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Center,
  Heading,
  StackDivider,
  Box,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

export type CancelModalProps = {
  title: string;
  notice: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

// 團購取消Modal：離開團購時或是取消團購的跳出視窗
export default function CancelModal({
  title,
  notice,
  isOpen,
  onClose,
  onConfirm,
}: CancelModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalBody>
          <Center mt={4}>
            <VStack spacing={4} justify="space-between" my={3}>
              <Heading fontSize="xl">{title}</Heading>
              <Text fontSize="sm" color="wsGray.500">
                {notice}
              </Text>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter
          borderTopWidth="1px"
          borderTopColor="admin.gray.300"
          justifyContent="center"
          p={0}
        >
          <HStack align="stretch" w="full" spacing={0} divider={<StackDivider />}>
            <Box as="button" flex="1" p={3} onClick={onConfirm} _focus={{ boxShadow: 'unset' }}>
              <Text fontSize="sm">{t('group.cancelModal.confirm')}</Text>
            </Box>
            <Box as="button" flex="1" p={3} onClick={onClose} _focus={{ boxShadow: 'unset' }}>
              <Text fontSize="sm">{t('group.cancelModal.wait')}</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
