import { HStack, Icon, Text } from '@chakra-ui/react';

import { IoAlertCircleOutline, IoCheckmarkCircleOutline, IoWarningOutline } from 'react-icons/io5';

type FormInputMessageProps = {
  type?: 'success' | 'error' | 'warn';
  message?: string;
};

export default function FormInputMessage({
  type = 'success',
  message = '',
}: FormInputMessageProps) {
  if (type === 'success') {
    return (
      <HStack bgColor="wsGreen.200" p={2} mt={2} borderRadius="5px">
        <Icon as={IoCheckmarkCircleOutline} color="wsGreen.500" boxSize="1.5rem" />
        <Text color="wsGreen.500" fontSize="sm">
          {message}
        </Text>
      </HStack>
    );
  }

  if (type === 'error') {
    return (
      <HStack bgColor="wsRed.200" p={2} mt={2} borderRadius="5px">
        <Icon as={IoAlertCircleOutline} color="wsRed.600" boxSize="1.5rem" />
        <Text color="wsRed.600" fontSize="sm">
          {message}
        </Text>
      </HStack>
    );
  }

  if (type === 'warn') {
    return (
      <HStack bgColor="yellow.100" p={2} mt={2} borderRadius="5px">
        <Icon as={IoWarningOutline} color="yellow.500" boxSize="1.5rem" />
        <Text color="yellow.500" fontSize="sm">
          {message}
        </Text>
      </HStack>
    );
  }

  return null;
}
