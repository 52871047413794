import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import cartItemsAtom from '~/recoil/atom/cartItems';
import groupItemsAtom from '~/recoil/atom/groupItems';
import isHostSelector from '~/recoil/selectors/isHost';

import useModalControl from '~/hooks/useModalControl';
import useOrderSetting from '~/hooks/useOrderSetting';

import { isEqual } from 'date-fns';

import { MealPickUpType, PickMealTime, PreOrderDate } from '~/types';

export default function useConfirmChangeOrderType({
  updateMealPickupType,
  updatePickMealTime,
  updatePreOrderDate,
  handlePickupSettingSubmit,
}: {
  updateMealPickupType: MealPickUpType;
  updatePickMealTime: PickMealTime;
  updatePreOrderDate: PreOrderDate;
  handlePickupSettingSubmit: (isShouldClearCart: boolean) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { groupId } = useParams();
  const cartItems = useRecoilValue(cartItemsAtom);
  const groupItems = useRecoilValue(groupItemsAtom);
  const isHost = useRecoilValue(isHostSelector);
  const { modalControl, changeModalOpen } = useModalControl();
  const {
    mealOrderSetting: { preOrderDate, pickMealTime, mealPickupType },
  } = useOrderSetting();
  const [notice, setNotice] = useState({
    title: '',
    message: '',
  });

  const handleCheckChangeMealOrderSetting = (): boolean => {
    // const isSameMealPickupType = updateMealPickupType === mealPickupType;
    // if (!isSameMealPickupType) {
    //   setNotice({
    //     title: '確定改變取餐方式？',
    //     message: '改變取餐方式，原本購物車的品項將會被清空',
    //   });
    //   changeModalOpen('isConfirmOpen', true);
    //   return false;
    // }

    // const isSamePickMealTime = updatePickMealTime === pickMealTime;

    // if (!isSamePickMealTime) {
    //   setNotice({
    //     title: '確定改變取餐時間？',
    //     message: '改變取餐時間，原本購物車的品項將會被清空',
    //   });
    //   changeModalOpen('isConfirmOpen', true);
    //   return false;
    // }

    if (updatePickMealTime === PickMealTime.RESERVATION) {
      if (!updatePreOrderDate) {
        toast({
          title: t('store.pickUpSetting.error.noPreorderTime'),
          status: 'error',
          position: 'top',
        });
        return false;
      }

      // const isSameDate = isEqual(preOrderDate, updatePreOrderDate);
      // if (!isSameDate) {
      //   setNotice({
      //     title: '確定改變取餐時間？',
      //     message: '改變取餐時間，原本購物車的品項將會被清空',
      //   });
      //   changeModalOpen('isConfirmOpen', true);
      //   return false;
      // }
    }

    return true;
  };

  const handleSafePickupSettingSubmit = () => {
    if (groupId && isHost && groupItems.length > 0) {
      const isSameOrderSetting = handleCheckChangeMealOrderSetting();
      if (!isSameOrderSetting) return;
      handlePickupSettingSubmit(false);
      return;
    }

    if (cartItems.length > 0) {
      const isSameOrderSetting = handleCheckChangeMealOrderSetting();
      if (!isSameOrderSetting) return;
      handlePickupSettingSubmit(false);
      return;
    }

    handlePickupSettingSubmit(false);
  };

  const handleConfirmChangeTime = () => {
    handlePickupSettingSubmit(true);
    changeModalOpen('isConfirmOpen', false);
    setNotice({
      title: '',
      message: '',
    });
  };

  const handleCancelChangeTime = () => {
    changeModalOpen('isConfirmOpen', false);
    setNotice({
      title: '',
      message: '',
    });
  };

  return {
    isConfirmModalOpen: modalControl.isConfirmOpen,
    title: notice.title,
    message: notice.message,
    handleSafePickupSettingSubmit,
    handleConfirmChangeTime,
    handleCancelChangeTime,
  };
}
