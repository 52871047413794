import React from 'react';
import { VStack, Text, RadioGroup, Radio, Divider, Image, Flex, Spacer } from '@chakra-ui/react';
import linepayImg from '~/assets/images/LINEPay.png';

import { MealPickUpType, PaymentType, DeliveryCashLimit } from '~/types';
import { useTranslation } from 'react-i18next';
import { eq } from 'lodash';
import { t } from 'i18next';

const allPaymentTypeList = [
  {
    type: PaymentType.LINEPAY,
  },
  {
    type: PaymentType.CREDIT,
  },
  {
    type: PaymentType.CASH,
  },
];

type PaymentTypeSelectorProps = {
  mealPickupType: MealPickUpType;
  subtotal: number;
  selectedPaymentType: PaymentType;
  paymentTypeList: Array<PaymentType>; // from store
  allowOverLimitInCash: boolean;
  onChangePaymentType: (paymentType: PaymentType) => void;
};

// 付款方式選擇
export default function PaymentTypeSelector({
  mealPickupType,
  subtotal,
  selectedPaymentType,
  paymentTypeList,
  allowOverLimitInCash,
  onChangePaymentType,
}: PaymentTypeSelectorProps) {
  const { t } = useTranslation();
  return (
    <RadioGroup
      onChange={(value) => onChangePaymentType(value as PaymentType)}
      value={selectedPaymentType}
      mt={2}
    >
      <VStack spacing={2} align="stretch">
        {allPaymentTypeList.map(({ type }) =>
          paymentTypeList.includes(type) ? (
            <React.Fragment key={type}>
              <PaymentOption
                paymentType={type}
                mealPickupType={mealPickupType}
                subtotal={subtotal}
                allowOverLimitInCash={allowOverLimitInCash}
              />
              <Divider />
            </React.Fragment>
          ) : (
            <React.Fragment key={type} />
          ),
        )}
      </VStack>
    </RadioGroup>
  );
}

type PaymentOptionProps = {
  paymentType: PaymentType;
  mealPickupType: MealPickUpType;
  allowOverLimitInCash: boolean;
  subtotal: number;
};

function PaymentOption({
  paymentType,
  mealPickupType,
  subtotal,
  allowOverLimitInCash,
}: PaymentOptionProps) {
  switch (paymentType) {
    case PaymentType.CASH: {
      return (
        <Radio
          value={PaymentType.CASH}
          isDisabled={
            !allowOverLimitInCash &&
            eq(mealPickupType, MealPickUpType.DELIVERY) &&
            subtotal >= DeliveryCashLimit
          }
        >
          <Flex justify="space-between" flex="1">
            <Text fontSize="sm" color="inherit">
              {t('payment.cash')}
            </Text>
            <Spacer />
            {!allowOverLimitInCash && eq(mealPickupType, MealPickUpType.DELIVERY) && (
              <Text color="wsRed.600" px={1} fontSize="xs">
                {t('payment.notCash', { money: DeliveryCashLimit })}
              </Text>
            )}
          </Flex>
        </Radio>
      );
    }
    case PaymentType.LINEPAY: {
      return (
        <Radio value={PaymentType.LINEPAY}>
          <Flex justify="space-between">
            <Image src={linepayImg} h={5} objectFit="cover" />
            <Spacer />
            {eq(mealPickupType, MealPickUpType.DELIVERY) && (
              <Text
                background="wsRed.200"
                color="wsRed.600"
                borderRadius="0.2rem"
                fontSize="xs"
                px={1}
              >
                {t('payment.save', { money: 'NT25' })}
              </Text>
            )}
          </Flex>
        </Radio>
      );
    }
    case PaymentType.CREDIT: {
      return (
        <Radio value={PaymentType.CREDIT}>
          <Flex justify="space-between" flex="1">
            <Text fontSize="sm">{t('payment.card')}</Text>
            <Spacer />
            {eq(mealPickupType, MealPickUpType.DELIVERY) && (
              <Text
                background="wsRed.200"
                color="wsRed.600"
                borderRadius="0.2rem"
                fontSize="xs"
                px={1}
              >
                {t('payment.save', { money: 'NT25' })}
              </Text>
            )}
          </Flex>
        </Radio>
      );
    }
    default:
      return null;
  }
}
