import { selector } from 'recoil';
import groupCartSelector from './groupCart';
import shippingFeeAtom from '../atom/shippingFee';

import calcSettlement from '~/utils/calcSettlement';
import { sumBy } from 'lodash';

import getCouponDiscount from '~/utils/getCouponDiscount';
import { storeLocaleSelector } from './storeLocale';

const groupCartSettlement = selector<{
  subtotal: number;
  discount: number;
  shippingFee: number;
  freightSubsidy: number;
  total: number;
}>({
  key: 'groupCartSettlement',
  get: ({ get }) => {
    const store = get(storeLocaleSelector);
    const shippingFeeInfo = get(shippingFeeAtom);
    const groupCart = get(groupCartSelector);
    const { orderType, paymentType, groupItems, coupon } = groupCart;

    const groupItemsTotal = groupItems
      .map(({ items }) => items)
      .flat()
      .reduce<number>((total, { price, count, modifiers }) => {
        const options = modifiers.map(({ options }) => options).flat();
        const itemPrice = price + sumBy(options, ({ price }) => price);
        return total + itemPrice * count;
      }, 0);

    const couponDiscount = coupon ? getCouponDiscount(coupon, groupItemsTotal) : 0;

    const { subtotal, shippingFee, freightSubsidy, total } = calcSettlement(
      orderType,
      paymentType,
      groupItemsTotal,
      couponDiscount,
      {
        shippingFee: shippingFeeInfo?.quotes || 0,
        handlingFee: store?.delivery.handlingFee || 25,
        costShare: store?.delivery?.costShare || 0,
        freeLimit: store?.delivery?.freeLimit || 0,
        costShareLimit: store?.delivery?.costShareLimit || 0,
      },
    );

    return {
      subtotal,
      discount: couponDiscount,
      shippingFee,
      freightSubsidy,
      total,
    };
  },
});

export default groupCartSettlement;
