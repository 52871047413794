import { startOfDay, startOfWeek, addDays, getDate } from 'date-fns';
import { DayType } from './type';
import { DayCheckMode } from '../types';

export type CheckDayTypeFn = (date: Date, mode: DayCheckMode) => DayType;

type DateInfo = {
  date: Date;
  day: number;
  dayType: DayType;
};

export default function daysGenerator(
  {
    year,
    month,
  }: {
    year: number;
    month: number;
  },
  checkDayTypeFn: CheckDayTypeFn,
): Array<DateInfo> {
  const initDay = startOfDay(startOfWeek(new Date(year, month, 1)));

  return Array.from({ length: 42 }, (_, index) => {
    const currentDate = addDays(initDay, index);
    return {
      date: currentDate,
      day: getDate(currentDate),
      dayType: checkDayTypeFn(currentDate, DayCheckMode.MONTH),
    };
  });
}
