import useCart from '~/hooks/useCart';
// import ChangeOrderTypeConfirmModal from '~/components/ChangeOrderTypeConfirmModal';

type CartProviderProps = {
  children: React.ReactElement;
};

export default function CartProvider({ children }: CartProviderProps) {
  useCart();
  return (
    <>
      {children}
      {/* <ChangeOrderTypeConfirmModal /> */}
    </>
  );
}
