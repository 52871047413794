import React from 'react';
import useGroupCart from '~/hooks/useGroupCart';
import useCheckGroupCartStatus from '~/hooks/useCheckGroupCartStatus';
import useDeliveryQuote from '~/hooks/useDeliveryQuote';
type GroupCartProviderProps = {
  children: React.ReactNode;
};

export default function GroupCartProvider({ children }: GroupCartProviderProps) {
  useGroupCart();
  useCheckGroupCartStatus();
  useDeliveryQuote();
  return <>{children}</>;
}
