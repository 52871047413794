import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';

import { updateCustomer } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import userLocationSelector from '~/recoil/selectors/userLocation';

import { Location } from '~/types';

export default function useDeliveryLocation() {
  const { storeId = '' } = useParams();
  const auth = useRecoilValue(authAtom);
  const userLocation = useRecoilValue(userLocationSelector);

  const handleLocationUpdate = async (updateIndex: number, editedLocation: Location) => {
    if (!userLocation || !auth) return;

    const newUserLocationList = userLocation.map((location, index) => {
      if (index !== updateIndex) {
        return location;
      }
      return editedLocation;
    });

    try {
      await updateCustomer(storeId, auth, {
        recentAddress: newUserLocationList,
      });
    } catch (error) {
      console.log('[handleLocationUpdate Error]', error);
    }
  };

  const handleLocationAdd = async (newLocation: Location) => {
    if (!userLocation || !auth) return;
    const newUserLocationList = [...userLocation, newLocation];

    try {
      await updateCustomer(storeId, auth, {
        recentAddress: newUserLocationList,
      });
    } catch (error) {
      console.log('[handleLocationAdd Error]', error);
    }
  };

  return {
    userLocationList: userLocation,
    handleLocationAdd,
    handleLocationUpdate,
  };
}
