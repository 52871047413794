import { VStack, Text, Icon, HStack, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

type PaymentInfoProps = {
  createdTime: string;
  total: number;
  isAbandonOrder: boolean;
  isTransactionFailed: boolean;
};

export default function PaymentInfo({
  createdTime,
  total,
  isAbandonOrder,
  isTransactionFailed,
}: PaymentInfoProps) {
  const { t } = useTranslation();
  if (isAbandonOrder) {
    return (
      <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
        <HStack spacing={1}>
          <Icon as={RiErrorWarningLine} color="wsRed.600" boxSize="1.5rem" />
          <Heading fontSize="sm">{t('store.order.failText.abandonTitle')}</Heading>
        </HStack>

        <Text fontSize="xs">{t('store.order.failText.abandonContent')}</Text>
      </VStack>
    );
  }
  if (isTransactionFailed) {
    return (
      <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
        <HStack spacing={1}>
          <Icon as={RiErrorWarningLine} color="wsRed.600" boxSize="1.5rem" />
          <Heading fontSize="sm">{t('store.order.failText.transactionFailedTitle')}</Heading>
        </HStack>

        <Text fontSize="xs">{t('store.order.failText.transactionFailedContent')}</Text>
      </VStack>
    );
  }
  return (
    <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
      <HStack spacing={1}>
        <Icon as={IoCheckmarkCircleOutline} color="wsGreen.500" boxSize="1.5rem" />
        <Heading fontSize="sm">{t('store.order.failText.refundTitle')}</Heading>
      </HStack>

      <Text fontSize="xs">{t('store.order.failText.refundContent')}</Text>

      <VStack align="stretch" p={2} borderRadius="5px" bgColor="white">
        <Text fontSize="xs">{t('store.order.failText.refundAt', { refundDate: createdTime })}</Text>
        <Text fontSize="xs">{t('store.order.failText.refundAmount', { refundAmount: total })}</Text>
      </VStack>
    </VStack>
  );
}
