import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, VStack, Text, Divider, Link, Icon, Center } from '@chakra-ui/react';
import { IoArrowForwardCircle } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import transformRewardConditionString from '~/utils/transformRewardConditionString';

import { RewardType, RewardCondition } from '~/types';

type CouponItemProps = {
  id: string;
  name: string;
  endDate: Date;
  rewardType: RewardType;
  rewardCondition: RewardCondition;
};

export default function CouponItem({
  id,
  name,
  endDate,
  rewardType,
  rewardCondition,
}: CouponItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { storeId, groupId } = useParams();

  const handleCouponDetailClick = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}/coupon/${id}`);
      return;
    }
    navigate(`/${storeId}/coupon/${id}`);
  };

  const handleGoOrderClick = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}`);
      return;
    }
    navigate(`/${storeId}`);
  };

  const couponRewardString = useMemo(() => {
    return transformRewardConditionString(rewardType, rewardCondition, t);
  }, [rewardType, rewardCondition]);

  return (
    <Flex>
      <Box
        flex="3"
        px={2}
        py={1}
        borderWidth="1px"
        borderColor="wsGray.400"
        borderRightWidth="0"
        borderLeftRadius="5px"
        overflow="hidden"
      >
        <VStack align="stretch" spacing={0}>
          <Text as="h5" fontSize="sm" fontWeight="normal" isTruncated>
            {name}
          </Text>
          <VStack alignSelf="stretch" align="flex-start" spacing={1} letterSpacing={1}>
            <Text fontSize="xs" color="wsGray.500">
              {t('coupon.time', { time: format(endDate, 'yyyy-MM-dd') })}
            </Text>
            <Link
              onClick={handleCouponDetailClick}
              fontSize="xs"
              color="wsGray.500"
              bgColor="wsGray.300"
              p={1}
              borderRadius="5px"
            >
              {t('coupon.detail')}
            </Link>
            <Divider color="wsGray.400" alignSelf="stretch" />
          </VStack>
        </VStack>
        <Text mt={0.5} fontSize="xs" fontWeight="bold" color="wsRed.600" isTruncated>
          {couponRewardString}
        </Text>
      </Box>
      <Box
        flex="1"
        borderWidth="1px"
        borderColor="wsGray.400"
        borderLeftWidth="0"
        borderRightRadius="5px"
        cursor="pointer"
        role="group"
      >
        <Flex
          cursor="pointer"
          position="relative"
          h="full"
          borderRightRadius="5px"
          onClick={handleGoOrderClick}
          _before={{
            ...topGapPseudo,
            top: '-.5rem',
          }}
          _after={{
            ...bottomGapPseudo,
            bottom: '-.5rem',
          }}
        >
          <Box
            w="1px"
            h="full"
            background="linear-gradient(to bottom, #c4c4c4 50%, transparent 50%)"
            backgroundSize="100%  .75rem"
          />
          <Center
            flex="1"
            h="full"
            bgColor="wsGray.300"
            borderRightRadius="5px"
            _groupHover={{
              bgColor: 'wsBlack',
            }}
          >
            <VStack align="center" color="wsGray.400">
              <Icon
                as={IoArrowForwardCircle}
                boxSize="1.6rem"
                _groupHover={{
                  color: 'white',
                }}
              />
              <Text
                fontSize="xs"
                color="inherit"
                _groupHover={{
                  color: 'white',
                }}
              >
                {t('coupon.order')}
              </Text>
            </VStack>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
}

const basePseudoStyle = {
  content: '""',
  display: 'block',
  position: ' absolute',
  zIndex: 100,
  left: '-.5rem',
  w: '1rem',
  h: '1rem',
  borderRadius: '50%',
  border: '1px solid #c4c4c4',
  bgColor: 'white',
  transform: 'rotate(45deg)',
};

const topGapPseudo = {
  ...basePseudoStyle,
  borderColor: 'transparent #c4c4c4 #c4c4c4 transparent',
};
const bottomGapPseudo = {
  ...basePseudoStyle,
  borderColor: '#c4c4c4 transparent transparent #c4c4c4',
};
