import { Box } from '@chakra-ui/react';
import useAuth from '~/hooks/useAuth';
import useCustomer from '~/hooks/useCustomer';

type AuthProviderProps = {
  children: React.ReactElement;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  useAuth();
  useCustomer();
  return (
    <>
      <Box id="recaptcha-container" display="none" />
      {children}
    </>
  );
}
