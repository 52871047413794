import { RadioGroup, Radio, HStack } from '@chakra-ui/react';

import { TaxType } from '~/types';

import { useTranslation } from 'react-i18next';

type TaxRadioGroupProps = {
  taxType: TaxType;
  onChangeTaxType?: (taxType: TaxType) => void;
};

// 發票類型選擇
export default function TaxRadioGroup({ taxType, onChangeTaxType }: TaxRadioGroupProps) {
  const { t } = useTranslation();

  const handleChangeTaxType = (value: TaxType) => {
    onChangeTaxType?.(value);
  };

  return (
    <RadioGroup onChange={handleChangeTaxType} value={taxType}>
      <HStack spacing={4}>
        <Radio value={TaxType.PERSONAL}>{t('store.order.invoice.personal')}</Radio>
        <Radio value={TaxType.COMPANY}>{t('store.order.invoice.company')}</Radio>
      </HStack>
    </RadioGroup>
  );
}
