export enum DayType {
  NORMAL = 'calendar_daytype_normal',
  ACTIVE = 'calendar_daytype_active',
  DISABLE = 'calendar_daytype_disable',
  NONE = 'calendar_daytype_none',
}
export type DateInfo = {
  date: Date;
  day: number;
  dayType: DayType;
};
