import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Center,
  VStack,
  Icon,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import SectionContainer from '~/container/SectionContainer';
import {
  AddressInput,
  AddressInputFloor,
  AddressInputRemark,
} from '~/components/DeliverySettingSection';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import { useRecoilValue } from 'recoil';

import { RiMapPinLine } from 'react-icons/ri';
import { GrLocation } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

import useLocationSetting from '~/components/DeliverySettingSection/hooks/useLocationSetting';
import useLocationModal from '~/components/EditAddressModal/hooks/useLocationModal';

export default function EditAddressModal() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const { t } = useTranslation();
  const { isModalVisible, close, submitLocation, editedLocation, editedLocationIndex } =
    useLocationModal();

  const toast = useToast();

  const {
    address,
    selectedAddress,
    addressErrorMessage,
    locationSearchList,
    isLoading,
    floor,
    floorErrorMessage,
    note,
    handleAddressChange,
    handleAddressClick,
    handleAddressClear,
    handleAddressBlur,
    handleFloorChange,
    handleNoteChange,
    handleFloorBlur,
  } = useLocationSetting({
    currentLocation: editedLocation,
  });

  const handleSubmitClick = () => {
    let errorMsg = '';

    if (!address) {
      errorMsg = t('address.enterDelivery');
      toast({ title: errorMsg, status: 'error', position: 'top' });
      return;
    }

    if (!floor) {
      errorMsg = t('address.enterFloor');
      toast({ title: errorMsg, status: 'error', position: 'top' });
      return;
    }

    if (isLoading) return;

    if (addressErrorMessage) {
      toast({ title: t(addressErrorMessage), status: 'error', position: 'top' });
      return;
    }

    if (floorErrorMessage) {
      toast({ title: t(floorErrorMessage), status: 'error', position: 'top' });
      return;
    }

    if (!selectedAddress) {
      errorMsg = t('address.selectAddress');
      toast({ title: errorMsg, status: 'error', position: 'top' });
      return;
    }

    submitLocation(
      {
        address,
        floor,
        note,
      },
      editedLocationIndex,
    );
  };

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={isModalVisible}
        onClose={close}
        isCentered
        size={isLargerThan768 ? 'lg' : 'full'}
      >
        <ModalOverlay />
        <ModalContent m={0} maxH={{ base: 'full', md: '95%' }} borderRadius="0">
          <ModalCloseButton />
          <ModalHeader borderColor="wsGray.300" borderBottomWidth="1px">
            <Center mt={4}>
              {editedLocation && editedLocationIndex !== null ? (
                <Heading fontSize="xl">
                  {t('address.editAddressRecord')}
                  {editedLocationIndex + 1}
                </Heading>
              ) : (
                <Heading fontSize="xl">{t('address.addAddress')}</Heading>
              )}
            </Center>
          </ModalHeader>

          <ModalBody overflow="auto" minH="400px">
            <VStack py={2} align="stretch">
              <SectionContainer
                title={t('address.delivery')}
                icon={<Icon as={GrLocation} boxSize="1.5rem" />}
              >
                <VStack spacing={3} align="stretch">
                  <AddressInput
                    address={address}
                    selectedAddress={selectedAddress}
                    locationSearchList={locationSearchList}
                    isLoading={isLoading}
                    errorMessage={addressErrorMessage}
                    onAddressChange={handleAddressChange}
                    onAddressClick={handleAddressClick}
                    onAddressClear={handleAddressClear}
                    onAddressBlur={handleAddressBlur}
                  />
                  <AddressInputFloor
                    floor={floor}
                    errorMessage={floorErrorMessage}
                    onFloorChange={handleFloorChange}
                    onFloorBlur={handleFloorBlur}
                  />
                  <AddressInputRemark note={note} onNoteChange={handleNoteChange} />
                </VStack>
              </SectionContainer>
            </VStack>
          </ModalBody>

          <ModalFooter p={0}>
            <Button
              onClick={handleSubmitClick}
              variant="unstyled"
              bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
              borderRadius="unset"
              color="white"
              isFullWidth
              h="auto"
              p={3.5}
            >
              <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
                {t('address.done')}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Drawer isOpen={isModalVisible} onClose={close} size="full">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader>
          <Center mt={4}>
            {editedLocation && editedLocationIndex !== null ? (
              <Heading fontSize="xl">
                {t('address.editAddressRecord')}
                {editedLocationIndex + 1}
              </Heading>
            ) : (
              <Heading fontSize="xl">{t('address.addAddress')}</Heading>
            )}
          </Center>
        </DrawerHeader>
        <DrawerBody>
          <VStack py={2} align="stretch">
            <SectionContainer
              title={t('address.delivery')}
              icon={<Icon as={RiMapPinLine} boxSize="1.5rem" />}
            >
              <VStack spacing={3} align="stretch">
                <AddressInput
                  address={address}
                  selectedAddress={selectedAddress}
                  locationSearchList={locationSearchList}
                  isLoading={isLoading}
                  errorMessage={addressErrorMessage}
                  onAddressChange={handleAddressChange}
                  onAddressClick={handleAddressClick}
                  onAddressClear={handleAddressClear}
                  onAddressBlur={handleAddressBlur}
                />
                <AddressInputFloor
                  floor={floor}
                  errorMessage={floorErrorMessage}
                  onFloorChange={handleFloorChange}
                  onFloorBlur={handleFloorBlur}
                />
                <AddressInputRemark note={note} onNoteChange={handleNoteChange} />
              </VStack>
            </SectionContainer>
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Button
            onClick={handleSubmitClick}
            variant="unstyled"
            bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
            borderRadius="unset"
            color="white"
            isFullWidth
            h="auto"
            p={3.5}
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
              {t('address.done')}
            </Text>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
