import { selectorFamily } from 'recoil';
import cartItemsAtom from '../atom/cartItems';
import { CartItem } from '~/types';

const cartItem = selectorFamily<CartItem | null, { itemId: string; index: number }>({
  key: 'cartItem',
  get:
    ({ itemId, index }) =>
    ({ get }) => {
      const cartItems = get(cartItemsAtom);

      if (isNaN(index)) {
        return null;
      }

      return cartItems.find((item, i) => itemId === item.id && i === index) || null;
    },
});

export default cartItem;
