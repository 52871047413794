import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Link: ComponentSingleStyleConfig = {
  baseStyle: {
    _hover: {
      textDecoration:"none",
    },
  },
};
export default Link;
