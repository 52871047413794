import { Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import cond from 'lodash/fp/cond';
import where from 'lodash/fp/where';
import eq from 'lodash/fp/eq';
import T from 'lodash/fp/T';
import always from 'lodash/fp/always';

type LargeOrderNoticeProps = {
  largeOrderSetting?: {
    isItemThreshold: boolean;
    isPriceThreshold: boolean;
    itemThreshold: number;
    priceThreshold: number;
  };
};

export default function LargeOrderNotice({
  largeOrderSetting = {
    isItemThreshold: false,
    isPriceThreshold: false,
    itemThreshold: Infinity,
    priceThreshold: Infinity,
  },
}: LargeOrderNoticeProps) {
  const { t } = useTranslation();

  const getLargeOrderNoticeString = cond<
    {
      isItemThreshold: boolean;
      isPriceThreshold: boolean;
      itemThreshold: number;
      priceThreshold: number;
    },
    string
  >([
    [
      where({
        isItemThreshold: eq(true),
        isPriceThreshold: eq(true),
      }),
      ({ itemThreshold, priceThreshold }) =>
        t(`cart.largeOrderNotice.both`, {
          item: itemThreshold,
          price: priceThreshold,
        }),
    ],
    [
      where({
        isItemThreshold: eq(true),
        isPriceThreshold: eq(false),
      }),
      ({ itemThreshold }) =>
        t(`cart.largeOrderNotice.item`, {
          item: itemThreshold,
        }),
    ],
    [
      where({
        isItemThreshold: eq(false),
        isPriceThreshold: eq(true),
      }),
      ({ priceThreshold }) =>
        t(`cart.largeOrderNotice.price`, {
          price: priceThreshold,
        }),
    ],
    [T, always('')],
  ]);

  const noticeString = getLargeOrderNoticeString(largeOrderSetting);

  if (!noticeString) {
    return <></>;
  }
  return (
    <Box bgColor="yellow.50" py={1} px={3} rounded="md">
      <Text fontSize="sm" color="yellow.500">
        {noticeString}
      </Text>
    </Box>
  );
}
