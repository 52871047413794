import React, { useCallback, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import localeAtom from '~/recoil/atom/locale';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import googleAutocompleteAsync from '~/utils/googleAutocompleteAsync';

import { Location } from '~/types';

type UserLocationSettingParams = {
  currentLocation?: Location | null;
};

export default function useLocationSetting(params?: UserLocationSettingParams) {
  const store = useRecoilValue(storeLocaleSelector);
  const locale = useRecoilValue(localeAtom);

  const currentLocation = params ? params.currentLocation : null;

  const [address, setAddress] = useState({
    address: currentLocation?.address || '',
    selectedAddress: currentLocation?.address || '',
    errorMessage: '',
  });

  const [locationSearch, setLocationSearch] = useState<{
    locationSearchList: { address: string; distance: number; key: string }[];
    isLoading: boolean;
  }>({
    locationSearchList: [],
    isLoading: false,
  });

  const [floor, setFloor] = useState({
    floor: currentLocation?.floor || '',
    errorMessage: '',
  });

  const [note, setNote] = useState({
    note: currentLocation?.note || '',
    errorMessage: '',
  });

  // ----------------------------------------------------------------

  useEffect(() => {
    if (currentLocation) {
      setAddress((pre) => ({
        ...pre,
        address: currentLocation.address,
        selectedAddress: currentLocation.address,
        errorMessage: '',
      }));

      setFloor((pre) => ({
        ...pre,
        floor: currentLocation.floor,
        errorMessage: '',
      }));

      setNote((pre) => ({
        ...pre,
        note: currentLocation.note,
        errorMessage: '',
      }));
    } else {
      resetLocationSetting();
    }
  }, [currentLocation]);

  // ----------------------------------------------------------------

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value;

    setAddress((pre) => ({
      ...pre,
      address,
      selectedAddress: '',
      errorMessage: '',
    }));

    if (address) {
      handleGetLocationSearchList(address);
    }
  };

  const handleGetLocationSearchList = useCallback(
    debounce(async (address: string) => {
      if (!store) return;

      if (isEmpty(address)) return;

      setLocationSearch((pre) => ({
        ...pre,
        isLoading: true,
      }));

      try {
        const predictionList = await googleAutocompleteAsync({
          input: address,
          storeLocation: {
            geopoint: {
              latitude: store.location.geopoint.latitude,
              longitude: store.location.geopoint.longitude,
            },
          },
          locale,
        });

        setLocationSearch((pre) => ({
          ...pre,
          locationSearchList: predictionList.map((prediction) => ({
            address: prediction.description,
            distance: prediction.distance,
            key: prediction.key,
          })),
        }));

        if (predictionList.length === 1) {
          if (!store) return false;
          const uniqueAddress = predictionList[0];
          const { distance, description } = uniqueAddress;

          const {
            delivery: { maxDistance },
          } = store;

          handleDistanceValid(description, distance, maxDistance);
        }
      } catch (error) {
        console.error('[handleGetLocationSearchList await googleAutocompleteAsync]', error);
      } finally {
        setLocationSearch((pre) => ({
          ...pre,
          isLoading: false,
        }));
      }
    }, 1000),
    [store, locale],
  );

  const handleAddressClear = () => {
    setAddress((pre) => ({
      ...pre,
      address: '',
      selectedAddress: '',
      errorMessage: '',
    }));

    setLocationSearch((pre) => ({
      ...pre,
      locationSearchList: [],
      isLoading: false,
    }));
  };

  const handleAddressClick = (clickedLocationSearchItem: {
    address: string;
    distance: number;
    key: string;
  }) => {
    if (!store) return;

    const { address, distance } = clickedLocationSearchItem;

    setAddress((pre) => ({
      ...pre,
      address,
      selectedAddress: address,
      // errorMessage: '',
    }));

    const {
      delivery: { maxDistance },
    } = store;

    if (isNaN(distance)) {
      //NaN
      setAddress((pre) => ({
        ...pre,
        errorMessage: 'address.addressNotFound',
      }));
      return;
    }

    if (distance > maxDistance) {
      setAddress((pre) => ({
        ...pre,
        errorMessage: 'address.overMaxDistance',
      }));
      return;
    }

    setAddress((pre) => ({
      ...pre,
      errorMessage: '',
    }));
  };

  const handleAddressBlur = async () => {
    if (locationSearch.isLoading) return;

    // const { address: addressTxt } = address;
    // if (!addressTxt) {
    //   setAddress((pre) => ({
    //     ...pre,
    //     errorMessage: 'store.order.inputAddress',
    //   }));
    //   return;
    // }

    // try {
    //   if (!store) return false;

    //   setAddress((pre) => ({
    //     ...pre,
    //     isLoading: true,
    //   }));

    //   const predictionList = await googleAutocompleteAsync({
    //     input: addressTxt,
    //     storeLocation: {
    //       geopoint: {
    //         latitude: store.location.geopoint.latitude,
    //         longitude: store.location.geopoint.longitude,
    //       },
    //     },
    //     locale,
    //   });

    //   if (predictionList.length > 0) {
    //     const defaultAddress = predictionList[0];
    //     const { distance, description } = defaultAddress;

    //     const {
    //       delivery: { maxDistance },
    //     } = store;

    //     handleDistanceValid(description, distance, maxDistance);
    //   }
    // } catch (error) {
    //   console.log('[handleAddressBlur googleAutocompleteAsync]', error);
    // } finally {
    //   setLocationSearch((pre) => ({
    //     ...pre,
    //     isLoading: false,
    //   }));
    // }
  };

  const handleFloorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const floor = e.target.value.trim();

    setFloor((pre) => ({
      ...pre,
      floor,
      errorMessage: '',
    }));
  };

  const handleFloorBlur = () => {
    const { floor: floorTxt } = floor;
    if (!floorTxt) {
      setFloor((pre) => ({
        ...pre,
        errorMessage: 'address.mustMoreThanOneLetter',
      }));
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const note = e.target.value.trim();

    setNote((pre) => ({
      ...pre,
      note,
      errorMessage: '',
    }));
  };

  const resetLocationSetting = () => {
    setAddress((pre) => ({
      ...pre,
      address: '',
      selectedAddress: '',
      errorMessage: '',
    }));

    setFloor((pre) => ({
      ...pre,
      floor: '',
      errorMessage: '',
    }));

    setNote((pre) => ({
      ...pre,
      note: '',
      errorMessage: '',
    }));
  };

  const handleDistanceValid = (description: string, distance: number, maxDistance: number) => {
    if (!distance) {
      //NaN
      setAddress((pre) => ({
        ...pre,
        errorMessage: 'address.addressNotFound',
      }));
      return false;
    }

    if (distance > maxDistance) {
      setAddress((pre) => ({
        ...pre,
        errorMessage: 'address.overMaxDistance',
      }));
      return false;
    }

    setAddress((pre) => ({
      ...pre,
      address: description,
      selectedAddress: description,
    }));

    setAddress((pre) => ({
      ...pre,
      errorMessage: '',
    }));
  };

  return {
    address: address.address,
    selectedAddress: address.selectedAddress,
    addressErrorMessage: address.errorMessage,
    locationSearchList: locationSearch.locationSearchList,
    isLoading: locationSearch.isLoading,
    floor: floor.floor,
    floorErrorMessage: floor.errorMessage,
    note: note.note,
    noteErrorMessage: note.errorMessage,
    handleAddressChange,
    handleAddressClick,
    handleAddressClear,
    handleAddressBlur,
    handleFloorChange,
    handleFloorBlur,
    handleNoteChange,
  };
}
