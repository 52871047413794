import { FormControl, FormLabel, VStack, Input } from '@chakra-ui/react';
import { useState } from 'react';

import { parse, format } from 'date-fns';

import { useTranslation } from 'react-i18next';

type BirthdayProps = {
  birthday: string;
};

// 生日日期輸入
export default function Birthday({ birthday }: BirthdayProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const handleChange = (event: any) => {
    const day = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setValue(format(day, 'yyyy-MM-dd'));
  };

  return (
    <VStack align="flex-start">
      <FormControl id="first-Birthday">
        <FormLabel fontWeight="normal" letterSpacing={2}>
          {t('personalInfo.birthday')}
        </FormLabel>

        <Input
          type="date"
          id="start"
          style={{
            flex: 1,
            outline: 'none',
          }}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
    </VStack>
  );
}
