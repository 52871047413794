import { useEffect } from 'react';
import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
} from '@chakra-ui/react';

import LoginForm from '~/components/LoginForm';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import isLoginModalOpenSelector from '~/recoil/selectors/isLoginModalOpen';
import { useRecoilValue, useRecoilState } from 'recoil';
import Title from './Title';
import { useTranslation, Trans } from 'react-i18next';

// 登入頁面
export default function LoginModal() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const auth = useRecoilValue(authAtom);
  const [isLoginModalOpen, setLoginModalOpen] = useRecoilState(isLoginModalOpenSelector);
  const { t } = useTranslation();
  useEffect(() => {
    if (auth) {
      setLoginModalOpen(false);
    }
  }, [auth]);

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent py={4} m={0}>
          <ModalHeader>
            <Title title={t('loginForm.phoneLogin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" px={{ base: 4, md: 4 }} spacing={4}>
              <VStack align="stretch" spacing={1}>
                <Trans i18nKey="loginForm.note">
                  <Text fontSize="xs"></Text>
                  <Text fontSize="xs"></Text>
                </Trans>
              </VStack>

              <LoginForm />
            </VStack>
          </ModalBody>
          <ModalFooter p={0}>
            <Trans i18nKey="loginForm.agreement">
              <HStack w="full" justify="center" p={2} spacing={0.5} wrap="wrap">
                <Text fontSize="xs"></Text>
                <Link
                  letterSpacing={0.8}
                  fontSize="xs"
                  color="wsRed.600"
                  href="https://www.weserve.tw/privacy/"
                  isExternal
                ></Link>
                <Text fontSize="xs"></Text>
                <Link
                  letterSpacing={0.8}
                  fontSize="xs"
                  color="wsRed.600"
                  href="https://www.weserve.tw/terms/"
                  isExternal
                ></Link>
              </HStack>
            </Trans>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  return (
    <Drawer isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} size="full">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader>
          <Title title={t('loginForm.phoneLogin')} />
        </DrawerHeader>
        <DrawerBody>
          <VStack align="stretch" px={{ base: 4, md: 4 }} spacing={4}>
            <VStack align="stretch" spacing={1}>
              <Trans i18nKey="loginForm.note">
                <Text fontSize="xs"></Text>
                <Text fontSize="xs"></Text>
              </Trans>
            </VStack>
            <LoginForm />
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Trans i18nKey="loginForm.agreement">
            <HStack w="full" justify="center" p={2} spacing={0.5} wrap="wrap">
              <Text fontSize="xs"></Text>
              <Link
                letterSpacing={0.8}
                fontSize="xs"
                color="wsRed.600"
                href="https://www.weserve.tw/privacy/"
                isExternal
              ></Link>
              <Text fontSize="xs"></Text>
              <Link
                letterSpacing={0.8}
                fontSize="xs"
                color="wsRed.600"
                href="https://www.weserve.tw/terms/"
                isExternal
              ></Link>
            </HStack>
          </Trans>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
