import { VStack, Text, Icon, HStack, Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BsCircleFill } from 'react-icons/bs';
import { WsOrderStatus } from '~/types';
import PaymentInfo from './PaymentInfo';

type FailOrderNoteProps = {
  noteType: WsOrderStatus;
  createdTime: string;
  total: number;
  isAbandonOrder: boolean;
  isTransactionFailed: boolean;
  reasonForCancelling: Array<string>;
};

export default function FailOrderNote({
  noteType,
  createdTime,
  total,
  isAbandonOrder,
  isTransactionFailed,
  reasonForCancelling,
}: FailOrderNoteProps) {
  const { t } = useTranslation();
  if (noteType === WsOrderStatus.FAILED) {
    return (
      <VStack align="stretch" spacing={3}>
        <Box borderRadius="5px" borderWidth="1px" borderColor="wsGray.400">
          <HStack align="stretch" px={2} py={4}>
            <Icon as={BsCircleFill} color="wsRed.600" boxSize="1rem" />
            <VStack spacing={3} align="flex-start">
              <Heading fontSize="sm">{t('store.order.status.failed')}</Heading>
              <Text fontSize="xs">
                {t('store.order.reasonForCancellation', {
                  reasons: reasonForCancelling.join('、'),
                })}
              </Text>
            </VStack>
          </HStack>
        </Box>

        <PaymentInfo
          createdTime={createdTime}
          total={total}
          isAbandonOrder={isAbandonOrder}
          isTransactionFailed={isTransactionFailed}
        />
      </VStack>
    );
  }

  return (
    <VStack align="stretch" spacing={3}>
      <Box borderRadius="5px" borderWidth="1px" borderColor="wsGray.400">
        <HStack align="stretch" px={2} py={4}>
          <Icon as={BsCircleFill} color="wsRed.600" boxSize="1rem" />
          <VStack spacing={3} align="flex-start">
            <Heading fontSize="sm">{t('store.order.status.canceled')}</Heading>
            <Text fontSize="xs">{t('store.order.cancelByCustomer')}</Text>
          </VStack>
        </HStack>
      </Box>

      <PaymentInfo
        createdTime={createdTime}
        total={total}
        isAbandonOrder={isAbandonOrder}
        isTransactionFailed={isTransactionFailed}
      />
    </VStack>
  );
}
