import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { VStack, Box, useDisclosure } from '@chakra-ui/react';
import PageContainer from '~/container/PageContainer';

import { categoryItemListSelector } from '~/recoil/selectors/menuItemList';

import {
  CategoriesMenu,
  CategoryItemList,
  StoreInfoSection,
  FixFooterSection,
  FixHeaderSection,
  GroupOrderLeaderNotice,
  GroupOrderMemberNotice,
  EmptyMenu,
  GroupOrderNoticeModal,
} from './components';
import ImageSlick from '~/components/ImageSlick';
import SearchItemModal from '~/components/SearchItemModal';
import PickupSettingModal from '~/components/PickupSettingModal';
import EditAddressModal from '~/components/EditAddressModal';
import NotOperatingTimeNotice from '~/components/NotOperatingTimeNotice';
import LoginModal from '~/components/LoginModal';

import useScroll from '~/hooks/useScroll';
import usePickCategory from '~/pages/Menu/hooks/usePickCategory';

import isHostSelector from '~/recoil/selectors/isHost';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import groupItemsAtom from '~/recoil/atom/groupItems';
import groupHostInfoAtom from '~/recoil/atom/groupHostInfo';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import { useRecoilValue } from 'recoil';

import calcGroupCartItemsPrice from '~/utils/calcGroupCartItemsPrice';
import sumBy from 'lodash/sumBy';
import { CancelModal } from '../GroupInvite/components';
import { updateGroupCart } from '~/firebase/updateData';
import { CartStatus } from '~/types';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import { IoReceiptOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

export type SelectedCategory = {
  isScrollingChange: boolean; // Is data changing from scrolling
  value: string;
};

export type ChangeCategoryFn = (val: string, isScrollingChange: boolean) => void;

export default function GroupMenu() {
  const { t } = useTranslation();
  const categoryItemList = useRecoilValue(categoryItemListSelector);
  const menuCategoryList = useMemo(
    () =>
      categoryItemList.map(({ categoryId, categoryName }) => ({
        label: categoryName,
        value: categoryId,
      })),
    [categoryItemList],
  );
  const { selectedCategory, handleChangeCategory, handleSaveSelectedCategory } = usePickCategory();

  const { positionInfo } = useScroll();

  const isHost = useRecoilValue(isHostSelector);
  const groupItems = useRecoilValue(groupItemsAtom);
  const groupInfo = useRecoilValue(groupHostInfoAtom);
  const store = useRecoilValue(storeLocaleSelector);
  const mealPickUpType = useRecoilValue(mealPickupTypeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);

  useEffect(() => {
    menuCategoryList.length && handleChangeCategory(menuCategoryList[0].value, true);
  }, [menuCategoryList, handleChangeCategory]);

  const { storeId, groupId } = useParams();

  const {
    isOpen: isLeaderNoticeOpen,
    onOpen: onLeaderNoticeOpen,
    onClose: onLeaderNoticeClose,
  } = useDisclosure();

  const {
    isOpen: isMemberNoticeOpen,
    onOpen: onMemberNoticeOpen,
    onClose: onMemberNoticeClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const handleLeaveGroup = () => {
    navigate(`/${storeId}`);
  };

  const handleCancelGroup = async () => {
    if (storeId && groupId) {
      await updateGroupCart(storeId, groupId, {
        status: CartStatus.CANCELLED,
      });
    }
  };

  if (!store) {
    return <Navigate to="404" />;
  }

  const groupCartItemsTotal = calcGroupCartItemsPrice(groupItems);
  const groupCartItemsAmount = sumBy(groupItems, ({ items }) => sumBy(items, ({ count }) => count));

  const isAllowOrder = store.allowOrders;
  const isAllowRealTimeOrder = store.allowRealTimeOrder;
  const isAllowPreOder = store.preOrderSetting.allowPreOrder;
  const preOrderSetting = store.preOrderSetting;

  return (
    <>
      <PageContainer>
        <FixHeaderSection
          isHost={isHost}
          hostName={groupInfo.name}
          menuCategoryList={menuCategoryList}
          selectedCategory={selectedCategory}
          positionInfo={positionInfo}
          onChangeCategory={handleChangeCategory}
        />

        <Box w="full" overflow="hidden">
          <ImageSlick images={store?.images} />
        </Box>
        <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={4}>
          <NotOperatingTimeNotice
            time={preOrderDate}
            mealPickUpType={mealPickUpType}
            pickMealTime={pickMealTime}
            timeRange={store.operatingTime}
            isAllowOrder={isAllowOrder}
            isAllowRealTimeOrder={isAllowRealTimeOrder}
            isAllowPreOder={isAllowPreOder}
            preOrderRange={{
              preOrderAfter: preOrderSetting.preOrderAfter,
              preOrderBefore: preOrderSetting.preOrderBefore,
            }}
          />
          <StoreInfoSection name={store.name} operatingTime={store?.operatingTime} />
          {isHost ? (
            <GroupOrderLeaderNotice notice={groupInfo.note} onOpen={onLeaderNoticeOpen} />
          ) : (
            <GroupOrderMemberNotice
              leader={groupInfo.name}
              notice={groupInfo.note}
              onOpen={onMemberNoticeOpen}
            />
          )}
          {menuCategoryList.length > 0 ? (
            <>
              <CategoriesMenu
                menuCategoryList={menuCategoryList}
                selectedCategory={selectedCategory}
                onChangeCategory={handleChangeCategory}
              />
              <VStack spacing={6} align="stretch">
                {categoryItemList.map((categoryItemInfo) => {
                  return (
                    <CategoryItemList
                      key={categoryItemInfo.categoryId}
                      categoryId={categoryItemInfo.categoryId}
                      categoryItemInfo={categoryItemInfo}
                      positionInfo={positionInfo}
                      selectedCategory={selectedCategory}
                      onChangeCategory={handleChangeCategory}
                      onSaveSelectedCategory={handleSaveSelectedCategory}
                    />
                  );
                })}
              </VStack>
            </>
          ) : (
            <EmptyMenu />
          )}
        </VStack>
        <FixFooterSection subtotal={groupCartItemsTotal} itemAmount={groupCartItemsAmount} />
      </PageContainer>
      {isHost ? (
        <CancelModal
          title={t('group.cancelModal.title')}
          notice={t('group.cancelModal.note')}
          isOpen={isLeaderNoticeOpen}
          onConfirm={handleCancelGroup}
          onClose={onLeaderNoticeClose}
        />
      ) : (
        <CancelModal
          title={t('group.leaveModal.title')}
          notice={t('group.leaveModal.note')}
          isOpen={isMemberNoticeOpen}
          onConfirm={handleLeaveGroup}
          onClose={onMemberNoticeClose}
        />
      )}
      <GroupOrderNoticeModal icon={IoReceiptOutline} />
      <PickupSettingModal />
      <EditAddressModal />
      <SearchItemModal />
      <LoginModal />
    </>
  );
}
