import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import components from './components';
import fontSizes from './fontSizes';
import styles from './styles';

const theme = extendTheme({
  styles,
  fontSizes,
  colors,
  components,
  fonts: {
    heading: "'Noto Sans TC', sans-serif",
    body: "'Noto Sans TC', sans-serif",
  },
});

export default theme;
