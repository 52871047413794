import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import isHostSelector from '~/recoil/selectors/isHost';
import couponAtom from '~/recoil/atom/coupon';
import { useRecoilState, useRecoilValue } from 'recoil';

import { updateCartData, updateGroupCart } from '~/firebase/updateData';

import checkCouponValid from '~/utils/checkCouponValid';

// just use for cart page and group cart page
export default function useCouponClear(subtotal: number) {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const [coupon, setCoupon] = useRecoilState(couponAtom);
  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const isHost = useRecoilValue(isHostSelector);

  const handleClearCoupon = async () => {
    if (!coupon) return;

    if (checkCouponValid(coupon, subtotal, { mealPickupType, pickMealTime, preOrderDate })) {
      return;
    }

    if (!auth) {
      setCoupon(null);
      return;
    }

    if (groupId && storeId && isHost) {
      try {
        await updateGroupCart(storeId, groupId, {
          coupon: null,
        });
      } catch (err) {
        console.error('clear group coupon error');
      }
      return;
    }

    if (storeId) {
      try {
        await updateCartData(storeId, auth, { coupon: null });
      } catch (err) {
        console.error('clear user coupon error');
      }
      return;
    }
  };

  // TODO: add item price dependency
  useEffect(() => {
    handleClearCoupon();
  }, [mealPickupType, pickMealTime, preOrderDate, auth, groupId, storeId, subtotal]);
}
