import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function WeekBar() {
  const { t } = useTranslation();
  const weeks = [
    'weekDay.SUN',
    'weekDay.MON',
    'weekDay.TUE',
    'weekDay.WED',
    'weekDay.THU',
    'weekDay.FRI',
    'weekDay.SAT',
  ];
  return (
    <HStack spacing={1}>
      {weeks.map((day) => {
        return (
          <Text key={day} flex="1" textAlign="center">
            {t(day)}
          </Text>
        );
      })}
    </HStack>
  );
}
