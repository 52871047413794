import { selector } from 'recoil';
import uidAtom from '../atom/uid';
import groupMemberInfoAtom from '../atom/groupMemberInfo';

// 是否為團長
const isHost = selector<boolean>({
  key: 'isHost',
  get: ({ get }) => {
    const uid = get(uidAtom);
    const groupMemberInfo = get(groupMemberInfoAtom);
    return uid === groupMemberInfo.memberId;
  },
});

export default isHost;
