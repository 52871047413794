import { useRecoilValue } from 'recoil';
import { useState, useEffect, useCallback, useMemo } from 'react';

import {
  isSameMonth,
  isSameYear,
  startOfDay,
  endOfDay,
  isSameDay,
  differenceInMinutes,
} from 'date-fns';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import checkOperatingTime from '~/utils/checkOperatingTime';

import { OperatingTime } from '~/recoil/selectors/operatingTime';
import { DayType } from '~/components/PickupSettingModal/Calendar/type';
import { DayCheckMode } from '~/components/PickupSettingModal/types';
import { Week } from '~/types';

type UseDayParams = {
  controlMonth: Date;
  preOrderAfterDate: Date | undefined;
  preOrderBeforeDate: Date | undefined;
};

export default function useDay({
  controlMonth,
  preOrderAfterDate,
  preOrderBeforeDate,
}: UseDayParams) {
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const getCheckDayType = useCallback(
    (dayDate: Date, mode: DayCheckMode) => {
      if (!preOrderBeforeDate || !preOrderAfterDate) return DayType.DISABLE;

      if (!operatingTime) return DayType.DISABLE;
      if (
        mode === DayCheckMode.MONTH &&
        (!isSameMonth(dayDate, controlMonth) || !isSameYear(dayDate, controlMonth))
      ) {
        return DayType.NONE;
      }

      const checkDayInStartAndEnd = (
        preOrderAfterDate: Date,
        preOrderBeforeDate: Date,
        dayDate: Date,
      ): boolean => {
        const startDayTime = startOfDay(preOrderAfterDate).getTime();
        const endDayTime = endOfDay(preOrderBeforeDate).getTime();
        const dayTime = dayDate.getTime();
        return dayTime >= startDayTime && dayTime <= endDayTime;
      };

      const checkDayInOperatingTime = (operatingTime: OperatingTime, dayDate: Date): boolean => {
        const nowDate = new Date();

        const allWeekDayTitle = Object.keys(Week);
        const dayDateWeekDayTitle = allWeekDayTitle[dayDate.getDay()];
        const operationWeekDayTitle = Object.entries(operatingTime).map(([weekday, time]) =>
          time?.length !== 0 ? weekday : null,
        );
        const daySchedule = operatingTime[dayDateWeekDayTitle as Week];

        if (!daySchedule) return false;

        if (!operationWeekDayTitle.includes(dayDateWeekDayTitle)) return false;

        const isPreOrderAfterDay = isSameDay(dayDate, preOrderAfterDate);
        const isPreOrderBeforeDay = isSameDay(dayDate, preOrderBeforeDate);
        const startDayPreOrderMinutes = differenceInMinutes(
          preOrderAfterDate,
          startOfDay(preOrderAfterDate),
        );
        const endDayPreOrderMinutes = differenceInMinutes(
          preOrderBeforeDate,
          startOfDay(preOrderBeforeDate),
        );

        if (isPreOrderAfterDay) {
          return daySchedule.some(({ close }) => {
            return close > startDayPreOrderMinutes;
          });
        }

        if (isPreOrderBeforeDay) {
          return daySchedule.some(({ open }) => {
            return open < endDayPreOrderMinutes;
          });
        }
        return true;
      };

      if (checkDayInStartAndEnd(preOrderAfterDate, preOrderBeforeDate, dayDate)) {
        if (checkDayInOperatingTime(operatingTime, dayDate)) {
          return DayType.NORMAL;
        } else {
          return DayType.DISABLE;
        }
      } else {
        return DayType.DISABLE;
      }
    },
    [controlMonth, operatingTime, preOrderAfterDate, preOrderBeforeDate],
  );

  return {
    getCheckDayType,
  };
}
