import { PreorderSetting } from '~/types';
import { addHours, getTime } from 'date-fns';
import { gt } from 'lodash';

export default function checkPreorderTime(
  preorderDate: Date,
  preorderSetting: PreorderSetting,
): boolean {
  const today = new Date();
  const startTime = addHours(today, preorderSetting.preOrderAfter);
  const endTime = addHours(today, preorderSetting.preOrderBefore);

  return (
    gt(getTime(preorderDate), getTime(startTime)) && gt(getTime(endTime), getTime(preorderDate))
  );
}
