import { sumBy } from 'lodash';

export default function checkValidTaxNumber(invoice: string): boolean {
  const TAX_INVOICE_VALID_MOD = 10;

  const times = [1, 2, 1, 2, 1, 2, 4, 1];

  const regex = /^[0-9]{8}$/;
  if (invoice.length !== 8 && !regex.test(invoice)) {
    return false;
  }

  const invoiceArray = invoice.split('').map((char) => parseInt(char));

  const productSum = times
    .map((time, index) => invoiceArray[index] * time)
    .map((num) => (num >= 10 ? Math.floor(num / 10) + (num % 10) : num));

  if (invoiceArray[6] === 7) {
    const sum = sumBy(productSum, (num) => num) - 10;

    return (sum + 1) % TAX_INVOICE_VALID_MOD === 0 || sum % TAX_INVOICE_VALID_MOD === 0;
  }

  return sumBy(productSum, (num) => num) % TAX_INVOICE_VALID_MOD === 0;
}
