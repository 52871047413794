import { Input, InputProps } from '@chakra-ui/react';

export default function DisplayInput(props: InputProps) {
  return (
    <Input
      isReadOnly
      _focus={{}}
      _invalid={{ borderColor: 'wsRed.600', color: 'wsRed.600' }}
      fontWeight="bold"
      userSelect="none"
      {...props}
    />
  );
}
