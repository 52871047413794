export default function formatPriceNumber(
  price: number,
  config: {
    locale: string;
    style: string;
    currency: string;
    minimumFractionDigits: 0;
  } = {
    locale: 'zh',
    style: 'currency',
    currency: 'TWD',
    minimumFractionDigits: 0,
  },
): string {
  const numberFormat = new Intl.NumberFormat(config.locale, {
    style: config.style,
    currency: config.currency,
    minimumFractionDigits: config.minimumFractionDigits,
  });

  return numberFormat.format(price);
}
