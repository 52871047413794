import { selector, atom, selectorFamily } from 'recoil';
import { Store, Menu } from '~/types';
import { storeLocaleSelector } from './storeLocale';

export const menusSelector = selector<Record<string, Menu> | null>({
  key: 'menusSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeLocaleSelector);
    return store?.menus ? store.menus : null;
  },
});

export const menuIdsSelector = selector<string[]>({
  key: 'menuIdsSelector',
  get: ({ get }) => {
    const menus = get(menusSelector);
    return menus ? Object.keys(menus) : [];
  },
});
