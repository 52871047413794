import firebase from 'firebase';
import { firestore } from './index';

import {
  FirebaseCart,
  CartType,
  OrderType,
  CartStatus,
  PaymentType,
  TaxType,
  FirebaseGroupCart,
} from '~/types';

export async function getFirebaseCart(storeId: string, auth: firebase.User) {
  const cartSnap = await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).get();
  if (!cartSnap.exists) {
    const initCart: FirebaseCart = {
      email: '',
      uid: auth.uid,
      cartType: CartType.PERSONAL,
      location: {
        address: '',
        floor: '',
        note: '',
      },
      orderType: OrderType.WS_PICKUP,
      preOrderDate: null,
      status: CartStatus.ACTIVE,
      paymentType: PaymentType.CASH,
      taxType: TaxType.PERSONAL,
      taxInfo: {
        invoice: '',
        title: '',
      },
      items: [],
      coupon: null,
      shippingFee: null,
    };

    try {
      await cartSnap.ref.set(initCart);
    } catch (error) {
      console.log('init cart failed', error);
    }
  }
}

export async function initFirebaseCustomer(storeId: string, auth: firebase.User) {
  const customerSnap = await firestore.doc(`stores/${storeId}/customers/${auth.uid}`).get();
  const customer = customerSnap.data();
  if (!customer) {
    const newCustomer = {
      recentAddress: [],
    };
    try {
      await customerSnap.ref.set(newCustomer);
    } catch (error) {
      console.log('init customer error', error);
    }
    return;
  }

  if (!customer.recentAddress || !Array.isArray(customer.recentAddress)) {
    try {
      await customerSnap.ref.set(
        {
          recentAddress: [],
        },
        { merge: true },
      );
    } catch (error) {
      console.log('[initFirebaseCustomer Error]', error);
    }
  }
}

export async function getFirebaseGroupCart(storeId: string, groupId: string) {
  const groupCartSnap = await firestore.doc(`stores/${storeId}/carts/${groupId}`).get();
  const groupCart = groupCartSnap.data();
  return groupCart as FirebaseGroupCart | undefined;
}

export async function getCart(storeId: string, auth: firebase.User) {
  const cartSnap = await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).get();
  const cart = cartSnap.data();
  return cart as FirebaseCart | undefined;
}
