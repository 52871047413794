import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  Flex,
  Divider,
  Center,
  HStack,
  StackDivider,
} from '@chakra-ui/react';

import { PaymentType, DeliveryCashLimit } from '~/types';

import { isEqual } from 'lodash';

import { useTranslation } from 'react-i18next';

type CheckoutCashLimitModalProps = {
  isOpen: boolean;
  message: string;
  paymentTypes: Array<PaymentType>;
  phoneNumber: string;
  onClose: () => void;
};

export default function CheckoutCashLimitModal({
  isOpen,
  message,
  paymentTypes,
  phoneNumber,
  onClose,
}: CheckoutCashLimitModalProps) {
  const { t } = useTranslation();
  const isOnlyCashPayment =
    isEqual(paymentTypes.length, 1) && paymentTypes.includes(PaymentType.CASH);

  const displayPaymentString = isOnlyCashPayment
    ? t('payment.onlyCash')
    : t('payment.selectAnotherMethod');

  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {t('payment.notCashAlertModal', { money: DeliveryCashLimit })}
        </ModalHeader>
        <ModalBody py={4}>
          <Text textAlign="center" color="wsGray.500">
            {displayPaymentString}
          </Text>
        </ModalBody>
        <ModalFooter p={0} borderTopWidth="1px" borderColor="wsGray.300">
          {isOnlyCashPayment ? (
            <HStack align="stretch" w="full" divider={<StackDivider />} spacing={0}>
              <Center
                as="button"
                py={3}
                flex="1"
                cursor="pointer"
                onClick={() => {
                  window.open(`tel:${phoneNumber}`);
                  onClose();
                }}
              >
                <Text>{t('payment.contactStore')}</Text>
              </Center>
              <Center as="button" py={3} flex="1" cursor="pointer" onClick={onClose}>
                <Text>{t('payment.editOrder')}</Text>
              </Center>
            </HStack>
          ) : (
            <Center as="button" w="full" py={3} cursor="pointer" onClick={onClose}>
              <Text>{t('payment.reselect')}</Text>
            </Center>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
