import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import StoreProvider from '~/provider/StoreProvider';
import AuthProvider from '~/provider/AuthProvider';
import CartProvider from '~/provider/CartProvider';
import GroupCartProvider from '~/provider/GroupCartProvider';
import RequireGlobalDataMiddleware from '~/provider/RequireGlobalDataMiddleware';
import GroupRequireGlobalDataMiddleware from '~/provider/GroupRequireGlobalDataMiddleware';

import Root from '~/pages/Root';

import Menu from '~/pages/Menu';
import Cart from '~/pages/Cart';
import Item from '~/pages/Item';
import ItemEdit from '~/pages/ItemEdit';
import StoreInfo from '~/pages/StoreInfo';
import Discount from '~/pages/Discount';
import Announcement from '~/pages/Announcement';
import OrderList from '~/pages/OrderList';
import CouponDetail from '~/pages/CouponDetail';
import GroupOrder from '~/pages/GroupOrder';
import Error from '~/pages/404';
import PersonalInfo from '~/pages/PersonalInfo';

// group page
import GroupCart from '~/pages/GroupCart';
import GroupMenu from '~/pages/GroupMenu';
import GroupItem from '~/pages/GroupItem';
import GroupEditItem from '~/pages/GroupEditItem';
import GroupOrderList from '~/pages/GroupOrderList';
import GroupStoreInfo from '~/pages/GroupStoreInfo';
import GroupDiscount from '~/pages/GroupDiscount';
import GroupCouponDetail from '~/pages/GroupCouponDetail';
import GroupInvite from '~/pages/GroupInvite';
import GroupAnnouncement from '~/pages/GroupAnnouncement';

import useLocale from '~/hooks/useLocale';

const routes = [
  {
    path: '/',
    element: <Root />,
  },
  {
    path: ':storeId',
    element: (
      <AuthProvider>
        <CartProvider>
          <StoreProvider>
            <RequireGlobalDataMiddleware />
          </StoreProvider>
        </CartProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: <Menu />,
      },
      {
        path: 'cart',
        element: <Cart />,
      },
      {
        path: 'item/:itemId',
        element: <Item />,
      },
      {
        path: 'item/:itemId/edit/:index',
        element: <ItemEdit />,
      },
      {
        path: 'storeInfo',
        element: <StoreInfo />,
      },
      {
        path: 'personalInfo',
        element: <PersonalInfo />,
      },
      {
        path: 'discount',
        element: <Discount />,
      },
      {
        path: 'coupon/:couponId',
        element: <CouponDetail />,
      },
      {
        path: 'announcement',
        element: <Announcement />,
      },
      {
        path: 'orders',
        element: <OrderList />,
      },
      {
        path: 'groupOrder',
        element: <GroupOrder />,
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ],
  },
  {
    path: ':storeId/group/:groupId',
    element: (
      <AuthProvider>
        <GroupCartProvider>
          <StoreProvider>
            <GroupRequireGlobalDataMiddleware />
          </StoreProvider>
        </GroupCartProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: <GroupMenu />,
      },
      {
        path: 'cart',
        element: <GroupCart />,
      },
      {
        path: 'item/:itemId',
        element: <GroupItem />,
      },
      {
        path: 'item/:itemId/edit/:memberId/index/:index',
        element: <GroupEditItem />,
      },
      {
        path: 'storeInfo',
        element: <GroupStoreInfo />,
      },
      {
        path: 'orders',
        element: <GroupOrderList />,
      },
      {
        path: 'discount',
        element: <GroupDiscount />,
      },
      {
        path: 'coupon/:couponId',
        element: <GroupCouponDetail />,
      },
      {
        path: 'groupOrder',
        element: <GroupInvite />,
      },
      {
        path: 'announcement',
        element: <GroupAnnouncement />,
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ],
  },
  {
    path: '404',
    element: <Error />,
  },
];

export default function RouteContainer() {
  const element = useRoutes(routes);
  const locale = useLocale();

  return element;
}
