import { firestore } from '~/firebase';
import { useDisclosure } from '@chakra-ui/hooks';
import { useParams } from 'react-router-dom';

import { useState } from 'react';
import authAtom from '~/recoil/atom/auth';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import cartSelector from '~/recoil/selectors/cart';
import cartSettlementSelector from '~/recoil/selectors/cartSettlement';
import groupCartSettlementSelector from '~/recoil/selectors/groupCartSettlement';
import groupCartSelector from '~/recoil/selectors/groupCart';

import shippingFeeAtom from '~/recoil/atom/shippingFee';
import cartItemsOptionsMapSelector from '~/recoil/selectors/cartItemsOptionsMap';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import { useRecoilValue } from 'recoil';

import checkOperatingTime from '~/utils/checkOperatingTime';
import checkValidItems from '~/utils/checkValidItems';
import convertOrderType from '~/utils/convertOrderType';
import filterGroupInvalidItems from '~/utils/filterGroupInvalidItems';

import getCouponList from '~/httpApi/getCouponList';

import {
  CartStatus,
  MealPickUpType,
  PaymentType,
  PickMealTime,
  TaxType,
  GroupCart,
  Cart,
  CartItem,
  GroupCartItem,
  Store,
  DeliveryCashLimit,
  ReservedResetTimeBase,
} from '~/types';

import checkoutOrder from '~/httpApi/checkoutOrder';
import getPaymentRedirectUrl from '~/utils/getPaymentRedirectUrl';
import checkValidTaxNumber from '~/utils/checkValidTaxNumber';
import checkCouponValid from '~/utils/checkCouponValid';
import checkPreorderTime from '~/utils/checkPreorderTime';

import { differenceInMilliseconds } from 'date-fns';
import isHostSelector from '~/recoil/selectors/isHost';

export enum CheckoutErrorType {
  NONE = 'NONE',
  NOT_LOGIN = 'NOT_LOGIN',
  STORE_NOT_ALLOW_ORDER = 'STORE_NOT_ALLOW_ORDER',
  STORE_NOT_ALLOW_PREORDER = 'STORE_NOT_ALLOW_PREORDER',
  OVER_PREORDER_TIME = 'OVER_PREORDER_TIME',
  INVALID_CART_STATUS = 'INVALID_CART_STATUS',
  INVALID_ORDER_TIME = 'INVALID_ORDER_TIME',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  INVALID_USER = 'INVALID_USER',
  INVALID_PAYMENT = 'INVALID_PAYMENT',
  INVALID_ORDER_TYPE = 'INVALID_ORDER_TYPE',
  INVALID_ITEM = 'INVALID_ITEM',
  INVALID_EMPTY_CART = 'INVALID_EMPTY_CART',
  INVALID_PREORDER_DATE = 'INVALID_PREORDER_DATE',
  INVALID_COUPON = 'INVALID_COUPON',
  INVALID_TAX_INFO = 'INVALID_TAX_INFO',
  INVALID_EMAIL = 'INVALID_EMAIL',
  MISSING_ADDRESS = 'MISSING_ADDRESS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  OVER_CASH_DELIVERY_LIMIT = 'OVER_CASH_DELIVERY_LIMIT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

const checkoutErrorMapString: Record<CheckoutErrorType, string> = {
  [CheckoutErrorType.NONE]: '',
  [CheckoutErrorType.NOT_LOGIN]: 'cart.checkoutError.notLogin',
  [CheckoutErrorType.STORE_NOT_ALLOW_ORDER]: 'cart.checkoutError.notAllowOrder',
  [CheckoutErrorType.STORE_NOT_ALLOW_PREORDER]: 'cart.checkoutError.notAllowPreorder',
  [CheckoutErrorType.OVER_PREORDER_TIME]: 'cart.checkoutError.overPreorderTime',
  [CheckoutErrorType.INVALID_CART_STATUS]: 'cart.checkoutError.orderInProcessing',
  [CheckoutErrorType.INVALID_ORDER_TIME]: 'cart.checkoutError.notOperatingTime',
  [CheckoutErrorType.INVALID_ADDRESS]: 'cart.checkoutError.invalidAddress',
  [CheckoutErrorType.INVALID_USER]: 'cart.checkoutError.invalidUser',
  [CheckoutErrorType.INVALID_PAYMENT]: 'cart.checkoutError.invalidPaymentType',
  [CheckoutErrorType.INVALID_ORDER_TYPE]: 'cart.checkoutError.invalidOrderType',
  [CheckoutErrorType.INVALID_ITEM]: 'cart.checkoutError.invalidItem',
  [CheckoutErrorType.INVALID_EMPTY_CART]: 'cart.checkoutError.emptyCart',
  [CheckoutErrorType.INVALID_PREORDER_DATE]: 'cart.checkoutError.invalidPreorderTime',
  [CheckoutErrorType.INVALID_COUPON]: 'cart.checkoutError.invalidCoupon',
  [CheckoutErrorType.INVALID_TAX_INFO]: 'cart.checkoutError.invalidTaxInfo',
  [CheckoutErrorType.MISSING_ADDRESS]: 'cart.checkoutError.missingAddress',
  [CheckoutErrorType.INTERNAL_SERVER_ERROR]: 'cart.checkoutError.internalError',
  [CheckoutErrorType.UNKNOWN_ERROR]: 'cart.checkoutError.unknownError',
  [CheckoutErrorType.OVER_CASH_DELIVERY_LIMIT]: 'cart.checkoutError.cashDeliveryLimit',
  [CheckoutErrorType.INVALID_EMAIL]: 'cart.checkoutError.invalidEmail',
};

export default function useCheckout() {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isPaymentRedirectOpen, onOpen: onPaymentRedirectOpen } = useDisclosure();
  const [checkoutError, setCheckoutError] = useState<{ type: CheckoutErrorType; message: string }>({
    type: CheckoutErrorType.NONE,
    message: '',
  });
  const [invalidItemsError, setInvalidItemsError] = useState<{
    type: CheckoutErrorType;
    items: Array<CartItem>;
    groupItems: Array<GroupCartItem>;
  }>({
    type: CheckoutErrorType.NONE,
    items: [],
    groupItems: [],
  });

  const [checkCashOverLimitError, setCheckCashOverLimitError] = useState<{
    type: CheckoutErrorType;
    message: string;
  }>({
    type: CheckoutErrorType.NONE,
    message: '',
  });

  const { storeId = '', groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const isHost = useRecoilValue(isHostSelector);
  const store = useRecoilValue(storeLocaleSelector);
  const cart = useRecoilValue(cartSelector);
  const groupCart = useRecoilValue(groupCartSelector);
  const shippingFee = useRecoilValue(shippingFeeAtom);
  const cartStatus = useRecoilValue(cartStatusAtom);
  const cartSettlement = useRecoilValue(cartSettlementSelector);
  const groupCartSettlement = useRecoilValue(groupCartSettlementSelector);
  const cartItemsOptionsMap = useRecoilValue(cartItemsOptionsMapSelector);
  const checkCart = groupId ? groupCart : cart;
  const checkSettlement = groupId ? groupCartSettlement : cartSettlement;

  const handleCheckOut = async () => {
    if (!store) return;

    // 檢查是否登入
    if (!auth) {
      setCheckoutError({
        type: CheckoutErrorType.NOT_LOGIN,
        message: checkoutErrorMapString[CheckoutErrorType.NOT_LOGIN],
      });
      return;
    }

    // 檢查購物車是否正在下單中
    if (cartStatus !== CartStatus.ACTIVE) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_CART_STATUS,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_CART_STATUS],
      });
      return;
    }
    const { orderType } = checkCart;
    const [mealPickupType, pickMealTime] = convertOrderType(orderType);

    // 檢查營業時間
    const { operatingTime } = store;
    if (pickMealTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_ORDER_TIME,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_ORDER_TIME],
      });
      return;
    }

    // 店家目前是否接受訂單
    const { allowOrders } = store;
    if (!allowOrders) {
      setCheckoutError({
        type: CheckoutErrorType.STORE_NOT_ALLOW_ORDER,
        message: checkoutErrorMapString[CheckoutErrorType.STORE_NOT_ALLOW_ORDER],
      });
      return;
    }

    // 店家是否接受預約
    const { preOrderSetting } = store;
    const { preOrderDate } = checkCart;
    if (pickMealTime === PickMealTime.RESERVATION && !preOrderSetting.allowPreOrder) {
      setCheckoutError({
        type: CheckoutErrorType.STORE_NOT_ALLOW_PREORDER,
        message: checkoutErrorMapString[CheckoutErrorType.STORE_NOT_ALLOW_PREORDER],
      });
      return;
    }

    // 預定時間檢查
    if (pickMealTime === PickMealTime.RESERVATION) {
      // 預定時間已過
      if (!preOrderDate || !checkPreorderTime(preOrderDate, preOrderSetting)) {
        setCheckoutError({
          type: CheckoutErrorType.OVER_PREORDER_TIME,
          message: checkoutErrorMapString[CheckoutErrorType.OVER_PREORDER_TIME],
        });
        return;
      }

      // 不在營業時間內
      if (!preOrderDate || (preOrderDate && !checkOperatingTime(operatingTime, preOrderDate))) {
        setCheckoutError({
          type: CheckoutErrorType.INVALID_PREORDER_DATE,
          message: checkoutErrorMapString[CheckoutErrorType.INVALID_PREORDER_DATE],
        });
        return;
      }
    }

    const {
      delivery: { maxDistance },
    } = store;
    // 地址及運費檢查
    if (
      mealPickupType === MealPickUpType.DELIVERY &&
      (!shippingFee || (shippingFee && shippingFee.distance > maxDistance))
    ) {
      setCheckoutError({
        type: CheckoutErrorType.MISSING_ADDRESS,
        message: checkoutErrorMapString[CheckoutErrorType.MISSING_ADDRESS],
      });
      return;
    }

    // 付款方式檢查
    const { paymentTypes, allowOverLimitInCash } = store;
    const { paymentType } = checkCart;
    if (!paymentTypes.includes(paymentType)) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_PAYMENT,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_PAYMENT],
      });
      return;
    }

    if (
      !allowOverLimitInCash &&
      mealPickupType === MealPickUpType.DELIVERY &&
      paymentType === PaymentType.CASH &&
      checkSettlement.subtotal >= DeliveryCashLimit
    ) {
      setCheckCashOverLimitError({
        type: CheckoutErrorType.OVER_CASH_DELIVERY_LIMIT,
        message: checkoutErrorMapString[CheckoutErrorType.OVER_CASH_DELIVERY_LIMIT],
      });
      return;
    }

    // 檢查品項是否合法
    const checkCartItems = groupId
      ? (checkCart as GroupCart).groupItems.reduce<Array<CartItem>>(
          (acc, { items }) => acc.concat(...items),
          [],
        )
      : (checkCart as Cart).items;
    const { menus, categories, items, modifiers, inventorySetting } = store;
    const { invalidItems, validItems } = checkValidItems(
      {
        mealPickupType,
        pickMealTime,
        preOrderDate,
        allowReserved: !!inventorySetting.allowReserved,
        resetTime: inventorySetting.allowResetTime
          ? inventorySetting.resetTime
          : ReservedResetTimeBase,
      },
      { menus, categories, items, modifiers },
      checkCartItems,
      cartItemsOptionsMap,
    );

    // 檢查購物車是否為空
    if (checkCartItems.length === 0) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_EMPTY_CART,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_EMPTY_CART],
      });
      return;
    }

    if (invalidItems.length > 0 && storeId && auth) {
      if (groupId && isHost) {
        try {
          const { groupItems } = groupCart;
          const validGroupItems = filterGroupInvalidItems(groupItems, invalidItems, 'valid');
          await firestore
            .doc(`stores/${storeId}/carts/${groupId}`)
            .update('groupItems', validGroupItems);

          setInvalidItemsError({
            type: CheckoutErrorType.INVALID_ITEM,
            groupItems: filterGroupInvalidItems(groupItems, invalidItems, 'invalid'),
            items: [],
          });
        } catch (err) {
          console.error('update group cart item error', err);
        }

        return;
      }

      try {
        await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).update('items', validItems);
        setInvalidItemsError({
          type: CheckoutErrorType.INVALID_ITEM,
          groupItems: [],
          items: invalidItems,
        });
      } catch (err) {
        console.error('update item error', err);
      }
      return;
    }

    // 發票資訊檢查
    const { taxType, taxInfo } = checkCart;
    if (
      taxType === TaxType.COMPANY &&
      (taxInfo.title.length < 1 ||
        taxInfo.invoice.length !== 8 ||
        !checkValidTaxNumber(taxInfo.invoice))
    ) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_TAX_INFO,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_TAX_INFO],
      });
      return;
    }

    // 使用電子發票店家檢查電子信箱
    const { isUseInvoice } = store;
    const { email } = checkCart;
    if (isUseInvoice && !/.@./.test(email)) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_EMAIL,
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_EMAIL],
      });
      return;
    }

    // 優惠券檢查
    const { coupon } = checkCart;
    const { subtotal } = checkSettlement;
    if (coupon) {
      try {
        setIsLoading(true);
        const couponList = await getCouponList(storeId, auth);
        if (
          !couponList.find(({ id }) => id === coupon.id) ||
          !checkCouponValid(coupon, subtotal, {
            mealPickupType,
            pickMealTime,
            preOrderDate,
          })
        ) {
          groupId
            ? await firestore.doc(`/stores/${storeId}/carts/${groupId}`).update('coupon', null)
            : await firestore.doc(`/stores/${storeId}/carts/${auth.uid}`).update('coupon', null);
          setCheckoutError({
            type: CheckoutErrorType.INVALID_COUPON,
            message: checkoutErrorMapString[CheckoutErrorType.INVALID_COUPON],
          });
          setIsLoading(false);
          return;
        }
      } catch (err) {
        setCheckoutError({
          type: CheckoutErrorType.UNKNOWN_ERROR,
          message: checkoutErrorMapString[CheckoutErrorType.UNKNOWN_ERROR],
        });
        setIsLoading(false);
        return;
      }
    }

    // checkout group order
    if (groupId) {
      try {
        await firestore
          .doc(`/stores/${storeId}/carts/${groupId}`)
          .update('status', CartStatus.ORDERING);
      } catch (err) {
        console.log('change status error');
      }

      try {
        setIsLoading(true);
        const token = await auth.getIdToken();
        const res = await checkoutOrder(storeId, token, groupId);
        paymentType !== PaymentType.CASH && onPaymentRedirectOpen();
        getPaymentRedirectUrl(res.orderId, token);
        console.log('checkout success');
      } catch (err: any) {
        console.log('group checkout error', err);
        if (err.response.data) {
          setCheckoutError({
            type: err.response.data.code as CheckoutErrorType,
            message: checkoutErrorMapString[err.response.data.code as CheckoutErrorType] || '',
          });
        }
      } finally {
        setIsLoading(false);
      }
      return;
    }

    // checkout general order
    try {
      await firestore
        .doc(`/stores/${storeId}/carts/${auth.uid}`)
        .update('status', CartStatus.ORDERING);
    } catch (err) {
      console.log('change status error');
    }

    try {
      setIsLoading(true);
      const token = await auth.getIdToken();
      const res = await checkoutOrder(storeId, token, auth.uid);
      setIsLoading(false);
      paymentType !== PaymentType.CASH && onPaymentRedirectOpen();
      getPaymentRedirectUrl(res.orderId, token);
      console.log('checkout success', res);
    } catch (err: any) {
      console.log('checkout error', err.response.data);
      if (err.response.data) {
        setCheckoutError({
          type: err.response.data.code as CheckoutErrorType,
          message: checkoutErrorMapString[err.response.data.code as CheckoutErrorType] || '',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearError = () => {
    setCheckoutError({
      type: CheckoutErrorType.NONE,
      message: checkoutErrorMapString[CheckoutErrorType.NONE],
    });
    setInvalidItemsError({
      type: CheckoutErrorType.NONE,
      items: [],
      groupItems: [],
    });
    setCheckCashOverLimitError({
      type: CheckoutErrorType.NONE,
      message: '',
    });
  };

  return {
    checkoutError,
    invalidItemsError,
    checkCashOverLimitError,
    isLoading,
    isPaymentRedirectOpen,
    handleCheckOut,
    handleClearError,
  };
}
