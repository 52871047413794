import { selector } from 'recoil';

import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import pickMealTimeAtom from '../atom/pickMealTime';
import { menusSelector } from './menus';
import { categoriesSelector } from './categories';
import { itemsSelector } from './items';

import { MealPickUpType, Menu, PickMealTime, ReservedResetTimeBase } from '~/types';
import { CategoryItemInfo } from '~/pages/Menu/components/CategoryItemList';

import getUnionCategoryIds from '~/utils/getUnionCategoryIds';
import checkOperatingTime from '~/utils/checkOperatingTime';
import calcRemainInventory from '~/utils/calcRemainInventory';
import { storeLocaleSelector } from './storeLocale';

const filteredMenusSelector = selector<Menu[] | null>({
  key: 'filteredMenuSelector',
  get: ({ get }) => {
    const menus = get(menusSelector);
    const mealPickupType = get(mealPickupTypeAtom);
    const pickMealTime = get(pickMealTimeAtom);
    const preOrderDate = get(preOrderDateAtom);

    if (!menus) return null;

    const availableMenus = Object.values(menus)
      .filter(({ allowPublish }) => allowPublish)
      .filter(({ allowPickup, allowDelivery }) =>
        mealPickupType === MealPickUpType.PICKUP ? allowPickup : allowDelivery,
      )
      .filter(({ allowPreorder, schedule }) => {
        if (pickMealTime === PickMealTime.RESERVATION && preOrderDate) {
          return allowPreorder && checkOperatingTime(schedule, preOrderDate);
        }

        if (pickMealTime === PickMealTime.RESERVATION && !preOrderDate) {
          return false;
        }

        return checkOperatingTime(schedule);
      });
    if (availableMenus.length === 0) return null;

    return availableMenus;
  },
});

export const categoryItemListSelector = selector<CategoryItemInfo[]>({
  key: 'categoryItemListSelector',
  get: ({ get }) => {
    const store = get(storeLocaleSelector);
    const filteredMenus = get(filteredMenusSelector);
    const filteredCategoryIds = getUnionCategoryIds({ menuList: filteredMenus || [] });
    const categories = get(categoriesSelector);
    const items = get(itemsSelector);
    const preOrderDate = get(preOrderDateAtom);
    const pickMealTime = get(pickMealTimeAtom);

    if (filteredCategoryIds.length === 0) return [];
    if (!categories || !items) return [];

    const today = new Date();

    const checkDate = pickMealTime === PickMealTime.RESERVATION ? preOrderDate || today : today;

    return filteredCategoryIds
      .reduce((prev: Array<CategoryItemInfo>, id) => {
        const category = categories[id];
        if (!category) {
          return prev;
        }
        const { name, items: itemIds } = category;
        prev.push({
          categoryId: id,
          categoryName: name,
          items: itemIds.reduce((acc, id) => {
            const item = items[id];
            if (!item) return acc;
            const { name, description, price, image, inventory, isSoldOut } = item;
            if (
              isSoldOut ||
              calcRemainInventory(
                inventory,
                !!store?.inventorySetting.allowReserved,
                today,
                checkDate,
                store?.inventorySetting.allowResetTime
                  ? store.inventorySetting.resetTime
                  : ReservedResetTimeBase,
              ) <= 0
            ) {
              return acc;
            }
            acc.push({
              itemId: id,
              itemName: name,
              description,
              price,
              imageUrl: image,
            });
            return acc;
          }, [] as Array<{ itemId: string; itemName: string; description: string; price: number; imageUrl: string }>),
        });
        return prev;
      }, [])
      .filter(({ items }) => items.length > 0);
  },
});

export const categoryMenuList = selector<Array<{ value: string; label: string }>>({
  key: 'categoryMenuList',
  get: ({ get }) => {
    const categoryItemsList = get(categoryItemListSelector);

    return categoryItemsList.map(({ categoryId, categoryName }) => ({
      value: categoryId,
      label: categoryName,
    }));
  },
});
