import { HStack, Flex, Text, Icon, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiFillStar } from 'react-icons/ai';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { RewardCondition, RewardType } from '~/types';
import transformCouponRewardConditionString from '~/utils/transformRewardConditionString';

type CouponInfoBarProps = {
  coupon: {
    condition: RewardCondition;
    type: RewardType;
  };
  onRemoveClick?: () => void;
};

export default function CouponInfoBar({ coupon, onRemoveClick }: CouponInfoBarProps) {
  const { t } = useTranslation();
  const conditionInfoString = transformCouponRewardConditionString(
    coupon.type,
    coupon.condition,
    t,
  );

  return (
    <Flex align="stretch" borderRadius="5px" bgColor="wsGray.200">
      <HStack flex="1" align="flex-start" px={2} py={3}>
        <Icon as={AiFillStar} boxSize="1.5rem" />
        <Text fontSize="sm">{conditionInfoString}</Text>
      </HStack>

      {onRemoveClick && (
        <Center as="button" p={2} onClick={onRemoveClick}>
          <Icon as={IoRemoveCircleOutline} boxSize="1.5rem" color="wsRed.600" />
        </Center>
      )}
    </Flex>
  );
}
