import { isSameDay, startOfDay, differenceInMinutes, addMinutes, format } from 'date-fns';
import { TFunction } from 'react-i18next';
import { MealPickUpType, PickupTimeRange } from '~/types';

export default function getPreOrderDateString(
  preOrderDate: Date,
  mealPickupType: MealPickUpType,
  t: TFunction<'translation', undefined>,
) {
  const nowDate = new Date();
  const weekDays = [
    'weekDay.SUN',
    'weekDay.MON',
    'weekDay.TUE',
    'weekDay.WED',
    'weekDay.THU',
    'weekDay.FRI',
    'weekDay.SAT',
  ];
  const timeStringFn = (time: number) => {
    const hour = Math.floor(time / 60);
    const minute = time % 60;
    const timeString = `${hour < 10 ? '0' + hour.toString() : hour.toString()}:${
      minute < 10 ? '0' + minute.toString() : minute.toString()
    }`;
    return timeString;
  };

  if (mealPickupType === MealPickUpType.DELIVERY) {
    if (isSameDay(preOrderDate, nowDate)) {
      const startOfToday = startOfDay(nowDate);
      const startToPreOrderMinutes = differenceInMinutes(preOrderDate, startOfToday);
      return `${timeStringFn(startToPreOrderMinutes)}`;
    }

    return `${format(preOrderDate, 'MM/dd')} (${t(weekDays[preOrderDate.getDay()])})`;
  }

  if (mealPickupType === MealPickUpType.PICKUP) {
    return `${format(preOrderDate, 'MM/dd')} (${t(weekDays[preOrderDate.getDay()])}) ${format(
      preOrderDate,
      'HH:mm',
    )}~${format(addMinutes(preOrderDate, PickupTimeRange), 'HH:mm')}`;
  }
}
