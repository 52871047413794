import { useToast } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';

import { updateCartData, updateGroupCart } from '~/firebase/updateData';

import isHostSelector from '~/recoil/selectors/isHost';
import cartItemsAtom from '~/recoil/atom/cartItems';
import groupItemsAtom from '~/recoil/atom/groupItems';
import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';
import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import useUser from '~/hooks/useUser';
import useOrderSetting from '~/hooks/useOrderSetting';
import useModalControl from '~/hooks/useModalControl';

import convertMealPickupType from '~/utils/convertMealPickupType';
import checkPickMealTimeError from '~/utils/checkPickMealTimeError';

import {
  MealPickUpType,
  PickMealTime,
  Location,
  CartItem,
  GroupCartItem,
  FirebaseCart,
  FirebaseGroupCart,
} from '~/types';

type useUpdateOrderSettingParams = {
  mealPickupType: MealPickUpType;
  address: string;
  selectedAddress: string;
  addressErrorMessage: string;
  floor: string;
  floorErrorMessage: string;
  note: string;
  isLoading: boolean;
  selectedLocation: Location | null;
  selectedTime: PickMealTime;
  selectedTimeError: string | null;
  preOrderDate: Date | null;
};

export default function useUpdateOrderSetting({
  mealPickupType,
  address,
  selectedAddress,
  addressErrorMessage,
  floor,
  floorErrorMessage,
  note,
  isLoading,
  selectedLocation,
  selectedTime,
  selectedTimeError,
  preOrderDate,
}: useUpdateOrderSettingParams) {
  const { t } = useTranslation();
  const toast = useToast();
  const { storeId = '', groupId } = useParams();
  const { auth, isLogin } = useUser();
  const isHost = useRecoilValue(isHostSelector);
  const cartItems = useRecoilValue(cartItemsAtom);
  const groupItems = useRecoilValue(groupItemsAtom);
  const { allowPreOrder, allowOrders, allowRealTimeOrder } =
    useRecoilValue(storeOrderSettingSelector);
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const { handleOrderSettingUpdate } = useOrderSetting();

  const { changeModalOpen } = useModalControl();

  const handlePickupSettingUpdate = async (isShouldClearCart: boolean) => {
    let errorMsg = '';

    if (mealPickupType === MealPickUpType.DELIVERY && isLogin) {
      if (!selectedLocation) {
        errorMsg = t('address.selectAddress');
        toast({ title: errorMsg, status: 'error', position: 'top' });
        return;
      }
    }

    if (mealPickupType === MealPickUpType.DELIVERY && !isLogin) {
      if (!address) {
        errorMsg = t('address.enterDelivery');
        toast({ title: errorMsg, status: 'error', position: 'top' });
        return;
      }

      if (!floor) {
        errorMsg = t('address.enterFloor');
        toast({ title: errorMsg, status: 'error', position: 'top' });
        return;
      }

      if (isLoading) return;

      if (addressErrorMessage) {
        toast({ title: t(addressErrorMessage), status: 'error', position: 'top' });
        return;
      }

      if (floorErrorMessage) {
        toast({ title: t(floorErrorMessage), status: 'error', position: 'top' });
        return;
      }

      if (!selectedAddress) {
        errorMsg = t('address.selectAddress');
        toast({ title: errorMsg, status: 'error', position: 'top' });
        return;
      }
    }

    if (selectedTimeError) {
      toast({ title: t(selectedTimeError), status: 'error', position: 'top' });
      return;
    }

    const orderTimeError = checkPickMealTimeError(selectedTime, preOrderDate, {
      allowOrders,
      allowPreOrder,
      allowRealTimeOrder,
      operatingTime,
    });

    if (orderTimeError) {
      toast({ title: t(orderTimeError), status: 'error', position: 'top' });
      return;
    }

    const location =
      isLogin && selectedLocation
        ? selectedLocation
        : {
            address,
            floor,
            note,
          };

    const newOrderSetting = {
      mealPickupType,
      location,
      pickMealTime: selectedTime,
      preOrderDate: selectedTime === PickMealTime.RESERVATION && preOrderDate ? preOrderDate : null,
    };

    const newCartItems: Array<CartItem> = isShouldClearCart ? [] : [...cartItems];

    const firebaseUpdateData: Partial<FirebaseCart> = {
      orderType: convertMealPickupType(mealPickupType, selectedTime),
      location,
      preOrderDate:
        selectedTime === PickMealTime.RESERVATION && preOrderDate
          ? firebase.firestore.Timestamp.fromDate(preOrderDate)
          : null,
      items: newCartItems,
    };

    const newGroupCartItems: Array<GroupCartItem> = isShouldClearCart ? [] : [...groupItems];

    const firebaseUpdateGroupData: Partial<FirebaseGroupCart> = {
      orderType: convertMealPickupType(mealPickupType, selectedTime),
      location,
      preOrderDate:
        selectedTime === PickMealTime.RESERVATION && preOrderDate
          ? firebase.firestore.Timestamp.fromDate(preOrderDate)
          : null,
      groupItems: newGroupCartItems,
    };

    if (!isLogin || !auth) {
      handleOrderSettingUpdate(newOrderSetting);
      changeModalOpen('isPickupSettingOpen', false);
      return;
    }

    if (groupId && isHost) {
      try {
        await updateGroupCart(storeId, groupId, firebaseUpdateGroupData);
        changeModalOpen('isPickupSettingOpen', false);
        return;
      } catch (error) {
        console.log('[updateGroupCart Error]', error);
      }
    }

    if (auth) {
      try {
        await updateCartData(storeId, auth, firebaseUpdateData);
        changeModalOpen('isPickupSettingOpen', false);
        return;
      } catch (error) {
        console.log('[updateCartData Error]', error);
      }
    }
  };

  return handlePickupSettingUpdate;
}
