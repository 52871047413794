import React from 'react';
import { Center, Text, Icon, VStack, HStack, Link } from '@chakra-ui/react';
import ContentContainer from '~/container/Content';

import { SiLine } from 'react-icons/si';
import { AiFillInstagram, AiFillFacebook, AiFillPhone } from 'react-icons/ai';
import { ImLocation2 } from 'react-icons/im';

import { useRecoilValue } from 'recoil';

import versionInfo from '~/versionInfo';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';
import { useTranslation, Trans } from 'react-i18next';

export default function Footer() {
  const store = useRecoilValue(storeLocaleSelector);
  const { t } = useTranslation();
  if (!store) {
    return <></>;
  }

  const { name, phoneNumber, location } = store;

  return (
    <Center bg="wsBlack" py={3}>
      <ContentContainer bg="wsBlack" px={{ base: 4, md: 0 }}>
        <VStack align="stretch">
          <Text fontSize="sm" fontWeight="bold" color="white">
            {name}
          </Text>

          {/** social media link */}
          {/* <HStack spacing={3}>
            <Icon as={SiLine} color="white" boxSize="1.2rem" />
            <Icon as={AiFillFacebook} color="white" boxSize="1.2rem" />
            <Icon as={AiFillInstagram} color="white" boxSize="1.2rem" />
          </HStack> */}

          <HStack>
            <Icon as={AiFillPhone} color="white" boxSize="1rem" />
            <Text fontSize="sm" color="white">
              {phoneNumber}
            </Text>
          </HStack>

          <HStack>
            <Icon as={ImLocation2} color="white" boxSize="1rem" />
            <Text fontSize="sm" color="white">
              {location.address}
            </Text>
          </HStack>

          <Trans i18nKey="footer.agreementText">
            <HStack spacing={0.5} wrap="wrap">
              <Text fontSize="xs" color="#787878"></Text>
              <Link
                letterSpacing={0.8}
                fontSize="xs"
                color="#A4A4A4"
                href="https://www.weserve.tw/privacy/"
                isExternal
              ></Link>
              <Text fontSize="xs" color="#787878"></Text>
              <Link
                letterSpacing={0.8}
                fontSize="xs"
                color="#A4A4A4"
                href="https://www.weserve.tw/terms/"
                isExternal
              ></Link>
            </HStack>
          </Trans>

          <Text fontSize="xs" color="wsGray.400">
            © 2021 {name}. All Rights Reserved. weserve . version {versionInfo.version}
          </Text>
        </VStack>
      </ContentContainer>
    </Center>
  );
}
