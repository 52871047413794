import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Text: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'wsBlack',
    letterSpacing: '0.1em',
  },
};

export default Text;
