import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import authAtom from '~/recoil/atom/auth';

import uidAtom from '~/recoil/atom/uid';
import cartTypeAtom from '~/recoil/atom/cartType';
import locationAtom from '~/recoil/atom/location';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import paymentTypeAtom from '~/recoil/atom/paymentType';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import taxTypeAtom from '~/recoil/atom/taxType';
import taxInfoAtom from '~/recoil/atom/taxInfo';
import cartItemsAtom from '~/recoil/atom/cartItems';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import couponAtom from '~/recoil/atom/coupon';
import shippingFeeAtom from '~/recoil/atom/shippingFee';
import emailAtom from '~/recoil/atom/email';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getFirebaseCart } from '~/firebase/getData';
import { cartListener } from '~/firebase/listener';
import useDeliveryQuote from './useDeliveryQuote';

import convertOrderType from '~/utils/convertOrderType';
import { FirebaseCart, CartStatus, TaxType, CartType } from '~/types';

export default function useCart() {
  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);
  useDeliveryQuote();

  const setUid = useSetRecoilState(uidAtom);
  const setCartType = useSetRecoilState(cartTypeAtom);
  const setMealPickupType = useSetRecoilState(mealPickupTypeAtom);
  const setPickMealTime = useSetRecoilState(pickMealTimeAtom);
  const setLocation = useSetRecoilState(locationAtom);
  const setPaymentType = useSetRecoilState(paymentTypeAtom);
  const setPreOrderDate = useSetRecoilState(preOrderDateAtom);

  const setEmail = useSetRecoilState(emailAtom);
  const setTaxType = useSetRecoilState(taxTypeAtom);
  const setTaxInfo = useSetRecoilState(taxInfoAtom);

  const setCartItems = useSetRecoilState(cartItemsAtom);
  const setCartStatus = useSetRecoilState(cartStatusAtom);

  const setShippingFee = useSetRecoilState(shippingFeeAtom);

  const setCoupon = useSetRecoilState(couponAtom);

  const handleUpdateCart = (firebaseCart: FirebaseCart) => {
    const [updateMealPickupType, updatePickMealTime] = convertOrderType(firebaseCart.orderType);

    setUid(firebaseCart.uid);

    setCartType(CartType.PERSONAL);

    setMealPickupType(updateMealPickupType);
    setPickMealTime(updatePickMealTime);
    setLocation(firebaseCart.location);
    setPreOrderDate(firebaseCart.preOrderDate ? firebaseCart.preOrderDate.toDate() : null);

    setCartItems(firebaseCart.items);
    setCartStatus(firebaseCart.status);

    setPaymentType(firebaseCart.paymentType);

    setEmail(firebaseCart.email || '');
    setTaxType(firebaseCart.taxType);
    setTaxInfo(firebaseCart.taxInfo);

    setShippingFee(firebaseCart.shippingFee);

    const coupon = firebaseCart.coupon;
    setCoupon(
      coupon
        ? { ...coupon, startDate: coupon.startDate.toDate(), endDate: coupon.endDate.toDate() }
        : null,
    );
  };

  useEffect(() => {
    let cartUnlisten: (() => void) | null = null;
    if (auth && storeId) {
      getFirebaseCart(storeId, auth);
      cartUnlisten = cartListener(storeId, auth, handleUpdateCart);
    } else {
      setUid('');
      setCartItems([]);
      setCartStatus(CartStatus.ACTIVE);
      setTaxType(TaxType.PERSONAL);
      setTaxInfo({
        invoice: '',
        title: '',
      });
      setCoupon(null);
    }
    return () => {
      cartUnlisten?.();
      setCartItems([]);
    };
  }, [storeId, auth]);
}
