import { VStack, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import image from '~/assets/images/404.png';

const Error = () => {
  const { t } = useTranslation();
  return (
    <VStack display="flex" alignItems="center" justifyContent="center" h="full" p={20}>
      <Image src={image} />
      <VStack>
        <Text fontSize="sm" color="wsGray.500">
          {t('404')}
        </Text>
      </VStack>
    </VStack>
  );
};

export default Error;
