import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  Text,
} from '@chakra-ui/react';
import { GroupCartItem, CartItem } from '~/types';
import Item from '~/components/ProductItem';
import GroupMemberProductItem from '~/components/GroupMemberProductItems';

import { useTranslation, Trans } from 'react-i18next';

type InvalidItemsModalProps = {
  isOpen: boolean;
  items: Array<CartItem>;
  groupItems: Array<GroupCartItem>;
  onClose: () => void;
};

export default function InvalidItemsModal({
  isOpen,
  items,
  groupItems,
  onClose,
}: InvalidItemsModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />

      <ModalContent m={2}>
        <ModalHeader>
          <Text>{t('store.order.error.invalidItems')}</Text>
        </ModalHeader>
        <ModalBody>
          <VStack align="stretch">
            {items.map((item, index) => {
              return <Item key={`${item.id}_${index}`} item={item} allowEdit={false} />;
            })}
            {groupItems.map((groupItem) => {
              return (
                <GroupMemberProductItem
                  key={groupItem.memberId}
                  groupItem={groupItem}
                  allowEdit={false}
                />
              );
            })}
          </VStack>
        </ModalBody>
        <ModalFooter
          as="button"
          cursor="pointer"
          borderTop="1px"
          borderColor="wsGray.300"
          onClick={onClose}
        >
          <Text m="auto">{t('understood')}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
