import { FormControl, FormLabel, HStack, Input, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type AddressInputRemarkProps = {
  note: string;
  onNoteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

// 外送備註
export default function AddressInputRemark({ note, onNoteChange }: AddressInputRemarkProps) {
  const { t } = useTranslation();
  return (
    <FormControl id="first-name">
      <HStack align="flex-start" spacing={0}>
        <FormLabel fontSize="sm" fontWeight="normal">
          {t('address.note')}
        </FormLabel>
        <Text fontSize="xs" color="wsGray.500">
          {t('address.noteExample')}
        </Text>
      </HStack>
      <Input defaultValue={note} value={note} autoComplete="off" onChange={onNoteChange} />
    </FormControl>
  );
}
