import { useMemo } from 'react';
import {
  ModifierWithId,
  Option,
  PickMealTime,
  OrderModifier,
  ReservedResetTimeBase,
} from '~/types';
import cartItemsOptionsMapSelector from '~/recoil/selectors/cartItemsOptionsMap';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import { useRecoilValue } from 'recoil';
import calcRemainInventory from '~/utils/calcRemainInventory';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

export type ModifierWithRemainCount = Omit<ModifierWithId, 'options'> & {
  options: Array<Option & { remainCount: number }>;
};

// 將 modifiers 的 option 剩餘量計算完重組後丟出
export default function useItemModifiers(
  modifiers: Array<ModifierWithId>,
  {
    cartItemModifiers = [],
    compensativeCount = 0,
  }: { cartItemModifiers?: Array<OrderModifier>; compensativeCount?: number },
) {
  const store = useRecoilValue(storeLocaleSelector);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const cartItemsOptionsMap = useRecoilValue(cartItemsOptionsMapSelector);

  const modifiersWithRemainCount = useMemo(() => {
    const today = new Date();

    const checkDate = pickMealTime === PickMealTime.RESERVATION ? preOrderDate || today : today;

    // 購物車單個品項內包含的所有選項
    const cartItemModifierOptionIdsSet = new Set([
      ...cartItemModifiers.flatMap(({ id: modifierId, options }) =>
        options.map(({ id }) => `${modifierId}_${id}`),
      ),
    ]);

    console.log('modifiers', modifiers);
    return modifiers.map((modifier) => {
      return {
        ...modifier,
        // 將庫存以及購物車已選擇選項數量做合併計算，產出當前可選剩餘數量
        options: modifier.options.map((option) => {
          const inventoryCount = calcRemainInventory(
            option.inventory,
            !!store?.inventorySetting.allowReserved,
            today,
            checkDate,
            store?.inventorySetting.allowResetTime
              ? store.inventorySetting.resetTime
              : ReservedResetTimeBase,
          );
          const cartOptionWithCount = cartItemsOptionsMap.get(`${modifier.id}_${option.id}`);

          // 尋找該 option 是否存在在購物車該品項內
          const isCartItemHasOption = cartItemModifierOptionIdsSet.has(
            `${modifier.id}_${option.id}`,
          );

          const remainCount = cartOptionWithCount
            ? inventoryCount -
              cartOptionWithCount.count +
              (isCartItemHasOption ? compensativeCount : 0) // 如果存在必須補回來
            : inventoryCount;

          return {
            ...option,
            remainCount,
          };
        }),
      };
    });
  }, [
    store?.inventorySetting,
    preOrderDate,
    pickMealTime,
    cartItemsOptionsMap,
    modifiers,
    compensativeCount,
    cartItemModifiers,
  ]);

  // 檢查當前選擇數量是否超出 "選取選項最少剩餘量"
  const handleCheckModifierOptionError = (itemModifiers: Array<OrderModifier>, count: number) => {
    const itemOptionIdsSet = new Set([
      ...itemModifiers.flatMap(({ id: modifierId, options }) =>
        options.map(({ id }) => `${modifierId}_${id}`),
      ),
    ]);

    const minimumCount = modifiersWithRemainCount.reduce<number>((acc, modifierWithRemainCount) => {
      const filterOptionsCountArr = modifierWithRemainCount.options
        .filter(({ id }) => itemOptionIdsSet.has(`${modifierWithRemainCount.id}_${id}`))
        .map(({ remainCount }) => remainCount);

      return Math.min(acc, ...filterOptionsCountArr);
    }, Infinity);

    return count > minimumCount;
  };

  return {
    modifiersWithRemainCount,
    handleCheckModifierOptionError,
  };
}
