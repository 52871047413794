import React from 'react';

import useStore from '~/hooks/useStore';
import useCheckMenuPickupType from '~/hooks/useCheckMenuPickupType';

type StoreProviderProps = {
  children: React.ReactNode;
};

export default function StoreProvider({ children }: StoreProviderProps) {
  useStore();
  useCheckMenuPickupType();

  return <>{children}</>;
}
