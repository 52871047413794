import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const CloseButton: ComponentSingleStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'unset',
    },
  },
};

export default CloseButton;
