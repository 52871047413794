import { useReducer, useState } from 'react';

type timeStateType = {
  isTimerStart: boolean;
  restTime: number;
  timer: NodeJS.Timeout | null;
};

enum timeStateAction {
  RESTART,
  COUNT_DOWN,
  END_COUNT,
}

type timeActionStates =
  | {
      type: timeStateAction.RESTART;
      payload: { restTime: number; timer: NodeJS.Timeout };
    }
  | {
      type: timeStateAction.COUNT_DOWN;
    }
  | {
      type: timeStateAction.END_COUNT;
    };

const reducer = (state: timeStateType, action: timeActionStates) => {
  switch (action.type) {
    case timeStateAction.RESTART:
      return {
        isTimerStart: true,
        ...action.payload,
      };
    case timeStateAction.COUNT_DOWN:
      return {
        ...state,
        restTime: state.restTime - 1,
      };
    case timeStateAction.END_COUNT:
      return {
        ...state,
        timer: null,
        isTimerStart: false,
      };
  }
};

const initState: timeStateType = {
  isTimerStart: false,
  restTime: 0,
  timer: null,
};

export default function useTimer(timeAmount: number) {
  const [timeState, dispatch] = useReducer(reducer, initState);

  const handleStart = () => {
    const timer = setInterval(handleCountdown, 1000);
    dispatch({ type: timeStateAction.RESTART, payload: { restTime: timeAmount, timer } });
  };

  const handleCountdown = () => {
    dispatch({ type: timeStateAction.COUNT_DOWN });
  };

  if (timeState.restTime <= 0 && timeState.timer) {
    clearInterval(timeState.timer);
    dispatch({ type: timeStateAction.END_COUNT });
  }

  return {
    ...timeState,
    handleStart,
  };
}
