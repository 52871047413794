import { useEffect, useState } from 'react';
import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Button,
  Icon,
  useToast,
} from '@chakra-ui/react';

import useLogin from '~/hooks/useLogin';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import { useRecoilValue } from 'recoil';
import Title from './Title';
import PhoneNumber from './PhoneNumber';
import VerificationCode from './VerificationCode';
import Name from './Name';
import Birthday from './Birthday';
import Gender from './Gender';
import Tax from './Tax';
import EditAddressModal from '~/components/EditAddressModal';
import AddressList from './AddressList';
import { IoCheckmarkCircle } from 'react-icons/io5';

import useLocationList from '~/components/DeliverySettingSection/hooks/useLocationList';

import { useTranslation } from 'react-i18next';

export type EditBaseInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// 登入頁面
export default function EditBaseInfoModal({ isOpen, onClose }: EditBaseInfoModalProps) {
  const { t } = useTranslation();
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const auth = useRecoilValue(authAtom);

  const [isCancel, setIsCancel] = useState(false);

  const toast = useToast();

  const {
    loginState,
    isVerifying,
    isConfirmed,
    verifyPhoneNumberCredential,
    handleUpdatePhoneNumber,
    handleModifierPhoneNumber,
    handleVerifyPhoneCode,
    handleCancelModified,
  } = useLogin();

  const handleIsCancelClick = () => {
    setIsCancel(true);
    handleCancelModified();
  };

  const { userLocationList, handleLocationEdit } = useLocationList();

  useEffect(() => {
    if (isConfirmed) {
      toast({
        position: 'top',
        title: t('personalInfo.authSuccess'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        render: () => {
          return (
            <HStack>
              <HStack
                justify="center"
                p={2}
                bg="white"
                borderRadius="5px"
                ml={20}
                boxShadow=" 0px 0px 10px rgba(0, 0, 0, 0.25)"
              >
                <Icon as={IoCheckmarkCircle} color="wsGreen.500" boxSize="1.5rem" />
                <Text fontSize="lg">{t('personalInfo.authSuccess')}</Text>
              </HStack>
            </HStack>
          );
        },
      });
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (!auth?.phoneNumber) {
      return;
    }
    if (auth?.phoneNumber !== phoneNumber) {
      handleUpdatePhoneNumber(auth.phoneNumber);
    }
  }, [auth?.phoneNumber]);

  const { phoneNumber, verificationCode, errors } = loginState;

  if (!auth) {
    return null;
  }

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        isCentered
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent m={0} h="100%" maxH="100%">
          <ModalHeader>
            <Title title={t('personalInfo.info')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" px={{ base: 4, md: 4 }} spacing={4}>
              <Name name={auth.displayName || ''} />
              <PhoneNumber
                isVerifying={isVerifying}
                value={phoneNumber}
                error={errors.phoneNumber}
                onSendCode={handleModifierPhoneNumber}
                onChange={(e) => handleUpdatePhoneNumber(e.target.value)}
                isCancel={verifyPhoneNumberCredential === '' || isConfirmed}
              />
              {verifyPhoneNumberCredential !== '' && !isConfirmed && (
                <VStack align="stretch">
                  <VerificationCode
                    isVerifying={isVerifying}
                    value={verificationCode}
                    error={errors.verificationCode}
                    onChange={(e) => handleVerifyPhoneCode(e.target.value)}
                  />
                  <Button
                    isFullWidth
                    h="auto"
                    py={3}
                    bgColor="wsGray.300"
                    letterSpacing={2}
                    color="wsBlack"
                    fontWeight="normal"
                    _hover={{
                      bgColor: 'wsGray.400',
                    }}
                    _focus={{
                      borderColor: 'wsBlack',
                    }}
                    onClick={() => handleIsCancelClick()}
                  >
                    {t('cancel')}
                  </Button>
                </VStack>
              )}
              <Birthday birthday="" />
              <Gender gender="-" />
              <Tax title="" invoice="" />
              <Text>{t('address.delivery')}</Text>
              <AddressList userLocationList={userLocationList} onAddressEdit={handleLocationEdit} />
            </VStack>
          </ModalBody>
          <ModalFooter p={0}>
            <Button
              variant="unstyled"
              bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
              borderRadius="unset"
              color="white"
              isFullWidth
              onClick={onClose}
              h="auto"
              p={3.5}
            >
              {t('confirm')}
            </Button>
          </ModalFooter>
          <EditAddressModal />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="full" placement="left">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader>
          <Title title={t('personalInfo.info')} />
        </DrawerHeader>
        <DrawerBody>
          <VStack align="stretch" px={{ base: 4, md: 4 }} spacing={4}>
            <Name name={auth.displayName || ''} />
            <PhoneNumber
              isVerifying={isVerifying}
              value={auth.phoneNumber || ''}
              error={errors.phoneNumber}
              onSendCode={handleModifierPhoneNumber}
              onChange={(e) => handleUpdatePhoneNumber(e.target.value)}
              isCancel={verifyPhoneNumberCredential === '' || isConfirmed}
            />
            {verifyPhoneNumberCredential !== '' && !isConfirmed && (
              <VStack align="stretch">
                <VerificationCode
                  isVerifying={isVerifying}
                  value={verificationCode}
                  error={errors.verificationCode}
                  onChange={(e) => handleVerifyPhoneCode(e.target.value)}
                />
                <Button
                  isFullWidth
                  h="auto"
                  py={3}
                  bgColor="wsGray.300"
                  letterSpacing={2}
                  color="wsBlack"
                  fontWeight="normal"
                  _hover={{
                    bgColor: 'wsGray.400',
                  }}
                  _focus={{
                    borderColor: 'wsBlack',
                  }}
                  onClick={() => handleIsCancelClick()}
                >
                  {t('cancel')}
                </Button>
              </VStack>
            )}
            <Birthday birthday="" />
            <Gender gender="-" />
            <Tax title="" invoice="" />
            <Text>{t('address.delivery')}</Text>
            <AddressList userLocationList={userLocationList} onAddressEdit={handleLocationEdit} />
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Button
            variant="unstyled"
            bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
            borderRadius="unset"
            color="white"
            isFullWidth
            onClick={onClose}
            h="auto"
            p={3.5}
          >
            {t('confirm')}
          </Button>
        </DrawerFooter>
        <EditAddressModal />
      </DrawerContent>
    </Drawer>
  );
}
