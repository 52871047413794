import React from 'react';
import { VStack, Flex, BoxProps } from '@chakra-ui/react';
import ContentContainer from './Content';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

import useDomain from '~/hooks/useDomain';

type PageContainerProps = {
  children?: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  beforeHeaderComponent?: React.ReactNode;
  afterFooterComponent?: React.ReactNode;
  contentStyle?: BoxProps;
};

export default function PageContainer({
  children,
  hideFooter = false,
  hideHeader = false,
  beforeHeaderComponent = null,
  afterFooterComponent = null,
  contentStyle = {},
}: PageContainerProps) {
  const defaultStoreId = useDomain();
  return (
    <Flex direction="column" h="100vh">
      {beforeHeaderComponent}

      {hideHeader ? null : <Header />}

      <ContentContainer flex="1" w="full" {...contentStyle}>
        {children}
      </ContentContainer>

      {hideFooter ? null : <Footer />}

      {afterFooterComponent}
    </Flex>
  );
}
