import { updateGroupCart } from '~/firebase/updateData';
import { useParams } from 'react-router-dom';
import isHostSelector from '~/recoil/selectors/isHost';
import groupMemberInfoAtom from '~/recoil/atom/groupMemberInfo';
import groupItemsAtom from '~/recoil/atom/groupItems';
import { useRecoilValue } from 'recoil';
import { CartItem, GroupCartItem } from '~/types';

export default function useGroupCartItems() {
  const { storeId, groupId } = useParams();
  const groupItems = useRecoilValue(groupItemsAtom);
  const isHost = useRecoilValue(isHostSelector);
  const groupMemberInfo = useRecoilValue(groupMemberInfoAtom);

  const handleAddGroupItem = async (cartItem: CartItem) => {
    if (!storeId || !groupId) return;

    if (cartItem.count <= 0) return;

    const findMemberItems = groupItems.find(
      ({ memberId }) => memberId === groupMemberInfo.memberId,
    );

    if (findMemberItems) {
      try {
        await updateGroupCart(storeId, groupId, {
          groupItems: groupItems.map((groupItem) => {
            if (groupItem.memberId === groupMemberInfo.memberId) {
              return {
                ...groupItem,
                items: [...groupItem.items, cartItem],
              };
            }
            return groupItem;
          }),
        });
      } catch (err) {
        console.error('add group item error', err);
      }

      return;
    }

    const newGroupItem: GroupCartItem = {
      memberId: groupMemberInfo.memberId,
      memberName: groupMemberInfo.memberName,
      items: [cartItem],
    };

    try {
      await updateGroupCart(storeId, groupId, {
        groupItems: [...groupItems, newGroupItem],
      });
    } catch (err) {
      console.error('add group item error', err);
    }
  };

  const handleDeleteGroupItem = async (targetMemberId: string, itemId: string, index: number) => {
    if (!storeId || !groupId || (!isHost && targetMemberId !== groupMemberInfo.memberId)) return;

    const findMemberItems = groupItems.find(({ memberId }) => memberId === targetMemberId);

    if (findMemberItems) {
      const newMemberItems = {
        ...findMemberItems,
        items: findMemberItems.items.filter((item, i) => !(itemId === item.id && index === i)),
      };

      try {
        await updateGroupCart(storeId, groupId, {
          groupItems: groupItems
            .map((groupItem) =>
              groupItem.memberId === newMemberItems.memberId ? newMemberItems : groupItem,
            )
            .filter(({ items }) => items.length > 0),
        });
      } catch (err) {
        console.error('delete group item error');
      }
    }
  };

  const handleEditGroupItem = async (cartItem: CartItem, targetMemberId: string, index: number) => {
    if (!storeId || !groupId || (!isHost && targetMemberId !== groupMemberInfo.memberId)) return;

    if (cartItem.count <= 0) {
      handleDeleteGroupItem(targetMemberId, cartItem.id, index);
      return;
    }

    const findMemberItems = groupItems.find(({ memberId }) => memberId === targetMemberId);

    if (findMemberItems) {
      const newMemberItems = {
        ...findMemberItems,
        items: findMemberItems.items.map((item, i) => {
          if (item.id === cartItem.id && index === i) {
            return cartItem;
          }
          return item;
        }),
      };

      try {
        await updateGroupCart(storeId, groupId, {
          groupItems: groupItems.map((groupItem) =>
            groupItem.memberId === newMemberItems.memberId ? newMemberItems : groupItem,
          ),
        });
      } catch (err) {
        console.error('edit group item error');
      }
    }
  };

  return {
    groupItems,
    handleDeleteGroupItem,
    handleEditGroupItem,
    handleAddGroupItem,
  };
}
