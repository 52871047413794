import { VStack, HStack, Text, Divider, Icon } from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';

import { IoPersonCircleOutline } from 'react-icons/io5';
import { GroupCartItem } from '~/types';

import ProductItem from './GroupProductItem';

type GroupMemberProductItemsProps = {
  groupItem: GroupCartItem;
  isSelfItems?: boolean;
  allowEdit?: boolean;
  onDeleteGroupItems?: (targetMemberId: string, itemId: string, index: number) => void;
};

export default function GroupMemberProductItems({
  groupItem,
  allowEdit = false,
  isSelfItems = false,
  onDeleteGroupItems,
}: GroupMemberProductItemsProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { memberName, memberId, items } = groupItem;

  const handleEditItem = (itemId: string, index: number) => {
    if (items.some((item, i) => item.id === itemId && index === i)) {
      navigate(`/${storeId}/group/${groupId}/item/${itemId}/edit/${memberId}/index/${index}`);
    }
  };
  return (
    <VStack
      align="stretch"
      borderRadius="5px"
      borderWidth={isSelfItems ? '3px' : '1px'}
      borderColor={isSelfItems ? 'wsBlack' : 'wsGray.400'}
    >
      <VStack align="stretch" p={2} pb={0}>
        <HStack>
          <Icon as={IoPersonCircleOutline} boxSize="1.25rem" />
          <Text fontSize="xs" fontWeight="bold">
            {memberName}
          </Text>
        </HStack>
        <Divider />
      </VStack>

      <VStack
        align="stretch"
        spacing={0}
        pb={0}
        divider={
          <Divider
            borderColor={isSelfItems ? 'wsBlack' : 'wsGray.400'}
            borderWidth={isSelfItems ? '1px' : ''}
          />
        }
      >
        {items.map((item, index) => {
          return (
            <ProductItem
              key={`${item.id}_${index}`}
              item={item}
              allowEdit={allowEdit}
              onDelete={() => onDeleteGroupItems?.(memberId, item.id, index)}
              onEdit={() => handleEditItem(item.id, index)}
            />
          );
        })}
      </VStack>
    </VStack>
  );
}
