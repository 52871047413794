import { WsOrderStatus, OrderType } from '~/types';

export const deliveryMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.partnerDeliveryPrepared',
  [WsOrderStatus.RETRY]: 'store.order.status.retry',
  [WsOrderStatus.PICKED]: 'store.order.status.picked',
  [WsOrderStatus.COMPLETED]: 'store.order.status.deliveryCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export const preOrderDeliveryMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.CONFIRMED]: 'store.order.status.confirmed',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.partnerDeliveryPrepared',
  [WsOrderStatus.RETRY]: 'store.order.status.retry',
  [WsOrderStatus.PICKED]: 'store.order.status.picked',
  [WsOrderStatus.COMPLETED]: 'store.order.status.deliveryCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export const selfDeliveryMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.selfDeliveryPrepared',
  [WsOrderStatus.PICKED]: 'store.order.status.picked',
  [WsOrderStatus.COMPLETED]: 'store.order.status.deliveryCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export const preOrderSelfDeliveryMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.CONFIRMED]: 'store.order.status.confirmed',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.selfDeliveryPrepared',
  [WsOrderStatus.PICKED]: 'store.order.status.picked',
  [WsOrderStatus.COMPLETED]: 'store.order.status.deliveryCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export const selfPickupMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.pickupPrepared',
  [WsOrderStatus.COMPLETED]: 'store.order.status.pickupCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export const preOrderSelfPickupMapString = {
  [WsOrderStatus.CREATED]: 'store.order.status.created',
  [WsOrderStatus.CONFIRMED]: 'store.order.status.confirmed',
  [WsOrderStatus.ACCEPTED]: 'store.order.status.accepted',
  [WsOrderStatus.PREPARED]: 'store.order.status.pickupPrepared',
  [WsOrderStatus.COMPLETED]: 'store.order.status.pickupCompleted',
  [WsOrderStatus.UNPAID]: 'store.order.status.abandonOrder',
  [WsOrderStatus.FAILED]: 'store.order.status.failed',
  [WsOrderStatus.CANCELLED]: 'store.order.status.canceled',
};

export function getMatchOrderTypeMapString(
  orderType: OrderType,
  isSelfDelivery: boolean = false,
): Partial<Record<WsOrderStatus, string>> {
  switch (orderType) {
    case OrderType.WS_PICKUP: {
      return selfPickupMapString;
    }
    case OrderType.WS_DELIVERY: {
      if (isSelfDelivery) {
        return selfDeliveryMapString;
      }
      return deliveryMapString;
    }
    case OrderType.WS_PREORDER_PICKUP: {
      return preOrderSelfPickupMapString;
    }
    case OrderType.WS_PREORDER_DELIVERY: {
      if (isSelfDelivery) {
        return preOrderSelfDeliveryMapString;
      }
      return preOrderDeliveryMapString;
    }
    default: {
      return {};
    }
  }
}
