import { HStack, Icon, Text } from '@chakra-ui/react';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { WsOrderStatus } from '~/types';

import { useTranslation } from 'react-i18next';

export type OrderTimeInfoProps = {
  time?: string;
  status: WsOrderStatus;
  isPreOrder: boolean;
};

export default function OrderTimeInfo({ status, time, isPreOrder }: OrderTimeInfoProps) {
  const { t } = useTranslation();

  let infoText = (
    <Text fontSize="sm">
      {t('store.order.estimatedPreparation')} {time}
    </Text>
  );
  if (status === WsOrderStatus.CANCELLED || status === WsOrderStatus.FAILED) {
    infoText = <Text fontSize="sm">{t('store.order.canceled')}</Text>;
  }
  if (status === WsOrderStatus.COMPLETED) {
    infoText = (
      <Text fontSize="sm">
        {t('store.order.completedAt')} {time}
      </Text>
    );
  }
  if (isPreOrder) {
    infoText = (
      <Text fontSize="sm">
        {t('store.order.preorderTime')} {time}
      </Text>
    );
  }

  return (
    <HStack spacing={0} px={2}>
      <Icon as={AiOutlineClockCircle} mr={2} boxSize="1.125rem" />
      {infoText}
    </HStack>
  );
}
