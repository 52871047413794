import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { firestore } from '~/firebase';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import authAtom from '~/recoil/atom/auth';
import cartSelector from '~/recoil/selectors/cart';
import { useRecoilValue } from 'recoil';

import { CartType, FirebaseGroupCart, CartStatus } from '~/types';

export default function useCreateGroupCart() {
  const { t } = useTranslation();
  const toast = useToast();
  const { storeId } = useParams();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const cart = useRecoilValue(cartSelector);

  const handleCreateGroupCart = async (groupNote: string) => {
    if (!auth || !storeId) return;

    const groupCartSnap = firestore.collection(`stores/${storeId}/carts`).doc();
    console.log(groupCartSnap.id);

    const {
      orderType,
      location,
      preOrderDate,
      paymentType,
      taxInfo,
      taxType,
      items,
      shippingFee,
      email,
    } = cart;

    const newGroupCart: FirebaseGroupCart = {
      email: email || '',
      uid: auth.uid,
      status: CartStatus.ACTIVE,
      cartType: CartType.GROUP,
      orderType: orderType,
      location: location,
      preOrderDate: preOrderDate ? firebase.firestore.Timestamp.fromDate(preOrderDate) : null,
      taxType: taxType,
      taxInfo: taxInfo,
      groupItems:
        items.length > 0
          ? [
              {
                memberId: auth.uid,
                memberName: auth.displayName || '',
                items: items,
              },
            ]
          : [],
      groupHostInfo: { note: groupNote, name: auth.displayName || auth.phoneNumber || '' },
      paymentType: paymentType,
      coupon: null,
      shippingFee,
    };

    try {
      await groupCartSnap.set(newGroupCart);
      navigate(`/${storeId}/group/${groupCartSnap.id}/groupOrder`);
      toast({
        title: t('group.created'),
        status: 'success',
        duration: 1000,
        position: 'top',
      });
    } catch (err) {
      console.log('create group error', err);
      toast({
        title: t('group.failed'),
        status: 'error',
        duration: 1000,
        position: 'top',
      });
    }
  };
  return handleCreateGroupCart;
}
