import { HStack, Text, Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type LoginBottomBarProps = {
  login: () => void;
};

// 購物車資訊
export default function LoginBottomBar({ login }: LoginBottomBarProps) {
  const { t } = useTranslation();

  return (
    <Box
      position="sticky"
      zIndex="1000"
      bottom="0"
      w="calc(100% + 2rem)"
      transition=".3s all"
      transform="translateX(-1rem)"
    >
      <HStack
        justify="space-between"
        px={4}
        py={2}
        bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
        spacing={1}
      >
        <HStack>
          <Text color="white" fontSize="xs">
            {t('store.item.loginFirst')}
          </Text>
        </HStack>
        <Button onClick={login} variant="unstyled" color="white" bg="wsGray.700" minW="9.6rem">
          {t('login')}
        </Button>
      </HStack>
    </Box>
  );
}
