import { VStack } from '@chakra-ui/react';
import React from 'react';
import TitleSection, { TitleSectionProps } from './TitleSection';

type ContainerProps = { children?: React.ReactNode } & TitleSectionProps;

// 公告外框：包含公告外框設定以及把公告標題和內文包起來成一個完整公告
export default function Container({ children, ...props }: ContainerProps) {
  const gapPseudoStyle = {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: '10px solid transparent',
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    left: '-23px',
  };

  return (
    <VStack
      align="stretch"
      borderRadius="5px"
      borderWidth="3px"
      borderColor="wsBlack"
      p={2}
      pt={3}
      position="relative"
      _before={{
        ...gapPseudoStyle,
        borderRightColor: 'wsBlack',
        borderRight: '10px solid',
      }}
      _after={{
        ...gapPseudoStyle,
        borderRight: '10px solid #fff',
        left: '-18px',
      }}
    >
      <VStack align="stretch" mt={-1}>
        <TitleSection {...props} />
        {children}
      </VStack>
    </VStack>
  );
}
