import { useState, useMemo } from 'react';
import { Divider, VStack, Icon, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import {
  DeliveryDiscountProgress,
  TaxRadioGroup,
  PaymentTypeSelector,
  ContactInfo,
  CouponBar,
  CheckoutBar,
  OrderTypeBar,
  CouponInfoBar,
  PickupSettingInfoSection,
  DeliverySettingInfoSection,
  TaxInfoForm,
  TaxInfoSection,
  TaxInfoWithEmailSection,
  CheckoutErrorModal,
  NoItemNotice,
  PaymentRedirectModal,
  InvalidItemsModal,
  CheckoutCashLimitModal,
} from './components';
import LoginForm from '~/components/LoginForm';
import GroupMemberProductItems from '~/components/GroupMemberProductItems';
import TotalPriceSection from '~/components/TotalPriceSection';
import SectionContainer from '~/container/SectionContainer';
import LoadingModal from '~/components/LoadingModal';
import PickupSettingModal from '~/components/PickupSettingModal';
import EditAddressModal from '~/components/EditAddressModal';
import PickupCouponModal from '~/components/PickupCouponModal';

import { useNavigate, useParams } from 'react-router-dom';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import modalControlAtom from '~/recoil/atom/modalControl';
import groupMemberInfoAtom from '~/recoil/atom/groupMemberInfo';
import groupCartSelector from '~/recoil/selectors/groupCart';
import groupCartSettlementSelector from '~/recoil/selectors/groupCartSettlement';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import usePaymentType from '~/pages/Cart/hooks/usePaymentType';
import useTaxType from '~/pages/Cart/hooks/useTaxType';
import useTaxInfo from '../Cart/hooks/useTaxInfo';
import useEmail from '~/pages/Cart/hooks/useEmail';
import useCheckout, { CheckoutErrorType } from '~/pages/Cart/hooks/useCheckout';
import useGroupCartItems from '~/hooks/useGroupCartItems';
import useCouponList from '~/hooks/useCouponList';
import useCouponClear from '~/hooks/useCouponClear';
import useCoupon from '~/hooks/useCoupon';

import convertOrderType from '~/utils/convertOrderType';
import getCouponCostLimit from '~/utils/getCouponCostLimit';

import { IoPersonOutline, IoRestaurantOutline, IoPeopleOutline } from 'react-icons/io5';
import { MealPickUpType, TaxType, CartStatus, Coupon } from '~/types';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import { useTranslation } from 'react-i18next';

export default function HostDisplayContent() {
  const [showError, setShowError] = useState(false);
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const setModalControl = useSetRecoilState(modalControlAtom);
  const {
    isOpen: isCouponModalOpen,
    onClose: onCouponModalClose,
    onOpen: onCouponModalOpen,
  } = useDisclosure();

  const { storeId, groupId } = useParams();
  const navigate = useNavigate();

  const auth = useRecoilValue(authAtom);
  const store = useRecoilValue(storeLocaleSelector);
  const groupCart = useRecoilValue(groupCartSelector);
  const groupMemberInfo = useRecoilValue(groupMemberInfoAtom);
  const { email, emailError, handleChangeEmail } = useEmail();
  const { handleChangeTaxType } = useTaxType();
  const { paymentTypes, handleUpdatePaymentType } = usePaymentType();
  const { handleRemoveCoupon } = useCoupon();

  const { couponList, fetchingState } = useCouponList();

  const { t } = useTranslation();

  const { subtotal, shippingFee, freightSubsidy, total, discount } = useRecoilValue(
    groupCartSettlementSelector,
  );

  // auto remove invalid coupon on firestore
  useCouponClear(subtotal);

  const {
    handleCheckOut,
    invalidItemsError,
    checkCashOverLimitError,
    checkoutError,
    isLoading,
    isPaymentRedirectOpen,
    handleClearError,
  } = useCheckout();

  const { taxInfoState, handleUpdateInvoice, handleUpdateTitle } = useTaxInfo();

  const { handleDeleteGroupItem } = useGroupCartItems();

  const { paymentType, taxType, orderType, location, preOrderDate, groupItems, status, coupon } =
    groupCart;

  const availableCouponsCount = useMemo(() => {
    return couponList.filter(
      (coupon) => coupon.allowDisplay && subtotal >= getCouponCostLimit(coupon),
    ).length;
  }, [couponList, subtotal]);

  const { publishCoupons, notPublishCoupons } = useMemo(() => {
    return couponList.reduce<{ publishCoupons: Array<Coupon>; notPublishCoupons: Array<Coupon> }>(
      (acc, coupon) => {
        if (coupon.allowDisplay) {
          acc.publishCoupons.push(coupon);
        } else {
          acc.notPublishCoupons.push(coupon);
        }

        return acc;
      },
      { publishCoupons: [], notPublishCoupons: [] },
    );
  }, [couponList]);

  const handleOpenPickupSettingModal = () => {
    setModalControl((prev) => ({ ...prev, isPickupSettingOpen: true }));
  };

  const handleCheckoutClick = () => {
    if (groupCart.taxType === TaxType.COMPANY && Object.keys(taxInfoState.errors).length !== 0) {
      setShowError(true);
      return;
    }
    if (store?.isUseInvoice && emailError) {
      setShowError(true);
      return;
    }

    handleCheckOut();
  };

  const [mealPickupType, pickMealTime] = convertOrderType(orderType);

  const handleGroupOrderButtonOnClick = () => {
    navigate(`/${storeId}/group/${groupId}/groupOrder`);
  };

  if (isLargerThan768) {
    return (
      <>
        <VStack spacing={5} align="stretch">
          <OrderTypeBar mealPickupType={mealPickupType} />

          <HStack align="flex-start" spacing={4}>
            <VStack flex="1" spacing={5} align="stretch">
              {mealPickupType === MealPickUpType.PICKUP && store && (
                <PickupSettingInfoSection
                  pickMealTime={pickMealTime}
                  preOrderDate={preOrderDate}
                  prepareMinutes={store?.orderPrepareMinutes || 30}
                  allowEdit={true}
                  onSectionClick={handleOpenPickupSettingModal}
                  allowOrder={store?.allowOrders || false}
                  preorderSetting={store.preOrderSetting}
                />
              )}

              {mealPickupType === MealPickUpType.DELIVERY && store && (
                <DeliverySettingInfoSection
                  address={location.address}
                  pickMealTime={pickMealTime}
                  preOrderDate={preOrderDate}
                  prepareMinutes={store?.orderPrepareMinutes || 30}
                  allowEdit={true}
                  onSectionClick={handleOpenPickupSettingModal}
                  allowOrder={store?.allowOrders || false}
                  preorderSetting={store.preOrderSetting}
                  distanceLimit={store?.delivery.maxDistance || 0}
                />
              )}

              <Divider />

              <SectionContainer
                title={t('store.order.items')}
                icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
                rightHandComponent={
                  <Button
                    size="sm"
                    iconSpacing={1}
                    leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
                    onClick={handleGroupOrderButtonOnClick}
                  >
                    {t('store.order.group')}
                  </Button>
                }
              >
                {groupItems.length > 0 ? (
                  <VStack spacing={3} align="stretch">
                    {groupItems.map((groupItem) => {
                      return (
                        <GroupMemberProductItems
                          key={groupItem.memberId}
                          groupItem={groupItem}
                          isSelfItems={groupMemberInfo.memberId === groupItem.memberId}
                          allowEdit={true}
                          onDeleteGroupItems={handleDeleteGroupItem}
                        />
                      );
                    })}
                  </VStack>
                ) : (
                  <NoItemNotice onNavigate={() => navigate(`/${storeId}/group/${groupId}`)} />
                )}
              </SectionContainer>

              {mealPickupType === MealPickUpType.DELIVERY && (
                <DeliveryDiscountProgress
                  orderPrice={subtotal - discount}
                  costShare={store?.delivery.costShare || 0}
                  costShareLimit={store?.delivery.costShareLimit || 0}
                  freeLimit={store?.delivery.freeLimit || 0}
                  onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
                />
              )}

              <SectionContainer
                title={t('store.pickUpSetting.contactInfo')}
                icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
              >
                {auth ? (
                  <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
                ) : (
                  <LoginForm />
                )}
              </SectionContainer>

              <Divider />

              {store?.isUseInvoice ? (
                <TaxInfoWithEmailSection
                  email={email}
                  emailError={emailError}
                  taxType={taxType}
                  taxInfoState={taxInfoState}
                  showError={showError}
                  onChangeTaxType={handleChangeTaxType}
                  onChangeEmail={handleChangeEmail}
                  onChangeInvoice={handleUpdateInvoice}
                  onChangeTitle={handleUpdateTitle}
                />
              ) : (
                <TaxInfoSection
                  taxType={taxType}
                  taxInfoState={taxInfoState}
                  showError={showError}
                  onChangeTaxType={handleChangeTaxType}
                  onChangeInvoice={handleUpdateInvoice}
                  onChangeTitle={handleUpdateTitle}
                />
              )}
              <Text fontSize="xs">{t('store.order.invoice.note')}</Text>
              <Divider />
            </VStack>

            <VStack
              flex="1"
              spacing={0}
              borderColor="wsGray.400"
              borderWidth="1px"
              align="stretch"
              pos="sticky"
              top={5}
            >
              <VStack align="stretch" p={4} spacing={4}>
                <PaymentTypeSelector
                  mealPickupType={mealPickupType}
                  subtotal={subtotal}
                  paymentTypeList={paymentTypes}
                  allowOverLimitInCash={!!store?.allowOverLimitInCash}
                  selectedPaymentType={paymentType}
                  onChangePaymentType={handleUpdatePaymentType}
                />
                {couponList.length > 0 && (
                  <CouponBar
                    onClick={onCouponModalOpen}
                    availableCouponCount={coupon ? 0 : availableCouponsCount}
                    couponBarStyle={publishCoupons.length > 0 ? 'publish' : 'no-publish'}
                  />
                )}

                {coupon && (
                  <VStack align="stretch">
                    <Text fontSize="xs" fontWeight="bold">
                      {t('store.order.couponApplied')}
                    </Text>
                    <CouponInfoBar
                      coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
                      onRemoveClick={handleRemoveCoupon}
                    />
                  </VStack>
                )}

                <TotalPriceSection
                  orderType={orderType}
                  subtotal={subtotal}
                  shippingFee={shippingFee}
                  freightSubsidy={freightSubsidy}
                  total={total}
                  discount={discount}
                />
              </VStack>
              <CheckoutBar
                total={total}
                onCheckout={handleCheckoutClick}
                title={t('store.order.confirm')}
              />
            </VStack>
          </HStack>
        </VStack>

        <PickupSettingModal />

        <EditAddressModal />

        <CheckoutErrorModal
          isOpen={checkoutError.type !== CheckoutErrorType.NONE}
          message={t(checkoutError.message)}
          onClose={handleClearError}
        />

        <CheckoutCashLimitModal
          phoneNumber={store?.phoneNumber || ''}
          isOpen={checkCashOverLimitError.type !== CheckoutErrorType.NONE}
          onClose={handleClearError}
          message={t(checkCashOverLimitError.message)}
          paymentTypes={store?.paymentTypes || []}
        />
        <PickupCouponModal
          isOpen={isCouponModalOpen}
          onClose={onCouponModalClose}
          subtotal={subtotal}
          publishCoupons={publishCoupons}
          notPublishCoupons={notPublishCoupons}
          fetchingState={fetchingState}
        />

        <PaymentRedirectModal isOpen={isPaymentRedirectOpen} />

        <InvalidItemsModal
          isOpen={invalidItemsError.type !== CheckoutErrorType.NONE}
          onClose={handleClearError}
          items={invalidItemsError.items}
          groupItems={invalidItemsError.groupItems}
        />

        <LoadingModal isVisible={isLoading || status === CartStatus.ORDERING} />
      </>
    );
  }

  return (
    <>
      <VStack py={5} px={4} spacing={5} align="stretch">
        <OrderTypeBar mealPickupType={mealPickupType} />

        {mealPickupType === MealPickUpType.PICKUP && store && (
          <PickupSettingInfoSection
            pickMealTime={pickMealTime}
            preOrderDate={preOrderDate}
            prepareMinutes={store?.orderPrepareMinutes || 30}
            allowEdit={true}
            onSectionClick={handleOpenPickupSettingModal}
            allowOrder={store?.allowOrders || false}
            preorderSetting={store.preOrderSetting}
          />
        )}

        {mealPickupType === MealPickUpType.DELIVERY && store && (
          <DeliverySettingInfoSection
            address={location.address}
            pickMealTime={pickMealTime}
            preOrderDate={preOrderDate}
            prepareMinutes={store?.orderPrepareMinutes || 30}
            allowEdit={true}
            onSectionClick={handleOpenPickupSettingModal}
            allowOrder={store?.allowOrders || false}
            preorderSetting={store.preOrderSetting}
            distanceLimit={store?.delivery.maxDistance || 0}
          />
        )}

        <Divider />

        <SectionContainer
          title={t('store.order.items')}
          icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
          rightHandComponent={
            <Button
              size="sm"
              iconSpacing={1}
              leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
              onClick={handleGroupOrderButtonOnClick}
            >
              {t('store.order.group')}
            </Button>
          }
        >
          {groupItems.length > 0 ? (
            <VStack spacing={3} align="stretch">
              {groupItems.map((groupItem) => {
                return (
                  <GroupMemberProductItems
                    key={groupItem.memberId}
                    groupItem={groupItem}
                    isSelfItems={groupMemberInfo.memberId === groupItem.memberId}
                    allowEdit={true}
                    onDeleteGroupItems={handleDeleteGroupItem}
                  />
                );
              })}
            </VStack>
          ) : (
            <NoItemNotice onNavigate={() => navigate(`/${storeId}/group/${groupId}`)} />
          )}
        </SectionContainer>

        {mealPickupType === MealPickUpType.DELIVERY && (
          <DeliveryDiscountProgress
            orderPrice={subtotal - discount}
            costShare={store?.delivery.costShare || 0}
            costShareLimit={store?.delivery.costShareLimit || 0}
            freeLimit={store?.delivery.freeLimit || 0}
            onNavigate={() => navigate(`/${storeId}/group/${groupId}`)}
          />
        )}

        <SectionContainer
          title={t('store.pickUpSetting.contactInfo')}
          icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
        >
          {auth ? (
            <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
          ) : (
            <LoginForm />
          )}
        </SectionContainer>

        <Divider />

        {store?.isUseInvoice ? (
          <TaxInfoWithEmailSection
            email={email}
            emailError={emailError}
            taxType={taxType}
            taxInfoState={taxInfoState}
            showError={showError}
            onChangeTaxType={handleChangeTaxType}
            onChangeEmail={handleChangeEmail}
            onChangeInvoice={handleUpdateInvoice}
            onChangeTitle={handleUpdateTitle}
          />
        ) : (
          <TaxInfoSection
            taxType={taxType}
            taxInfoState={taxInfoState}
            showError={showError}
            onChangeTaxType={handleChangeTaxType}
            onChangeInvoice={handleUpdateInvoice}
            onChangeTitle={handleUpdateTitle}
          />
        )}
        <Divider />

        <PaymentTypeSelector
          mealPickupType={mealPickupType}
          subtotal={subtotal}
          paymentTypeList={paymentTypes}
          allowOverLimitInCash={!!store?.allowOverLimitInCash}
          selectedPaymentType={paymentType}
          onChangePaymentType={handleUpdatePaymentType}
        />
        {couponList.length > 0 && (
          <CouponBar
            onClick={onCouponModalOpen}
            availableCouponCount={coupon ? 0 : availableCouponsCount}
            couponBarStyle={publishCoupons.length > 0 ? 'publish' : 'no-publish'}
          />
        )}

        {coupon && (
          <VStack align="stretch">
            <Text fontSize="xs" fontWeight="bold">
              {t('store.order.couponApplied')}
            </Text>
            <CouponInfoBar
              coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
              onRemoveClick={handleRemoveCoupon}
            />
          </VStack>
        )}

        <TotalPriceSection
          orderType={orderType}
          subtotal={subtotal}
          shippingFee={shippingFee}
          freightSubsidy={freightSubsidy}
          total={total}
          discount={discount}
        />
      </VStack>

      <CheckoutBar
        total={total}
        onCheckout={handleCheckoutClick}
        title={t('store.order.confirm')}
      />

      <PickupSettingModal />
      <EditAddressModal />

      <PickupCouponModal
        isOpen={isCouponModalOpen}
        onClose={onCouponModalClose}
        subtotal={subtotal}
        publishCoupons={publishCoupons}
        notPublishCoupons={notPublishCoupons}
        fetchingState={fetchingState}
      />
      <CheckoutErrorModal
        isOpen={checkoutError.type !== CheckoutErrorType.NONE}
        message={t(checkoutError.message)}
        onClose={handleClearError}
      />

      <CheckoutCashLimitModal
        phoneNumber={store?.phoneNumber || ''}
        isOpen={checkCashOverLimitError.type !== CheckoutErrorType.NONE}
        onClose={handleClearError}
        message={t(checkCashOverLimitError.message)}
        paymentTypes={store?.paymentTypes || []}
      />

      <PaymentRedirectModal isOpen={isPaymentRedirectOpen} />

      <InvalidItemsModal
        isOpen={invalidItemsError.type !== CheckoutErrorType.NONE}
        onClose={handleClearError}
        items={invalidItemsError.items}
        groupItems={invalidItemsError.groupItems}
      />

      <LoadingModal isVisible={isLoading || status === CartStatus.ORDERING} />
    </>
  );
}
