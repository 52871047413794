import { HStack, Flex, Text, Checkbox, Spacer } from '@chakra-ui/react';

import formatPriceNumber from '~/utils/formatPriceNumber';

import { useTranslation } from 'react-i18next';

type ModifierOptionsSelectorItemProps = {
  optionName: string;
  price: number;
  isSoldOut: boolean;
  isChecked: boolean;
  inventory: number;
  itemCount: number;
  onChecked: () => void;
};

// 餐點單一選項，可打勾選擇及顯示附加價格
export default function ModifierOptionsSelectorItem({
  optionName,
  price,
  isSoldOut,
  isChecked = false,
  inventory,
  itemCount,
  onChecked,
}: ModifierOptionsSelectorItemProps) {
  const { t } = useTranslation();
  const isDisabled = inventory - itemCount < 0 || isSoldOut;

  return (
    <Flex
      onClick={(e) => {
        e.preventDefault();
        (!isDisabled || (isChecked && isDisabled)) && onChecked();
      }}
      justify="space-between"
      fontSize="sm"
    >
      <Checkbox isChecked={isChecked} isDisabled={isDisabled} fontSize="sm">
        <Text fontSize="sm">{optionName}</Text>
      </Checkbox>
      <Spacer />
      <HStack spacing={2}>
        {isSoldOut && (
          <Text display="inline-block" color="red">
            {t('store.item.soldOut')}
          </Text>
        )}
        {inventory !== Infinity && !isSoldOut && (
          <Text display="inline-block" color="wsGray.500">
            {t('store.item.remain')} {Math.max(inventory, 0)}
          </Text>
        )}
        {price > 0 && <Text>+{formatPriceNumber(price)}</Text>}
      </HStack>
    </Flex>
  );
}
