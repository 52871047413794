import { atom } from 'recoil';
import { Location } from '~/types';

const location = atom<Location>({
  key: 'location',
  default: {
    address: '',
    floor: '',
    note: '',
  },
});

export default location;
