import { VStack, HStack, Text } from '@chakra-ui/react';
import { Schedule, Week } from '~/types';

import { startOfToday, format, addMinutes, differenceInMinutes } from 'date-fns';

import { useTranslation } from 'react-i18next';

type OperatingTimeProps = {
  operatingTime: Schedule;
};

const displayWeek: { title: string; value: Week }[] = [
  {
    title: 'store.operating.days.sun',
    value: Week.SUN,
  },
  {
    title: 'store.operating.days.mon',
    value: Week.MON,
  },
  {
    title: 'store.operating.days.tue',
    value: Week.TUE,
  },
  {
    title: 'store.operating.days.wed',
    value: Week.WED,
  },
  {
    title: 'store.operating.days.thu',
    value: Week.THU,
  },
  {
    title: 'store.operating.days.fri',
    value: Week.FRI,
  },
  {
    title: 'store.operating.days.sat',
    value: Week.SAT,
  },
];

// 店家營業時間 （當天的營業時間會顯示為粗體）
export default function OperatingTime({ operatingTime }: OperatingTimeProps) {
  const today = new Date(); // 取得當天星期
  const todayWeek = today.getDay(); // 取得當天星期數字

  const { t } = useTranslation();

  return (
    <VStack spacing={4} align="flex-start">
      {displayWeek.map(({ value, title }, i) => (
        <HStack key={i} spacing={4} align="flex-start">
          <Text fontSize="sm" fontWeight={todayWeek == i ? 'bold' : 'normal'}>
            {t(title)}
          </Text>
          <HandleOperatingTime operatingTime={operatingTime} value={value} dayWeek={i} />
        </HStack>
      ))}
    </VStack>
  );
}

type handleOperatingTimeProps = {
  operatingTime: Schedule;
  value: Week;
  dayWeek: number;
};

export function HandleOperatingTime({ operatingTime, value, dayWeek }: handleOperatingTimeProps) {
  const { t } = useTranslation();
  const today = new Date(); // 取得當天星期
  const startTimeOfToday = startOfToday();
  const todayWeek = today.getDay(); // 取得當天星期數字
  const nowTimeMinutes = differenceInMinutes(Date.now(), startTimeOfToday); // 取得當下時間

  const scheduleTime = operatingTime[value];

  if (!scheduleTime || scheduleTime.length === 0) {
    return <Text fontSize="sm">{t('store.closed')}</Text>;
  }

  return (
    <VStack spacing={2} align="flex-start">
      {scheduleTime.map(({ open, close }) => {
        const isWithinTime =
          todayWeek == dayWeek && open < nowTimeMinutes && close > nowTimeMinutes;
        return (
          <Text fontSize="sm" fontWeight={isWithinTime ? 'bold' : 'normal'}>
            {format(addMinutes(startTimeOfToday, open), 'HH:mm')}-
            {format(addMinutes(startTimeOfToday, close), 'HH:mm')}
          </Text>
        );
      })}
    </VStack>
  );
}
