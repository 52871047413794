import { FormControl, FormLabel, HStack, Input, Icon, Text } from '@chakra-ui/react';

import FormInputMessage from '~/components/FormInputMessage';

import { BtnVariant } from '~/customTheme/components/Button';
import { useTranslation } from 'react-i18next';

type AddressInputFloorProps = {
  floor: string;
  errorMessage: string;
  onFloorChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFloorBlur: () => void;
};

// 樓層輸入
export default function AddressInputFloor({
  floor,
  errorMessage,
  onFloorChange,
  onFloorBlur,
}: AddressInputFloorProps) {
  const { t } = useTranslation();
  return (
    <>
      <FormControl id="first-name" isRequired>
        <FormLabel fontSize="sm" fontWeight="normal">
          {t('address.floor')}
        </FormLabel>
        <Input
          defaultValue={floor}
          value={floor}
          borderColor={errorMessage ? 'wsRed.600' : 'wsGray.400'}
          onChange={onFloorChange}
          onBlur={onFloorBlur}
        />
      </FormControl>
      {errorMessage && <FormInputMessage type="error" message={t(errorMessage)} />}
    </>
  );
}
