import { firestore } from '~/firebase';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import taxTypeAtom from '~/recoil/atom/taxType';
import { TaxType } from '~/types';

export default function useTaxType() {
  const { storeId, groupId } = useParams();
  const auth = useRecoilValue(authAtom);
  const [taxType, setTaxType] = useRecoilState(taxTypeAtom);

  const handleChangeTaxType = async (taxType: TaxType) => {
    if (!auth) {
      setTaxType(taxType);
      return;
    }

    if (storeId && groupId) {
      try {
        await firestore.doc(`stores/${storeId}/carts/${groupId}`).update('taxType', taxType);
      } catch (err) {
        console.error('change taxType error', err);
      }
      return;
    }

    if (storeId) {
      try {
        await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).update('taxType', taxType);
        setTaxType(taxType);
      } catch (err) {
        console.error('change taxType error', err);
      }
    }
  };

  return { taxType, handleChangeTaxType };
}
