import { useRecoilState } from 'recoil';

import modalControlAtom from '~/recoil/atom/modalControl';

import ModalControlType from '~/recoil/atom/modalControl';

export default function useModalControl() {
  const [modalControl, setModalControl] = useRecoilState(modalControlAtom);

  const changeModalOpen = (modal: string, isOpen: boolean) => {
    setModalControl((state) => {
      return {
        ...state,
        [modal]: isOpen,
      };
    });
  };

  return {
    modalControl,
    changeModalOpen,
  };
}
