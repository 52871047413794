import { useState, useEffect } from 'react';

import useOrderSetting from '~/hooks/useOrderSetting';

import { MealPickUpType } from '~/types';

export default function usePickupType() {
  const { mealOrderSetting } = useOrderSetting();

  const [mealPickupType, setMealPickType] = useState<MealPickUpType>(
    mealOrderSetting.mealPickupType,
  );

  useEffect(() => {
    const { mealPickupType } = mealOrderSetting;
    handleMealPickupTypeChange(mealPickupType);
  }, [mealOrderSetting]);

  const handleMealPickupTypeChange = (type: MealPickUpType) => {
    setMealPickType(type);
  };

  return {
    mealPickupType,
    handleMealPickupTypeChange,
  };
}
