import { VStack, HStack, Text, Icon, Heading } from '@chakra-ui/react';

import { IoInformationCircleOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

export default function LargeOrderNotice() {
  const { t } = useTranslation();
  return (
    <VStack px={2} py={4} align="flex-start" borderRadius="5px" bgColor="wsGray.300">
      <HStack align="center" spacing={1}>
        <Icon as={IoInformationCircleOutline} boxSize="1.5rem" />
        <Heading size="sm">{t('store.order.largeOrderTitle')}</Heading>
      </HStack>
      <Text fontSize="xs">{t('store.order.largeOrderNote')}</Text>
    </VStack>
  );
}
