import { HStack, Text, Button } from '@chakra-ui/react';
import formatPriceNumber from '~/utils/formatPriceNumber';

// 結帳按鈕
export default function CheckOutBar({
  total,
  title,
  onCheckout,
}: {
  total: number;
  title: string;
  onCheckout?: () => void;
}) {
  return (
    <HStack
      position="sticky"
      bottom={0}
      justify="space-between"
      px={4}
      py={4}
      bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
    >
      <Text color="white" fontWeight="bold">
        {formatPriceNumber(total)}
      </Text>
      <Button variant="unstyled" color="white" onClick={onCheckout} letterSpacing={1}>
        {title}
      </Button>
    </HStack>
  );
}
