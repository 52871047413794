import firebase from 'firebase';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateCartData, updateGroupCart } from '~/firebase/updateData';

import { useToast } from '@chakra-ui/react';

import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import couponAtom from '~/recoil/atom/coupon';
import authAtom from '~/recoil/atom/auth';
import cartSettlementSelector from '~/recoil/selectors/cartSettlement';
import groupCartSettlementSelector from '~/recoil/selectors/groupCartSettlement';
import isHostSelector from '~/recoil/selectors/isHost';
import { useRecoilState, useRecoilValue } from 'recoil';

import checkCouponValid from '~/utils/checkCouponValid';
import { Coupon, FirebaseCoupon } from '~/types';

export default function useCoupon() {
  const { t } = useTranslation();
  const toast = useToast();

  const { storeId = '', groupId } = useParams();

  const [coupon, setCoupon] = useRecoilState(couponAtom);
  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const cartSettlement = useRecoilValue(cartSettlementSelector);
  const groupCartSettlement = useRecoilValue(groupCartSettlementSelector);
  const auth = useRecoilValue(authAtom);
  const isHost = useRecoilValue(isHostSelector);

  const checkSettlement = groupId ? groupCartSettlement : cartSettlement;

  const handleSelectCoupon = async (coupon: Coupon): Promise<boolean> => {
    if (
      !checkCouponValid(coupon, checkSettlement.subtotal, {
        mealPickupType,
        pickMealTime,
        preOrderDate,
      })
    ) {
      toast({
        title: t('coupon.couponNotApplicable'),
        position: 'top',
        status: 'error',
      });
      return false;
    }

    const updateCoupon: FirebaseCoupon = {
      ...coupon,
      startDate: firebase.firestore.Timestamp.fromDate(coupon.startDate),
      endDate: firebase.firestore.Timestamp.fromDate(coupon.endDate),
    };

    if (!auth) {
      setCoupon(coupon);
      return true;
    }

    if (groupId && isHost) {
      try {
        await updateGroupCart(storeId, groupId, {
          coupon: updateCoupon,
        });
        return true;
      } catch (err) {
        console.error('update group cart coupon error', err);
        return false;
      }
    }

    try {
      await updateCartData(storeId, auth, {
        coupon: updateCoupon,
      });
      return true;
    } catch (err) {
      console.error('update user cart coupon error', err);
      return false;
    }
  };

  const handleRemoveCoupon = async (): Promise<boolean> => {
    if (!auth || !storeId) {
      setCoupon(null);
      return false;
    }

    if (groupId && isHost) {
      try {
        await updateGroupCart(storeId, groupId, {
          coupon: null,
        });
        return true;
      } catch (err) {
        console.error('remove group cart coupon error', err);
        return false;
      }
    }

    try {
      await updateCartData(storeId, auth, {
        coupon: null,
      });
      return true;
    } catch (err) {
      console.error('remove user cart coupon error', err);
      return false;
    }
  };

  return {
    coupon,
    handleSelectCoupon,
    handleRemoveCoupon,
  };
}
