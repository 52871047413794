import firebase from 'firebase';
import { useEffect } from 'react';
import { authListener } from '~/firebase/listener';
import { useSetRecoilState, useRecoilState } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';

import uidAtom from '~/recoil/atom/uid';
import locationAtom from '~/recoil/atom/location';
import couponListAtom from '~/recoil/atom/couponList';

export default function useAuth() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const setIsGotInitData = useSetRecoilState(isGotInitDataAtom);

  const setUid = useSetRecoilState(uidAtom);
  const setLocation = useSetRecoilState(locationAtom);
  const setCouponList = useSetRecoilState(couponListAtom);

  const handleUpdate = (auth: firebase.User | null) => {
    setIsGotInitData((prev) => ({ ...prev, auth: true }));
    setAuth(auth);
  };

  useEffect(() => {
    const authUnlisten = authListener(handleUpdate);

    return () => {
      authUnlisten?.();
    };
  }, []);

  useEffect(() => {
    if (!auth) {
      setUid('');
      setLocation({
        address: '',
        floor: '',
        note: '',
      });
      setCouponList([]);
    }
  }, [auth]);
}
