import {
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  Text,
  HStack,
  ModalHeader,
} from '@chakra-ui/react';
import { css, keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';

type PaymentRedirectModalProps = {
  isOpen: boolean;
};

export default function PaymentRedirectModal({ isOpen }: PaymentRedirectModalProps) {
  const { t } = useTranslation();
  const extend = keyframes`
    0%{
      width: 0rem;
    }
    100%{
      width: 3rem;
    }
  `;

  const loadingText = css`
    animation: ${extend} 3s steps(4, end) infinite;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  `;

  return (
    <Modal size="xs" isOpen={isOpen} onClose={() => {}} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent p={3}>
        <ModalHeader textAlign="center" w="full" pb={0} color="wsBlack">
          {t('payment.redirectModal.title')}
          <HStack css={loadingText} border="1px solid #fff">
            <Text w="2rem" color="wsBlack" textAlign="center" letterSpacing=".2em">
              ...
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody px={5} pt={2} pb={5}>
          <Text textAlign="center" color="#C8161D">
            {t('payment.redirectModal.content')}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
