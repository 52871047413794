import { selector } from 'recoil';

import customerAtom from '~/recoil/atom/customer';

import { Location } from '~/types';

const userLocation = selector<Array<Location> | null>({
  key: 'userLocation',
  get: ({ get }) => {
    const customer = get(customerAtom);
    const userLocation = customer?.recentAddress || null;

    return userLocation;
  },
});

export default userLocation;
