import { HStack, Text, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export type SectionLabelProps = {
  icon: IconType;
  content?: string;
};

// 區段標題：不同區段的內容可以用props傳不同的icon跟內文
export default function SectionLabel({ icon, content }: SectionLabelProps) {
  return (
    <HStack>
      <Icon as={icon} boxSize="1.25rem" />
      <Text fontSize="sm" letterSpacing={2}>
        {content ? content : '-'}
      </Text>
    </HStack>
  );
}
