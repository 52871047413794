import { useMemo } from 'react';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import { useRecoilValue } from 'recoil';
import { CartItem, Inventory, PickMealTime, ReservedResetTimeBase } from '~/types';
import calcRemainInventory from '~/utils/calcRemainInventory';
import getSameItemCount from '~/utils/getSameItemCount';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

export default function useRemainInventory(
  inventory: Inventory = {
    defaultInventory: Infinity,
    currentInventory: Infinity,
  },
  itemId: string,
  items: Array<CartItem>,
) {
  const store = useRecoilValue(storeLocaleSelector);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);

  const remainInventory = useMemo(() => {
    if (!store) {
      return Infinity;
    }

    const checkDate =
      pickMealTime === PickMealTime.RESERVATION ? preOrderDate || new Date() : new Date();

    return calcRemainInventory(
      inventory,
      !!store.inventorySetting.allowReserved,
      new Date(),
      checkDate,
      store.inventorySetting.allowResetTime
        ? store.inventorySetting.resetTime
        : ReservedResetTimeBase,
    );
  }, [store, preOrderDate, pickMealTime, inventory]);

  const targetItemCount = useMemo(() => {
    return getSameItemCount(items, itemId);
  }, [itemId, items]);

  return { remainInventory, targetItemCount };
}
