import { useState } from 'react';
import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  Heading,
  Divider,
  Box,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Circle,
  Icon,
  Flex,
  StackDivider,
  FormErrorMessage,
  Spacer,
} from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';

import authAtom from '~/recoil/atom/auth';
import groupItemsAtom from '~/recoil/atom/groupItems';
import groupMemberInfoAtom from '~/recoil/atom/groupMemberInfo';
import uidAtom from '~/recoil/atom/uid';
import groupHostInfoAtom from '~/recoil/atom/groupHostInfo';

import { useRecoilValue, useRecoilState } from 'recoil';

import { v4 as uuidV4 } from 'uuid';

import { IoMailOpenOutline, IoTriangleSharp } from 'react-icons/io5';

import { GroupCartItem } from '~/types';

import LocaleSelect from '~/components/LocaleSelect';

import { updateGroupCart } from '~/firebase/updateData';
import { useTranslation } from 'react-i18next';

function replaceMemberName(
  groupItems: Array<GroupCartItem>,
  uid: string,
  newMemberName: string,
): Array<GroupCartItem> {
  if (!groupItems.some(({ memberId }) => memberId === uid)) {
    return groupItems;
  }

  return groupItems.map((groupItem) => {
    if (uid === groupItem.memberId) {
      return {
        ...groupItem,
        memberName: newMemberName,
      };
    }
    return groupItem;
  });
}

type GroupOrderUnfinishedModalProps = {
  storeName: string;
  isOpen: boolean;
  defaultMemberName: string;
  onClose: () => void;
};

// 揪團時間尚未結束時，剛進到點餐網站時跳出，包括團員選擇是否參加團購以及輸入暱稱
export default function JoinGroupOrderModal({
  storeName,
  isOpen,
  onClose,
  defaultMemberName,
}: GroupOrderUnfinishedModalProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const auth = useRecoilValue(authAtom);
  const uid = useRecoilValue(uidAtom);
  const groupItems = useRecoilValue(groupItemsAtom);
  const groupHostInfo = useRecoilValue(groupHostInfoAtom);
  const [groupMemberInfo, setGroupMemberInfo] = useRecoilState(groupMemberInfoAtom);

  const [memberName, setMemberName] = useState(defaultMemberName);
  const [memberNameError, setMemberNameError] = useState<string>('');

  const handleChangeMemberName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberName(e.target.value);
    setMemberNameError('');
  };

  const handleLeaveGroup = () => {
    onClose();
    navigate(`/${storeId}`);
  };

  const handleJoinGroup = async () => {
    if (memberName.length < 2) {
      setMemberNameError(t('group.warnName'));
      return;
    }

    if (auth && storeId && groupId) {
      try {
        const updateGroupItems = replaceMemberName(groupItems, auth.uid, memberName);
        await updateGroupCart(storeId, groupId, { groupItems: updateGroupItems });
        setGroupMemberInfo({
          memberId: auth.uid,
          memberName,
        });
        onClose();
      } catch (err) {
        console.error('update cart error', err);
      }

      return;
    }

    if (groupMemberInfo.memberId !== '' && storeId && groupId) {
      try {
        const updateGroupItems = replaceMemberName(
          groupItems,
          groupMemberInfo.memberId,
          memberName,
        );
        await updateGroupCart(storeId, groupId, { groupItems: updateGroupItems });

        window.localStorage.setItem('groupMemberName', memberName);
        setGroupMemberInfo({
          memberId: groupMemberInfo.memberId,
          memberName,
        });
        onClose();
      } catch (err) {
        console.error('update cart error', err);
      }
      return;
    }

    // is first join, create uuid
    const newUUID = uuidV4();

    window.localStorage.setItem('groupMemberId', newUUID);
    window.localStorage.setItem('groupMemberName', memberName);

    setGroupMemberInfo({
      memberId: newUUID,
      memberName,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} size="md" isCentered>
      <ModalOverlay />
      <ModalContent m={0}>
        <ModalBody>
          <VStack align="stretch" px={{ base: 2, md: 2 }} spacing={4} mb={4}>
            <Center>
              <VStack spacing={4} align="center" justify="space-between">
                <HStack alignSelf="stretch">
                  <Spacer flex={1} />
                  <Circle size="3rem" bg="wsGray.200" mt={4}>
                    <Icon boxSize="2rem" as={IoMailOpenOutline} />
                  </Circle>
                  <Flex flex={1} justifyContent="flex-end">
                    <LocaleSelect />
                  </Flex>
                </HStack>
                <Heading fontSize="md">{t('group.welcome', { storeName })}</Heading>
                <Text fontSize="sm">{t('group.invite', { hostName: groupHostInfo.name })}</Text>
              </VStack>
            </Center>
            <Divider />
            <VStack align="stretch" spacing={2}>
              {groupHostInfo.note && (
                <>
                  <Text fontSize="sm" fontWeight="bold">
                    {t('group.note')}
                  </Text>
                  <Flex direction="column">
                    <Icon
                      ml={6}
                      my={-1}
                      as={IoTriangleSharp}
                      color="wsGray.300"
                      boxSize="0.75rem"
                    />
                    <Box w="full" px={3} py={2} borderRadius="5px" backgroundColor="wsGray.300">
                      <Text fontSize="xs">{groupHostInfo.note}</Text>
                    </Box>
                  </Flex>
                </>
              )}

              <FormControl id="first-name" isRequired isInvalid={!!memberNameError}>
                <FormLabel fontSize="sm">{t('group.enterName')}</FormLabel>
                <Input
                  width="inherit"
                  size="md"
                  value={memberName}
                  onChange={handleChangeMemberName}
                />
                <FormErrorMessage>{memberNameError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter
          borderTopWidth="1px"
          borderTopColor="admin.gray.300"
          justifyContent="center"
          p={0}
        >
          <HStack align="stretch" w="full" spacing={0} divider={<StackDivider />}>
            <Box as="button" flex="1" p={3} onClick={handleLeaveGroup}>
              <Text fontSize="sm">{t('group.exit')}</Text>
            </Box>
            <Box as="button" flex="1" p={3} onClick={handleJoinGroup}>
              <Text fontSize="sm">{t('group.join')}</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
