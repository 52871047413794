import axios from 'axios';
import isEmpty from 'lodash/fp/isEmpty';
import { Locales } from '~/types';

const googleAutocompleteAsync = async (addressRequest: {
  input: string;
  storeLocation: {
    geopoint: {
      latitude: number;
      longitude: number;
    };
  };
  locale: Locales;
}) => {
  const {
    input,
    storeLocation: {
      geopoint: { latitude, longitude },
    },
    locale,
  } = addressRequest;

  const urlForAutocomplete = process.env.REACT_APP_AUTOCOMPLETE_URL || '';
  if (isEmpty(urlForAutocomplete)) {
    throw new Error('Google Autocomplete API url is missing');
  }

  try {
    const response = await axios.post(urlForAutocomplete, {
      input,
      origin: {
        geopoint: {
          latitude,
          longitude,
        },
      },
      locale,
    });
    const autocompleteResponse = response.data;

    let addressList = new Array<{
      key: string;
      description: string;
      distance: number;
    }>();

    const predictionSliced =
      autocompleteResponse.predictions.length <= 2
        ? autocompleteResponse.predictions
        : autocompleteResponse.predictions.slice(0, 5);

    predictionSliced.forEach(
      (prediction: { place_id: string; description: string; distance_meters: number }) => {
        addressList.push({
          key: prediction.place_id,
          description: prediction.description,
          distance: prediction.distance_meters / 1000,
        });
      },
    );

    return addressList;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export default googleAutocompleteAsync;
