import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum InputVariants {
  OUTLINE = 'outline',
}

const Input: ComponentSingleStyleConfig = {
  baseStyle: {
    borderColor: 'wsGray.400',
    focusBorderColor: 'wsBlack',
  },

  variants: {
    outline: {
      focusBorderColor: 'wsBlack',
      field: {
        borderColor: 'wsGray.400',
        _hover: {
          borderColor: 'wsGray.400',
        },
      },
    },
  },

  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'wsBlack',
  
  },
};

export default Input;
