import { useState, useMemo } from 'react';
import { VStack, Divider, Icon, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';

import PageContainer from '~/container/PageContainer';
import SectionContainer from '~/container/SectionContainer';
import PageTitle from '~/components/PageTitle';
import {
  DeliverySettingInfoSection,
  PickupSettingInfoSection,
  ItemList,
  DeliveryDiscountProgress,
  PaymentTypeSelector,
  ContactInfo,
  CouponBar,
  CheckoutBar,
  TaxRadioGroup,
  TaxInfoForm,
  TaxInfoWithEmailSection,
  TaxInfoSection,
  OrderTypeBar,
  LargeOrderNotice,
  CheckErrorModal,
  NoItemNotice,
  InvalidItemsModal,
  PaymentRedirectModal,
  CouponInfoBar,
  CheckoutCashLimitModal,
} from './components';
import PickupCouponModal from '~/components/PickupCouponModal';
import TotalPriceSection from '~/components/TotalPriceSection';
import LoginForm from '~/components/LoginForm';
import LoadingModal from '~/components/LoadingModal';
import PickupSettingModal from '~/components/PickupSettingModal';
import EditAddressModal from '~/components/EditAddressModal';

// import TaxRadioGroup from '~/components/TaxRadioGroup';

import cartSelector from '~/recoil/selectors/cart';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import modalControlAtom from '~/recoil/atom/modalControl';
import cartSettlementSelector from '~/recoil/selectors/cartSettlement';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  IoPersonOutline,
  IoCashOutline,
  IoRestaurantOutline,
  IoPeopleOutline,
} from 'react-icons/io5';

import { CartStatus, MealPickUpType, PickMealTime, TaxType } from '~/types';

import useTaxType from './hooks/useTaxType';
import usePaymentType from './hooks/usePaymentType';
import useTaxInfo from './hooks/useTaxInfo';
import useEmail from './hooks/useEmail';
import useCheckout, { CheckoutErrorType } from './hooks/useCheckout';
import useCouponList from '~/hooks/useCouponList';
import useCouponClear from '~/hooks/useCouponClear';
import useCoupon from '~/hooks/useCoupon';

import convertOrderType from '~/utils/convertOrderType';
import getCouponCostLimit from '~/utils/getCouponCostLimit';

import { Coupon } from '~/types';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

import { useTranslation } from 'react-i18next';

export default function Cart() {
  const [showError, setShowError] = useState(false);
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const setModalControl = useSetRecoilState(modalControlAtom);
  const {
    isOpen: isCouponModalOpen,
    onClose: onCouponModalClose,
    onOpen: onCouponModalOpen,
  } = useDisclosure();

  const { storeId = '' } = useParams();
  const navigate = useNavigate();

  const store = useRecoilValue(storeLocaleSelector);
  const auth = useRecoilValue(authAtom);
  const cart = useRecoilValue(cartSelector);

  const { couponList, fetchingState, handleFetchCouponList } = useCouponList();
  const { handleRemoveCoupon } = useCoupon();
  // control taxType
  const { email, emailError, handleChangeEmail } = useEmail();
  const { handleChangeTaxType } = useTaxType();
  const { taxInfoState, handleUpdateInvoice, handleUpdateTitle } = useTaxInfo();
  // control paymentType
  const { paymentTypes, handleUpdatePaymentType } = usePaymentType();
  const { subtotal, shippingFee, freightSubsidy, total, discount } =
    useRecoilValue(cartSettlementSelector);

  // auto remove invalid coupon on firestore
  useCouponClear(subtotal);

  const { t } = useTranslation();

  const {
    handleCheckOut,
    invalidItemsError,
    checkCashOverLimitError,
    checkoutError,
    isLoading,
    isPaymentRedirectOpen,
    handleClearError,
  } = useCheckout();

  const handleOpenPickupSettingModal = () => {
    setModalControl((prev) => ({ ...prev, isPickupSettingOpen: true }));
  };

  const handleCheckoutClick = () => {
    if (cart.taxType === TaxType.COMPANY && Object.keys(taxInfoState.errors).length !== 0) {
      setShowError(true);
      return;
    }
    if (store?.isUseInvoice && emailError) {
      setShowError(true);
      return;
    }
    handleCheckOut();
  };

  const { paymentType, taxType, orderType, location, preOrderDate, items, status, coupon } = cart;

  const [mealPickupType, pickMealTime] = convertOrderType(orderType);

  const availableCouponsCount = useMemo(() => {
    return couponList.filter(
      (coupon) =>
        coupon.allowDisplay &&
        subtotal >= getCouponCostLimit(coupon) &&
        coupon.allowOrderTypes.includes(orderType),
    ).length;
  }, [couponList, subtotal, orderType]);

  const { publishCoupons, notPublishCoupons } = useMemo(() => {
    return couponList.reduce<{ publishCoupons: Array<Coupon>; notPublishCoupons: Array<Coupon> }>(
      (acc, coupon) => {
        if (coupon.allowDisplay) {
          acc.publishCoupons.push(coupon);
        } else {
          acc.notPublishCoupons.push(coupon);
        }

        return acc;
      },
      { publishCoupons: [], notPublishCoupons: [] },
    );
  }, [couponList]);

  return (
    <PageContainer
      hideHeader
      hideFooter
      contentStyle={{
        opacity: isPaymentRedirectOpen || isLoading || status === CartStatus.ORDERING ? 0 : 1,
      }}
    >
      <PageTitle title={t('store.order.title')} onNavigate={() => navigate(`/${storeId}`)} />
      {isLargerThan768 ? (
        <VStack px={4} spacing={5} align="stretch">
          <OrderTypeBar mealPickupType={mealPickupType} />

          <HStack align="flex-start" spacing={4}>
            <VStack flex="1" spacing={5} align="stretch">
              {mealPickupType === MealPickUpType.PICKUP && store && (
                <PickupSettingInfoSection
                  pickMealTime={pickMealTime}
                  preOrderDate={preOrderDate}
                  prepareMinutes={store?.orderPrepareMinutes || 30}
                  allowEdit={true}
                  onSectionClick={handleOpenPickupSettingModal}
                  allowOrder={store?.allowOrders || false}
                  preorderSetting={store.preOrderSetting}
                />
              )}
              {mealPickupType === MealPickUpType.DELIVERY && store && (
                <DeliverySettingInfoSection
                  address={location.address}
                  pickMealTime={pickMealTime}
                  preOrderDate={preOrderDate}
                  prepareMinutes={store?.orderPrepareMinutes || 30}
                  allowEdit={true}
                  onSectionClick={handleOpenPickupSettingModal}
                  allowOrder={store.allowOrders || false}
                  preorderSetting={store.preOrderSetting}
                  distanceLimit={store?.delivery.maxDistance || 0}
                />
              )}

              {pickMealTime === PickMealTime.IMMEDIATELY && (
                <LargeOrderNotice largeOrderSetting={store?.largeOrderSetting} />
              )}

              <Divider />

              <SectionContainer
                title={t('store.order.items')}
                icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
                rightHandComponent={
                  <Button
                    size="sm"
                    iconSpacing={1}
                    leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
                    onClick={() => navigate(`/${storeId}/groupOrder`)}
                  >
                    {t('store.order.group')}
                  </Button>
                }
              >
                {items.length > 0 ? (
                  <ItemList items={items} allowEdit={true} />
                ) : (
                  <NoItemNotice onNavigate={() => navigate(`/${storeId}`)} />
                )}
              </SectionContainer>

              {mealPickupType === MealPickUpType.DELIVERY && (
                <DeliveryDiscountProgress
                  orderPrice={subtotal - discount}
                  costShare={store?.delivery.costShare || 0}
                  costShareLimit={store?.delivery.costShareLimit || 0}
                  freeLimit={store?.delivery.freeLimit || 0}
                  onNavigate={() => navigate(`/${storeId}`)}
                />
              )}

              <SectionContainer
                title={t('store.pickUpSetting.contactInfo')}
                icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
              >
                {auth ? (
                  <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
                ) : (
                  <LoginForm />
                )}
              </SectionContainer>

              <Divider />

              {store?.isUseInvoice ? (
                <TaxInfoWithEmailSection
                  email={email}
                  emailError={emailError}
                  taxType={taxType}
                  taxInfoState={taxInfoState}
                  showError={showError}
                  onChangeTaxType={handleChangeTaxType}
                  onChangeEmail={handleChangeEmail}
                  onChangeInvoice={handleUpdateInvoice}
                  onChangeTitle={handleUpdateTitle}
                />
              ) : (
                <TaxInfoSection
                  taxType={taxType}
                  taxInfoState={taxInfoState}
                  showError={showError}
                  onChangeTaxType={handleChangeTaxType}
                  onChangeInvoice={handleUpdateInvoice}
                  onChangeTitle={handleUpdateTitle}
                />
              )}
              <Divider />
            </VStack>

            <VStack
              flex="1"
              spacing={0}
              borderColor="wsGray.400"
              borderWidth="1px"
              align="stretch"
              pos="sticky"
              top={5}
            >
              <VStack align="stretch" p={4} spacing={4}>
                <SectionContainer
                  icon={<Icon as={IoCashOutline} boxSize="1.5rem" />}
                  title={t('store.order.paymentMethod')}
                >
                  <PaymentTypeSelector
                    mealPickupType={mealPickupType}
                    subtotal={subtotal}
                    selectedPaymentType={paymentType}
                    paymentTypeList={paymentTypes}
                    allowOverLimitInCash={!!store?.allowOverLimitInCash}
                    onChangePaymentType={handleUpdatePaymentType}
                  />
                </SectionContainer>
                {auth && couponList.length > 0 && (
                  <CouponBar
                    onClick={() => {
                      handleFetchCouponList(storeId, auth);
                      onCouponModalOpen();
                    }}
                    availableCouponCount={coupon ? 0 : availableCouponsCount}
                    couponBarStyle={publishCoupons.length > 0 ? 'publish' : 'no-publish'}
                  />
                )}

                {coupon && (
                  <VStack align="stretch">
                    <Text fontSize="xs" fontWeight="bold">
                      {t('store.order.couponApplied')}
                    </Text>
                    <CouponInfoBar
                      coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
                      onRemoveClick={handleRemoveCoupon}
                    />
                  </VStack>
                )}

                <TotalPriceSection
                  orderType={orderType}
                  subtotal={subtotal}
                  shippingFee={shippingFee}
                  freightSubsidy={freightSubsidy}
                  total={total}
                  discount={discount}
                />
              </VStack>
              <CheckoutBar
                total={total}
                onCheckout={handleCheckoutClick}
                title={t('store.order.confirm')}
              />
            </VStack>
          </HStack>
        </VStack>
      ) : (
        <>
          <VStack py={5} px={4} spacing={5} align="stretch">
            <OrderTypeBar mealPickupType={mealPickupType} />

            {mealPickupType === MealPickUpType.PICKUP && store && (
              <PickupSettingInfoSection
                pickMealTime={pickMealTime}
                preOrderDate={preOrderDate}
                prepareMinutes={store?.orderPrepareMinutes || 30}
                allowEdit={true}
                onSectionClick={handleOpenPickupSettingModal}
                allowOrder={store?.allowOrders || false}
                preorderSetting={store.preOrderSetting}
              />
            )}
            {mealPickupType === MealPickUpType.DELIVERY && store && (
              <DeliverySettingInfoSection
                address={location.address}
                pickMealTime={pickMealTime}
                preOrderDate={preOrderDate}
                prepareMinutes={store?.orderPrepareMinutes || 30}
                allowEdit={true}
                onSectionClick={handleOpenPickupSettingModal}
                allowOrder={store?.allowOrders || false}
                preorderSetting={store?.preOrderSetting}
                distanceLimit={store?.delivery.maxDistance || 0}
              />
            )}

            <Divider />

            <SectionContainer
              title={t('store.order.items')}
              icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
              rightHandComponent={
                <Button
                  size="sm"
                  iconSpacing={1}
                  leftIcon={<Icon as={IoPeopleOutline} boxSize="1.2rem" />}
                  onClick={() => navigate(`/${storeId}/groupOrder`)}
                >
                  {t('store.order.group')}
                </Button>
              }
            >
              {items.length > 0 ? (
                <ItemList items={items} allowEdit={true} />
              ) : (
                <NoItemNotice onNavigate={() => navigate(`/${storeId}`)} />
              )}
            </SectionContainer>

            {mealPickupType === MealPickUpType.DELIVERY && (
              <DeliveryDiscountProgress
                orderPrice={subtotal - discount}
                costShare={store?.delivery.costShare || 0}
                costShareLimit={store?.delivery.costShareLimit || 0}
                freeLimit={store?.delivery.freeLimit || 0}
                onNavigate={() => navigate(`/${storeId}`)}
              />
            )}

            <SectionContainer
              title={t('store.pickUpSetting.contactInfo')}
              icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
            >
              {auth ? (
                <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
              ) : (
                <LoginForm />
              )}
            </SectionContainer>

            <Divider />
            {store?.isUseInvoice ? (
              <TaxInfoWithEmailSection
                email={email}
                emailError={emailError}
                taxType={taxType}
                taxInfoState={taxInfoState}
                showError={showError}
                onChangeTaxType={handleChangeTaxType}
                onChangeEmail={handleChangeEmail}
                onChangeInvoice={handleUpdateInvoice}
                onChangeTitle={handleUpdateTitle}
              />
            ) : (
              <TaxInfoSection
                taxType={taxType}
                taxInfoState={taxInfoState}
                showError={showError}
                onChangeTaxType={handleChangeTaxType}
                onChangeInvoice={handleUpdateInvoice}
                onChangeTitle={handleUpdateTitle}
              />
            )}
            <Divider />

            <SectionContainer
              icon={<Icon as={IoCashOutline} boxSize="1.5rem" />}
              title={t('store.order.paymentMethod')}
            >
              <PaymentTypeSelector
                mealPickupType={mealPickupType}
                subtotal={subtotal}
                selectedPaymentType={paymentType}
                paymentTypeList={paymentTypes}
                allowOverLimitInCash={!!store?.allowOverLimitInCash}
                onChangePaymentType={handleUpdatePaymentType}
              />
            </SectionContainer>

            {auth && couponList.length > 0 && (
              <CouponBar
                onClick={() => {
                  handleFetchCouponList(storeId, auth);
                  onCouponModalOpen();
                }}
                availableCouponCount={coupon ? 0 : availableCouponsCount}
                couponBarStyle={publishCoupons.length > 0 ? 'publish' : 'no-publish'}
              />
            )}

            {coupon && (
              <VStack align="stretch">
                <Text fontSize="xs" fontWeight="bold">
                  {t('store.order.couponApplied')}
                </Text>
                <CouponInfoBar
                  coupon={{ type: coupon.rewardType, condition: coupon.rewardCondition }}
                  onRemoveClick={handleRemoveCoupon}
                />
              </VStack>
            )}

            <Divider />

            <TotalPriceSection
              orderType={orderType}
              subtotal={subtotal}
              shippingFee={shippingFee}
              freightSubsidy={freightSubsidy}
              total={total}
              discount={discount}
            />
          </VStack>
          <CheckoutBar
            total={total}
            title={t('store.order.confirm')}
            onCheckout={handleCheckoutClick}
          />
        </>
      )}

      <PickupSettingModal />
      <PickupCouponModal
        isOpen={isCouponModalOpen}
        onClose={onCouponModalClose}
        subtotal={subtotal}
        publishCoupons={publishCoupons}
        notPublishCoupons={notPublishCoupons}
        fetchingState={fetchingState}
      />
      <CheckErrorModal
        isOpen={checkoutError.type !== CheckoutErrorType.NONE}
        message={t(checkoutError.message)}
        onClose={handleClearError}
      />

      <CheckoutCashLimitModal
        phoneNumber={store?.phoneNumber || ''}
        isOpen={checkCashOverLimitError.type !== CheckoutErrorType.NONE}
        onClose={handleClearError}
        message={t(checkCashOverLimitError.message)}
        paymentTypes={store?.paymentTypes || []}
      />

      <EditAddressModal />

      <PaymentRedirectModal isOpen={isPaymentRedirectOpen} />

      <InvalidItemsModal
        isOpen={invalidItemsError.type !== CheckoutErrorType.NONE}
        items={invalidItemsError.items}
        groupItems={invalidItemsError.groupItems}
        onClose={handleClearError}
      />

      <LoadingModal isVisible={isLoading || status === CartStatus.ORDERING} />
    </PageContainer>
  );
}
