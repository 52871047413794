import { Select } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import localeAtom from '~/recoil/atom/locale';

import { Locales } from '~/types';

export default function LocaleSelect() {
  const [locale, setLocale] = useRecoilState(localeAtom);

  return (
    <Select
      borderColor="transparent"
      focusBorderColor="transparent"
      _hover={{ borderColor: 'transparent' }}
      value={locale}
      maxW={40}
      onChange={(event) => setLocale(event.target.value as Locales)}
      dir="rtl"
      pr="4"
    >
      <option value={Locales.en_US}>English</option>
      <option value={Locales.zh_TW}>中文</option>
    </Select>
  );
}
