import { Modifier, Option } from '~/types';
import { OrderOptionWithCount } from '~/recoil/selectors/cartItemsOptionsMap';

import fp from 'lodash/fp';

export default function getAvailableOptionsMap(
  modifiers: Record<string, Modifier>,
  cartItemsOptionsMap: Map<string, OrderOptionWithCount>,
): Map<string, Option> {
  const uniqModifierIds = fp.uniq(
    [...cartItemsOptionsMap.values()].map(({ modifierId }) => modifierId),
  );

  return uniqModifierIds.reduce<Map<string, Option>>((acc, modifierId) => {
    const modifier = modifiers[modifierId];

    if (modifier) {
      modifier.options.forEach((option) => {
        acc.set(`${modifierId}_${option.id}`, option);
      });
    }

    return acc;
  }, new Map());
}
