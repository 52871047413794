import { Heading, Center } from '@chakra-ui/react';

export type TitleProps = {
  title: string;
};

// modal title
export default function Title({ title }: TitleProps) {
  return (
    <Center>
      <Heading fontSize="xl" letterSpacing={2}>
        {title}
      </Heading>
    </Center>
  );
}
