import { useEffect } from 'react';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
import { useRecoilValue } from 'recoil';

import { Navigate, Outlet, useParams } from 'react-router-dom';

import LoadingModal from '~/components/LoadingModal';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';

// NOTE: add store id and domain map here
const storeDomainMap: Record<string, string> = {
  fpBROOlpjZyfF1F9TSOd: 'www.theslice.shop',
};

export default function RequireGlobalMiddleware() {
  const store = useRecoilValue(storeLocaleSelector);
  const isGotInitData = useRecoilValue(isGotInitDataAtom);

  const { storeId = '' } = useParams();

  useEffect(() => {
    if (storeDomainMap[storeId] && window.location.host !== storeDomainMap[storeId]) {
      window.location.replace(`https://${storeDomainMap[storeId]}${window.location.pathname}`);
    }
  }, [storeId]);

  if (!isGotInitData.store) {
    return <LoadingModal isVisible={true} />;
  }

  if (isGotInitData.store && !store) {
    return <Navigate to="/404" />;
  }

  return <Outlet />;
}
