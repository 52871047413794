import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import CouponItem from '~/components/CouponItem';

import { Coupon } from '~/types';

type CouponListProps = {
  couponList: Array<Coupon>;
};

// 顯示優惠券列表
export default function CouponList({ couponList }: CouponListProps) {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      {couponList.map((couponItem) => {
        const { id, name, endDate, rewardType, rewardCondition } = couponItem;
        return (
          <CouponItem
            key={id}
            id={id}
            name={name}
            endDate={endDate}
            rewardType={rewardType}
            rewardCondition={rewardCondition}
          />
        );
      })}
    </SimpleGrid>
  );
}
