import { FormControl, FormLabel, Input, InputGroup, VStack } from '@chakra-ui/react';
import { useState } from 'react';

import FormInputMessage from '~/components/FormInputMessage';

import { useTranslation } from 'react-i18next';

type NameProps = {
  name: string;
};

// 姓名輸入
export default function Name({ name }: NameProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(name);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  return (
    <VStack align="flex-start">
      <FormControl id="first-name">
        <FormLabel fontWeight="normal" letterSpacing={2}>
          {t('personalInfo.name')}
        </FormLabel>
        <InputGroup>
          <Input
            defaultValue={name}
            width="inherit"
            type="text"
            value={value}
            onChange={handleChange}
          />
        </InputGroup>
        {value.length <= 1 && <FormInputMessage message={t('group.warnName')} type="error" />}
      </FormControl>
    </VStack>
  );
}
