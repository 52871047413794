import firebase from 'firebase';
import { atom } from 'recoil';

const auth = atom<firebase.User | null>({
  key: 'firebaseAuth',
  default: null,
  dangerouslyAllowMutability: true,
});

export default auth;
