import { Text } from '@chakra-ui/react';
import { TaxInfoState } from '~/pages/Cart/hooks/useTaxInfo';
import { TaxInfoForm, TaxRadioGroup } from '.';

import { useTranslation } from 'react-i18next';
import { TaxType } from '~/types';

type TaxInfoSectionProps = {
  taxType: TaxType;
  taxInfoState: TaxInfoState;
  showError: boolean;
  onChangeTaxType: (value: TaxType) => void;
  onChangeTitle: (value: string) => void;
  onChangeInvoice: (value: string) => void;
};

export default function TaxInfoSection({
  taxType,
  taxInfoState,
  showError,
  onChangeTaxType,
  onChangeInvoice,
  onChangeTitle,
}: TaxInfoSectionProps) {
  const { t } = useTranslation();

  return (
    <>
      <TaxRadioGroup taxType={taxType} onChangeTaxType={onChangeTaxType} />
      {taxType === TaxType.COMPANY && (
        <TaxInfoForm
          taxInfoState={taxInfoState}
          onChangeInvoice={onChangeInvoice}
          onChangeTitle={onChangeTitle}
          showError={showError}
        />
      )}
      <Text fontSize="xs">{t('store.order.invoice.note')}</Text>
    </>
  );
}
