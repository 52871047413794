import { Box, VStack, Heading, Button } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

type NoItemNoticeProps = {
  onNavigate?: () => void;
};

export default function NoItemNotice({ onNavigate }: NoItemNoticeProps) {
  const { t } = useTranslation();
  return (
    <Box bgColor="wsGray.200" borderRadius="5px" p={3}>
      <VStack w="full" spacing={4}>
        <Heading size="sm">{t('store.order.emptyCart')}</Heading>
        <Button width="full" onClick={onNavigate}>
          {t('store.order.CTABtn')}
        </Button>
      </VStack>
    </Box>
  );
}
