import { selector } from 'recoil';
import {
  StoreWithLocale,
  Store,
  Locales,
  LocaleString,
  Menu,
  MenuWithLocale,
  Category,
  CategoryWithLocale,
  Item,
  ItemWithLocale,
  Modifier,
  ModifierWithLocale,
  Option,
  OptionWithLocale,
} from '~/types';
import storeAtom from '~/recoil/atom/store';
import localeAtom from '~/recoil/atom/locale';

const getLocaleStringValue = (localeString: LocaleString, locale: Locales) => {
  if (localeString == null) return '';
  return localeString?.[locale] || Object.values(localeString)?.[0] || '';
};

//convert all locale objects to string with a default value as well
export const storeLocaleSelector = selector<Store | null>({
  key: 'storeLocaleSelector',
  get: ({ get }) => {
    const locale: Locales = get(localeAtom);
    const store: Store | StoreWithLocale | null = get(storeAtom);
    //convert modifiers, items, categories, menus to single string if they are an object. Otherwise just return the string
    if (store == null) return null;
    let newStore = { ...store };
    newStore.menus = Object.fromEntries(
      Object.entries(store.menus).map(
        ([menuId, { name, ...menu }]: [string, Menu | MenuWithLocale]) => [
          menuId,
          {
            name: typeof name === 'string' ? name : getLocaleStringValue(name, locale),
            ...menu,
          },
        ],
      ),
    );

    newStore.categories = Object.fromEntries(
      Object.entries(store.categories).map(
        ([categoryId, { name, ...category }]: [string, Category | CategoryWithLocale]) => [
          categoryId,
          {
            name: typeof name === 'string' ? name : getLocaleStringValue(name, locale),
            ...category,
          },
        ],
      ),
    );

    newStore.items = Object.fromEntries(
      Object.entries(store.items).map(
        ([itemId, { name, description, ...item }]: [string, Item | ItemWithLocale]) => [
          itemId,
          {
            name: typeof name === 'string' ? name : getLocaleStringValue(name, locale),
            description:
              typeof description === 'string'
                ? description
                : getLocaleStringValue(description, locale),
            ...item,
          },
        ],
      ),
    );

    newStore.modifiers = Object.fromEntries(
      Object.entries(store.modifiers).map(
        ([itemId, { name, options, ...mod }]: [string, Modifier | ModifierWithLocale]) => [
          itemId,
          {
            name: typeof name === 'string' ? name : getLocaleStringValue(name, locale),
            options: options.map(({ name, ...option }: Option | OptionWithLocale) => ({
              name: typeof name === 'string' ? name : getLocaleStringValue(name, locale),
              ...option,
            })),
            ...mod,
          },
        ],
      ),
    );

    return newStore as Store;
  },
});
