import { HStack, Text, Icon, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoEnterOutline } from 'react-icons/io5';
import { AiFillEnvironment } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';

type DetailProps = {
  phoneNumber: string;
  location: string;
  boxSize: string;
};

// 店家詳細資訊
export default function Detail({ phoneNumber, location, boxSize }: DetailProps) {
  const { t } = useTranslation();
  return (
    <>
      <HStack>
        <Icon as={FiPhone} boxSize={boxSize} />
        <Text fontSize="sm"> {phoneNumber}</Text>
      </HStack>
      <HStack>
        <Icon as={AiFillEnvironment} boxSize={boxSize} />
        <Text fontSize="sm"> {location}</Text>
      </HStack>
      <Link
        bg="wsBlack"
        rounded={8}
        p="0.5rem"
        isExternal
        href={`https://www.google.com.tw/maps/place/${location}`}
      >
        <HStack>
          <Text fontSize="sm" color="white">
            {t('store.openMap')}
          </Text>
          <Icon as={IoEnterOutline} boxSize={boxSize} color="white" />
        </HStack>
      </Link>
    </>
  );
}
