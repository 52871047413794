import firebase from 'firebase';
import axios from 'axios';
import { parseISO } from 'date-fns';

import { Coupon, CouponFromApi } from '~/types';

export default async function getCouponList(
  storeId: string,
  auth: firebase.User,
): Promise<Array<Coupon>> {
  const token = await auth.getIdToken();
  const res = await axios.get(`${process.env.REACT_APP_GET_COUPON_LIST}/${storeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return res.data.coupons.map((coupon: CouponFromApi) => {
    return {
      ...coupon,
      startDate: parseISO(coupon.startDate),
      endDate: parseISO(coupon.endDate),
    };
  });
}
