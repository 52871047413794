import { addMinutes } from 'date-fns';
import { PreOrderSetting } from '~/recoil/selectors/preOrderSetting';

export const getUpdatedPreOrderAfterDate = (preOrderSetting: PreOrderSetting) => {
  const preOrderAfter = preOrderSetting ? preOrderSetting.preOrderAfter : null;
  const preOrderAfterUnit = preOrderSetting ? preOrderSetting.preOrderAfterUnit : null;

  if (!preOrderSetting) return;
  let initialValue;
  const nowDate = new Date();
  if (preOrderAfterUnit === 'hour' && preOrderAfter) {
    initialValue = addMinutes(nowDate, preOrderAfter * 60);
    return initialValue;
  }
  if (preOrderAfterUnit === 'day' && preOrderAfter) {
    initialValue = addMinutes(nowDate, preOrderAfter * 24 * 60);
    return initialValue;
  }
  if (preOrderAfter) return addMinutes(nowDate, preOrderAfter * 60);
};

export const getUpdatedPreOrderBeforeDate = (preOrderSetting: PreOrderSetting) => {
  const preOrderBefore = preOrderSetting ? preOrderSetting.preOrderBefore : null;
  const preOrderBeforeUnit = preOrderSetting ? preOrderSetting.preOrderBeforeUnit : null;
  if (!preOrderSetting) return;
  let initialValue;
  const nowDate = new Date();
  if (preOrderBeforeUnit === 'hour' && preOrderBefore) {
    initialValue = addMinutes(nowDate, preOrderBefore * 60);
    return initialValue;
  }
  if (preOrderBeforeUnit === 'day' && preOrderBefore) {
    initialValue = addMinutes(nowDate, preOrderBefore * 24 * 60);
    return initialValue;
  }
  if (preOrderBefore) return addMinutes(nowDate, preOrderBefore * 60);
};
