import React from 'react';
import { Icon, Text, Flex, HStack, StackProps } from '@chakra-ui/react';

import {
  IoClipboardOutline,
  IoPeopleOutline,
  IoReceiptOutline,
  IoSearchOutline,
  IoLogIn,
} from 'react-icons/io5';
import { BiStoreAlt } from 'react-icons/bi';
import { StoreInfoIcon } from '~/icons';
import { Coupon } from '~/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const navList = [
  {
    title: 'nav.menu',
    icon: IoClipboardOutline,
    link: '',
  },
  {
    title: 'nav.groupOrder',
    icon: IoPeopleOutline,
    link: 'groupOrder',
  },
  {
    title: 'nav.orders',
    icon: IoReceiptOutline,
    link: 'orders',
  },
  {
    title: 'nav.discount',
    icon: Coupon,
    link: 'discount',
  },
  {
    title: 'nav.storeInfo',
    icon: StoreInfoIcon,
    link: 'storeInfo',
  },
];

type NavBarProps = {
  isLogin: boolean;
  onLoginModalOpen: () => void;
  onLogout: () => void;
  onSearchModalOpen: () => void;
};

export default function NavBar({
  isLogin,
  onLoginModalOpen,
  onLogout,
  onSearchModalOpen,
}: NavBarProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const itemBaseStyle: StackProps = {
    justify: 'center',
    py: 2,
    transition: '0.3s',
    cursor: 'pointer',
    _hover: { bg: 'wsGray.300' },
  };

  const handleNavigate = (path: string) => {
    if (storeId && groupId) {
      return navigate(`/${storeId}/group/${groupId}/${path}`);
    }

    if (storeId) {
      return navigate(`/${storeId}/${path}`);
    }
  };

  return (
    <Flex borderTopWidth="thin" borderTopColor="wsGray.200">
      {navList.map(({ title, icon, link }) => {
        return (
          <HStack key={title} flex="1" {...itemBaseStyle} onClick={() => handleNavigate(link)}>
            <Icon as={icon} boxSize="1.5rem" />
            <Text fontSize="lg">{t(title)}</Text>
          </HStack>
        );
      })}
      <HStack flex="1" {...itemBaseStyle} onClick={onSearchModalOpen}>
        <Icon as={IoSearchOutline} boxSize="1.5rem" />
        <Text fontSize="lg">{t('nav.search')}</Text>
      </HStack>
      <HStack
        flex="1"
        {...itemBaseStyle}
        onClick={() => (isLogin ? onLogout() : onLoginModalOpen())}
      >
        <Icon as={IoLogIn} boxSize="1.5rem" />
        <Text fontSize="lg">{isLogin ? t('nav.logout') : t('nav.login')}</Text>
      </HStack>
    </Flex>
  );
}
