import { Menu } from '~/types';
import uniq from 'lodash/uniq';

const getUnionCategoryIds = ({ menuList }: { menuList: Menu[] }): string[] => {
  const categoryIds = menuList.reduce((acc, menu) => {
    return acc.concat(menu.categories);
  }, [] as string[]);

  const uniqCategoryIds = uniq(categoryIds);

  return uniqCategoryIds;
};

export default getUnionCategoryIds;
