import { Center, Text } from '@chakra-ui/react';

type NormalProps = {
  children?: JSX.Element | string | number;
};

export default function Normal({ children }: NormalProps) {
  return (
    <Center
      w="full"
      py={3}
      borderRadius="5px"
      borderWidth="1px"
      borderColor="wsGray.300"
      cursor="pointer"
      // _hover={{
      //   bgColor: 'wsBlack',
      //   color: 'white',
      // }}
    >
      <Text w="1.4rem" textAlign="center" fontWeight="bold" color="inherit">
        {children}
      </Text>
    </Center>
  );
}
