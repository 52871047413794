import fp from 'lodash/fp';
import {
  MealPickUpType,
  PickMealTime,
  Menu,
  Category,
  Item,
  CartItem,
  PreOrderDate,
  Modifier,
} from '~/types';
import checkOperatingTime from './checkOperatingTime';

export default function getAvailableItemsMap(
  {
    mealPickupType,
    pickMealTime,
    preOrderDate,
    today,
  }: {
    mealPickupType: MealPickUpType;
    pickMealTime: PickMealTime;
    preOrderDate: PreOrderDate;
    today: Date;
  },
  {
    menus,
    categories,
    items,
  }: {
    menus: Record<string, Menu>;
    categories: Record<string, Category>;
    items: Record<string, Item>;
  },
): Map<string, Item> {
  const availableMenus = Object.values(menus)
    .filter(({ allowPublish }) => allowPublish)
    .filter(({ allowPickup, allowDelivery }) => {
      switch (mealPickupType) {
        case MealPickUpType.DELIVERY:
          return allowDelivery;
        case MealPickUpType.PICKUP:
          return allowPickup;
        default:
          return false;
      }
    })
    .filter(({ allowPreorder, schedule }) => {
      if (pickMealTime !== PickMealTime.RESERVATION) {
        return checkOperatingTime(schedule, today);
      }
      return allowPreorder && preOrderDate && checkOperatingTime(schedule, preOrderDate);
    });

  const availableItems = availableMenus.reduce<Map<string, Item>>(
    (acc, { categories: menuCategories }) => {
      menuCategories.forEach((categoryId) => {
        const category = categories[categoryId];

        if (!category) return;

        const { items: categoryItems } = category;

        categoryItems.forEach((itemId) => {
          const item = items[itemId];
          if (item) {
            acc.set(itemId, item);
          }
        });
      });
      return acc;
    },
    new Map(),
  );
  return availableItems;
}
