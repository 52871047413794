import { useState, useEffect, useRef } from 'react';

function useCounter(time = 0) {
  const [remainSecond, setRemainSecond] = useState<number>(time);
  const [isStart, setStart] = useState<boolean>(false);
  const saveDelay = useRef<number | null>(null);
  const handleReset = () => {
    setRemainSecond(time);
    setStart(false);
    saveDelay.current = time;
  };

  const handleReStart = () => {
    setRemainSecond(time);
    setStart(true);
    saveDelay.current = time;
  };

  const handleSetTime = (num: number) => {
    num > 0 && setRemainSecond(num);
  };

  const handleStop = () => {
    setStart(false);
  };

  const handleStart = () => {
    setStart(true);
  };
  useEffect(() => {
    const tick = (id: number) => {
      if (!isStart) {
        clearInterval(id);
      }
      if (saveDelay.current !== null) {
        setRemainSecond(saveDelay.current);
        if (saveDelay.current >= 0) {
          saveDelay.current -= 1;
        }
        if (saveDelay.current < 0) {
          setStart(false);
          clearInterval(id);
        }
      } else {
        clearInterval(id);
      }
    };

    saveDelay.current = time;
    setRemainSecond(time);
    if (saveDelay.current !== null) {
      const id = window.setInterval(() => tick(id), 1000);
      return () => clearInterval(id);
    }
  }, [time, isStart]);

  return {
    count: remainSecond,
    isStart,
    handleReset,
    handleSetTime,
    handleStop,
    handleStart,
    handleReStart,
  };
}

export default useCounter;
