import { selector } from 'recoil';

import { Week } from '~/types';
import { storeLocaleSelector } from './storeLocale';

type StoreOrderSetting = {
  allowOrders: boolean;
  allowRealTimeOrder: boolean;
  orderPrepareMinutes: number | null;
  allowPreOrder: boolean;
  preOrderSetting: {
    allowPreOrder: boolean;
    preOrderAfter: number;
    preOrderAfterUnit: string;
    preOrderBefore: number;
    preOrderBeforeUnit: string;
  } | null;
};

const storeOrderSetting = selector<StoreOrderSetting>({
  key: 'storeOrderSettingSelector',
  get: ({ get }) => {
    const store = get(storeLocaleSelector);

    const allowOrders = store?.allowOrders || false;
    const allowRealTimeOrder = store?.allowRealTimeOrder || false;
    // const allowReservations = store?.allowReservations || false;

    const preOrderSetting = store?.preOrderSetting || null;
    const allowPreOrder = preOrderSetting?.allowPreOrder || false;
    const preOrderLimit = store?.preorderLimit || null;

    const orderPrepareMinutes = store?.orderPrepareMinutes || null;

    const allowOverLimitInCash = store?.allowOverLimitInCash || false;
    const allowPartnerDelivery = store?.allowPartnerDelivery || false;
    const allowQueue = store?.allowQueue || false;

    const orderNumCount = store?.orderNumCount || null;
    const orderNumLimit = store?.orderNumLimit || null;

    const currency = store?.currency || null;

    const paymentType = store?.paymentTypes || null;

    const delivery = store?.delivery || null;
    const deliveryShare = store?.deliveryShare || null;
    return {
      allowOrders,
      allowRealTimeOrder,
      preOrderSetting,
      allowPreOrder,
      orderPrepareMinutes,
    };
  },
});

export default storeOrderSetting;
