import { selectorFamily } from 'recoil';
import groupItemsAtom from '../atom/groupItems';

import { CartItem } from '~/types';

const memberCartItem = selectorFamily<
  CartItem | null,
  { memberId: string; itemId: string; index: number }
>({
  key: 'memberCartItem',
  get:
    ({ memberId, itemId, index }) =>
    ({ get }) => {
      const groupItems = get(groupItemsAtom);

      const findGroupItem = groupItems.find((groupItem) => groupItem.memberId === memberId);

      if (!findGroupItem) {
        return null;
      }

      return findGroupItem.items.find(({ id }, i) => itemId === id && index === i) || null;
    },
});

export default memberCartItem;
