export default function updateDocumentHeader(
  title: string = '',
  description: string = '',
  logoUrl: string = '',
) {
  document.title = title;

  const metaDescription: HTMLMetaElement | null = document.querySelector('meta[name=description]');

  if (metaDescription) {
    metaDescription.content = description;
  } else {
    let meta = document.createElement('meta');

    meta.name = 'description';
    meta.content = description;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  const iconElement: HTMLLinkElement | null = document.querySelector('link[rel=icon]');

  if (iconElement) {
    iconElement.href = logoUrl;
  } else {
    let icon = document.createElement('link');
    icon.rel = 'icon';
    icon.href = logoUrl;

    document.getElementsByTagName('head')[0].appendChild(icon);
  }
}
