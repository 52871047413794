import { firestore } from '~/firebase';
import { useParams } from 'react-router-dom';

import paymentTypeAtom from '~/recoil/atom/paymentType';
import authAtom from '~/recoil/atom/auth';
import { useRecoilValue, useRecoilState } from 'recoil';

import { PaymentType } from '~/types';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';
export default function usePaymentType() {
  const [paymentType, setPaymentType] = useRecoilState(paymentTypeAtom);
  const auth = useRecoilValue(authAtom);
  const store = useRecoilValue(storeLocaleSelector);
  const { storeId, groupId } = useParams();

  const handleUpdatePaymentType = async (paymentType: PaymentType) => {
    if (!auth) {
      setPaymentType(paymentType);
      return;
    }

    if (storeId && groupId) {
      try {
        await firestore
          .doc(`stores/${storeId}/carts/${groupId}`)
          .update('paymentType', paymentType);
      } catch (err) {
        console.log('change payment type error', err);
      }
      return;
    }

    if (storeId) {
      try {
        await firestore
          .doc(`stores/${storeId}/carts/${auth.uid}`)
          .update('paymentType', paymentType);
        setPaymentType(paymentType);
      } catch (err) {
        console.log('change payment type error', err);
      }
      return;
    }
  };

  return {
    paymentType,
    paymentTypes: store?.paymentTypes || [],
    handleUpdatePaymentType,
  };
}
