import { useCallback, useState, useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { categoriesSelector } from '~/recoil/selectors/categories';
import { categoryMenuList as categoryMenuListSelector } from '~/recoil/selectors/menuItemList';

import { SelectedCategory, ChangeCategoryFn } from '../types';

import fp from 'lodash/fp';

const selectedCategoryAtom = atom<SelectedCategory>({
  key: 'selectedCategory',
  default: {
    isScrollingChange: false,
    value: '',
    label: '',
  },
});

export default function usePickCategory() {
  const categoryMenuList = useRecoilValue(categoryMenuListSelector);
  const [storeCategory, setStoreCategory] = useRecoilState(selectedCategoryAtom);
  const [selectedCategory, setCategory] = useState<SelectedCategory>({
    isScrollingChange: false,
    label: '',
    value: '',
  });
  const categories = useRecoilValue(categoriesSelector);

  const handleChangeCategory: ChangeCategoryFn = useCallback(
    (val: string, isScrollingChange: boolean) => {
      setCategory({
        isScrollingChange,
        value: val,
        label: categoryMenuList.find(({ value }) => fp.eq(value, val))?.label || '',
      });
    },
    [categories, setCategory],
  );

  const handleSaveSelectedCategory = (categoryInfo: SelectedCategory) => {
    setStoreCategory(categoryInfo);
  };

  useEffect(() => {
    if (fp.eq(storeCategory.value, '')) {
      return;
    }

    const foundCategory = categoryMenuList.find(({ value }) => fp.eq(storeCategory.value, value));

    if (foundCategory) {
      setCategory({ ...foundCategory, isScrollingChange: false });
      setStoreCategory({ isScrollingChange: false, value: '', label: '' });
      return;
    }

    if (categoryMenuList.length > 0) {
      setCategory({ ...categoryMenuList[0], isScrollingChange: false });
    }
  }, [categoryMenuList, storeCategory]);

  return {
    selectedCategory,
    handleChangeCategory,
    handleSaveSelectedCategory,
  };
}
