import { selector } from 'recoil';
import uidAtom from '../atom/uid';
import cartStatusAtom from '../atom/cartStatus';
import locationAtom from '../atom/location';
import pickMealTimeAtom from '../atom/pickMealTime';
import preOrderDateAtom from '../atom/preOrderDate';
import mealPickupTypeAtom from '../atom/mealPickupType';
import taxInfoAtom from '../atom/taxInfo';
import taxTypeAtom from '../atom/taxType';
import paymentTypeAtom from '../atom/paymentType';
import cartItemsAtom from '../atom/cartItems';
import couponAtom from '../atom/coupon';
import shippingFeeAtom from '../atom/shippingFee';
import emailAtom from '../atom/email';

import convertMealPickupType from '~/utils/convertMealPickupType';

import { Cart, CartType } from '~/types';

const cart = selector<Cart>({
  key: 'cart',
  get: ({ get }) => {
    const uid = get(uidAtom);
    const location = get(locationAtom);
    const mealPickupType = get(mealPickupTypeAtom);
    const pickMealTime = get(pickMealTimeAtom);
    const preOrderDate = get(preOrderDateAtom);
    const taxInfo = get(taxInfoAtom);
    const taxType = get(taxTypeAtom);
    const cartStatus = get(cartStatusAtom);
    const paymentType = get(paymentTypeAtom);
    const cartItems = get(cartItemsAtom);
    const coupon = get(couponAtom);
    const shippingFee = get(shippingFeeAtom);
    const email = get(emailAtom);

    const orderType = convertMealPickupType(mealPickupType, pickMealTime);

    return {
      uid,
      cartType: CartType.PERSONAL,
      location,
      orderType,
      preOrderDate,
      status: cartStatus,
      paymentType,
      taxType,
      taxInfo,
      items: cartItems,
      coupon,
      shippingFee,
      email,
    };
  },
});

export default cart;
