import { atom } from 'recoil';
const taxInfo = atom<{ invoice: string; title: string }>({
  key: 'taxInfo',
  default: {
    invoice: '',
    title: '',
  },
});

export default taxInfo;
