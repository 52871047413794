import { useEffect } from 'react';
import defaultStoreId from '~/domainMap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function useDomain() {
  const { storeId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let from = location?.pathname.replace(storeId, defaultStoreId) || '/';

    if (defaultStoreId !== '' && !location.pathname.includes(defaultStoreId)) {
      navigate(from, {
        replace: true,
      });
    }

    return () => {
      //do nothing
    };
  }, []);

  return defaultStoreId;
}
