import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_CHANNEL,
  enabledReleaseStages: ['STAGE', 'PROD'],
  autoDetectErrors: true,
});

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const ErrorBoundary = plugin.createErrorBoundary(React);

export default ErrorBoundary;
