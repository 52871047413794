import { atom } from 'recoil';

const groupHostInfo = atom<{ note: string; name: string }>({
  key: 'groupHostInfo',
  default: {
    note: '',
    name: '',
  },
});

export default groupHostInfo;
