import { VStack, Text, Icon } from '@chakra-ui/react';
import SectionContainer from '~/container/SectionContainer';
import DisplayInput from './DisplayInput';
import FormInputMessage from '~/components/FormInputMessage';

import { RiMapPinLine } from 'react-icons/ri';
import { AiOutlineClockCircle } from 'react-icons/ai';

import generatePrepareString from '../utils/generatePrepareString';

import shippingFeeAtom from '~/recoil/atom/shippingFee';
import operatingTimeSelector from '~/recoil/selectors/operatingTime';
import { useRecoilValue } from 'recoil';

import checkPickMealTimeError from '../utils/checkPickMealTimeError';

import { PickMealTime, DeliveryTimeRange, PreorderSetting, MealPickUpType } from '~/types';

import { useTranslation } from 'react-i18next';

type DeliverySettingInfoSectionProps = {
  address: string;
  pickMealTime: PickMealTime;
  prepareMinutes: number;
  preOrderDate: Date | null;
  allowOrder: boolean;
  preorderSetting: PreorderSetting;
  distanceLimit: number;
  onSectionClick?: () => void;
  allowEdit?: boolean;
  allowDisplayError?: boolean;
};

// 取餐資訊區塊 地址跟時間 (自取不會有地址)
export default function DeliverySettingInfoSection({
  address,
  pickMealTime,
  preOrderDate,
  prepareMinutes,
  allowOrder,
  preorderSetting,
  distanceLimit,
  onSectionClick,
  allowEdit = false,
  allowDisplayError = true,
}: DeliverySettingInfoSectionProps) {
  const shippingFee = useRecoilValue(shippingFeeAtom);
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const { t } = useTranslation();

  const pickMealTimeErrorText = checkPickMealTimeError({
    pickMealTime,
    allowOrder,
    preorderSetting,
    operatingTime: operatingTime || {},
    preOrderDate,
  });

  const enterDelivery = t('address.enterDelivery');

  const isTimeError = !!pickMealTimeErrorText;

  const shippingFeeErrorText = checkAddressError(distanceLimit, shippingFee);

  return (
    <VStack spacing={7} align="stretch">
      <SectionContainer
        icon={<Icon as={RiMapPinLine} boxSize="1.5rem" />}
        title={t('address.delivery')}
        rightHandComponent={allowEdit && <Text onClick={onSectionClick}>{t('edit')}</Text>}
      >
        <DisplayInput
          onClick={onSectionClick}
          isInvalid={allowDisplayError && !address}
          value={address ? address : enterDelivery}
          cursor="pointer"
        />
        {allowDisplayError && shippingFeeErrorText && (
          <FormInputMessage type="error" message={t(shippingFeeErrorText)} />
        )}
      </SectionContainer>

      <SectionContainer
        icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
        title={t('store.pickUpSetting.pickUpTime')}
        rightHandComponent={
          allowEdit && (
            <Text onClick={onSectionClick} color={isTimeError ? 'wsRed.500' : 'wsBlack'}>
              {t('store.pickUpSetting.edit')}
            </Text>
          )
        }
      >
        <DisplayInput
          onClick={onSectionClick}
          isInvalid={isTimeError}
          value={generatePrepareString(
            MealPickUpType.DELIVERY,
            pickMealTime,
            prepareMinutes,
            preOrderDate,
            DeliveryTimeRange,
          )}
          cursor="pointer"
        />
        {pickMealTimeErrorText && (
          <FormInputMessage type="error" message={t(pickMealTimeErrorText)} />
        )}
      </SectionContainer>
    </VStack>
  );
}

function checkAddressError(
  distanceLimit: number,
  shippingFee: {
    distance: number;
    quotes: number;
    estimateSecondsToArrival: number;
  } | null,
) {
  if (!shippingFee) {
    return 'store.order.error.shippingFee';
  }

  if (shippingFee.distance > distanceLimit) {
    return 'store.order.error.maxDistance';
  }

  return '';
}
