import { useRecoilValue } from 'recoil';
import { VStack, Icon, Text } from '@chakra-ui/react';
import SectionContainer from '~/container/SectionContainer';
import DisplayInput from './DisplayInput';
import FromInputMessage from '~/components/FormInputMessage';

import { AiOutlineClockCircle } from 'react-icons/ai';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import generatePrepareString from '../utils/generatePrepareString';

import checkPickMealTimeError from '../utils/checkPickMealTimeError';

import { MealPickUpType, PickMealTime, PickupTimeRange, PreorderSetting } from '~/types';

import { useTranslation } from 'react-i18next';

type PickupSettingInfoSectionProps = {
  pickMealTime: PickMealTime;
  prepareMinutes: number;
  preOrderDate: Date | null;
  preorderSetting: PreorderSetting;
  allowOrder: boolean;
  onSectionClick?: () => void;
  allowEdit?: boolean;
};

export default function PickupSettingInfoSection({
  pickMealTime,
  prepareMinutes,
  preOrderDate,
  preorderSetting,
  allowOrder,
  onSectionClick,
  allowEdit = false,
}: PickupSettingInfoSectionProps) {
  const { t } = useTranslation();
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const pickMealTimeErrorText = checkPickMealTimeError({
    pickMealTime,
    allowOrder,
    preorderSetting,
    operatingTime: operatingTime || {},
    preOrderDate,
  });

  const isTimeError = !!pickMealTimeErrorText;

  return (
    <VStack align="stretch">
      <SectionContainer
        title={t('store.pickUpSetting.pickUpTime')}
        icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
        rightHandComponent={
          allowEdit && (
            <Text onClick={onSectionClick} color={isTimeError ? 'wsRed.500' : 'wsBlack'}>
              {t('store.pickUpSetting.edit')}
            </Text>
          )
        }
      >
        <DisplayInput
          onClick={onSectionClick}
          isInvalid={isTimeError}
          color={isTimeError ? 'wsRed.500' : 'wsBlack'}
          value={generatePrepareString(
            MealPickUpType.PICKUP,
            pickMealTime,
            prepareMinutes,
            preOrderDate,
            PickupTimeRange,
          )}
          cursor="pointer"
        />
        {pickMealTimeErrorText && (
          <FromInputMessage type="error" message={t(pickMealTimeErrorText)} />
        )}
      </SectionContainer>
    </VStack>
  );
}
