import { selector } from 'recoil';
import modalControlAtom from '../atom/modalControl';

const isSearchItemModalOpen = selector<boolean>({
  key: 'isSearchItemModalOpen',
  get: ({ get }) => {
    const { isSearchItemModalOpen } = get(modalControlAtom);
    return isSearchItemModalOpen;
  },
  set: ({ get, set }, value) => {
    const modalControl = get(modalControlAtom);
    set(modalControlAtom, { ...modalControl, isSearchItemModalOpen: value as boolean });
  },
});

export default isSearchItemModalOpen;
