import { VStack, Text, Box, HStack } from '@chakra-ui/react';
import formatPriceNumber from '~/utils/formatPriceNumber';
import { OrderType } from '~/types';
import { useTranslation } from 'react-i18next';

type TotalPriceSectionProps = {
  orderType: OrderType;
  subtotal: number;
  shippingFee: number;
  discount: number;
  freightSubsidy: number; // 運費補助金額
  total: number;
};

// 商品結算區塊
export default function TotalPriceSection({
  orderType,
  subtotal,
  shippingFee,
  freightSubsidy,
  total,
  discount,
}: TotalPriceSectionProps) {
  const { t } = useTranslation();

  const isDelivery = [OrderType.WS_DELIVERY, OrderType.WS_PREORDER_DELIVERY].includes(orderType);

  const isReachDiscount = discount > 0;
  return (
    <VStack spacing={3} align="stretch">
      <VStack spacing={1} align="stretch">
        <HStack justify="space-between" align="stretch">
          <Text fontSize="lg">{t('store.order.itemTotal')}</Text>
          <Text fontSize="lg">{formatPriceNumber(subtotal)}</Text>
        </HStack>
        {isReachDiscount && (
          <HStack justify="space-between" align="stretch">
            <Text fontSize="lg">{t('store.order.useCoupon')}</Text>
            <Text color="wsRed.600" fontSize="lg">
              -{formatPriceNumber(discount)}
            </Text>
          </HStack>
        )}
        {isDelivery && (
          <>
            <HStack justify="space-between" align="stretch">
              <Text fontSize="lg">{t('store.order.shipping')}</Text>
              <Text fontSize="lg">{formatPriceNumber(shippingFee)}</Text>
            </HStack>
            {freightSubsidy > 0 && (
              <HStack justify="space-between" align="stretch">
                <Text fontSize="lg">{t('store.order.shippingDiscount')}</Text>
                <Text color="wsRed.600" fontSize="lg">
                  -{formatPriceNumber(freightSubsidy)}
                </Text>
              </HStack>
            )}
          </>
        )}
      </VStack>

      <Box backgroundColor="wsBlack" height="0.175rem" />
      <HStack justify="space-between" align="stretch">
        <Text fontSize="lg" fontWeight="bold">
          {t('store.order.total')}
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {formatPriceNumber(total)}
        </Text>
      </HStack>
      <Text fontSize="xs">{t('store.order.note')}</Text>
    </VStack>
  );
}
