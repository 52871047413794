import { VStack } from '@chakra-ui/react';
import React from 'react';
import SectionLabel, { SectionLabelProps } from './SectionLabel';

type SectionContainerProps = { children?: React.ReactNode } & SectionLabelProps;

// 訂單標題
export default function SectionContainer({ children, ...props }: SectionContainerProps) {
  return (
    <VStack spacing={8} align="stretch">
      <SectionLabel {...props} />
      {children}
    </VStack>
  );
}
