import { Colors } from '@chakra-ui/react';

const colors: Colors = {
  wsBlack: '#333333',
  wsGray: {
    200: '#F8F8F8',
    300: '#F2F2F2',
    400: '#C4C4C4',
    500: '#8D8D8D',
    700: '#454545',
  },
  wsRed: {
    200: '#FFF4F4',
    300: '#FCB4B4',
    600: '#E83B3B',
  },
  wsGreen: {
    200: '#F7FFF8',
    500: '#43CE62',
  },

  checkBox: {
    500: '#333333',
  },
};

export default colors;
