import { Box, Flex, VStack, Text, Divider, Link, Icon, Center } from '@chakra-ui/react';
import { AiFillCheckCircle } from 'react-icons/ai';

import { RewardCondition, RewardType } from '~/types';
import { format } from 'date-fns';
import transformRewardConditionString from '~/utils/transformRewardConditionString';
import { useTranslation } from 'react-i18next';

type CouponItemProps = {
  id: string;
  name: string;
  endDate: Date;
  rewardCondition: RewardCondition;
  rewardType: RewardType;
  isActive?: boolean;
  onSelectClick?: () => void;
  onNavigate?: () => void;
};

export default function CouponItem({
  id,
  name,
  endDate,
  rewardCondition,
  rewardType,
  onSelectClick,
  onNavigate,
  isActive = false,
}: CouponItemProps) {
  const { t } = useTranslation();

  const basePseudoStyle = {
    content: '""',
    display: 'block',
    position: ' absolute',
    zIndex: 100,
    left: '-.5rem',
    w: '1rem',
    h: '1rem',
    borderRadius: '50%',
    border: '1px solid #c4c4c4',
    bgColor: 'white',
    transform: 'rotate(45deg)',
  };

  const topGapPseudo = {
    ...basePseudoStyle,
    borderColor: 'transparent #c4c4c4 #c4c4c4 transparent',
  };
  const buttonGapPseudo = {
    ...basePseudoStyle,
    borderColor: '#c4c4c4 transparent transparent #c4c4c4',
  };

  const rewardConditionDisplayString = transformRewardConditionString(
    rewardType,
    rewardCondition,
    t,
  );

  return (
    <Flex opacity={onSelectClick ? 1 : 0.5}>
      <Box
        flex="1"
        px={2}
        py={1}
        borderWidth="1px"
        borderColor="wsGray.400"
        borderRightWidth="0"
        borderLeftRadius="5px"
        overflow="hidden"
      >
        <VStack align="stretch" spacing={0}>
          <Text as="h5" fontSize="sm" fontWeight="bold" isTruncated>
            {name}
          </Text>
          <VStack alignSelf="stretch" align="flex-start" spacing={1} letterSpacing={1}>
            <Text fontSize="xs" color="wsGray.500">
              {t('coupon.time', { time: format(endDate, 'yyyy/MM/dd') })}
            </Text>
            <Link
              onClick={onNavigate}
              fontSize="xs"
              color="wsGray.500"
              bgColor="wsGray.300"
              p={1}
              borderRadius="5px"
            >
              {t('coupon.detail')}
            </Link>
            <Divider color="wsGray.400" alignSelf="stretch" />
          </VStack>
        </VStack>
        <Text mt={0.5} fontSize="xs" fontWeight="bold" color="wsRed.600" isTruncated>
          {rewardConditionDisplayString}
        </Text>
      </Box>
      <Box
        minW="5rem"
        borderWidth="1px"
        borderColor="wsGray.400"
        borderLeftWidth="0"
        borderRightRadius="5px"
        cursor="pointer"
        role="group"
        style={{
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        }}
      >
        <Flex
          position="relative"
          h="full"
          borderRightRadius="5px"
          _before={{
            ...topGapPseudo,
            top: '-.5rem',
          }}
          _after={{
            ...buttonGapPseudo,
            bottom: '-.5rem',
          }}
        >
          <Box
            w="1px"
            h="full"
            background="linear-gradient(to bottom, #c4c4c4 50%, transparent 50%)"
            backgroundSize="100%  .75rem"
          />
          <Center
            flex="1"
            h="full"
            borderRightRadius="5px"
            bgColor={isActive ? 'wsBlack' : 'wsGray.300'}
            color={isActive ? 'white' : 'wsGray.500'}
            onClick={onSelectClick}
          >
            <VStack align="center">
              <Icon as={AiFillCheckCircle} boxSize="1.6rem" />

              <Text fontSize="xs" color="inherit">
                {t('coupon.select')}
              </Text>
            </VStack>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
}
