import { MealPickUpType, PickMealTime, OrderType } from '~/types';

export default function convertOrderType(orderType: OrderType): [MealPickUpType, PickMealTime] {
  if (orderType === OrderType.WS_PICKUP) {
    return [MealPickUpType.PICKUP, PickMealTime.IMMEDIATELY];
  }
  if (orderType === OrderType.WS_PREORDER_PICKUP) {
    return [MealPickUpType.PICKUP, PickMealTime.RESERVATION];
  }
  if (orderType === OrderType.WS_DELIVERY) {
    return [MealPickUpType.DELIVERY, PickMealTime.IMMEDIATELY];
  }
  if (orderType === OrderType.WS_PREORDER_DELIVERY) {
    return [MealPickUpType.DELIVERY, PickMealTime.RESERVATION];
  }

  return [MealPickUpType.PICKUP, PickMealTime.IMMEDIATELY];
}
