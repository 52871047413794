import {
  MealPickUpType,
  PickMealTime,
  Menu,
  Category,
  Item,
  CartItem,
  PreOrderDate,
  Modifier,
} from '~/types';
import { OrderOptionWithCount } from '~/recoil/selectors/cartItemsOptionsMap';
import getSameItemCount from './getSameItemCount';
import calcRemainInventory from './calcRemainInventory';
import getAvailableItemsMap from './getAvailableItemsMap';
import getAvailableOptionsMap from './getAvailableOptionsMap';

import fp from 'lodash/fp';

// 找出可用的品項及選項 -> 過濾品項或選項已不存在及售完的品項 -> 過濾品項或選項庫存不足的品項
export default function checkValidItems(
  {
    mealPickupType,
    pickMealTime,
    preOrderDate,
    allowReserved,
    resetTime,
  }: {
    mealPickupType: MealPickUpType;
    pickMealTime: PickMealTime;
    preOrderDate: PreOrderDate;
    allowReserved: boolean;
    resetTime: number;
  },
  {
    menus,
    categories,
    items,
    modifiers,
  }: {
    menus: Record<string, Menu>;
    categories: Record<string, Category>;
    items: Record<string, Item>;
    modifiers: Record<string, Modifier>;
  },
  cartItems: Array<CartItem>,
  cartItemsOptionsMap: Map<string, OrderOptionWithCount>,
): {
  invalidItems: Array<CartItem>;
  validItems: Array<CartItem>;
} {
  const today = new Date();

  const availableItemsMap = getAvailableItemsMap(
    { mealPickupType, pickMealTime, preOrderDate, today },
    { menus, categories, items },
  );

  const availableOptionsMap = getAvailableOptionsMap(modifiers, cartItemsOptionsMap);

  // ==================================================== \\
  let invalidItems: Array<CartItem> = [];
  let validItems: Array<CartItem> = [];

  // check Exist item and soldOut item
  validItems = cartItems.reduce<Array<CartItem>>((acc, cartItem) => {
    // 檢查是否有不存在或已售完的品項
    const availableItem = availableItemsMap.get(cartItem.id);

    if (!availableItem || availableItem.isSoldOut) {
      invalidItems.push(cartItem);
      return acc;
    }

    // 檢查是否有不存在或已售完的選項
    const optionIds = cartItem.modifiers.flatMap(({ id: modifierId, options }) =>
      options.map(({ id }) => `${modifierId}_${id}`),
    );

    const isOptionInValidInMap = optionIds.some((id) => {
      const option = availableOptionsMap.get(id);
      return !option || option.isSoldOut;
    });

    if (isOptionInValidInMap) {
      invalidItems.push(cartItem);
      return acc;
    }

    acc.push(cartItem);
    return acc;
  }, []);

  // ==================================================== \\
  // 過濾重複的 item
  const uniqItems = fp.uniqBy<CartItem>('id')(validItems);

  const checkPreOrderDate =
    pickMealTime === PickMealTime.RESERVATION ? preOrderDate || today : today;

  // check remain inventory
  const inValidInventoryItems = uniqItems.reduce<Set<string>>((acc, uniqItem) => {
    const { id, modifiers } = uniqItem;

    // 檢查是否有庫存不足的品項
    const totalCount = getSameItemCount(validItems, id);

    const availableItem = availableItemsMap.get(id);
    if (
      !availableItem ||
      calcRemainInventory(
        availableItem.inventory,
        allowReserved,
        today,
        checkPreOrderDate,
        resetTime,
      ) -
        totalCount <
        0
    ) {
      acc.add(id);
    }

    // 檢查是否有庫存不足的選項
    const optionIds = modifiers.flatMap(({ id: modifierId, options }) =>
      options.map(({ id }) => ({ modifierId, optionId: id })),
    );
    const isOptionInventoryInvalidInMap = optionIds.some(({ modifierId, optionId }) => {
      const optionTotalCount = getSameOptionCount(validItems, modifierId, optionId);

      const option = availableOptionsMap.get(`${modifierId}_${optionId}`);

      return (
        !option ||
        calcRemainInventory(option.inventory, allowReserved, today, checkPreOrderDate, resetTime) -
          optionTotalCount <
          0
      );
    });

    if (isOptionInventoryInvalidInMap) {
      acc.add(id);
    }

    return acc;
  }, new Set());

  // ==================================================== \\
  validItems = validItems.reduce<Array<CartItem>>((acc, item) => {
    const { id } = item;

    if (inValidInventoryItems.has(id)) {
      invalidItems.push(item);
      return acc;
    }

    acc.push(item);
    return acc;
  }, []);

  return {
    invalidItems,
    validItems,
  };
}

function getSameOptionCount(cartItems: Array<CartItem>, modifierId: string, optionId: string) {
  return cartItems.reduce<number>((acc, cartItem) => {
    const { count, modifiers } = cartItem;

    const isFoundOption = !!modifiers
      .find(({ id }) => id === modifierId)
      ?.options.find(({ id }) => id === optionId);

    return isFoundOption ? acc + count : acc;
  }, 0);
}
