import { HStack, Heading, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export type SectionLabelProps = {
  icon: IconType;
  title: string;
};

//區段標題：畫面區段的icon跟標題內文
export default function SectionLabel({ icon, title }: SectionLabelProps) {
  return (
    <HStack>
      <Icon as={icon} boxSize='1.25rem' />
      <Heading fontSize='sm' letterSpacing={2}>
        {title}
      </Heading>
    </HStack>
  );
}
