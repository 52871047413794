import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import { storeLocaleSelector } from '~/recoil/selectors/storeLocale';
import { useRecoilValue } from 'recoil';

import checkMenuOperatingTime from '~/utils/checkMenuOperatingTime';
import checkOperatingTime from '~/utils/checkOperatingTime';
import { PickMealTime } from '~/types';

export default function useAllowOperateCart(itemId: string): boolean {
  const store = useRecoilValue(storeLocaleSelector);
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);

  const checkTime =
    pickMealTime === PickMealTime.RESERVATION && preOrderDate ? preOrderDate : new Date();

  return (
    checkOperatingTime(store?.operatingTime || {}, checkTime) &&
    checkMenuOperatingTime(store?.menus || {}, store?.categories || {}, itemId, checkTime)
  );
}
