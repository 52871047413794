import { useRecoilValue } from 'recoil';
import { Link as ReactRouterLink, useNavigate, useParams } from 'react-router-dom';
import { Avatar, HStack, VStack, Box, Text, Center, Link } from '@chakra-ui/react';

import authAtom from '~/recoil/atom/auth';

import useUser from '~/hooks/useUser';

import PhoneIcon from '~/icons/Phone';

import { useTranslation } from 'react-i18next';

type UserInfoProps = {};

// 顯示會員資訊區塊，已登入會顯示頭貼、姓名、編輯個人資訊連結，為登入則顯示手機註冊
export default function UserInfo(props: UserInfoProps) {
  const { groupId, storeId } = useParams();
  const navigate = useNavigate();

  const auth = useRecoilValue(authAtom);

  const { t } = useTranslation();
  const { login } = useUser();

  const handleEditUserInfoClick = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}/personalInfo`);
      return;
    }
    navigate(`/${storeId}/personalInfo`);
  };

  const handleRegisterClick = () => {
    login();
  };
  return (
    <Box>
      {auth ? (
        <HStack spacing={3}>
          <Avatar name={auth.displayName || ''} src="" />
          <VStack align="flex-start" spacing={0}>
            <Text>{auth.displayName}</Text>
            {/* <Text fontSize="xs" color="wsGray.500" cursor="pointer">
              編輯個人資訊
            </Text> */}
          </VStack>
        </HStack>
      ) : (
        <Center
          onClick={handleRegisterClick}
          position="relative"
          px={4}
          py={2}
          color="white"
          bgColor="wsBlack"
          borderRadius="5px"
          overflow="hidden"
          cursor="pointer"
        >
          <PhoneIcon
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-9rem,-50%)"
            boxSize="3.3rem"
            color="wsGray.300"
          />
          <VStack spacing={0}>
            <Text fontWeight="bold" color="white">
              {t('discount.registerByPhone')}
            </Text>
            <Text fontSize="xs" color="white">
              {t('discount.registerCTA')}
            </Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
}
