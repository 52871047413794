import React, { FC } from 'react';
import {
  chakra,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Image,
  Box,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import type { HTMLChakraProps } from '@chakra-ui/react';
import type { HTMLMotionProps } from 'framer-motion';

import LoadingMiddle from '~/assets/images/LoadingMiddle.png';
import LoadingLeft from '~/assets/images/LoadingLeft.png';
import LoadingRight from '~/assets/images/LoadingRight.png';

type Merge<P, T> = Omit<P, keyof T> & T;
type MotionBoxProps = Merge<HTMLChakraProps<'div'>, HTMLMotionProps<'div'>>;

const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div);

type LoadingModalProps = {
  isVisible: boolean;
};

type MotionBoxStyleFactory = (delay: number) => MotionBoxProps;

const LoadingModal: FC<LoadingModalProps> = ({ isVisible }) => {
  const motionBoxStyleFactory: MotionBoxStyleFactory = (delay) => ({
    borderRadius: 'full',

    animate: {
      translateY: [120, 128, 120],
    },
    transition: {
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
      delay,
    },
  });

  const motionBoxStyleFactoryRight: MotionBoxStyleFactory = (delay) => ({
    borderRadius: 'full',

    animate: {
      translateY: [128, 120, 128],
    },
    transition: {
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
      delay,
    },
  });

  return (
    <Modal size="xs" isOpen={isVisible} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody boxShadow="0px 0px 10px rgba(8, 87, 195, 0.3)">
          <VStack spacing={2} position="relative">
            <HStack justify="space-between" spacing={12} pl={2} mt={-16}>
              <MotionBox {...motionBoxStyleFactory(0.1)}>
                <Image src={LoadingLeft} />
              </MotionBox>

              <MotionBox {...motionBoxStyleFactoryRight(0.1)}>
                <Image src={LoadingRight} />
              </MotionBox>
            </HStack>
            <Image src={LoadingMiddle} />
            <Box position="absolute" bottom="5">
              <Text textAlign="center" fontSize="sm">
                Loading...
              </Text>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
