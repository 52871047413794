import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

type ContentContainerProps = {
  children?: React.ReactNode;
} & BoxProps;

export default function ContentContainer({
  children,
  ...props
}: ContentContainerProps) {
  return (
    <Box maxW='900px' w='full' m='auto' {...props}>
      {children}
    </Box>
  );
}
