import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerFooter,
  DrawerHeader,
  VStack,
  FormControl,
  FormLabel,
  InputRightAddon,
  InputGroup,
  Input,
  Text,
  FormErrorMessage,
  Spinner,
  Center,
  Image,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import Title from '~/components/Title';
import CouponItem from './CouponItem';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';

import { IoArrowForwardCircle } from 'react-icons/io5';

import useCoupon from '~/hooks/useCoupon';

import { Coupon, FetchingState } from '~/types';

import convertMealPickupType from '~/utils/convertMealPickupType';
import getCouponCostLimit from '~/utils/getCouponCostLimit';

import CouponImage from '~/assets/images/Coupon.png';
import { useTranslation } from 'react-i18next';

type PickupCouponModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subtotal: number;
  publishCoupons: Array<Coupon>;
  notPublishCoupons: Array<Coupon>;
  fetchingState: FetchingState;
};

//（結帳前）店內優惠券使用彈窗，可選擇現有優惠券或輸入優惠碼新增並使用
export default function PickupCouponModal({
  isOpen,
  onClose,
  subtotal,
  publishCoupons,
  notPublishCoupons,
  fetchingState,
}: PickupCouponModalProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);

  const mealPickupType = useRecoilValue(mealPickupTypeAtom);
  const pickMealTime = useRecoilValue(pickMealTimeAtom);

  const { coupon, handleRemoveCoupon, handleSelectCoupon } = useCoupon();

  const [codeValue, setCodeValue] = React.useState<string>('');
  const [searchCode, setSearchCode] = useState('');

  const [selectedCouponId, setSelectedCouponId] = useState<string>(coupon?.id || '');

  // display allowDisplay or searched coupons
  const { availableCoupons, notAvailableCoupons } = useMemo(() => {
    const orderType = convertMealPickupType(mealPickupType, pickMealTime);

    let filteredCoupons = [];
    if (searchCode) {
      filteredCoupons = [...publishCoupons, ...notPublishCoupons].filter(
        ({ allowOrderTypes, code }) =>
          allowOrderTypes.includes(orderType) && code.includes(searchCode),
      );
    } else {
      filteredCoupons = publishCoupons.filter(({ allowOrderTypes }) =>
        allowOrderTypes.includes(orderType),
      );
    }

    return filteredCoupons.reduce<{
      availableCoupons: Array<Coupon>;
      notAvailableCoupons: Array<Coupon>;
    }>(
      (acc, coupon) => {
        if (subtotal >= getCouponCostLimit(coupon)) {
          acc.availableCoupons.push(coupon);
        } else {
          acc.notAvailableCoupons.push(coupon);
        }
        return acc;
      },
      { availableCoupons: [], notAvailableCoupons: [] },
    );
  }, [mealPickupType, pickMealTime, searchCode, subtotal, publishCoupons, notPublishCoupons]);

  useEffect(() => {
    setSelectedCouponId(coupon?.id || '');
  }, [coupon]);

  const handleSelect = (couponId: string) => {
    if (couponId === selectedCouponId) {
      setSelectedCouponId('');
      return;
    }

    setSelectedCouponId(couponId);
  };

  const handleConfirmClick = async () => {
    const updateCoupon = [...publishCoupons, ...notPublishCoupons].find(
      ({ id }) => id === selectedCouponId,
    );

    if (!updateCoupon) {
      await handleRemoveCoupon();
      onClose();
      return;
    }
    const updateSuccess = await handleSelectCoupon(updateCoupon);
    updateSuccess && onClose();
  };

  const handleNavigateCoupon = (couponId: string) => {
    if (!storeId) return;

    if (groupId) {
      navigate(`/${storeId}/group/${groupId}/coupon/${couponId}`);
      return;
    }
    navigate(`/${storeId}/coupon/${couponId}`);
  };

  const handleNavigateMenu = () => {
    if (!storeId) return;
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}`);
      return;
    }
    navigate(`/${storeId}`);
  };

  const handleClose = () => {
    setSelectedCouponId(coupon?.id || '');
    onClose();
  };

  if (isLargerThan768) {
    return (
      <Modal isOpen={isOpen} onClose={handleClose} size={'lg'} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent m={2} borderRadius="unset">
          <ModalCloseButton />
          <ModalHeader pt={9}>
            <Title title={t('coupon.coupon')} />
          </ModalHeader>

          <ModalBody overflow="auto" px={4} pb={4} pt={0}>
            <VStack align="stretch" spacing={5} pb={4}>
              {fetchingState === FetchingState.LOADING && (
                <Center>
                  <Spinner size="lg" />
                </Center>
              )}

              {fetchingState !== FetchingState.LOADING && publishCoupons.length === 0 && (
                <VStack py={10}>
                  <Image src={CouponImage} />
                  <Text color="wsGray.500">{t('coupon.noAvailable')}</Text>
                </VStack>
              )}

              {fetchingState !== FetchingState.LOADING && (
                <>
                  <Flex align="center">
                    <Box h="1px" flex="1" bgColor="wsGray.400" />
                    <Text px={2} fontSize="sm" fontWeight="bold">
                      {t('coupon.available')}
                    </Text>
                    <Box h="1px" flex="1" bgColor="wsGray.400" />
                  </Flex>
                  {availableCoupons.length > 0 ? (
                    <VStack align="stretch" spacing={4}>
                      {availableCoupons.map(
                        ({ id, name, endDate, rewardCondition, rewardType }) => {
                          const isActive = id === selectedCouponId;

                          return isActive ? (
                            <React.Fragment key={id}>
                              <CouponItem
                                id={id}
                                isActive={isActive}
                                name={name}
                                endDate={endDate}
                                rewardCondition={rewardCondition}
                                rewardType={rewardType}
                                onSelectClick={() => handleSelect(id)}
                                onNavigate={() => handleNavigateCoupon(id)}
                              />
                              <Text fontSize="sm" color="green.500">
                                {t(`coupon.usedCoupon`)}
                              </Text>
                            </React.Fragment>
                          ) : (
                            <CouponItem
                              key={id}
                              id={id}
                              isActive={isActive}
                              name={name}
                              endDate={endDate}
                              rewardCondition={rewardCondition}
                              rewardType={rewardType}
                              onSelectClick={() => handleSelect(id)}
                              onNavigate={() => handleNavigateCoupon(id)}
                            />
                          );
                        },
                      )}
                    </VStack>
                  ) : (
                    <VStack spacing={5}>
                      <Text color="wsGray.500">{t('coupon.noApplicableCoupon')}</Text>
                      <Button
                        minW="16rem"
                        size="lg"
                        color="white"
                        rightIcon={<Icon as={IoArrowForwardCircle} boxSize="1.5rem" />}
                        onClick={handleNavigateMenu}
                      >
                        {t('coupon.order')}
                      </Button>
                    </VStack>
                  )}
                </>
              )}

              {fetchingState !== FetchingState.LOADING && notAvailableCoupons.length > 0 && (
                <>
                  <Flex align="center">
                    <Box h="1px" flex="1" bgColor="wsGray.400" />
                    <Text px={2} fontSize="sm" fontWeight="bold">
                      {t('coupon.inapplicableCoupon')}
                    </Text>
                    <Box h="1px" flex="1" bgColor="wsGray.400" />
                  </Flex>
                  <VStack align="stretch" spacing={4}>
                    {notAvailableCoupons.map(
                      ({ id, name, endDate, rewardCondition, rewardType }) => {
                        return (
                          <CouponItem
                            key={id}
                            id={id}
                            isActive={id === selectedCouponId}
                            name={name}
                            endDate={endDate}
                            rewardCondition={rewardCondition}
                            rewardType={rewardType}
                            onNavigate={() => handleNavigateCoupon(id)}
                          />
                        );
                      },
                    )}
                  </VStack>
                </>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter p={0}>
            <Box w="full" pos="relative">
              <Box px={4} pb={4} pt={2}>
                <FormControl isInvalid={!!searchCode && availableCoupons.length === 0}>
                  <FormLabel fontSize="sm" fontWeight="normal">
                    {t('coupon.enterCode')}
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      value={codeValue}
                      onChange={(e) => {
                        setSearchCode('');
                        setCodeValue(e.target.value);
                      }}
                      placeholder={t('coupon.plzEnterCode')}
                      px={4}
                      borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                      borderRightColor="white"
                      borderWidth="1px"
                      _hover={{
                        borderRightColor: 'white',
                      }}
                      _focus={{
                        borderWidth: '1px',
                      }}
                      _placeholder={{
                        color: 'wsGray.400',
                        fontSize: 'sm',
                      }}
                      _invalid={{
                        borderWidth: '1px',
                        borderColor: 'wsRed.600',
                      }}
                    ></Input>
                    <InputRightAddon
                      px={5}
                      fontWeight="bold"
                      fontSize="sm"
                      bgColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.300'}
                      color={codeValue.length > 0 ? 'white' : 'wsGray.400'}
                      border="1px solid"
                      borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                      cursor="pointer"
                      onClick={() => setSearchCode(codeValue)}
                    >
                      {t('coupon.submit')}
                    </InputRightAddon>
                  </InputGroup>

                  <FormErrorMessage>{t('coupon.invalid')}</FormErrorMessage>
                </FormControl>
              </Box>
              <Button
                variant="unstyled"
                isFullWidth
                h="auto"
                py={4}
                bgColor="wsBlack"
                borderRadius="unset"
                color="white"
                letterSpacing={2}
                onClick={handleConfirmClick}
              >
                {t('coupon.confirm')}
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Drawer size="full" onClose={handleClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader>
          <Title title={t('coupon.coupon')} />
        </DrawerHeader>
        <DrawerBody>
          <VStack align="stretch" spacing={5} pb={4}>
            {fetchingState === FetchingState.LOADING && (
              <Center>
                <Spinner size="lg" />
              </Center>
            )}

            {fetchingState !== FetchingState.LOADING && publishCoupons.length === 0 && (
              <VStack py={10}>
                <Image src={CouponImage} />
                <Text color="wsGray.500">{t('coupon.noAvailable')}</Text>
              </VStack>
            )}

            {fetchingState !== FetchingState.LOADING && (
              <>
                <Flex align="center">
                  <Box h="1px" flex="1" bgColor="wsGray.400" />
                  <Text px={2} fontSize="sm" fontWeight="bold">
                    {t('coupon.available')}
                  </Text>
                  <Box h="1px" flex="1" bgColor="wsGray.400" />
                </Flex>
                {availableCoupons.length > 0 ? (
                  <VStack align="stretch" spacing={4}>
                    {availableCoupons.map(({ id, name, endDate, rewardCondition, rewardType }) => {
                      const isActive = id === selectedCouponId;

                      return isActive ? (
                        <React.Fragment key={id}>
                          <CouponItem
                            id={id}
                            isActive={isActive}
                            name={name}
                            endDate={endDate}
                            rewardCondition={rewardCondition}
                            rewardType={rewardType}
                            onSelectClick={() => handleSelect(id)}
                            onNavigate={() => handleNavigateCoupon(id)}
                          />
                          <Text fontSize="sm" color="green.500">
                            {t(`coupon.usedCoupon`)}
                          </Text>
                        </React.Fragment>
                      ) : (
                        <CouponItem
                          key={id}
                          id={id}
                          isActive={isActive}
                          name={name}
                          endDate={endDate}
                          rewardCondition={rewardCondition}
                          rewardType={rewardType}
                          onSelectClick={() => handleSelect(id)}
                          onNavigate={() => handleNavigateCoupon(id)}
                        />
                      );
                    })}
                  </VStack>
                ) : (
                  <VStack spacing={5}>
                    <Text color="wsGray.500">{t('coupon.noApplicableCoupon')}</Text>
                    <Button
                      minW="16rem"
                      size="lg"
                      color="white"
                      rightIcon={<Icon as={IoArrowForwardCircle} boxSize="1.5rem" />}
                      onClick={handleNavigateMenu}
                    >
                      {t('coupon.order')}
                    </Button>
                  </VStack>
                )}
              </>
            )}

            {fetchingState !== FetchingState.LOADING && notAvailableCoupons.length > 0 && (
              <>
                <Flex align="center">
                  <Box h="1px" flex="1" bgColor="wsGray.400" />
                  <Text px={2} fontSize="sm" fontWeight="bold">
                    {t('coupon.inapplicableCoupon')}
                  </Text>
                  <Box h="1px" flex="1" bgColor="wsGray.400" />
                </Flex>
                <VStack align="stretch" spacing={4}>
                  {notAvailableCoupons.map(({ id, name, endDate, rewardCondition, rewardType }) => {
                    return (
                      <CouponItem
                        key={id}
                        id={id}
                        isActive={id === selectedCouponId}
                        name={name}
                        endDate={endDate}
                        rewardCondition={rewardCondition}
                        rewardType={rewardType}
                        onNavigate={() => handleNavigateCoupon(id)}
                      />
                    );
                  })}
                </VStack>
              </>
            )}
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Box w="full" pos="relative">
            <Box px={4} pb={4} pt={2}>
              <FormControl isInvalid={!!searchCode && availableCoupons.length === 0}>
                <FormLabel fontSize="sm" fontWeight="normal">
                  {t('coupon.enterCode')}
                </FormLabel>
                <InputGroup>
                  <Input
                    value={codeValue}
                    onChange={(e) => {
                      setSearchCode('');
                      setCodeValue(e.target.value);
                    }}
                    placeholder={t('coupon.plzEnterCode')}
                    _placeholder={{
                      color: 'wsGray.400',
                      fontSize: 'sm',
                    }}
                    borderRightColor="white"
                    borderWidth="1px"
                    _hover={{
                      borderRightColor: 'white',
                    }}
                    px={4}
                    borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                    _focus={{
                      borderWidth: '1px',
                    }}
                    _invalid={{
                      borderWidth: '1px',
                      borderColor: 'wsRed.600',
                    }}
                  ></Input>
                  <InputRightAddon
                    px={5}
                    bgColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.300'}
                    fontWeight="bold"
                    fontSize="sm"
                    color={codeValue.length > 0 ? 'white' : 'wsGray.400'}
                    border="1px solid"
                    borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                    cursor="pointer"
                    onClick={() => setSearchCode(codeValue)}
                  >
                    {t('coupon.submit')}
                  </InputRightAddon>
                </InputGroup>

                <FormErrorMessage>{t('coupon.invalid')}</FormErrorMessage>
              </FormControl>
            </Box>
            <Button
              variant="unstyled"
              isFullWidth
              h="auto"
              py={6}
              bgColor="wsBlack"
              borderRadius="unset"
              color="white"
              letterSpacing={2}
              onClick={handleConfirmClick}
            >
              {t('coupon.confirm')}
            </Button>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
