import { CartItem } from '~/types';
import sumBy from 'lodash/sumBy';

export default function calcCartItemsPrice(cartItems: Array<CartItem>): number {
  return cartItems.reduce((acc: number, cartItem) => {
    const { count, price, modifiers } = cartItem;
    const modifiersPrice = modifiers.reduce((acc: number, { options }) => {
      return acc + sumBy(options, ({ price }) => price);
    }, 0);

    return acc + (price + modifiersPrice) * count;
  }, 0);
}
