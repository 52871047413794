import { RewardType, RewardCondition } from '~/types';
import { TFunction } from 'react-i18next';
import formatPriceNumber from './formatPriceNumber';

export default function transformRewardConditionString(
  rewardType: RewardType,
  rewardCondition: RewardCondition,
  t: TFunction<'translation', undefined>,
) {
  const couponDiscount = rewardCondition[RewardType.DISCOUNT];
  const couponDiscountPercentage = rewardCondition[RewardType.DISCOUNT_PERCENTAGE];
  const couponGift = rewardCondition[RewardType.DESCRIPTION];

  if (rewardType === RewardType.DISCOUNT && couponDiscount) {
    return t('coupon.discountText', {
      costLimit: formatPriceNumber(couponDiscount.costLimit),
      discount: formatPriceNumber(couponDiscount.discount),
    });
  }

  if (rewardType === RewardType.DISCOUNT_PERCENTAGE && couponDiscountPercentage) {
    return t('coupon.discountPercentageText', {
      costLimit: formatPriceNumber(couponDiscountPercentage.costLimit),
      discountLimit: formatPriceNumber(couponDiscountPercentage.discountLimit),
      percentage: couponDiscountPercentage.percentage * 100,
    });
  }

  if (rewardType === RewardType.DESCRIPTION && couponGift) {
    return t('coupon.descriptionText', {
      costLimit: formatPriceNumber(couponGift.costLimit),
      name: couponGift.name,
      giftInfo: couponGift.price
        ? t('coupon.descriptionPrice', { price: formatPriceNumber(couponGift.price) })
        : '',
    });
  }

  return t('coupon.informationError');
}
