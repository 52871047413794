import { useNavigate } from 'react-router-dom';
import { Text, HStack, VStack, Icon } from '@chakra-ui/react';

import { BiStoreAlt } from 'react-icons/bi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import addMinutes from 'date-fns/addMinutes';

import { Schedule, Week } from '~/types';

import { useTranslation } from 'react-i18next';

type StoreInfoSectionProps = {
  name: string;
  operatingTime: Schedule;
};

// 顯示店家資訊及目前對應營業時間 可導向 storeInfo 頁面
export default function StoreInfoSection({ name, operatingTime }: StoreInfoSectionProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const operatingTimeOfToday = operatingTime[format(new Date(), 'eee').toUpperCase() as Week] || [];
  const operatingText = operatingTimeOfToday.map(({ open, close }) => {
    const startDay = startOfDay(new Date());

    return (
      <Text key={`${open}_${close}`} fontSize="sm">{`${format(
        addMinutes(startDay, open),
        'HH:mm',
      )}-${format(addMinutes(startDay, close), 'HH:mm')}`}</Text>
    );
  });

  const handleNavigateToStoreInfo = () => {
    navigate('./storeInfo');
  };

  return (
    <HStack align="center" justify="space-between">
      <VStack align="flex-start">
        <HStack align="center">
          <Icon as={BiStoreAlt} boxSize="1.2rem" />
          <Text fontSize="sm">{name}</Text>
        </HStack>
        <HStack align="flex-start">
          <Icon as={AiOutlineClockCircle} boxSize="1.2rem" mt="0.1rem" />
          <VStack>{operatingText}</VStack>
        </HStack>
      </VStack>

      <Text fontSize="xs" color="wsGray.500" cursor="pointer" onClick={handleNavigateToStoreInfo}>
        {t('store.more')}
      </Text>
    </HStack>
  );
}
