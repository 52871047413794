import { atom } from 'recoil';

import { FirebaseCustomer } from '~/types';

const customer = atom<FirebaseCustomer | null>({
  key: 'customer',
  default: null,
});

export default customer;
