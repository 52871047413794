import {
  HStack,
  Text,
  Icon,
  Box,
  useDisclosure,
  VStack,
  StackDivider,
} from '@chakra-ui/react';

import { IoCheckmarkSharp, IoChevronDown, IoChevronUp } from 'react-icons/io5';

import { SelectedCategory, ChangeCategoryFn, MenuCategoryList } from '~/pages/Menu/types';

type CategoriesMenuProps = {
  menuCategoryList: MenuCategoryList;
  selectedCategory: SelectedCategory;
  onChangeCategory: ChangeCategoryFn;
};

// 類別選項列表 可導向特定類別
export default function CategoriesMenu({
  menuCategoryList,
  selectedCategory,
  onChangeCategory,
}: CategoriesMenuProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const handleClickCategory = (value: string) => {
    onChangeCategory(value, false);
    onClose();
  };
  return (
    <Box bgColor="white" borderBottomWidth="2px" borderColor="wsBlack">
      <HStack
        cursor="pointer"
        justify="space-between"
        px={4}
        py={2}
        align="center"
        onClick={onToggle}
      >
        <Text>{selectedCategory.label}</Text>
        <Icon as={isOpen ? IoChevronDown : IoChevronUp} boxSize="1.2rem" />
      </HStack>
      {isOpen ? (
        <VStack align="stretch" divider={<StackDivider />} spacing={0}>
          {menuCategoryList.map(({ label, value }) => {
            return (
              <HStack
                key={value}
                align="center"
                py={2}
                px={4}
                onClick={() => handleClickCategory(value)}
                bgColor={value === selectedCategory.value ? 'wsBlack' : 'white'}
              >
                <Icon
                  as={IoCheckmarkSharp}
                  color={value === selectedCategory.value ? 'white' : 'transparent'}
                  boxSize="1.2rem"
                />
                <Text color={value === selectedCategory.value ? 'white' : 'wsBlack'}>{label}</Text>
              </HStack>
            );
          })}
        </VStack>
      ) : null}
    </Box>
  );
}
