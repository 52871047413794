import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import firebase from 'firebase';

import { initFirebaseCustomer } from '~/firebase/getData';
import { customerListener } from '~/firebase/listener';
import authAtom from '~/recoil/atom/auth';
import customerAtom from '~/recoil/atom/customer';

import { FirebaseCustomer } from '~/types';

export default function useCustomer() {
  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);
  const setCustomer = useSetRecoilState(customerAtom);

  const handleUpdateCustomer = useCallback(
    (firebaseCustomer: FirebaseCustomer | undefined) => {
      if (firebaseCustomer) {
        setCustomer(firebaseCustomer);
      }
    },
    [setCustomer],
  );

  useEffect(() => {
    let customerUnListener: (() => void) | null = null;
    if (auth && storeId) {
      initFirebaseCustomer(storeId, auth);
      customerUnListener = customerListener(storeId, auth, handleUpdateCustomer);
    }
    return () => {
      customerUnListener?.();
    };
  }, [auth, storeId, handleUpdateCustomer]);
}
