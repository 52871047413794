import { useState, useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  isSameDay,
  startOfDay,
  startOfMonth,
  addMinutes,
  differenceInMinutes,
  addDays,
  differenceInDays,
  differenceInMilliseconds,
  setMinutes,
  getTime,
} from 'date-fns';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';
import storeOrderSettingSelector from '~/recoil/selectors/storeOrderSetting';

import useOrderSetting from '~/hooks/useOrderSetting';
import useDay from './useDay';
import useDuration from './useDuration';

import checkOperatingTime from '~/utils/checkOperatingTime';
import { getUpdatedPreOrderAfterDate, getUpdatedPreOrderBeforeDate } from '../utils';

import { MealPickUpType, PickMealTime, PickupTimeRange, DeliveryTimeRange } from '~/types';
import { DayType } from '~/components/PickupSettingModal/Calendar/type';
import { DayCheckMode } from '~/components/PickupSettingModal/types';
import { useTranslation } from 'react-i18next';

const DEFAULT_DAYLIST_LEN = 5;

export default function usePickupTime(mealPickupType: MealPickUpType) {
  const { mealOrderSetting } = useOrderSetting();
  const operatingTime = useRecoilValue(operatingTimeSelector);
  const { allowRealTimeOrder, preOrderSetting } = useRecoilValue(storeOrderSettingSelector);

  const [selectedTime, setSelectedTime] = useState<PickMealTime>(
    mealOrderSetting.pickMealTime || PickMealTime.IMMEDIATELY,
  );
  const [selectedTimeError, setSelectedTimeError] = useState<null | string>(null);
  const [controlMonth, setControlMonth] = useState<Date>(new Date());
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>();
  const [preOrderDate, setPreOrderDate] = useState<Date | null>(null);

  const [preOrderAfterDate, setPreOrderAfterDate] = useState<Date | undefined>();
  const [preOrderBeforeDate, setPreOrderBeforeDate] = useState<Date | undefined>();

  const timeRange = useMemo(() => {
    if (mealPickupType === MealPickUpType.DELIVERY) {
      return DeliveryTimeRange;
    }
    if (mealPickupType === MealPickUpType.PICKUP) {
      return PickupTimeRange;
    }
    return DeliveryTimeRange;
  }, [mealPickupType]);
  const { t } = useTranslation();
  // ================================================================

  useEffect(() => {
    if (!operatingTime) return;

    if (selectedTime === PickMealTime.RESERVATION && preOrderDate) {
      if (!checkOperatingTime(operatingTime, preOrderDate)) {
        setSelectedTimeError('store.pickUpSetting.preorderNotInRange');
        return;
      }

      if (getTime(preOrderDate) < getTime(new Date())) {
        setSelectedTimeError('store.pickUpSetting.error.expiredPreorderTime');
        return;
      }
    }

    if (selectedTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
      setSelectedTimeError('store.operating.close');
      return;
    }

    setSelectedTimeError(null);
  }, [selectedTime, operatingTime, allowRealTimeOrder, preOrderDate]);

  useEffect(() => {
    const { pickMealTime, preOrderDate } = mealOrderSetting;
    setSelectedTime(pickMealTime);
    if (!preOrderDate) return;
    setPreOrderDate(preOrderDate);
    setControlMonth(preOrderDate);
    setSelectedDay(startOfDay(preOrderDate));
    setSelectedDuration(differenceInMinutes(preOrderDate, startOfDay(preOrderDate)));
  }, [mealOrderSetting]);

  useEffect(() => {
    if (!preOrderSetting) return;
    setPreOrderAfterDate((pre) => {
      return getUpdatedPreOrderAfterDate(preOrderSetting);
    });
    setPreOrderBeforeDate((pre) => {
      return getUpdatedPreOrderBeforeDate(preOrderSetting);
    });

    const nowDate = new Date();
    const refreshTimeUnit = (Math.floor(nowDate.getMinutes() / timeRange) + 1) * timeRange;
    const refreshTime = differenceInMilliseconds(
      setMinutes(nowDate, refreshTimeUnit).setSeconds(0),
      nowDate,
    );
    const refreshInterval = setInterval(() => {
      setPreOrderAfterDate((pre) => {
        return getUpdatedPreOrderAfterDate(preOrderSetting);
      });
      setPreOrderBeforeDate((pre) => {
        return getUpdatedPreOrderBeforeDate(preOrderSetting);
      });
      handlePreOrderDateChange(null);
    }, refreshTime);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [preOrderSetting, timeRange]);

  // ============================================================================

  const handlePickupTimeChange = (time: PickMealTime) => {
    setSelectedTime(time);
  };

  const handleMonthChange = (month: Date) => {
    setControlMonth(startOfMonth(month));
  };

  const handleSelectedDayChange = (day: Date) => {
    setSelectedDay(startOfDay(day));
    setPreOrderDate(null);
  };

  const handleSelectedDurationChange = (minutes: number) => {
    if (!selectedDay) return;
    setSelectedDuration(minutes || 0);
    const newDate = addMinutes(selectedDay, minutes || 0);
    setPreOrderDate(newDate);
  };

  const handlePreOrderDateChange = (newDate: Date | null) => {
    setPreOrderDate(newDate);
  };

  // ============================================================================

  const { getCheckDayType } = useDay({
    controlMonth,
    preOrderAfterDate,
    preOrderBeforeDate,
  });

  const weekDayList = useMemo(() => {
    if (!preOrderBeforeDate || !preOrderAfterDate) return;

    let dayArr = [];
    let day = startOfDay(Date.now());
    const defaultDayListLength = DEFAULT_DAYLIST_LEN;
    const maxCanPreOrderDay = differenceInDays(preOrderBeforeDate, preOrderAfterDate) + 2;

    for (let i = 0; i <= maxCanPreOrderDay; i++) {
      if (dayArr.length < defaultDayListLength) {
        const dayType = getCheckDayType(day, DayCheckMode.WEEK);

        if (dayType !== DayType.DISABLE) {
          dayArr.push(day);
        }
        day = addDays(day, 1);
      }
    }

    const dayList = dayArr.map((day) => {
      const weeks = [
        'store.pickUpSetting.time.sun',
        'store.pickUpSetting.time.mon',
        'store.pickUpSetting.time.tue',
        'store.pickUpSetting.time.wed',
        'store.pickUpSetting.time.thu',
        'store.pickUpSetting.time.fri',
        'store.pickUpSetting.time.sat',
      ];
      const months = [
        'store.pickUpSetting.month.jan',
        'store.pickUpSetting.month.feb',
        'store.pickUpSetting.month.mar',
        'store.pickUpSetting.month.apr',
        'store.pickUpSetting.month.may',
        'store.pickUpSetting.month.jun',
        'store.pickUpSetting.month.jul',
        'store.pickUpSetting.month.aug',
        'store.pickUpSetting.month.sep',
        'store.pickUpSetting.month.oct',
        'store.pickUpSetting.month.nov',
        'store.pickUpSetting.month.dec',
      ];
      const isToday = isSameDay(day, Date.now());
      return {
        date: day,
        weekDay: isToday ? t('store.pickUpSetting.time.today') : t(weeks[day.getDay()]),
        day: day.getDate().toString(),
        month: t(months[day.getMonth()]),
      };
    });

    return dayList;
  }, [t, getCheckDayType, preOrderAfterDate, preOrderBeforeDate]);

  const { durationOptionList } = useDuration({
    selectedDay,
    timeRange,
    preOrderAfterDate,
    preOrderBeforeDate,
  });

  return {
    selectedTime,
    selectedTimeError,
    controlMonth,
    selectedDay,
    selectedDuration,
    timeRange,
    preOrderDate,
    weekDayList,
    getCheckDayType,
    durationOptionList,
    handlePickupTimeChange,
    handleMonthChange,
    handleSelectedDayChange,
    handleSelectedDurationChange,
    handlePreOrderDateChange,
  };
}
