import { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Text,
  Icon,
  Badge,
  Spinner,
  Center,
} from '@chakra-ui/react';
import PageContainer from '~/container/PageContainer';
import { UserInfo, UserDiscount, CouponList, PointList, EmptyDiscount } from './components';
import BackwardBar from '~/components/BackwardBar';
import Title from '~/components/Title';
import LoginModal from '~/components/LoginModal';

import useCouponList from '~/hooks/useCouponList';

import CouponIcon from '~/icons/Coupon';
import PointIcon from '~/icons/Point';

import { FetchingState } from '~/types';

import { useTranslation } from 'react-i18next';

export default function Discount() {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const { couponList, fetchingState } = useCouponList();

  const { t } = useTranslation();

  const handleBack = () => {
    if (groupId) {
      navigate(`/${storeId}/group/${groupId}`);
      return;
    }
    navigate(`/${storeId}`);
  };

  const publishCoupons = useMemo(() => {
    return couponList.filter(({ allowDisplay }) => allowDisplay);
  }, [couponList]);

  return (
    <>
      <PageContainer hideHeader hideFooter>
        <BackwardBar title={t('backMenu')} onBack={handleBack} />
        <Title title={t('discount.rewards')} />
        <VStack align="stretch" p={4} spacing={3}>
          <UserInfo />
          {/* <UserDiscount earnedDiscount={100} currentPoint={30} /> */}
          {/* <Tabs isFitted colorScheme="wsBlack"> */}
          <Tabs colorScheme="wsBlack">
            <TabList borderBottomWidth="1px" borderColor="wsGray.400">
              {/* <Tab
                _focus={{
                  boxShadow: 'none',
                }}
              > */}
              <Tab
                _selected={{
                  borderWidth: '0',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <HStack align="center">
                  <Icon as={CouponIcon} boxSize="1.5rem" />
                  <Text fontWeight="bold">
                    {t('discount.coupon')}
                    {publishCoupons.length > 0 && (
                      <Badge
                        variant="solid"
                        ml={1}
                        bgColor="wsRed.600"
                        borderRadius="50%"
                        colorScheme="wsRed.600"
                      >
                        {publishCoupons.length}
                      </Badge>
                    )}
                  </Text>
                </HStack>
              </Tab>
              {/* <Tab
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <HStack align="center">
                  <PointIcon boxSize="1.5rem" />
                  <Text fontWeight="bold">集點卡</Text>
                </HStack>
              </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel p={0} pt={4}>
                {fetchingState === FetchingState.LOADING && (
                  <Center>
                    <Spinner size="xl" />
                  </Center>
                )}

                {fetchingState === FetchingState.SUCCESS && publishCoupons.length > 0 && (
                  <CouponList couponList={publishCoupons} />
                )}

                {fetchingState !== FetchingState.LOADING && publishCoupons.length === 0 && (
                  <EmptyDiscount />
                )}
              </TabPanel>
              {/* <TabPanel p={0} pt={4}>
                <PointList />
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </VStack>
      </PageContainer>

      <LoginModal />
    </>
  );
}
